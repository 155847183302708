// ActionsCarousel.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './ActionsCarousel.module.css';

// Import event images
import eventImage1 from '../assets/images/event-image.png';
import eventImage2 from '../assets/images/event-image2.png';
import eventImage3 from '../assets/images/event-image3.png';
import defaultImage from '../assets/images/event-image.png'; // Add a default image
import { getProfilePictureUrl } from '../utils/getProfileUrl';

const ActionsCarousel = () => {
  const [apiItems, setApiItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_API_URL;

  // Default items in case API response is empty or fails
  const defaultItems = [
    {
      id: 'default1',
      title: "General Dental Conference",
      date: "15th July, 2024",
      by: "Dr. Roosevelt Pitt",
      image: eventImage1,
    },
    {
      id: 'default2',
      title: "Hygiene Management",
      date: "20th August, 2024",
      by: "Dr. Anna Will",
      image: eventImage2,
    },
    {
      id: 'default3',
      title: "Financial Conference",
      date: "25th September, 2024",
      by: "Dr. Roosevelt Pitt",
      image: eventImage3,
    },
  ];

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${apiUrl}/events/headlines`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if response data exists and is an array
        if (response.data && Array.isArray(response.data)) {
          // Map the API response to match the carousel's item structure
          const mappedItems = await Promise.all(response.data.map(async (item) => {
            const event = item.event;

            // Fetch organiser's name based on organiserId
            let organiserName = 'Unknown';
            let profilePhoto = ''
            if (event.organiserId) {
              try {
                const organiserResponse = await axios.get(`${apiUrl}/users/${event.organiserId}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                const organiser = organiserResponse.data;
                // profilePhoto = 'https://'+organiserResponse.data.profilePhotoFile.fileUrl;
                profilePhoto = await getProfilePictureUrl(organiserResponse.data);
                organiserName = `${organiser.firstName} ${organiser.lastName}`;
              } catch (error) {
                console.error(`Error fetching organiser (${event.organiserId}) details:`, error);
              }
            }

            return {
              id: event.id || `event-${Math.random()}`, // Ensure each item has a unique ID
              title: event.title || 'No Title',
              date: event.startDate ? moment(event.startDate).format('Do MMMM, YYYY') : 'No Date',
              by: organiserName,
              image: event.eventsDisplayPicture || profilePhoto || defaultImage , // Use event's image or default
            };
          }));

          setApiItems(mappedItems);
        } else {
          setApiItems([]); // Set to empty array if data is not as expected
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        setIsError(true);
        setApiItems([]); // Fallback to default items on error
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, [apiUrl, token]);

  // Determine which items to display
  const itemsToDisplay = apiItems.length > 0 ? apiItems : defaultItems;

  // Define carousel settings
  const settings = {
    dots: false,
    arrows: false,
    infinite: itemsToDisplay.length > 1, // Infinite only if more than one item
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: itemsToDisplay.length > 1, // Autoplay only if more than one item
    autoplaySpeed: 3000,
  };

  // Function to trim text
  const trimText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  if (isLoading) {
    return <div className={styles.loading}>{/* You can add a spinner here */}Loading...</div>;
  }

  return (
    <div className={styles.carouselContainer}>
      <Slider {...settings}>
        {itemsToDisplay.map((item) => (
          <div
            key={item.id}
            className={styles.carouselItem}
            onClick={() => navigate(`/mosaik-calendar/event/${item.id}`)}
            style={{ cursor: 'pointer' }}
          >
            <div className={styles.eventDetailsOne}>
              <h4 className={styles.h4main}>Actions</h4>
              <p className={styles.pstyles}>{item.date}</p>
            </div>
            <div className={styles.eventDetails}>
              <img src={item.image} alt="Event" className={styles.eventImage} />
            </div>
            <div className={styles.eventDetailsTwo}>
              {/* Apply the trimText function to the title */}
              <h3 className={styles.h3main}>{trimText(item.title, 18)}</h3>
              <p className={styles.pstyles}>by {item.by}</p>
            </div>
          </div>
        ))}
      </Slider>
      {isError && (
        <div className={styles.error}>
          {/* Optionally display an error message */}
          {/* Failed to load events. Showing default events. */}
        </div>
      )}
    </div>
  );
};

export default ActionsCarousel;
