import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MosaikFooter from '../MosaikFooter';
import styles from './InstructionDetail.module.css'; // Ensure this import points to the correct CSS file
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import userImage from '../../assets/images/assigned-by1.png';
import { useTranslation } from 'react-i18next';

const InstructionDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checklists, setChecklists] = useState([
    { id: 1, title: 'Share Digital Libraries', checked: false },
    { id: 2, title: 'Explain General Rules', checked: true },
    { id: 3, title: 'Quality Assurances', checked: true },
  ]);
  const [showIncompleteForm, setShowIncompleteForm] = useState(false);
  const [incompleteReason, setIncompleteReason] = useState('');
  const [attachment, setAttachment] = useState(null);

  const toggleChecklist = (id) => {
    setChecklists((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const markTaskAsDone = () => {
    // Implement your logic to mark the task as done
    alert(t('Task marked as done!'));
    navigate('/tasks'); // Redirect to the tasks homepage
  };

  const handleIncompleteSubmit = () => {
    // Implement the logic for handling task marked as incomplete
    alert(t('Task marked as incomplete: {{reason}}', { reason: incompleteReason }));
    navigate('/tasks'); // Redirect to the tasks homepage
  };

  const handleFileChange = (event) => {
    setAttachment(event.target.files[0]);
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Task Details')} />
      <div className={styles.container}>
        <div className={styles.taskHeader}>
          <div className={styles.taskInfo}>
            <span className={`${styles.priorityTag} ${styles.high}`}>{t('High')}</span>
            <span className={styles.categoryTag}>{t('Daily')}</span>
          </div>
          <h2>
            {t(
              'Rechecking of all dental operational hygiene practices with new interns'
            )}
          </h2>
          <div className={styles.taskMeta}>
            <p>
              <strong>{t('Location:')}</strong> {t('Meeting Room A')}
            </p>
          </div>
          <div className={styles.taskDate}>
            <img src={userImage} alt={t('Assigned By')} className={styles.userImage} />
            <div>
              <p>
                <strong>{t('Assigned by:')}</strong>
              </p>
              <h4>{t('Kevin J')}</h4>
            </div>
            <div className={styles.dateContainer}>
              <img
                src={require('../../assets/images/calendar-vector.png')}
                alt={t('Calendar')}
                className={styles.calendarVector}
              />
              <p className={styles.time}>4:30 PM</p>
              <p className={styles.date}>10th July 2024</p>
            </div>
          </div>
        </div>

        <div className={styles.taskDetails}>
          <h3>{t('Task Details:')}</h3>
          <p>
            {t(
              'Ensure that practices are shared regularly with all new team interns. Explain them the priority of having these things done at time.'
            )}
          </p>
        </div>

        <div className={styles.checkLists}>
          <h3>{t('Check Lists:')}</h3>
          {checklists.map((item) => (
            <div key={item.id} className={styles.checklistItem}>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => toggleChecklist(item.id)}
                />
                <span className={styles.slider}></span>
              </label>
              <span>{t(item.title)}</span>
            </div>
          ))}
        </div>

        {!showIncompleteForm ? (
          <>
            <button className={styles.markDoneButton} onClick={markTaskAsDone}>
              {t('Mark this Task as Done')}
            </button>
            <button
              className={styles.markUndoneButton}
              onClick={() => setShowIncompleteForm(true)}
            >
              {t('or Mark as Undone')}
            </button>
          </>
        ) : (
          <div className={styles.incompleteForm}>
            <h3>{t('Incomplete Reason')}</h3>
            <textarea
              className={styles.incompleteReason}
              placeholder={t('Please state the reason the task is incomplete.')}
              value={incompleteReason}
              onChange={(e) => setIncompleteReason(e.target.value)}
            />
            <div className={styles.attachmentContainer}>
              <label htmlFor="attachment" className={styles.attachmentLabel}>
                {t('Upload an attachment')}
                <img
                  src={require('../../assets/images/attachment-icon.png')}
                  alt={t('Upload')}
                  className={styles.uploadIcon}
                />
              </label>
              <input
                type="file"
                id="attachment"
                className={styles.attachmentInput}
                onChange={handleFileChange}
              />
            </div>
            <button
              className={styles.markIncompleteButton}
              onClick={handleIncompleteSubmit}
            >
              {t('Mark Task as Incomplete')}
            </button>
          </div>
        )}
      </div>
      <MosaikFooter />
    </div>
  );
};

export default InstructionDetail;
