import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MessageCard.module.css';
import userAvatar from '../../assets/images/user-avatar.png';

const MessageCard = ({ user, avatar, date, time, message, hasAttachment, attachmentUrl, attachmentName }) => {
  const { t } = useTranslation();
  return (
    <article className={styles.card}>
      <div className={styles.cardContent}>
        <header className={styles.userInfo}>
          <div className={styles.avatarContainer}>
            <img loading="lazy" src={avatar || userAvatar} alt={t("{{name}}'s avatar", { name: user.name })} className={styles.avatar} />
            <div className={styles.divider} />
          </div>
          <div className={styles.userDetails}>
            <h2 className={styles.userName}>{user.name}</h2>
            <p className={styles.userRole}>{user.role}</p>
          </div>
        </header>

        <p className={styles.message}>
          {message}
        </p>

        <div className={styles.dateTime}>
          <time className={styles.date}>{date}</time>
          <time className={styles.time}>{time}</time>
        </div>

        {hasAttachment && attachmentUrl && (
          <div className={styles.attachmentContainer}>
            {/* <FileDisplay fileUrl={attachmentUrl} fileName={attachmentName} /> */}
          </div>
        )}
      </div>
    </article>
  );
};

export default MessageCard;
