import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import styles from './ResetPassword.module.css'; // Ensure this CSS file exists with proper styling
import lockIcon from '../assets/icons/lock-icon.png';
import visibilityIcon from '../assets/images/visibility-icon.png';
import logo from '../assets/images/logo.png';
import backIcon from '../assets/icons/back-icon.png';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL; // Get the base API URL

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const resetToken = localStorage.getItem('accessToken'); // Retrieve the reset token from local storage

    if (!resetToken) {
      setError('Reset token not found. Please try the reset process again.');
      return;
    }

    try {
      // Make an API request to reset the password
      const response = await axios.post(
        `${apiUrl}/auth/reset-password`,
        {
          password,  // New password
          // email: location.state.email,  // Pass the email from location state
        },
        {
          headers: {
            Authorization: `Bearer ${resetToken}`, // Send the reset token in the Authorization header
          },
        }
      );

      if (response.data.result === true) {
        alert('Password reset successfully!');
        navigate('/login'); // Navigate to login page after successful password reset
      } else {
        setError('Failed to reset password. Please try again.');
      }
    } catch (error) {
      console.error('Error resetting password:', error?.response?.data || error.message);
      setError(`Failed to reset password. ${error?.message}`);
    }
  };

  return (
    <main className={styles.resetPasswordContainer}>
      <img src={logo} alt="Mosaik Logo" className={styles.logo} />
      <section className={styles.resetPasswordCard}>
        <div className={styles.header}>
          <img src={backIcon} alt="Go back" className={styles.backIcon} onClick={() => navigate('/otp-verification')} />
          <h1 className={styles.heading}>Create New Password</h1>
        </div>
        <div className={styles.divider}></div>
        <p className={styles.instructionText}>
          Please set a new password, we suggest using a strong password for your mosaik account.
        </p>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <div className={styles.inputField}>
            <img src={lockIcon} alt="Lock icon" className={styles.inputIcon} />
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              className={styles.inputText}
            />
            <img
              src={visibilityIcon}
              alt="Toggle visibility"
              className={styles.visibilityIcon}
              onClick={togglePasswordVisibility}
            />
          </div>
          <div className={styles.inputField}>
            <img src={lockIcon} alt="Lock icon" className={styles.inputIcon} />
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className={styles.inputText}
            />
            <img
              src={visibilityIcon}
              alt="Toggle visibility"
              className={styles.visibilityIcon}
              onClick={toggleConfirmPasswordVisibility}
            />
          </div>
          {error && <p className={styles.errorText}>{error}</p>}
          <button type="submit" className={styles.submitButton}>
            Save Password
          </button>
        </form>
      </section>
    </main>
  );
};

export default ResetPassword;
