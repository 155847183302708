import React, { useEffect, useState } from "react";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import { useNavigate } from "react-router-dom";
import MosaikFooter from "../MosaikFooter";
import styles from "./CreateRepair.module.css";
import axios from "axios";
import { uploadFile } from "../../services/mosaikUploadService";
import { useTranslation } from 'react-i18next';
import spinner from '../../assets/images/spinner.gif';
import checkmarkIcon from '../../assets/icons/check-icon.png'; // Ensure you have this icon
import moment from 'moment'; // Ensure moment is installed: npm install moment
import { dummyImage } from '../../constants'; // Ensure you have a dummy image path
import { getRepairImageUrl } from "../../utils/getFileUrl";

const CreateRepair = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate();

  const [products, setProducts] = useState([{ id: 1 }]);
  const [supplierList, setSupplierList] = useState([]);
  const userid = localStorage.getItem("user");
  const id = userid ? JSON.parse(userid).id : null;
  const [userId] = useState(id);
  const [files, setFiles] = useState({});
  const [completionDate, setCompletionDate] = useState("");
  const [priority, setPriority] = useState("low");
  const apiUrl = process.env.REACT_APP_API_URL;

  const [loading, setLoading] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [createdRepairIds, setCreatedRepairIds] = useState([]);
  const [repairDetails, setRepairDetails] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const storedData = localStorage.getItem("user");
        const token = storedData ? JSON.parse(storedData).accessToken : null;

        if (token) {
          const response = await axios.get(`${apiUrl}/suppliers`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setSupplierList(response.data);
        }
      } catch (error) {
        console.error(t("Error fetching suppliers:"), error);
      }
    };

    fetchSuppliers();
  }, [apiUrl]);

  const handleFileChange = (e, productId) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => ({
      ...prevFiles,
      [productId]: selectedFiles,
    }));
  };

  const handleCreateRepair = async () => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem("accessToken");
    const createdIds = [];

    try {
      for (const product of products) {
        const itemName = document.querySelector(`#itemName-${product.id}`).value;
        const description = document.querySelector(`#description-${product.id}`).value || null;
        const location = document.querySelector(`#location-${product.id}`).value;
        const fileIds = [];
        const images = files[product.id] || [];

        for (const file of images) {
          const fileResponse = await uploadFile(file, "repair", file.name);
          fileIds.push(fileResponse.id);
        }

        const statusEventHistory = [
          {
            status: "pending",
            eventDateTime: new Date().toISOString(),
          },
        ];

        const repairPayload = {
          location,
          description,
          itemName,
          status: "pending",
          requestedCompletionDate: completionDate,
          priority,
          statusEventHistory,
        };

        if (fileIds.length > 0) {
          repairPayload.files = {
            connect: fileIds.map((id) => ({ id })),
          };
        } else {
          delete repairPayload.files;
        }

        const repairResponse = await axios.post(`${apiUrl}/repairs`, repairPayload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const repairId = repairResponse.data.id;
        createdIds.push(repairId);
      }

      setCreatedRepairIds(createdIds);

      if (createdIds.length > 0) {
        await fetchRepairDetails(createdIds, token);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error(t("Error uploading files or creating repair:"), error);
      setError(t("An error occurred while submitting repairs. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const fetchRepairDetails = async (repairIds, token) => {
    try {
      const requests = repairIds.map(id =>
        axios.get(`${apiUrl}/repairs/detail/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
  
      const responses = await Promise.all(requests);
      const details = await Promise.all(
        responses.map(async (response) => {
          const repair = response.data;
          const imageUrl = await getRepairImageUrl(repair);
          return { ...repair, imageUrl };
        })
      );
      setRepairDetails(details);
    } catch (err) {
      console.error(t('Failed to fetch repair details:'), err);
      setError(t('Failed to fetch repair details. Please try again later.'));
    }
  };
  

  const addProduct = () => {
    if (products.length < 10) {
      setProducts([...products, { id: products.length + 1 }]);
    }
  };

  const removeProduct = (id) => {
    setProducts(products.filter((product) => product.id !== id));
  };

  const handleViewRepair = (repairId) => {
    navigate(`/repair-detail/${repairId}`); // Navigate to the repair detail page dynamically
  };

  const handleRepairs = () => {
    navigate('/mosaik-repairs');
  };

  const formatDate = (dateString) => {
    return moment(dateString).isValid() ? moment(dateString).format('MMMM Do, YYYY') : t('Invalid Date');
  };

  return (
    <div className={styles.container}>
      <MosaikHeaderWithBack pageTitle={t("Create New Repair")} />
      <div className={styles.repairForm}>
        <h1>{t("New Repair")}</h1>
        {products.map((product, index) => (
          <div key={product.id} className={styles.productCard}>
            <div className={styles.productHeader}>
              <h3>{t("Item")} #{index + 1}</h3>
              {products.length > 1 && (
                <button
                  onClick={() => removeProduct(product.id)}
                  className={styles.removeButton}
                >
                  {t("Remove")}
                </button>
              )}
            </div>
            <input
              id={`itemName-${product.id}`}
              type="text"
              placeholder={t("Item Name")}
              required
            />
            <input
              id={`description-${product.id}`}
              type="text"
              placeholder={t("Description")}
              required
            />
            <input
              id={`location-${product.id}`}
              type="text"
              placeholder={t("Location")}
            />
            <div className={styles.imageUploadSection}>
              <div className={styles.repairImages}>
                {files[product.id]?.map((file, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt={t(`Uploaded ${index + 1}`)}
                    className={styles.uploadedImage}
                  />
                ))}
              </div>
              <label>{t("Repair Pictures")}</label>
              <label className={styles.uploadLabel}>
                <input
                  type="file"
                  multiple
                  onChange={(e) => handleFileChange(e, product.id)}
                />
              </label>
            </div>
          </div>
        ))}
        {products.length < 10 && (
          <button onClick={addProduct} className={styles.addButton}>
            {t("+ Add another")}
          </button>
        )}
        <div className={styles.dateAndPrioritySection}>
          <label>{t("Desired Completion Date")}</label>
          <input
            type="date"
            min={new Date().toISOString().split("T")[0]}
            value={completionDate}
            onChange={(e) => setCompletionDate(e.target.value)}
            className={styles.dateInput}
          />
          <label>{t("Repair Priority")}</label>
          <select
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            className={styles.prioritySelect}
          >
            <option value="">{t("Select Priority")}</option>
            <option value="high">{t("High")}</option>
            <option value="medium">{t("Medium")}</option>
            <option value="low">{t("Low")}</option>
          </select>
        </div>
        <button onClick={handleCreateRepair} className={styles.submitButton}>
          {t("Submit a new Repair Request")}
        </button>
      </div>
      <MosaikFooter />

      {/* Spinner */}
      {loading && (
        <div className={styles.spinnerContainer}>
          <img src={spinner} alt={t("Loading...")} className={styles.spinner} />
        </div>
      )}

      {/* Success Popup */}
    {/* Success Popup */}
{showSuccessPopup && (
  <div className={styles.popup}>
    <div className={styles.popupContent}>
      <img src={checkmarkIcon} alt={t('Success')} className={styles.checkmark} />
      <h2>{t("Repairs Submitted Successfully!")}</h2>
      <p>{t("Your Repair request(s) have been successfully registered. Please find the repair details below.")}</p>
      {repairDetails.map(repair => (
        <div key={repair.id} className={styles.repairSummary}>
          {/* Product Image */}
          <img 
            src={repair.imageUrl} 
            alt={t('Product')} 
            className={styles.productImage} 
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = dummyImage;
            }}
          />
          
          {/* Repair Details */}
          <div className={styles.repairDetails}>
            <h4>{t('Item Name')}: {repair.itemName || t('N/A')}</h4>
            <p>{t('Description')}: {repair.description || t('N/A')}</p>
            <p>{t('Location')}: {repair.location || t('N/A')}</p>
            {/* Uncomment and update other fields as needed
            <p>{t('Priority')}: {t(repair.priority.charAt(0).toUpperCase() + repair.priority.slice(1))}</p>
            <p>{t('Requested Completion Date')}: {formatDate(repair.requestedCompletionDate)}</p>
            <p>{t('Status')}: {t(repair.status.charAt(0).toUpperCase() + repair.status.slice(1))}</p>
            <p>{t('Submitted On')}: {formatDate(repair.createdAt)}</p> 
            */}
          </div>
          <button onClick={()=>handleViewRepair(repair?.id)} className={styles.popupViewButton}>
            {t("View")}
          </button>
        </div>
      ))}
      {error && <p className={styles.errorMessage}>{error}</p>}
      <div className={styles.popupButtons}>
        <button onClick={handleRepairs} className={styles.popupButton}>
          {t("Repairs")}
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default CreateRepair;
