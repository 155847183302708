// ManualItem.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ManualItem.module.css";
import defaultImage from "../../assets/images/placeholder.webp";
import menuIcon from "../../assets/images/menu-icon.png"; // Ensure correct path
import RolesManager from '../RolesManager'; // Ensure correct path
import { useTranslation } from 'react-i18next';

function ManualItem({
  id,
  file,
  title,
  category,
  createdAt,
  content,
  name,
  onDelete,
  imageUrl
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRolesManagerOpen, setIsRolesManagerOpen] = useState(false);

  // Replace with actual logic to determine if the user is admin or owner
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdminOrOwner = user?.id === content?.ownerId;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleView = () => {
    navigate(`/mosaik-library/manual/${id}`);
    setIsMenuOpen(false);
  };

  const handlePermissions = () => {
    setIsRolesManagerOpen(true);
    setIsMenuOpen(false);
  };

  const handleDelete = () => {
    if (window.confirm(t('Are you sure you want to delete this manual?'))) {
      onDelete();
    }
  };

  const closeRolesManager = () => {
    setIsRolesManagerOpen(false);
  };

  const libraryImage = file?.fileUrl;
  const displayImage = libraryImage ? `https://${libraryImage}` : defaultImage;

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
      .replace(/,/, "");
  };

  // Safely capitalize words
  const safeCapitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <article className={styles.manualItem}>
      <img
        src={imageUrl}
        alt="Manual"
        className={styles.manualImage}
        onClick={handleView}
      />

      <div className={styles.manualInfo} onClick={handleView}>
        <h3 className={styles.manualTitle}>{safeCapitalize(name)}</h3>
        <p className={styles.manualCategory}>
          {safeCapitalize(content?.categoryType)}
        </p>
      </div>

      <div className={styles.manualActions}>
        {/* Manual Date */}
        <div className={styles.manualDate}>
          <span>{formatDate(createdAt)}</span>
        </div>


        {/* Menu Button */}
        {isAdminOrOwner && (
          <div className={styles.menuContainer}>
            <button
              className={styles.menuButton}
              onClick={toggleMenu}
              aria-label="More options"
            >
              <img src={menuIcon} alt="Menu" className={styles.menuIcon} />
            </button>
            {isMenuOpen && (
              <div className={styles.dropdownMenu}>
                {isAdminOrOwner && (
                  <button
                    className={styles.dropdownItem}
                    onClick={handlePermissions}
                  >
                    Permissions
                  </button>
                )}
                <button className={styles.dropdownItem} onClick={handleDelete}>
                  Delete
                </button>
              </div>
            )}
          </div>
        )}

        {/* Roles Manager Modal */}
        {isRolesManagerOpen && (
          <div className={styles.modalOverlay} onClick={closeRolesManager}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <RolesManager
                onClose={closeRolesManager}
                resourceId={id}
                resourceType={"libraryContent"}
              />
            </div>
          </div>
        )}
      </div>
    </article>
  );
}

export default ManualItem;
