// src/hooks/useAdminMode.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useAdminMode = () => {
  const [adminMode, setAdminMode] = useState(false);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null); // To handle errors
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        console.error('No access token found in localStorage.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          `${apiUrl}/auth/get-loggedin-user`,
          null, // No data payload
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const userInfo = response.data;
        const roles = userInfo.roles || [];

        if (roles.includes('admin') || roles.includes('poManager') || roles.includes('owner')) {
          setAdminMode(true);
        } else {
          setAdminMode(false);
        }
        // if (roles.includes('employee')) {
        //     setAdminMode(true);
        //   } else {
        //     setAdminMode(false);
        //   }
      } catch (err) {
        console.error('Error fetching user info:', err);
        setError(err);
        setAdminMode(false);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [apiUrl]);

  return { adminMode, loading, error };
};

export default useAdminMode;
