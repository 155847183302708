// src/components/chats/ChatInformation.js

import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import MosaikFooter from "../MosaikFooter";
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Typography,
  Avatar,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import GroupIcon from '@mui/icons-material/Group';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import HideImageIcon from '@mui/icons-material/VisibilityOff';

function ChatInformation() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Snackbar state for notifications
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success'); // 'success' | 'error' | 'warning' | 'info'

  const handleGoBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  const handleMessageClick = () => {
    // Logic to initiate a message can be added here
    navigate(-1);
  };

  const handlePhoneClick = () => {
    window.location.href = 'tel:+923305320050'; // Dials the test phone number
  };

  const handleMuteUser = () => {
    // Logic to mute the chat goes here
    // For now, simply show a notification
    setSnackbarMessage(t('Chat has been muted.'));
    setSnackbarSeverity('info');
    setOpenSnackbar(true);
  };

  const handleViewAllMedia = () => {
    navigate('/chat-information/:id/chat-media'); // Replace with the actual path to your Chat's Media detail page
  };

  const handleHideHistory = () => {
    // Logic to delete all chat history goes here
    // For now, simply show a notification
    setSnackbarMessage(t('Chat history has been hidden.'));
    setSnackbarSeverity('info');
    setOpenSnackbar(true);
    navigate(-1);
  };

  // Sample media items array for demonstration
  const mediaItems = [
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
  ];

  // Sample group data with URLs
  const commonGroups = [
    { name: t('Medical Support Group'), url: '/group/medical-support' },
    { name: t('Billing Department'), url: '/group/billing-department' },
  ];

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{ backgroundColor: '#f5f5f5' }}
    >
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        sx={{ backgroundColor: '#fff', borderBottom: '1px solid #ddd' }}
      >
        <IconButton onClick={handleGoBack} aria-label={t("Back")} color="primary">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="div">
          {t('Chat Details')}
        </Typography>
        <IconButton aria-label={t("Menu")} color="primary">
          {/* Placeholder for additional menu options */}
          <ArrowBackIcon sx={{ visibility: 'hidden' }} /> {/* Hidden to maintain spacing */}
        </IconButton>
      </Box>

      {/* Info Block */}
      <Box
        p={2}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          backgroundColor: '#fff',
        }}
      >
        {/* Profile Section */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Avatar
            src="https://via.placeholder.com/150"
            alt={t("User Profile")}
            sx={{ width: 100, height: 100, mb: 2 }}
          />
          <Typography variant="h6">Dr. Johan Jules</Typography>
          <Typography variant="body2" color="text.secondary">
            {t('Billing Department')}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {t('Online!')} · {t('Last Seen')}: 20 {t('Minutes Ago')}
          </Typography>
        </Box>

        {/* Action Buttons */}
        <Box
          display="flex"
          justifyContent="space-around"
          sx={{ mb: 3 }}
        >
          <Button
            variant="outlined"
            startIcon={<MessageIcon />}
            onClick={handleMessageClick}
            sx={{ width: '30%' }}
          >
            {t('Message')}
          </Button>
          <Button
            variant="outlined"
            startIcon={<PhoneIcon />}
            onClick={handlePhoneClick}
            sx={{ width: '30%' }}
          >
            {t('Phone Call')}
          </Button>
          <Button
            variant="outlined"
            startIcon={<VolumeOffIcon />}
            onClick={handleMuteUser}
            sx={{ width: '30%' }}
          >
            {t('Mute User')}
          </Button>
        </Box>

        {/* Shared Media Section */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t('Shared Media')}
          </Typography>
          <Grid container spacing={1}>
            {mediaItems.slice(0, 8).map((media, index) => (
              <Grid item xs={3} key={index}>
                <Box
                  component="img"
                  src={media}
                  alt={`${t('Media')} ${index + 1}`}
                  sx={{
                    width: '100%',
                    height: '100px',
                    objectFit: 'cover',
                    borderRadius: 1,
                  }}
                />
              </Grid>
            ))}
            {mediaItems.length > 8 && (
              <Grid item xs={12}>
                <Button
                  variant="text"
                  onClick={handleViewAllMedia}
                  startIcon={<VisibilityOffIcon />}
                >
                  {t('View All')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        {/* Common Groups Section */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t('Groups in Common')}
          </Typography>
          <List>
            {commonGroups.map((group, index) => (
              <ListItem
                key={index}
                button
                onClick={() => navigate(group.url)}
                sx={{
                  borderBottom: '1px solid #eee',
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <GroupIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={group.name} />
              </ListItem>
            ))}
            {commonGroups.length === 0 && (
              <Typography variant="body2" color="text.secondary">
                {t('No common groups found.')}
              </Typography>
            )}
          </List>
        </Box>

        {/* Hide History Button */}
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteSweepIcon />}
            onClick={handleHideHistory}
          >
            {t('Hide History')}
          </Button>
        </Box>
      </Box>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Footer */}
      <MosaikFooter />
    </Box>
  );
}

export default ChatInformation;
