import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
// import styles from "./TaskList.module.css"; // Create and define appropriate styles
import AssignedOne from "../../assets/images/assigned-by1.png";
import AssignedTwo from "../../assets/images/assigned-by2.png";
import styles from "./MosaikTasks.module.css";
const TaskList = ({
  tasks,
  type, // 'my' for My Tasks or 'assigned' for Assigned Tasks
  handleStatusToggle,
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    if (tasks.length === 0) {
      setShowLoader(true);
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 3000); // 3-second delay
  
      // Cleanup the timer on component unmount or when tasks change
      return () => clearTimeout(timer);
    } else {
      setShowLoader(false);
    }
  }, [tasks]);
  
  return (
    <div className={styles.taskListContainer}>
      {/* Show Loader */}
      {showLoader ? (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
          <p>Loading...</p>
        </div>
      ) : tasks.length > 0 ? (
        /* Render Tasks if Available */
        tasks.map((task) => (
          <div
            key={task.id}
            className={styles.taskItem}
            onClick={() =>
              navigate(
                type === "my" ? `/task-detail/${task.id}` : `/assigned-task-detail/${task.id}`
              )
            }
          >
            <div className={styles.taskDetails}>
              <div className={styles.TasksIntro}>
                <img
                  src={type === "my" ? AssignedOne : AssignedTwo}
                  alt={
                    type === "my"
                      ? task.assignerDetails?.firstName || "Assigned By"
                      : task.assigneeDetails?.firstName || "Assigned To"
                  }
                  className={styles.assigneeImage}
                />
                <div className={styles.tasksImager}>
                  <h4>{type === "my" ? "Assigned by" : "Assigned to"}</h4>
                  <h5>
                    {type === "my"
                      ? task.assignerDetails
                        ? `${task.assignerDetails.firstName} ${task.assignerDetails.lastName}`
                        : "Unknown"
                      : task.assigneeDetails
                      ? `${task.assigneeDetails.firstName} ${task.assigneeDetails.lastName}`
                      : "Unknown"}
                  </h5>
                </div>
              </div>
              <div className={styles.TasksTImer}>
                <p className={styles.taskTime}>{moment(task.createdAt).format("h:mm A")}</p>
              </div>
            </div>
            <p>{task.name || "Unknown Task"}</p>
            <div className={styles.tasksPthings}>
              <div>
                <span
                  className={`${styles.priorityTag} ${
                    styles[task.priority ? task.priority.toLowerCase() : "default"]
                  }`}
                >
                  {task.priority || "N/A"}
                </span>
                <span className={styles.categoryTag}>
                  {task.taskCategory && task.taskCategory.name
                    ? task.taskCategory.name
                    : "No Category"}
                </span>
              </div>
              {/* Uncomment and implement if needed
              {type === "my" && (
                <button
                  className={styles.markAsDoneButton}
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handleStatusToggle(task);
                  }}
                >
                  {task.status === "Completed" ? "Mark as Incomplete" : "Mark as Done!"}
                </button>
              )}
              */}
            </div>
          </div>
        ))
      ) : (
        /* Show No Tasks Found Message after Loader */
        <p>No tasks found for the selected criteria.</p>
      )}
  
      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.paginationButton}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={styles.paginationButton}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
  
};

export default TaskList;
