// OrganisationPage.js
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import styles from "./Orgupdate.module.css";
import MosaikFooter from "../MosaikFooter";
import SuccessImage from "../../assets/images/payment-image.png";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeaderWithoutRoles from "../HeaderWithoutRoles";
import profilePicPlaceholder from "../../assets/images/placeholder.webp";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { uploadFile } from "../../services/mosaikUploadService";
import attachmentIcon from "../../assets/images/attachment-icon.png";
import taskIcon from "../../assets/images/tasks-icon.png";
import { getProfilePictureUrl } from "../../utils/getProfileUrl";
const OrganisationPage = ({ subscriptionId }) => {
  const { t } = useTranslation();
  const [organizationName, setOrganizationName] = useState(t("Default Name"));
  const [profilePicture, setProfilePicture] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { login } = useContext(AuthContext);
  const [planId, setplanId] = useState();

  useEffect(() => {
    const plan = searchParams.get("planId") ?? null;
    if (!plan) {
      window.alert("No Plan Selected");
      navigate("/mosaik-home");
    } else {
      setplanId(plan);
    }
  }, []);

  const handleOrganizationCreation = async () => {
    const token = authState.accessToken;
    setIsCreating(true);
    try {
      const orgData = {
        name: organizationName,
        planId: planId,
        ...(profilePicture && {
          files: { connect: { id: profilePicture.id } },
        }), // Attach logo if available
      };

      // Create organization
      const orgResponse = await axios.post(
        `${apiUrl}/organisations/create-organisation`,
        orgData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (orgResponse) {
        // const organizationId = orgResponse.data.id;
        alert(t("Organization created successfully!"));
        handleCompanyChange(orgResponse.data);
      } else {
        alert(t("Failed to create the organization."));
        navigate("/mosaik-home");
      }

      // Create subscription for the user with the new organization ID
      // const subscriptionData = {
      //   organisation: { id: String(organizationId) },
      //   plan: { id: String(planId) },
      // };
      // await axios.post(
      //   `${apiUrl}/subscriptions/createSubscription`,
      //   subscriptionData,
      //   {
      //     headers: { Authorization: `Bearer ${token}` },
      //   }
      // );
      navigate("/mosaik-home");
    } catch (error) {
      console.error(t("Error creating organization:"), error);
      alert(t("Failed to create the organization."));
      navigate("/mosaik-home");
      setErrorMessage(t("Failed to create the organization."));
    } finally {
      setIsCreating(false);
    }
  };
  const handleLogoPictureChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        const fileData = await uploadFile(
          file,
          "profile",
          "Organisation Picture"
        );
        setProfilePicture({
          fileUrl: fileData?.fileUrl,
          fileName: fileData?.fileName,
          id: fileData?.id,
        });
      } catch (error) {
        console.error("Error uploading profile picture:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleCompanyChange = async (organisation) => {
    console.log(organisation);
    const requestBody = { organisationId: organisation.id };
    const token = authState.accessToken;
    // POST request to switch organisation
    const switchResponse = await axios.post(
      `${apiUrl}/auth/switch-organisation`,
      requestBody,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log("SWITCH ORGANIZATION", switchResponse);
    if (switchResponse.status === 200 || switchResponse.status === 201) {
      localStorage.setItem("switchResponse", switchResponse);
      const newAccessToken =
        switchResponse.data.accessToken || switchResponse.data;

      if (!newAccessToken) {
        console.error(t("No accessToken received from the server."));
        return;
      }
      const updatedSession = await axios.post(
        `${apiUrl}/auth/get-session`,
        {},
        {
          headers: {
            Authorization: `Bearer ${newAccessToken}`,
          },
        }
      );

      // // Update the authState with the new access token
      const updatedAuthState = {
        ...authState,
        user: updatedSession?.data,
        accessToken: newAccessToken,
        currentOrganisationId: organisation.id,
      };
      setAuthState(updatedAuthState);

      // Update localStorage
      localStorage.setItem("accessToken", newAccessToken);
      localStorage.setItem("authState", JSON.stringify(updatedAuthState));
      localStorage.setItem("userInfo", JSON.stringify(updatedSession?.data));
      // Update the UI to show the selected company
      // setSelectedCompany(selectedOrg.organisation);
      console.log("CURRENT AUTH STATE", authState);
      // Fetch user info with the new access token
      await fetchUserInfo();

      // Show alert to the user
      alert(
        t("You switched to {{orgName}} with a new access", {
          orgName: organisation.name,
        })
      );

      // Refresh the page after alert
      window.location.reload();
    } else {
      console.error(
        t("Failed to switch organization:"),
        switchResponse.statusText
      );
    }
  };
  const handlelogoPictureChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setIsUploading(true); // Start the loader
      try {
        const token = localStorage.getItem("accessToken");
        const fileData = await uploadFile(
          file,
          "profile",
          "Organisation Picture"
        );
        // Extract only the 'url' and 'fileName' properties from 'fileData'
        const filteredFileData = {
          fileUrl: fileData?.fileUrl,
          fileName: fileData?.fileName,
          id: fileData?.id,
        };
        console.log("FILE DATA", filteredFileData);
        setProfilePicture(filteredFileData);
      } catch (error) {
        console.error("Error uploading profile picture:", error);
      } finally {
        setIsUploading(false); // Stop the loader after the upload
      }
    }
  };
  const fetchUserInfo = async () => {
    try {
      const token = authState.accessToken;
      const response = await axios.get(
        `${apiUrl}/users/${authState.user?.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const userData = response.data;
      // const profilePictureUrl = userData.profilePhotoFile?.fileUrl
      //   ? userData.profilePhotoFile.fileUrl.startsWith("https")
      //     ? userData.profilePhotoFile.fileUrl
      //     : `https://${userData.profilePhotoFile.fileUrl}`
      //   : profilePicPlaceholder;

      // Use getFileUrl to fetch the actual URL
      let profilePictureUrl = await getProfilePictureUrl(userData);

      // Update authState with the fetched user data
      setAuthState((prevState) => ({
        ...prevState,
        user: {
          ...prevState.user,
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          mobile: userData.mobile,
          profilePicture: profilePictureUrl,
        },
      }));
    } catch (error) {
      console.error(
        t("Error fetching user info after switching organizations:"),
        error
      );
    }
  };
  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  // if (isLoading) {
  //   return (
  //     <div className={styles.mosaikContainer}>
  //       <HeaderWithoutRoles pageTitle={t("Membership Plans")} />
  //       <div className={styles.loadingScreen}>
  //         <p>{t("Loading organization details...")}</p>
  //       </div>
  //       <MosaikFooter />
  //     </div>
  //   );
  // }

  return (
    <div className={styles.mosaikContainer}>
      <HeaderWithoutRoles pageTitle={t("Create Organization")} />
      <div className={styles.updateScreen}>
        <h2>{t("Congratulations!")}</h2>
        <p>{t("You can now create your new organization!")}</p>
        <img
          src={SuccessImage}
          alt={t("Success")}
          className={styles.successImage}
        />
        <div className={styles.NewOrgfields}>
          <p>{t("Let's add your new organization details.")}</p>
          <input
            type="text"
            placeholder={t("Organization Name")}
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            className={styles.orgInput}
          />

          {/* File upload field */}
          <div className={styles.fileInputContainer}>
            <label htmlFor="fileInput" className={styles.customFileLabel}>
              {t("Add Organization Logo Picture")}
            </label>
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              onChange={handleLogoPictureChange}
              className={styles.fileInput}
            />
            {profilePicture?.fileUrl && (
              <img
                src={profilePicture.fileUrl}
                alt="Organisation logo"
                className={styles.attachmentIcon}
              />
            )}
          </div>

          {/* Create button */}
          <button
            className={styles.saveButton}
            onClick={handleOrganizationCreation}
            disabled={!organizationName.trim() || isCreating}
          >
            {isCreating ? t("Creating...") : t("Create Organization")}
          </button>
        </div>
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
      <MosaikFooter />
    </div>
  );
};

export default OrganisationPage;
