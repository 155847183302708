import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './OtpVerification.module.css'; // Adjust import to match your file structure
import logo from '../assets/images/logo.png';
import backIcon from '../assets/icons/back-icon.png';
import axios from 'axios';

const MobileOtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [error, setError] = useState('');
  const inputRefs = useRef([]);  // Initialize the useRef hook for managing input references

  // Redirect to the previous page if the mobile number is not present
  useEffect(() => {
    if (!location.state || !location.state.mobile) {
      navigate('/signup'); // Redirect back if mobile is missing
    } 
  }, [location, navigate]);

  useEffect(() => {
    inputRefs.current[0].focus()
  }, []);
  const handleOtpChange = (element, index) => {
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.value && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    setError('');

    // Automatically verify when all OTP fields are filled
    if (newOtp.every((digit) => digit !== '')) {
      handleVerifyMobileOtp(newOtp.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').slice(0, 6); // Get only the first 6 digits
    const newOtp = [...otp];

    pasteData.split('').forEach((char, index) => {
      if (index < 6) {
        newOtp[index] = char;
        inputRefs.current[index].value = char; // Set value to each input field
      }
    });

    setOtp(newOtp);

    // Automatically verify when all digits are pasted
    if (pasteData.length === 6) {
      handleVerifyMobileOtp(newOtp.join(''));
    }
  };

  const handleVerifyMobileOtp = async (otpCode) => {
    const token = localStorage.getItem('accessToken'); // Retrieve stored OTP token

    if (!token) {
      setError('Token not found. Please request OTP again.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify-otp`,
        {
          purpose: "reset",  // Purpose is 'reset' for password reset
          method: "mobile",  // Method is mobile
          destination: location.state.mobile,  // Mobile number from location state
          otp: otpCode,  // User entered OTP
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,  // Send token in header
          },
        }
      );

      // If OTP is verified successfully
      if (response.data.result === true) {
        localStorage.setItem('accessToken', response.data.accessToken); // Store the new reset token
        navigate('/reset-password', { state: { mobile: location.state.mobile } }); // Proceed to reset password page
      } else {
        setError('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Failed to verify OTP. Please try again.');
    }
  };

  if (!location.state || !location.state.mobile) {
    return null; // Optionally, render nothing while redirecting
  }

  return (
    <main className={styles.signupContainer}>
      <img src={logo} alt="Mosaik Logo" className={styles.logo} />
      <section className={styles.signupCard}>
        <div className={styles.header}>
          <img src={backIcon} alt="Go back" className={styles.backIcon} onClick={() => navigate('/signup')} />
          <h1 className={styles.signupHeading}>Mobile Number Verification</h1>
        </div>
        <div className={styles.divider}></div>
        <p className={styles.instructionText}>
          We sent a six-digit OTP code to {location.state.mobile}
        </p>
        <div className={styles.otpInputContainer} onPaste={handlePaste}>
          {otp.map((data, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              className={styles.otpInputBox}
              value={data}
              onChange={(e) => handleOtpChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        {error && <p className={styles.errorText}>{error}</p>}
        <button onClick={() => handleVerifyMobileOtp(otp.join(''))} className={styles.verifyButton}>
          Verify
        </button>
      </section>
    </main>
  );
};

export default MobileOtpVerification;
