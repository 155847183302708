import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import moment from "moment";
import axios from "axios";
import styles from "./MosaikTasks.module.css";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import MosaikWelcomeScreen from "../MosaikWelcomeScreen";
import welcomeBgimage from "../../assets/images/tasks-bg.jpg";
import welcomeIcon from "../../assets/images/tasks-iconinverted.png";
import profilePicPlaceholder from "../../assets/images/placeholder.webp"; // Import placeholder image
import { useTranslation } from "react-i18next";
import { getProfilePictureUrl } from "../../utils/getProfileUrl";

const MosaikTasks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [overview, setOverview] = useState({
    pending: 0,
    incomplete: 0,
    completed: 0,
  });
  const [myTasks, setMyTasks] = useState([]);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [enrichedMyTasks, setEnrichedMyTasks] = useState([]);
  const [enrichedAssignedTasks, setEnrichedAssignedTasks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [sortOption, setSortOption] = useState("Date");
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const token = localStorage.getItem("accessToken");
  const storedUser = JSON.parse(localStorage.getItem("userInfo"));
  const apiUrl = process.env.REACT_APP_API_URL;

  // Cache for user details to prevent redundant API calls
  const userCache = useRef({});

  // Debug: Verify storedUser and token
  console.log("Stored User:", storedUser);
  console.log("Access Token:", token);

  useEffect(() => {
    fetchOverview();
    fetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch Overview Data
  const fetchOverview = async () => {
    if (!token) {
      console.error(t("Access token is missing."));
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/tasks/overview`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOverview(response.data);
      console.log("Fetched Overview:", response.data);
    } catch (error) {
      console.error(t("Error fetching overview data:"), error.message);
    }
  };

  const calculateProgress = () => {
    const total = overview.pending + overview.incomplete + overview.completed;
    return {
      pending: total ? (overview.pending / total) * 100 : 0,
      incomplete: total ? (overview.incomplete / total) * 100 : 0,
      completed: total ? (overview.completed / total) * 100 : 0,
    };
  };
  console.log("Total", calculateProgress());
  // Fetch all tasks
  const fetchTasks = async () => {
    if (!token) {
      console.error(t("Access token is missing."));
      return;
    }

    if (!storedUser || !storedUser.id) {
      console.error(t("Stored user information is missing or incomplete."));
      return;
    }

    try {
      const tasksResponse = await axios.get(`${apiUrl}/tasks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const allTasks = tasksResponse.data;

      // Debug: Log fetched tasks
      console.log("Fetched Tasks:", allTasks);

      // Filter tasks for the logged-in user (my tasks and assigned tasks)
      const myFilteredTasks = allTasks.filter(
        (task) => task.assignee && task.assignee.id === storedUser.id
      );
      const assignedFilteredTasks = allTasks.filter(
        (task) =>
          task.assigner &&
          task.assigner.id === storedUser.id &&
          (!task.assignee || task.assignee.id !== storedUser.id)
      );

      // Debug: Log filtered tasks
      console.log("My Tasks:", myFilteredTasks);
      console.log("Assigned Tasks:", assignedFilteredTasks);

      // Update tasks state with fetched data
      setTasks(allTasks);
      setMyTasks(myFilteredTasks);
      setAssignedTasks(assignedFilteredTasks);
    } catch (error) {
      console.error(t("Error fetching tasks:"), error.message);
    }
  };

  // Fetch user details by ID with caching
  const fetchUserDetails = async (userId) => {
    if (userCache.current[userId]) {
      // Debug: Log cache hit
      console.log(`Cache hit for user ID: ${userId}`);
      return userCache.current[userId];
    }
    try {
      const userResponse = await axios.get(`${apiUrl}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = userResponse.data;

      // Apply profile picture logic
      // const profilePictureUrl = userData.profilePhotoFile?.fileUrl
      //   ? userData.profilePhotoFile.fileUrl.startsWith("https")
      //     ? userData.profilePhotoFile.fileUrl
      //     : `https://${userData.profilePhotoFile.fileUrl}`
      //   : profilePicPlaceholder;
        let profilePictureUrl = await getProfilePictureUrl(userData)

      const enrichedUser = { ...userData, profilePicture: profilePictureUrl };
      userCache.current[userId] = enrichedUser;

      // Debug: Log fetched user details
      console.log(`Fetched details for user ID: ${userId}`, enrichedUser);

      return enrichedUser;
    } catch (error) {
      console.error(t("Error fetching user details:"), error.message);
      // Return placeholder data on error
      return {
        firstName: t("Unknown"),
        lastName: "",
        profilePicture: profilePicPlaceholder,
      };
    }
  };

  // Fetch user details for each task's assignee or assigner and enrich tasks
  useEffect(() => {
    const fetchUsersForTasks = async () => {
      try {
        const updatedAssignedTasks = await Promise.all(
          assignedTasks.map(async (task) => {
            if (task.assignee && task.assignee.id) {
              const assigneeDetails = await fetchUserDetails(task.assignee.id);
              return { ...task, assigneeDetails };
            } else {
              return {
                ...task,
                assigneeDetails: {
                  firstName: t("Unknown"),
                  lastName: "",
                  profilePicture: profilePicPlaceholder,
                },
              };
            }
          })
        );

        const updatedMyTasks = await Promise.all(
          myTasks.map(async (task) => {
            if (task.assigner && task.assigner.id) {
              const assignerDetails = await fetchUserDetails(task.assigner.id);
              return { ...task, assignerDetails };
            } else {
              return {
                ...task,
                assignerDetails: {
                  firstName: t("Unknown"),
                  lastName: "",
                  profilePicture: profilePicPlaceholder,
                },
              };
            }
          })
        );

        // Debug: Log enriched tasks
        console.log("Enriched Assigned Tasks:", updatedAssignedTasks);
        console.log("Enriched My Tasks:", updatedMyTasks);

        // Update enriched tasks state with fetched user details
        setEnrichedAssignedTasks(updatedAssignedTasks);
        setEnrichedMyTasks(updatedMyTasks);
      } catch (error) {
        console.error(t("Error enriching tasks:"), error.message);
      }
    };

    if (assignedTasks.length > 0 || myTasks.length > 0) {
      fetchUsersForTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedTasks, myTasks]);

  // Toggle task status between Completed and Incomplete
  const handleStatusToggle = async (task) => {
    const newStatus = task.status === "Completed" ? "Pending" : "Completed";
    try {
      await axios.patch(
        `${apiUrl}/tasks/update/${task.id}`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      await fetchTasks(); // Refresh the tasks
      await fetchOverview(); // Refresh the overview
    } catch (error) {
      console.error(t("Error updating task status:"), error.message);
    }
  };

  // useMemo to filter and sort My Tasks
  const filteredTasks = useMemo(() => {
    const priorityOrder = { urgent: 1, high: 2, medium: 3, low: 4 };

    return enrichedMyTasks
      .filter((task) => {
        // Debug: Log task dates
        console.log(
          "Filtering My Task:",
          task.name,
          "Created At:",
          task.createdAt,
          "Selected Date:",
          selectedDate
        );
        return moment(task.createdAt).format("YYYY-MM-DD") === selectedDate;
      })
      .sort((a, b) => {
        switch (sortOption) {
          case "Alphabetical":
            return a.name.localeCompare(b.name);
          case "Priority":
            return (
              priorityOrder[a.priority.toLowerCase()] -
              priorityOrder[b.priority.toLowerCase()]
            );
          default: // Date
            return new Date(b.createdAt) - new Date(a.createdAt); // Most recent first
        }
      });
  }, [enrichedMyTasks, selectedDate, sortOption]);

  // useMemo to filter and sort Assigned Tasks
  const filteredAssignedTasks = useMemo(() => {
    const priorityOrder = { urgent: 1, high: 2, medium: 3, low: 4 };

    return enrichedAssignedTasks
      .filter((task) => {
        // Debug: Log task dates
        console.log(
          "Filtering Assigned Task:",
          task.name,
          "Created At:",
          task.createdAt,
          "Selected Date:",
          selectedDate
        );
        return moment(task.createdAt).format("YYYY-MM-DD") === selectedDate;
      })
      .sort((a, b) => {
        switch (sortOption) {
          case "Alphabetical":
            return a.name.localeCompare(b.name);
          case "Priority":
            return (
              priorityOrder[a.priority.toLowerCase()] -
              priorityOrder[b.priority.toLowerCase()]
            );
          default: // Date
            return new Date(b.createdAt) - new Date(a.createdAt); // Most recent first
        }
      })
      .filter((task) => {
        // Debug: Log search filtering
        console.log(
          "Filtering Assigned Task by Search:",
          task.name,
          "Search Term:",
          searchTerm
        );
        return task.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
  }, [enrichedAssignedTasks, selectedDate, sortOption, searchTerm]);

  // Handle date change
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  // Handle sort change
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  // Handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const progress = calculateProgress();

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Tasks')} />
      <div className={styles.container}>
        {/* Overview Section */}
        <div className={styles.overviewSection}>
          <h3>{t("Overview")}</h3>
          <div className={styles.circularProgress}>
            <CircularProgressbar
              value={progress.completed}
              text={`${Math.round(progress.completed)}%`}
              styles={buildStyles({
                textColor: "#094F54",
                pathColor: "#094F54",
                trailColor: "#f3752c",
              })}
            />
          </div>
          <div className={styles.progressLegend}>
            <div>
              <span style={{ color: "#f3752c" }}>●</span> {t("Pending Tasks")}:{" "}
              {overview.pending}
               {/* ({Math.round(progress.pending)}%) */}
            </div>
            <div>
              <span style={{ color: "#094F54" }}>●</span> {t("Completed Tasks")}
              : {overview.completed}
            </div>
            <div>
              <span style={{ color: "#C7D7D7" }}>●</span>{" "}
              {t("Incomplete Tasks")}: {overview.incomplete} 
            </div>
          </div>
        </div>

        {/* My Tasks Section */}
        <div className={styles.myTasksSection}>
          <h3>{t("My Tasks")}</h3>
          <div className={styles.datePicker}>
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
          <div className={styles.sortAndFilter}>
            <select
              onChange={handleSortChange}
              value={sortOption}
              className={styles.sortSelect}
            >
              <option value="Date">{t("Sort by Date")}</option>
              <option value="Alphabetical">{t("Sort Alphabetically")}</option>
              <option value="Priority">{t("Sort by Priority")}</option>
            </select>
            <button
              onClick={() => setShowSearch(!showSearch)}
              className={styles.iconButton}
            >
              <AiOutlineSearch />
            </button>
            <button
              onClick={() => navigate("/assign-task")}
              className={styles.iconButton}
            >
              <AiOutlinePlus />
            </button>
          </div>
          {showSearch && (
            <div className={styles.searchField}>
              <input
                type="text"
                placeholder={t("Search tasks...")}
                value={searchTerm}
                onChange={handleSearch}
                className={styles.searchInput}
              />
            </div>
          )}
          {filteredTasks.length === 0 ? (
            <p>{t("No tasks found for the selected filter.")}</p>
          ) : (
            filteredTasks.slice(0, 5).map((task) => (
              <div
                key={task.id}
                className={styles.taskItem}
                onClick={() => navigate(`/task-detail/${task.id}`)}
              >
                <div className={styles.taskDetails}>
                  <div className={styles.TasksIntro}>
                    <img
                      src={
                        task.assignerDetails?.profilePicture ||
                        profilePicPlaceholder
                      }
                      alt={t("Assigned by")}
                      className={styles.assigneeImage}
                    />
                    <div className={styles.tasksImager}>
                      <h4>{t("Assigned by")}</h4>
                      <h5>
                        {task.assignerDetails
                          ? `${task.assignerDetails.firstName} ${task.assignerDetails.lastName}`
                          : t("Unknown")}
                      </h5>
                    </div>
                  </div>
                  <div className={styles.TasksTImer}>
                    <p className={styles.taskTime}>
                      {moment(task.createdAt).format("h:mm A")}
                    </p>
                  </div>
                </div>
                <p>{task.name}</p>
                <div className={styles.tasksPthings}>
                  <div>
                    <span
                      className={`${styles.priorityTag} ${
                        styles[task.priority.toLowerCase()]
                      }`}
                    >
                      {task.priority}
                    </span>
                    <span className={styles.categoryTag}>
                      {task.taskCategory?.name || t("No Category")}
                    </span>
                  </div>
                  <button
                    className={styles.markAsDoneButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStatusToggle(task);
                    }}
                  >
                    {task.status === "Completed"
                      ? t("Mark as Incomplete")
                      : t("Mark as Done!")}
                  </button>
                </div>
              </div>
            ))
          )}
          <button
            className={styles.allTasksButton}
            onClick={() => navigate("/my-tasks")}
          >
            {t("All My Tasks")}
          </button>
        </div>

        {/* Assigned Tasks Section */}
        <div className={styles.assignedTasksSection}>
          <h3>{t("Assigned Tasks")}</h3>
          <div className={styles.sortAndFilter}>
            <select
              onChange={handleSortChange}
              value={sortOption}
              className={styles.sortSelect}
            >
              <option value="Date">{t("Sort by Date")}</option>
              <option value="Alphabetical">{t("Sort Alphabetically")}</option>
              <option value="Priority">{t("Sort by Priority")}</option>
            </select>
            {/* Uncomment the following lines if you want search and assign buttons in Assigned Tasks */}
            {/* <button onClick={() => setShowSearch(!showSearch)} className={styles.iconButton}><AiOutlineSearch /></button>
            <button onClick={() => navigate("/assign-task")} className={styles.iconButton}><AiOutlinePlus /></button> */}
          </div>
          {/* Uncomment the following block if you want the search field in Assigned Tasks */}
          {/* {showSearch && (
            <div className={styles.searchField}>
              <input type="text" placeholder={t('Search tasks...')} value={searchTerm} onChange={handleSearch} className={styles.searchInput} />
            </div>
          )} */}
          {filteredAssignedTasks.length === 0 ? (
            <p>{t("No assigned tasks found for the selected filter.")}</p>
          ) : (
            filteredAssignedTasks.slice(0, 5).map((task) => (
              <div
                key={task.id}
                className={styles.assignedTaskItem}
                onClick={() => navigate(`/assigned-task-detail/${task.id}`)}
              >
                <img
                  src={
                    task.assigneeDetails?.profilePicture ||
                    profilePicPlaceholder
                  }
                  alt={task.assigneeDetails?.firstName || t("Unknown")}
                  className={styles.assigneeImage}
                />
                <div className={styles.assignedTaskDetails}>
                  <h4>
                    {task.assigneeDetails
                      ? `${task.assigneeDetails.firstName} ${task.assigneeDetails.lastName}`
                      : t("Unknown")}
                  </h4>
                  <p>{task.location || t("No Location")}</p>
                </div>
                <div className={styles.priorityLevel}>
                  <span className={styles[task.priority.toLowerCase()]}>
                    {t(task.priority)}
                  </span>
                </div>
              </div>
            ))
          )}
          <button
            className={styles.allAssignedTasksButton}
            onClick={() => navigate("/assigned-tasks")}
          >
            {t("All Assigned Tasks")}
          </button>
        </div>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default MosaikTasks;
