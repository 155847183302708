// src/components/MosaikOrders/MosaikOrders.js

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikWelcomeScreen from "../MosaikWelcomeScreen";
import MosaikFooter from "../MosaikFooter";
import { useTranslation } from "react-i18next";
import welcomeIcon from "../../assets/images/order-iconinverted.png";
import ReceiptIcon from "../../assets/images/receipt-icon.png";
import welcomeBgimage from "../../assets/images/orders-bg.jpg";
import searchIcon from "../../assets/images/search-button.png";
import addIcon from "../../assets/images/plus-button.png";
import calendarIcon from "../../assets/images/calendar-iconinverted.png";
import spinner from "../../assets/images/spinner.gif";
import menuIcon from "../../assets/images/menu-icon.png";
import styles from "./MosaikOrders.module.css";
import useAdminMode from "../../hooks/useAdminMode";
import { getOrderImageUrl } from "../../utils/getFileUrl";
 // Import the new function

const MosaikOrders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [activeTab, setActiveTab] = useState("pending");
  const [openMenuId, setOpenMenuId] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("all");
  const menuRef = useRef(null);

  const token = localStorage.getItem("accessToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo?.id;
  const { adminMode, loading, error } = useAdminMode();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch suppliers from API
  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/suppliers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuppliers(response.data);
    } catch (error) {
      console.error(t("Error fetching suppliers:"), error);
      alert(t("Failed to fetch suppliers. Please try again later."));
    }
  };

  // Fetch orders for the logged-in user based on activeTab and selectedSupplier
  const fetchOrders = async (status, supplierId = null) => {
    setIsLoading(true);
    try {
      // Prepare query parameters
      const params = {
        where: {
          user: {
            id: userId,
          },
          status: status,
        },
      };

      if (supplierId && supplierId !== "all") {
        params.where.supplier = { id: supplierId };
      }

      // Fetch the list of orders with the specified status and supplier
      const response = await axios.get(`${apiUrl}/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      });

      const ordersList = response.data;

      // Fetch order details for each order
      const orderDetailPromises = ordersList.map((order) =>
        axios.get(`${apiUrl}/orders/detail/${order.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );

      const orderDetailsResponses = await Promise.all(orderDetailPromises);
      const orderDetails = orderDetailsResponses.map((res) => res.data);

      // Fetch image URLs for all orders concurrently
      const ordersWithImageUrls = await Promise.all(
        orderDetails.map(async (order) => {
          const imageUrl = await getOrderImageUrl(order);
          return { ...order, imageUrl };
        })
      );

      // Sort the orders by 'createdAt' in descending order
      const sortedOrders = ordersWithImageUrls.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setOrders(sortedOrders);
      setFilteredOrders(sortedOrders);
    } catch (error) {
      console.error(t("Error fetching orders:"), error);
      alert(t("Failed to fetch orders. Please try again later."));
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch suppliers and orders when the component mounts and when dependencies change
  useEffect(() => {
    if (token && userId) {
      fetchSuppliers();
      fetchOrders(activeTab, selectedSupplier);
    }
  }, [token, userId, activeTab, selectedSupplier, apiUrl]);

  // Handle clicks outside the dropdown menu to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    if (openMenuId !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuId]);

  const openOrderDetail = (orderId) => {
    navigate(`/order-detail/${orderId}`);
  };

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const goToCreateNewOrder = () => {
    navigate("/create-order");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const toggleMenu = (orderId) => {
    if (openMenuId === orderId) {
      setOpenMenuId(null);
    } else {
      setOpenMenuId(orderId);
    }
  };

  const handleDelete = async (orderId) => {
    if (window.confirm(t("Are you sure you want to delete this manual?"))) {
      try {
        // Send DELETE request to the API to remove the order
        await axios.delete(`${apiUrl}/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Update the orders state by removing the deleted order
        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );

        // Update the filteredOrders state similarly
        setFilteredOrders((prevFiltered) =>
          prevFiltered.filter((order) => order.id !== orderId)
        );

        // Notify the user of successful deletion
        alert(t("Order deleted successfully."));
      } catch (error) {
        console.error(
          t("Error deleting order:"),
          error.response?.data?.message || error.message
        );
        alert(t("Failed to delete the order. Please try again."));
      } finally {
        setOpenMenuId(null); // Close the menu after deletion attempt
      }
    }
  };

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      const filtered = orders.filter((order) =>
        order.productRefNo.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOrders([...filtered]);
    } else {
      setFilteredOrders(orders);
    }

    setCurrentPage(1);
  }, [searchTerm, orders, t]);

  // Calculate pagination details
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    setSearchTerm("");
    setSelectedSupplier("all"); // Reset supplier filter on tab change
    setOrders([]);
    setFilteredOrders([]);
  };

  // Helper function to get supplier name by ID
  const getSupplierName = (supplierId) => {
    if (!supplierId) {
      return t("Unknown Supplier");
    }
    const supplier = suppliers.find((s) => s.id === supplierId);
    return supplier ? supplier.name : t("Unknown Supplier");
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t("Orders")} />

      {/* Tabs for Pending and Completed Orders */}
      <div className={styles.tabsContainer}>
        <button
          className={`${styles.tabButton} ${
            activeTab === "pending" ? styles.activeTab : ""
          }`}
          onClick={() => handleTabChange("pending")}
        >
          {t("Pending Orders")}
        </button>
        <button
          className={`${styles.tabButton} ${
            activeTab === "completed" ? styles.activeTab : ""
          }`}
          onClick={() => handleTabChange("completed")}
        >
          {t("Recent Orders")}
        </button>
      </div>

      <div className={`${styles.ticketSection}`}>
        <div className={styles.ticketSectionContainerForAdmin}>
          <div className={styles.ticketSectionHeader}>
            <h3>
              {activeTab === "pending"
                ? t("Pending Orders")
                : t("Completed Orders")}
            </h3>
            <div className={styles.sortAndFilter}>
              {/* Sort Dropdown */}
              <select
                className={styles.sortSelect}
                value={selectedSupplier}
                onChange={(e) => setSelectedSupplier(e.target.value)}
              >
                <option value="all">{t("All Suppliers")}</option>
                {suppliers.map((supplier) => (
                  <option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </option>
                ))}
              </select>
              <div className={styles.ticketSearchAndAdd}>
                <button onClick={toggleSearchBar}>
                  <img src={searchIcon} alt={t("Search")} />
                </button>
                <button onClick={goToCreateNewOrder}>
                  <img src={addIcon} alt={t("Add")} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {showSearchBar && (
          <div className={styles.searchField}>
            <input
              type="text"
              placeholder={t("Product REF #...")}
              value={searchTerm}
              onChange={handleSearch}
              className={styles.searchInput}
            />
          </div>
        )}

        {isLoading ? (
          <div className={styles.spinnerContainer}>
            <img
              src={spinner}
              alt={t("Loading...")}
              className={styles.spinner}
            />
          </div>
        ) : filteredOrders.length > 0 ? (
          <>
            {currentOrders.map((order) => {
              return (
                <div key={order.id} className={styles.ticketItem}>
                  <div
                    className={styles.ticketDetails}
                    onClick={() => openOrderDetail(order.id)}
                  >

                    <div className={styles.OrderLister}>
                      {order.imageUrl ? (
                        <img
                          src={order.imageUrl}
                          alt={`${order.productName} ${t("Image")}`}
                          className={styles.OrderImage}
                          loading="lazy"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = ReceiptIcon; // Fallback image
                          }}
                        />
                      ) : (
                        <img
                          src={ReceiptIcon}
                          alt={t("Receipt")}
                          className={styles.ReceiptIcon}
                        />
                      )}
                      <h4>{order.productRefNo}</h4>
                    </div>
                    <div className={styles.ticketCatContainer}>
                      <div className={styles.ticketDate}>
                        <img
                          src={calendarIcon}
                          alt={t("Date")}
                          className={styles.calendarIcon}
                        />
                        <span>
                          {new Date(order.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                      <div className={styles.ticketCategory}>
                        <p>{order.status || t("Ordered")}</p>
                      </div>
                    </div>
                    {/* Uncomment if you want to display supplier details
                    <div className={styles.orderSupplier}>
                      <p>
                        <strong>{t('Supplier')}:</strong> {getSupplierName(order.supplier?.id)}
                      </p>
                    </div>
                    */}
                  </div>

                  {/* Dropdown Menu for Each Order */}
                  <div
                    className={styles.menuContainer}
                    ref={openMenuId === order.id ? menuRef : null}
                  >
                    <button
                      className={styles.menuButton}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the order detail view
                        toggleMenu(order.id);
                      }}
                      aria-label="More options"
                    >
                      <img
                        src={menuIcon}
                        alt="Menu"
                        className={styles.menuIcon}
                      />
                    </button>
                    {openMenuId === order.id && (
                      <div className={styles.dropdownMenu}>
                        <button
                          className={styles.dropdownItem}
                          onClick={() => {
                            openOrderDetail(order.id);
                            setOpenMenuId(null); // Close the menu after action
                          }}
                        >
                          {t("View")}
                        </button>
                        {order.status === "pending" && (
                          <button
                            className={styles.dropdownItem}
                            onClick={() => handleDelete(order.id)}
                          >
                            {t("Delete")}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

            {/* Pagination Controls */}
            <div className={styles.paginationOrders}>
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className={styles.paginationButton}
              >
                {t("Previous")}
              </button>
              <span className={styles.pageInfo}>
                {t("Page")} {currentPage} {t("of")} {totalPages}
              </span>
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className={styles.paginationButton}
              >
                {t("Next")}
              </button>
            </div>
          </>
        ) : (
          <div className={styles.noOrders}>
            <p>{t("No orders found.")}</p>
          </div>
        )}
      </div>

      <div className={styles.submitNewTicketButton}>
        <button onClick={goToCreateNewOrder}>
          <span>+</span> {t("Submit a new")}
        </button>
      </div>

      <MosaikFooter />
    </div>
  );
};

export default MosaikOrders;
