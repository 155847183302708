// src/components/Editor/Mentions.js

import { createReactInlineContentSpec } from '@blocknote/react';

// Define your API URL in the environment variables
const apiUrl = process.env.REACT_APP_API_URL;

// Function to fetch users for mentions
export const fetchUser = async () => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await fetch(`${apiUrl}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch team members');
    }

    const data = await response.json();
    return data; // Expecting an array of users
  } catch (error) {
    console.error('Error fetching team members:', error);
    return []; // Return an empty array on error to prevent issues
  }
};

// The Mention inline content specification
export const Mention = createReactInlineContentSpec(
  {
    type: 'mention',
    propSchema: {
      user: {
        default: 'Unknown',
      },
    },
    content: 'text', // Allow text as content
  },
  {
    render: (props) => (
      <span style={{ backgroundColor: '#8400ff33', padding: '2px 4px', borderRadius: '4px' }}>
        @{props.inlineContent.props.user}
      </span>
    ),
  }
);
