import React from 'react';
import styles from './ProfileCompletion.module.css';
import downArrow from '../assets/images/downarrow.png';
import { useTranslation } from 'react-i18next';
const ProfileCompletion = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.profileCompletionWrapperOuter}>
    <div className={styles.profileCompletionWrapper}>
      <span>{t('Profile Completion')}</span>
      <img src={downArrow} alt="Profile completion status icon" className={styles.statusIcon} />
      </div>
    </section>
  );
};

export default ProfileCompletion;