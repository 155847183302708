// src/components/OrderDetail/OrderDetail.js

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import styles from "./OrderDetail.module.css";
import pendingIcon from "../../assets/images/pending-icon.png";
import placeholderImage from "../../assets/images/placeholder.webp";
import cancelledIcon from "../../assets/images/pending-icon.png"; // Ensure you have this icon
import spinner from '../../assets/images/spinner.gif'; // Import spinner
import useAdminMode from "../../hooks/useAdminMode"; // Import the custom hook
import AdminActions from "./AdminActions"; // Import the new AdminActions component
import { calculatorIcon, emptyTickIcon, lineIcon, tickIcon } from "../../constants";
import { getOrderImageUrl } from "../../utils/getFileUrl";


const TimelineItem = ({
  date,
  icon,
  title,
  description,
  approved,
  onStatusChange,
  isAdmin,
}) => (
  <div
    className={`${styles.timelineItem} ${!approved ? styles.unApproved : ""}`}
  >
    <div className={styles.timelineHeader}>
      <div className={styles.timelineDate}>{date}</div>
      <div className={styles.timelineIcon}>
        <img src={icon} alt={`${title} icon`} className={styles.iconImage} />
        <img
          src={lineIcon}
          alt="Timeline line"
          className={styles.timelineLine}
        />
      </div>
      <div className={styles.timelineDetails}>
        <div className={styles.timelineTitle}>{title}</div>
        <div className={styles.timelineDescription}>{description}</div>
      </div>
      {/* Uncomment if you need status change functionality */}
      {/* {isAdmin && (
        <button onClick={onStatusChange} className={styles.changeStatusButton}>
          {t("Change")}
        </button>
      )} */}
    </div>
  </div>
);

const OrderDetail = () => {
  const { t } = useTranslation(); // Use translation hook
  const { id } = useParams();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [timelineItems, setTimelineItems] = useState([
    {
      title: t("Completed"),
      description: t("Your order is completed."),
      approved: false,
      date: "",
      icon: pendingIcon,
    },
    {
      title: t("Delivered"),
      description: t("Your order has been delivered."),
      approved: false,
      date: "",
      icon: pendingIcon,
    },
    {
      title: t("Ordered"),
      description: t("Your order is confirmed."),
      approved: false,
      date: "",
      icon: pendingIcon,
    },
    {
      title: t("Pending"),
      description: t("Your order is pending."),
      approved: false,
      date: "",
      icon: pendingIcon,
    },
    {
      title: t("Draft"),
      description: t("Your order is in draft state."),
      approved: false,
      date: "",
      icon: pendingIcon,
    },
  ]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { adminMode, loading, error } = useAdminMode(); // Use the custom hook
  const apiUrl = process.env.REACT_APP_API_URL;

  const updateTimeline = (eventHistory) => {
    const updatedItems = [
      {
        title: t("Completed"),
        description: t("Your order is completed."),
        approved: false,
        date: "",
        icon: pendingIcon,
      },
      {
        title: t("Delivered"),
        description: t("Your order has been delivered."),
        approved: false,
        date: "",
        icon: pendingIcon,
      },
      {
        title: t("Ordered"),
        description: t("Your order is confirmed."),
        approved: false,
        date: "",
        icon: pendingIcon,
      },
      {
        title: t("Pending"),
        description: t("Your order is pending."),
        approved: false,
        date: "",
        icon: pendingIcon,
      },
      {
        title: t("Draft"),
        description: t("Your order is in draft state."),
        approved: false,
        date: "",
        icon: pendingIcon,
      },
    ];

    const newTimelineItems = updatedItems.map((item) => {
      const matchingEvent = eventHistory.find(
        (event) => event?.status?.toLowerCase() === item.title.toLowerCase()
      );

      let icon = pendingIcon; // Default to pending icon

      if (matchingEvent) {
        // Current status with date uses the main custom icon
        icon = tickIcon;
      }

      return {
        ...item,
        date: matchingEvent
          ? new Date(matchingEvent.eventDateTime).toLocaleString()
          : "",
        approved: !!matchingEvent, // Approved if the status has been completed
        icon,
      };
    });

    setTimelineItems(newTimelineItems);
  };

  const handleChangeStatus = async (newStatus) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error(t("No token found"));
        navigate("/login");
        return;
      }

      const payload = { status: newStatus };
      const response = await axios.patch(
        `${apiUrl}/orders/${id}/status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(t("Order status changed to"), newStatus);

      const updatedOrderDetails = {
        ...orderDetails,
        status: newStatus,
        statusEventHistory: [
          ...orderDetails.statusEventHistory,
          {
            status: newStatus,
            eventDateTime: new Date().toISOString(),
          },
        ],
      };

      setOrderDetails(updatedOrderDetails);
      updateTimeline(updatedOrderDetails.statusEventHistory);
    } catch (error) {
      console.error(t("Error changing order status"), newStatus, error);
      alert(t("Failed to change order status. Please try again."));
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        console.error(t("No token found"));
        navigate("/login");
        return;
      }

      if (id) {
        try {
          const response = await axios.get(`${apiUrl}/orders/detail/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const fetchedOrderDetails = response.data;

          // Fetch the image URL using getOrderImageUrl
          const imageUrl = await getOrderImageUrl(fetchedOrderDetails);

          // Enhance the orderDetails with imageUrl
          const enhancedOrderDetails = {
            ...fetchedOrderDetails,
            imageUrl,
          };

          setOrderDetails(enhancedOrderDetails);

          const eventHistory = fetchedOrderDetails.statusEventHistory || [];
          updateTimeline(eventHistory);
        } catch (error) {
          console.error(t("Error fetching order details"), error);
          navigate("/login");
        }
      } else {
        console.error(t("Order ID is missing"));
      }
    };

    if (!loading && !error) {
      fetchOrderDetails();
    }
  }, [id, navigate, apiUrl, loading, error]);

  const handleCancelOrderClick = () => {
    setShowCancelModal(true);
  };

  const handleConfirmCancel = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error(t("No token found"));
        navigate("/login");
        return;
      }

      const payload = { status: "cancelled" };
      const response = await axios.patch(
        `${apiUrl}/orders/${id}/status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(t("Order cancelled"), response.data);
      alert(t("Order has been cancelled successfully."));
      navigate("/mosaik-orders");
    } catch (error) {
      console.error(t("Error cancelling order"), error);
      alert(t("Failed to cancel the order. Please try again."));
    } finally {
      setShowCancelModal(false);
    }
  };

  if (loading) {
    return (
      <main className={styles.container}>
        <MosaikHeaderWithBack pageTitle={t("Order Detail")} />
        <div className={styles.loader}>
          <img src={spinner} alt={t("Loading...")} className={styles.spinner} />
        </div>
        <MosaikFooter />
      </main>
    );
  }

  if (error) {
    return (
      <main className={styles.container}>
        <MosaikHeaderWithBack pageTitle={t("Order Detail")} />
        <div className={styles.error}>
          {t("Failed to load order information. Please try again later.")}
        </div>
        <MosaikFooter />
      </main>
    );
  }

  return (
    <main className={styles.container}>
      <MosaikHeaderWithBack pageTitle={t("Order Detail")} />
      <div className={styles.OrderHeader}>
        <h1 className={styles.referenceNumber}>
          {t("Ref")} {orderDetails?.productRefNo || "1002457745"}
        </h1>
        <p className={styles.category}>
          {orderDetails?.productName || t("General items")}
        </p>
      </div>
      <div className={styles.OrderDetail}>
        {orderDetails?.product ? (
          <section className={styles.productCard}>
            <div className={styles.productInfo}>
              <img
                src={orderDetails.imageUrl} // Use the fetched imageUrl
                alt={`${orderDetails.productName || t("Unknown Product")} ${t("Image")}`}
                className={styles.productThumbnail}
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = placeholderImage; // Fallback image
                }}
              />
              <div className={styles.divider} />
              <div className={styles.productDetails}>
                <h2 className={styles.productName}>
                  {orderDetails.productName || t("Unknown Product")}
                </h2>
              </div>
              <div className={styles.deliveryDate}>
                <img
                  src={calculatorIcon}
                  alt="Calendar"
                  className={styles.calendarIcon}
                />
                <p>
                  {new Date(orderDetails?.createdAt).toLocaleDateString() ||
                    t("July 22 2024")}
                </p>
              </div>
            </div>
          </section>
        ) : (
          <p>{t("No products found in this order.")}</p>
        )}

        {orderDetails?.supplier && (
          <section>
            <h2 className={styles.sectionTitle}>{t("Supplier Details")}</h2>
            <div className={styles.supplierCard}>
              <div className={styles.supplierInfo}>
                <div className={styles.supplierDetails}>
                  <img
                    src={orderDetails.imageUrl || placeholderImage} // Use imageUrl or placeholder
                    alt={t("Supplier Logo")}
                    className={styles.supplierLogo}
                    loading="lazy"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = placeholderImage;
                    }}
                  />
                  <div className={styles.divider} />
                  <div>
                    <h3 className={styles.supplierName}>
                      {orderDetails.supplier.name || t("Acme Office Supplies")}
                    </h3>
                    <p className={styles.supplierLocation}>
                      {orderDetails.supplier.address || t("Berlin")}
                    </p>
                  </div>
                </div>
                {!adminMode && (
                  <button className={styles.emailButton}>{t("Send Email")}</button>
                )}
              </div>
            </div>
          </section>
        )}

        <section className={styles.orderDetailsHeader}>
          <h2 className={styles.sectionTitle}>{t("Order Details")}</h2>
          <div className={styles.priorityLabel}>
            <span>{t("Priority")}:</span>
            <span className={styles.priorityHigh}>
              {orderDetails?.priority || t("High")}
            </span>
          </div>
        </section>

        <div className={styles.orderDescription}>
          <img
            src={emptyTickIcon}
            alt="Order Icon"
            className={styles.orderIcon}
          />
          <p>{orderDetails?.description || t("Order a new chair")}</p>
        </div>

        <h2 className={styles.sectionTitle}>{t("Order Status")}</h2>

        {orderDetails?.status.toLowerCase() === "cancelled" ? (
          <div className={styles.cancelledMessage}>
            <h3>{t("Your order has been cancelled.")}</h3>
          </div>
        ) : (
          <div className={styles.orderTimeline}>
            <div className={styles.timelineContent}>
              {timelineItems.map((item, index) => (
                <TimelineItem
                  key={index}
                  date={item.date}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  approved={item.approved}
                  onStatusChange={() => handleChangeStatus(item.title.toLowerCase())}
                  isAdmin={adminMode}
                />
              ))}
            </div>
          </div>
        )}

        {adminMode && (
          <AdminActions
            status={orderDetails?.status}
            onChangeStatus={handleChangeStatus}
          />
        )}

        {(orderDetails?.status.toLowerCase() === "pending" ||
          orderDetails?.status.toLowerCase() === "draft") && (
          <>
            <button
              className={styles.cancelOrder}
              onClick={handleCancelOrderClick}
            >
              {t("Cancel Order")}
            </button>

            {showCancelModal && (
              <div className={styles.modal}>
                <div className={styles.modalContent}>
                  <p>{t("Are you sure you want to cancel this order?")}</p>
                  <button
                    onClick={handleConfirmCancel}
                    className={styles.confirmButton}
                  >
                    {t("Yes")}
                  </button>
                  <button
                    onClick={() => setShowCancelModal(false)}
                    className={styles.cancelButton}
                  >
                    {t("No")}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <MosaikFooter />
    </main>
  );
};

export default OrderDetail;
