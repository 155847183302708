import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ReplyComponent.module.css';
import supportAgent from '../../assets/images/support-agent.png';
import ratingStars from '../../assets/images/rating-stars.png';
import plusIcon from '../../assets/images/plus.png';

const FeedbackSection = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.feedbackSection}>
      <div className={styles.feedbackContent}>
        <img loading="lazy" src={supportAgent} alt={t('Support Agent')} className={styles.feedbackAvatar} />
        <div className={styles.feedbackText}>
          <h2 className={styles.feedbackQuestion}>{t("Satisfied with the support's response?")}</h2>
          <p className={styles.rateSupport}>{t('Rate our support:')}</p>
        </div>
        <img loading="lazy" src={ratingStars} alt={t('Rating Stars')} className={styles.ratingStars} />
        <button className={styles.resolveButton}>
          <div className={styles.resolveButtonContent}>
            <img loading="lazy" src={plusIcon} alt={t('Resolve Icon')} className={styles.resolveIcon} />
            <span>{t('Mark as Resolved')}</span>
          </div>
        </button>
      </div>
    </section>
  );
};

export default FeedbackSection;
