import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchGroupInfo } from '../../hooks/useFetchGroupInfo'; 
import styles from './ChatInformation.module.css';
import profilePic from '../../assets/images/group-icon.png';
import backArrowIcon from '../../assets/icons/arrow-back.png';
import MessageIcon from '../../assets/images/message-icon.png';
import MutedIcon from '../../assets/images/muted-icon.png';
import MediaImage from '../../assets/images/media-image.jpg';
import GroupIcon from '../../assets/images/groupmembers-icon.png';
import HideIcon from '../../assets/images/hide-icon.png';

const GroupChatInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { roomInfo, error } = useFetchGroupInfo(id);
  const groupAvatar = roomInfo?.avatar ? `https://${roomInfo?.avatar}` : profilePic;

  const handleGoBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  const handleMessageClick = () => {
    navigate(-1);
  };

  const handleMemberClick = () => {
    navigate(`/groupchat-information/${id}/groupchat-members`);
  };

  const handleMuteUser = () => {
    navigate(-1);
  };

  const handleViewAllMedia = () => {
    navigate(`/groupchat-information/${id}/groupchat-media`);
  };

  const handleHideHistory = () => {
    navigate(-1);
  };

  // Sample media items array for demonstration
  const mediaItems = [
    MediaImage, MediaImage, MediaImage, MediaImage,
    MediaImage, MediaImage, MediaImage, MediaImage,
    MediaImage, MediaImage, MediaImage, MediaImage,
    MediaImage, MediaImage
  ];

  return (
    <div className={styles.chatInformation}>
      <header className={styles.headerContainer}>
        <div className={styles.headerLeft} onClick={handleGoBack}>
          <img src={backArrowIcon} alt={t("Back")} className={styles.backArrow} />
        </div>
        <div className={styles.headerTitle}>
          <h2>{t('Group Info')}</h2>
        </div>
        <div></div>
      </header>

      <div className={styles.infoBlock}>
        <div className={styles.profileSection}>
          <img src={groupAvatar} alt={t("User Profile")} className={styles.profilePic} />
          <div className={styles.userInfo}>
            <h3>{roomInfo?.name ?? ''}</h3>
            <p>{roomInfo?.description ?? ''}</p>
          </div>
        </div>

        <div className={styles.actionButtons}>
          <button className={styles.actionButton} onClick={handleMessageClick}>
            <img src={MessageIcon} alt={t("Message")} />
            <span>{t('Message')}</span>
          </button>
          <button className={styles.actionButton} onClick={handleMemberClick}>
            <img src={GroupIcon} alt={t("group members")} />
            <span>{t('Members')}</span>
          </button>
          <button className={styles.actionButton} onClick={handleMuteUser}>
            <img src={MutedIcon} alt={t("Mute")} />
            <span>{t('Mute')}</span>
          </button>
        </div>

        <div className={styles.sharedMediaSection}>
          <h4>{t('Shared Media')}</h4>
          <div className={styles.sharedMedia}>
            {mediaItems.slice(0, 8).map((media, index) => (
              <img key={index} src={media} alt={`${t('Media')} ${index + 1}`} />
            ))}
            {mediaItems.length > 8 && (
              <button className={styles.viewAllButton} onClick={handleViewAllMedia}>
                {t('View All')}
              </button>
            )}
          </div>
        </div>

        <button className={styles.hideHistoryButton} onClick={handleHideHistory}>
          <img src={HideIcon} alt={t("leave group")} />{t('Leave group')}
        </button>
      </div>
    </div>
  );
};

export default GroupChatInformation;
