import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MosaikFooter from "../MosaikFooter";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import styles from "./TaskDetails.module.css";
import userImage from "../../assets/images/assigned-by1.png";
import { useTranslation } from 'react-i18next';
import { getProfilePictureUrl } from "../../utils/getProfileUrl";

const TaskDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { taskId } = useParams(); // Get task ID from URL parameters
  const [task, setTask] = useState(null);
  const [assignee, setAssignee] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(userImage);
  const token = localStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch task details from API
  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/tasks/detail/${taskId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTask(response.data);

        // Fetch assignee details
        fetchAssigneeDetails(response.data.assignee.id);
      } catch (error) {
        console.error("Error fetching task details:", error);
      }
    };

    const fetchAssigneeDetails = async (assigneeId) => {
      try {
        const response = await axios.get(`${apiUrl}/users/${assigneeId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
       const UserProfilePhoto = await getProfilePictureUrl(response.data);
        setProfilePhoto(UserProfilePhoto)
        setAssignee(response.data);
      } catch (error) {
        console.error("Error fetching assignee details:", error);
      }
    };

    fetchTaskDetails();
  }, [taskId, token]);

  // Handle checklist item toggle and update the occurrence
  const handleChecklistToggle = async (
    occurrenceIndex,
    itemIndex,
    occurrenceId
  ) => {
    const updatedTask = { ...task };
    const checklistItem =
      updatedTask.taskOccurrences[occurrenceIndex].checklist[itemIndex];

    // Toggle the checked state
    checklistItem.checked = checklistItem.checked === "true" ? "false" : "true";

    // Prepare body for the PATCH request
    const updatedChecklist = updatedTask.taskOccurrences[
      occurrenceIndex
    ].checklist.map((item) => ({
      itemName: item.itemName,
      checked: item.checked === "true",
    }));

    const body = {
      checklist: updatedChecklist,
      incompleteReason:
        updatedTask.taskOccurrences[occurrenceIndex].incompleteReason || "",
      status: updatedTask.taskOccurrences[occurrenceIndex].status,
    };

    try {
      // Send PATCH request to update the checklist
      await axios.patch(`${apiUrl}/taskOccurrences/${occurrenceId}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTask(updatedTask); // Update the task state after the PATCH request
    } catch (error) {
      console.error("Error updating checklist:", error);
    }
  };

  // Function to toggle task occurrence status between Complete and Incomplete
  const handleStatusToggle = async (occurrence, occurrenceIndex) => {
    const newStatus =
      occurrence.status === "Completed" ? "Pending" : "Completed";
    const updatedTask = { ...task };

    // Update the status in the local state
    updatedTask.taskOccurrences[occurrenceIndex].status = newStatus;

    try {
      await axios.patch(
        `${apiUrl}/taskOccurrences/${occurrence.id}`, // Adjust URL according to your backend
        { status: newStatus }, // Toggle status between Completed and Pending
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTask(updatedTask); // Update task state after the PATCH request
    } catch (error) {
      console.error("Error updating task occurrence status:", error);
    }
  };

  if (!task) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Task Details')} />
      <div className={styles.container}>
        {/* Task Header Section */}
        <div className={styles.taskHeader}>
          <div className={styles.taskInfo}>
            <span className={`${styles.priorityTag} ${styles[task.priority.toLowerCase()]}`}>
              {t(task.priority)}
            </span>
            <span className={styles.categoryTag}>
              {task.taskCategory?.id || t('No Category')}
            </span>
          </div>
          <h2 className={styles.taskTitle}>{task.name}</h2>
          <div className={styles.taskMeta}>
            <p>
              <strong>{t('Location:')}</strong> {task.location}
            </p>
          </div>
          <div className={styles.taskDate}>
            <div className={styles.assignedInfo}>
              <img src={profilePhoto} alt={t('Assigned By')} className={styles.userImage} />
              <div>
                <p>
                  <strong>{t('Assigned by:')}</strong>
                </p>
                <h4>{task.assigner?.firstName}</h4>
              </div>
            </div>
            <div className={styles.dateContainer}>
              <img
                src={require('../../assets/images/calendar-vector.png')}
                alt={t('Calendar')}
                className={styles.calendarVector}
              />
              <p className={styles.time}>{new Date(task.endDate).toLocaleTimeString()}</p>
              <p className={styles.date}>{new Date(task.endDate).toLocaleDateString()}</p>
            </div>
          </div>
        </div>

        {/* Task Details Section */}
        <div className={styles.taskDetails}>
          <h3>{t('Task Details:')}</h3>
          <p>{task.description}</p>
        </div>

        {/* Task Occurrences and Checklist Section */}
        {task.taskOccurrences.map((occurrence, index) => (
          <div key={occurrence.id} className={styles.occurrenceSection}>
            <h3>{t('Occurrence {{index}}', { index: index + 1 })}</h3>
            <p>
              <strong>{t('Status:')}</strong> {t(occurrence.status)}
            </p>

            {/* Checklist for the occurrence */}
            <div className={styles.checkLists}>
              <h4>{t('Check Lists:')}</h4>
              {occurrence.checklist.map((item, itemIndex) => (
                <div key={itemIndex} className={styles.checklistItem}>
                  <span className={styles.itemName}>{item.itemName}</span>
                  <label className={styles.toggleSwitch}>
                    <input
                      type="checkbox"
                      checked={item.checked === "true"}
                      onChange={() =>
                        handleChecklistToggle(index, itemIndex, occurrence.id)
                      }
                    />
                    <span className={styles.slider}></span>
                  </label>
                </div>
              ))}
            </div>

            {/* Buttons to mark as Done or Incomplete */}
            <div className={styles.statusButtons}>
              <button
                className={styles.markAsDoneButton}
                onClick={() => handleStatusToggle(occurrence, index)}
              >
                {occurrence.status === 'Completed'
                  ? t('Mark as Pending')
                  : t('Mark as Done!')}
              </button>
              <div className={styles.markAsUndone}>
                {t('or')}{' '}
                <span onClick={() => handleStatusToggle(occurrence, index)}>
                  {t('Mark as Undone')}
                </span>
              </div>
            </div>

            {/* Incomplete Reason if any */}
            {occurrence.status === 'incomplete' && occurrence.incompleteReason && (
              <div className={styles.incompleteSection}>
                <h4>{t('Incomplete Reason')}</h4>
                <p>{occurrence.incompleteReason}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <MosaikFooter />
    </div>
  );
};

export default TaskDetails;
