import React, { useState } from 'react';
import axios from 'axios';
import AttachmentSection from './AttachmentSection';
import FeedbackSection from './FeedbackSection';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';
import styles from './ReplyComponent.module.css';

const ReplyComponent = ({ ticketId, onReplySubmit, onResolveTicket }) => {
  const { t } = useTranslation();
  const [replyMessage, setReplyMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleReplyChange = (content) => {
    setReplyMessage(content);
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleSubmitReply = async () => {
    if (!replyMessage.trim()) {
      alert(t('Reply message cannot be empty.'));
      return;
    }

    setIsSubmitting(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const formData = new FormData();
      formData.append('comment', replyMessage);
      if (selectedFile) {
        formData.append('file', selectedFile);
      }

      const response = await axios.post(`${apiUrl}/supports/${ticketId}/comment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      console.log(t('Reply submitted:'), response.data);
      setReplyMessage('');
      setSelectedFile(null);
      onReplySubmit();
    } catch (error) {
      console.error(t('Error submitting reply:'), error);
      alert(t('Failed to submit reply. Please try again.'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResolveTicket = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.patch(`${apiUrl}/supports/${ticketId}/status`, {
        status: 'Closed',
        resolved: true,
        rating: 5,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      console.log(t('Ticket resolved:'), response.data);
      onResolveTicket();
    } catch (error) {
      console.error(t('Error resolving ticket:'), error);
      alert(t('Failed to resolve ticket. Please try again.'));
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.replyHeader}>{t('Reply to the Support Staff:')}</h1>
      <div className={styles.replyBox}>
        <ReactQuill
          value={replyMessage}
          onChange={handleReplyChange}
          className={styles.replyInput}
          placeholder={t('Type your message here...')}
        />
      </div>
      <AttachmentSection onFileSelect={handleFileSelect} selectedFile={selectedFile} />
      <button
        className={styles.submitButton}
        onClick={handleSubmitReply}
        disabled={isSubmitting || !replyMessage.trim()}
      >
        {isSubmitting ? t('Submitting...') : t('Submit Reply')}
      </button>
      <button
        className={styles.resolveButton}
        onClick={handleResolveTicket}
        disabled={isSubmitting}
      >
        {isSubmitting ? t('Marking as Resolved...') : t('Mark as Resolved')}
      </button>
      <FeedbackSection />
    </div>
  );
};

export default ReplyComponent;
