import React from 'react';
import styles from './ProfileCompletionModal.module.css';
import { FaTrophy } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProfileCompletionModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const completionSteps = [
    { id: 1, title: t('Verify Email & Phone'), status: 'done' },
    { id: 2, title: t('Complete Profile'), status: 'done' },
    { id: 3, title: t('Personal Information'), status: 'done' },
    { id: 4, title: t('Additional Information'), status: 'undone' },
  ];

  const handleStepClick = (step) => {
    if (step.status === 'undone') {
      // Navigate to the respective settings page based on the step
      navigate('/settings'); // Replace with the correct route
    }
  };

  return (
    isOpen && (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <h2>{t('Profile Completion')}</h2>
            <button className={styles.closeButton} onClick={onClose}>
              &times;
            </button>
          </div>
          <div className={styles.stepsContainer}>
            {completionSteps.map((step) => (
              <div key={step.id} className={styles.stepItem}>
                <div className={styles.stepInfo}>
                  <span className={styles.stepIcon}>➔</span>
                  <span className={styles.stepTitle}>{step.title}</span>
                </div>
                {step.status === 'done' ? (
                  <button className={styles.doneButton}>
                    <FaTrophy className={styles.trophyIcon} /> {t('Done')}
                  </button>
                ) : (
                  <button className={styles.undoneButton} onClick={() => handleStepClick(step)}>
                    {t('Undone')}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default ProfileCompletionModal;
