import React from 'react';
import styles from './MosaikWelcomeScreen.module.css';
const MosaikWelcomeScreen = ({ welcomeTitle, welcomeDescription, welcomeIcon, welcomeBgimage }) => {
  return (
    <div 
        className={styles.welcomeContainer} 
        // style={{ backgroundImage: `url(${welcomeBgimage})` }}
      >
      <div className={styles.overlay}></div>
        <div className={styles.content}>
          <div className={styles.IconWrapper}>
            <img src={welcomeIcon} alt="Icon" className={styles.welcomeIcon} />
          </div>
          <h1 className={styles.welcomeTitle}>{welcomeTitle}</h1>
            {/* <p className={styles.welcomeDescription}>{welcomeDescription}</p> */}
        </div>
        
    </div>
  );
};

export default MosaikWelcomeScreen;
