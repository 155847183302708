import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './MessageCard.module.css';
import userAvatar from '../../assets/images/user-avatar.png';

const MessageCard = ({ user, date, time, message, file }) => {
  const { t } = useTranslation(); // Initialize t
  console.log('file', file);
  return (
    <article className={styles.card}>
      <div className={styles.cardContent}>
        <header className={styles.userInfo}>
          <h3>{t("User Reply")}</h3>
        </header>
        {file && (
          <div className={styles.imageContainer}>
            <img
              loading="lazy"
              src={file}
              alt={file.fileName}
              className={styles.uploadedImage}
            />
          </div>
        )}
        <p
          className={styles.message}
          dangerouslySetInnerHTML={{ __html: message }} // Render HTML
        ></p>
        <div className={styles.dateTime}>
          <time className={styles.date}>{date}</time>
          <time className={styles.time}>{time}</time>
        </div>
      </div>
    </article>
  );
};

export default MessageCard;
