import React, { useEffect, useState } from 'react';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import for translation
import MosaikFooter from '../MosaikFooter';
import styles from './CreateOrder.module.css';
import axios from 'axios';
import useAdminMode from '../../hooks/useAdminMode';
import CreateSupplier from './CreateSupplier';

const CreateOrder = () => {
  const { t } = useTranslation(); // Translation hook
  const navigate = useNavigate();
  const [products, setProducts] = useState([
    {
      id: 1,
      refNo: '',
      productName: '',
      description: '',
      categoryId: '',
      supplierId: '',
    },
  ]);
  const [supplierList, setSupplierList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [files, setFiles] = useState({});
  const [priority, setPriority] = useState('low');
  const apiUrl = process.env.REACT_APP_API_URL;
  const { adminMode, loading: adminLoading, error: adminError } = useAdminMode();

  // State for managing popup visibility
  const [isAddSupplierOpen, setIsAddSupplierOpen] = useState(false);

  // States for order suggestions
  const [completedOrders, setCompletedOrders] = useState([]);
  const [suggestions, setSuggestions] = useState({
    refNo: {},
    productName: {},
  });

  // Fetch suppliers, categories, and completed orders
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        if (token) {
          const response = await axios.get(`${apiUrl}/suppliers`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setSupplierList(response.data);
        }
      } catch (error) {
        console.error(t("Error fetching suppliers:"), error);
      }
    };

    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${apiUrl}/productCategories`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategoryList(response.data);
      } catch (error) {
        console.error(t("Error fetching categories:"), error);
      }
    };

    const fetchCompletedOrders = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${apiUrl}/orders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCompletedOrders(response.data);
      } catch (error) {
        console.error(t("Error fetching completed orders:"), error);
      }
    };

    fetchSuppliers();
    fetchCategories();
    fetchCompletedOrders();
  }, [apiUrl, t]);

  const handleAddSupplierSuccess = () => {
    const fetchSuppliers = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        if (token) {
          const response = await axios.get(`${apiUrl}/suppliers`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setSupplierList(response.data);
        }
      } catch (error) {
        console.error(t("Error fetching suppliers:"), error);
      }
    };
    setIsAddSupplierOpen(false);
    fetchSuppliers(); // Refresh the supplier list
  };

  const handleFileChange = (e, productId) => {
    const file = e.target.files[0];
    setFiles(prevFiles => ({ ...prevFiles, [productId]: file }));
  };

  const handleCategoryChange = (e, productId) => {
    const selectedCategoryId = e.target.value;
    setProducts(prevProducts =>
      prevProducts.map(product =>
        product.id === productId ? { ...product, categoryId: selectedCategoryId } : product
      )
    );
  };

  const uploadFileToBackend = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('documentTitle', file.name);
    formData.append('fileMimeType', file.type);
    formData.append('fileType', 'ProductDocument');
    formData.append('mediaType', file.type.split('/')[0]);
    formData.append('moduleField', 'order');

    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${apiUrl}/files/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.id;
    } catch (error) {
      console.error(t('Error uploading file to backend:'), error);
      throw error;
    }
  };

  const handleCreateOrder = async (status) => {
    const token = localStorage.getItem('accessToken');

    for (const product of products) {
      const { refNo, productName, description, categoryId, supplierId } = product;
      const file = files[product.id];

      const statusEventHistory = [
        {
          status: status,
          eventDateTime: new Date().toISOString(),
        }
      ];

      try {
        let fileId = null;
        if (file) {
          fileId = await uploadFileToBackend(file);
        }

        const productPayload = {
          imageFile: fileId ? { id: fileId } : null,
          name: productName || '',
          productRefNo: refNo || '',
          ...(categoryId ? { productCategory: { id: categoryId } } : {}),
        };

        const productResponse = await axios.post(`${apiUrl}/products`, productPayload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const productId = productResponse.data.id;

        const orderPayload = {
          description: description || '',
          file: fileId ? { id: fileId } : null,
          product: { id: productId },
          productName: productName || '',
          productRefNo: refNo || '',
          supplier: supplierId ? { id: supplierId } : null,
          priority: priority,
          status: status,
          statusEventHistory: statusEventHistory,
          user: { id: '66f6ade5e24fea6d335ebbc0' },
          organisation: { id: '66f5c0b267b4afa732c6a646' },
        };

        console.log(orderPayload);
        const orderResponse = await axios.post(`${apiUrl}/orders`, orderPayload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const orderId = orderResponse.data.id;
        navigate('/order-sent', { state: { orderId: orderId } });
      } catch (error) {
        console.error(t('Error creating product or order:'), error);
      }
    }
  };

  const addProduct = () => {
    if (products.length < 10) {
      setProducts([...products, {
        id: products.length + 1,
        refNo: '',
        productName: '',
        description: '',
        categoryId: '',
        supplierId: '',
      }]);
    }
  };

  const removeProduct = (id) => {
    setProducts(products.filter(product => product.id !== id));
    // Remove associated suggestions when a product is removed
    setSuggestions(prev => ({
      refNo: { ...prev.refNo, [id]: [] },
      productName: { ...prev.productName, [id]: [] },
    }));
  };

  // Handle input change for suggestions
  const handleInputChange = (e, productId, field) => {
    const value = e.target.value;
    setSearchTerm(value, productId, field);
    // Update product state
    setProducts(prevProducts =>
      prevProducts.map(product =>
        product.id === productId ? { ...product, [field]: value } : product
      )
    );
  };

  const setSearchTerm = (value, productId, field) => {
    if (field === 'refNo') {
      if (value.length > 0) {
        const filteredSuggestions = completedOrders.filter(order =>
          order.productRefNo.toLowerCase().includes(value.toLowerCase())
        ).slice(0, 5); // Limit to top 5 suggestions
        setSuggestions(prev => ({
          ...prev,
          refNo: { ...prev.refNo, [productId]: filteredSuggestions },
        }));
      } else {
        setSuggestions(prev => ({
          ...prev,
          refNo: { ...prev.refNo, [productId]: [] },
        }));
      }
    } else if (field === 'productName') {
      if (value.length > 0) {
        const filteredSuggestions = completedOrders.filter(order =>
          order.productName.toLowerCase().includes(value.toLowerCase())
        ).slice(0, 5); // Limit to top 5 suggestions
        setSuggestions(prev => ({
          ...prev,
          productName: { ...prev.productName, [productId]: filteredSuggestions },
        }));
      } else {
        setSuggestions(prev => ({
          ...prev,
          productName: { ...prev.productName, [productId]: [] },
        }));
      }
    }
  };

  const handleSelectSuggestion = (order, productId, field) => {
    if (field === 'refNo') {
      setProducts(prevProducts =>
        prevProducts.map(product =>
          product.id === productId
            ? {
                ...product,
                refNo: order.productRefNo,
                productName: order.productName || '',
                description: order.description || '',
                categoryId: order.productCategory ? order.productCategory.id : '',
                supplierId: order.supplier ? order.supplier.id : '',
              }
            : product
        )
      );
      setSuggestions(prev => ({
        ...prev,
        refNo: { ...prev.refNo, [productId]: [] },
      }));
    } else if (field === 'productName') {
      setProducts(prevProducts =>
        prevProducts.map(product =>
          product.id === productId
            ? {
                ...product,
                productName: order.productName,
                refNo: order.productRefNo || '',
                description: order.description || '',
                categoryId: order.productCategory ? order.productCategory.id : '',
                supplierId: order.supplier ? order.supplier.id : '',
              }
            : product
        )
      );
      setSuggestions(prev => ({
        ...prev,
        productName: { ...prev.productName, [productId]: [] },
      }));
    }
  };

  return (
    <div className={styles.container}>
      <MosaikHeaderWithBack pageTitle={t("Create New Order")} />
      <div className={styles.orderForm}>
        <h1>{t('Create New Order')}</h1>

        {products.map((product, index) => (
          <div key={product.id} className={styles.productCard}>
            <div className={styles.productHeader}>
              <h3>{t('Product')} #{index + 1}</h3>
              {products.length > 1 && (
                <button onClick={() => removeProduct(product.id)} className={styles.removeButton}>
                  {t('Remove')}
                </button>
              )}
            </div>

            {/* Ref Number Input with Suggestions */}
            <div className={styles.autocomplete}>
              <input
                id={`productRefNo-${product.id}`}
                type="text"
                placeholder={t("Ref Number")}
                required
                className={styles.input}
                value={product.refNo}
                onChange={(e) => handleInputChange(e, product.id, 'refNo')}
              />
              {suggestions.refNo[product.id] && suggestions.refNo[product.id].length > 0 && (
                <ul className={styles.suggestionList}>
                  {suggestions.refNo[product.id].map(order => (
                    <li
                      key={order.id}
                      onClick={() => handleSelectSuggestion(order, product.id, 'refNo')}
                      className={styles.suggestionItem}
                    >
                      {order.productRefNo}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Product Name Input with Suggestions */}
            <div className={styles.autocomplete}>
              <input
                id={`productName-${product.id}`}
                type="text"
                placeholder={t("Product Name")}
                required
                className={styles.input}
                value={product.productName}
                onChange={(e) => handleInputChange(e, product.id, 'productName')}
              />
              {suggestions.productName[product.id] && suggestions.productName[product.id].length > 0 && (
                <ul className={styles.suggestionList}>
                  {suggestions.productName[product.id].map(order => (
                    <li
                      key={order.id}
                      onClick={() => handleSelectSuggestion(order, product.id, 'productName')}
                      className={styles.suggestionItem}
                    >
                      {order.productName}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <input
              id={`description-${product.id}`}
              type="text"
              placeholder={t("Description (optional)")}
              className={styles.input}
              value={product.description}
              onChange={(e) => {
                const value = e.target.value;
                setProducts(prevProducts =>
                  prevProducts.map(p =>
                    p.id === product.id ? { ...p, description: value } : p
                  )
                );
              }}
            />

            {/* Category Selection */}
            <select
              id={`category-${product.id}`}
              value={product.categoryId}
              onChange={(e) => handleCategoryChange(e, product.id)}
              required
              className={styles.select}
            >
              <option value="">{t('Select Category')}</option>
              {categoryList.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>

            <select
              id={`supplier-${product.id}`}
              value={product.supplierId}
              onChange={(e) => {
                const value = e.target.value;
                setProducts(prevProducts =>
                  prevProducts.map(p =>
                    p.id === product.id ? { ...p, supplierId: value } : p
                  )
                );
              }}
              required
              className={styles.select}
            >
              <option value="">{t('Select Supplier (optional)')}</option>
              {supplierList.map((supplier) => (
                <option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </option>
              ))}
            </select>

            <select
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              className={`${styles.select} ${styles.prioritySelect}`}
            >
              <option value="low">{t('Low')}</option>
              <option value="medium">{t('Medium')}</option>
              <option value="high">{t('High')}</option>
            </select>

            <input
              type="file"
              onChange={(e) => handleFileChange(e, product.id)}
              className={styles.input}
            />
          </div>
        ))}

        <div className={styles.addButtons}>
          {products.length < 10 && (
            <button onClick={addProduct} className={styles.addButton}>+ {t('Add another')}</button>
          )}
          {adminMode && (
            <button onClick={() => setIsAddSupplierOpen(true)} className={styles.addButton}>
              + {t('Add Supplier')}
            </button>
          )}
        </div>

        <button onClick={() => handleCreateOrder('pending')} className={styles.submitButton}>{t('Create New Order')}</button>
        <button onClick={() => handleCreateOrder('draft')} className={styles.saveDraftButton}>{t('Save as Draft')}</button>
      </div>
      <MosaikFooter />

      {/* Popup for CreateSupplier */}
      {isAddSupplierOpen && adminMode && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <button className={styles.popupCloseButton} onClick={() => setIsAddSupplierOpen(false)}>
              &times;
            </button>
            <CreateSupplier onSuccess={handleAddSupplierSuccess} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateOrder;
