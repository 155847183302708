import React, { useState } from "react";
import { FaYoutube } from "react-icons/fa";
import "./YoutubeBlock.css";

const YoutubeBlock = (props) => {
  const [url, setUrl] = useState(props.block.props.url || "");
  const [showDialog, setShowDialog] = useState(false);

  const handleEmbedVideo = () => {
    props.editor.updateBlock(props.block, {
      type: "youtube",
      props: {
        url: url.replace("/watch?v=", "/embed/"),
      },
    });
    setShowDialog(false);
  };

  return (
    <div
      className={`youtube-block ${props.block.props.url ? "youtube-has-url" : "youtube-no-url"}`}
    >
      {props.block.props.url ? (
        <iframe
          width="560"
          height="315"
          src={props.block.props.url}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
          allowFullScreen
        />
      ) : (
        <>
          <button
            className="youtube-add-button"
            onClick={() => setShowDialog(true)}
          >
            <FaYoutube className="youtube-icon" />
            Add YouTube Video
          </button>

          {showDialog && (
            <div className="youtube-dialog-overlay">
              <div className="youtube-dialog">
                <h3>Add YouTube Video</h3>
                <input
                  type="text"
                  placeholder="Enter YouTube URL"
                  className="youtube-input"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
                <div className="youtube-dialog-actions">
                  <button
                    className="youtube-dialog-button"
                    onClick={() => setShowDialog(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="youtube-dialog-button-primary"
                    onClick={handleEmbedVideo}
                  >
                    Embed
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default YoutubeBlock;
