// MosaikHeaderManual.js
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MosaikHeaderManual.module.css";
import profilePicPlaceholder from "../assets/images/placeholder.webp";
import backArrowIcon from "../assets/icons/arrow-back.png";
import pencilIcon from "../assets/images/edit-icon.png";
import { AiOutlineShareAlt } from "react-icons/ai";
import { AuthContext } from "../context/AuthContext";
import { uploadFile } from "../services/mosaikUploadService";
import { useTranslation } from 'react-i18next';
import RolesManager from "./RolesManager";
import axios from "axios";

const MosaikHeaderManual = ({
  initialPageTitle,
  onSaveTitle,
  manualImageUrl,
  setManualImageId,
  onFileUpload,
  resourceId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [pageTitle, setPageTitle] = useState(initialPageTitle);
  const [manualImage, setManualImage] = useState(manualImageUrl ? `${manualImageUrl}` : profilePicPlaceholder);
  const [organisationLogo, setOrganisationLogo] = useState(null); // New state for organization logo
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isRolesManagerOpen, setIsRolesManagerOpen] = useState(false);

  useEffect(() => {
    setPageTitle(initialPageTitle);
  }, [initialPageTitle]);

  useEffect(() => {
    setManualImage(manualImageUrl ? `${manualImageUrl}` : profilePicPlaceholder);
  }, [manualImageUrl]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  // Fetch organization logo if currentOrganizationId is available
  useEffect(() => {
    const fetchOrganisationLogo = async () => {
      if (authState.currentOrganisationId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/organisations/${authState.currentOrganisationId}`,
            {
              headers: { Authorization: `Bearer ${authState.accessToken}` },
            }
          );
          const orgLogo = response.data?.files?.[0]?.fileUrl
            ? `https://${response.data.files[0].fileUrl}`
            : profilePicPlaceholder;
          setOrganisationLogo(orgLogo);
        } catch (error) {
          console.error(t("Error fetching organization logo:"), error);
        }
      }
    };
    fetchOrganisationLogo();
  }, []);

  const handleTitleClick = () => setIsEditing(true);

  const handleInputChange = (e) => setPageTitle(e.target.value);

  const handleSaveTitle = () => {
    setIsEditing(false);
    if (onSaveTitle) onSaveTitle(pageTitle);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSaveTitle();
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileResponse = await uploadFile(file, "library", file.name);
      setManualImageId(fileResponse?.id);
      onFileUpload(fileResponse?.id);
      const reader = new FileReader();
      reader.onload = () => setManualImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handlePermissions = () => {
    setIsRolesManagerOpen(true);
  };

  const closeRolesManager = () => {
    setIsRolesManagerOpen(false);
  };

  return (
    <header className={styles.headerContainer}>
      <div className={styles.headerLeft} onClick={() => navigate(-1)}>
        <img src={backArrowIcon} alt={t('Back')} className={styles.backArrow} />
      </div>

      {/* Manual Image Upload */}
      <div className={styles.imageUploadContainer}>
        <img
          src={manualImage === 'https://null' ? profilePicPlaceholder : manualImage}
          alt={t('Manual')}
          className={styles.manualImage}
          onClick={() => fileInputRef.current.click()}
        />
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
      </div>

      <div className={styles.headerTitle}>
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={pageTitle}
            onChange={handleInputChange}
            onBlur={handleSaveTitle}
            onKeyDown={handleKeyDown}
            className={styles.titleInput}
          />
        ) : (
          <div className={styles.titleDisplay} onClick={handleTitleClick}>
            <img src={pencilIcon} alt={t('Edit')} className={styles.pencilIcon} />
            <h2>{pageTitle}</h2>
          </div>
        )}
        <button
          className={styles.shareButton}
          onClick={handlePermissions}
          aria-label="Share"
          title={t('Share')}
        >
          <AiOutlineShareAlt size={30} />
        </button>
      </div>

      <div className={styles.headerRight}>
        <div className={styles.profilePicContainer}>
          <img
            src={authState.user?.profilePicture || profilePicPlaceholder}
            alt={t('Profile')}
            className={styles.profilePic}
          />
          {authState.user?.status === "online" && (
            <span className={styles.onlineIndicator}></span>
          )}
        </div>
       
      </div>

      {/* New div for selected organization logo */}
      <div
        className={styles.mosaikSelectedOrg}
        style={{
          backgroundImage: `url(${organisationLogo || profilePicPlaceholder})`,
        }}
      ></div>

      {isRolesManagerOpen && (
        <div className={styles.modalOverlay} onClick={closeRolesManager}>
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <RolesManager
              onClose={closeRolesManager}
              resourceId={resourceId}
              resourceType={"libraryContent"}
            />
          </div>
        </div>
      )}
    </header>
  );
};

export default MosaikHeaderManual;
