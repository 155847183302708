import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './CreateEvent.module.css';

const apiUrl = process.env.REACT_APP_API_URL;

// Function to upload a file to the backend
const uploadFileToBackend = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('documentTitle', file.name);
  formData.append('fileMimeType', file.type);
  formData.append('fileType', 'ProductDocument');
  formData.append('mediaType', file.type.split('/')[0]);
  formData.append('moduleField', 'Library');
  formData.append('user', JSON.stringify({ id: 'userId', organisationId: 'organisationId' }));

  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post(`${apiUrl}/files/upload`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.id; // Return file ID from the backend
  } catch (error) {
    console.error('Error uploading file to backend:');
    throw error;
  }
};

const EventForm = ({ onFormChange, formData }) => {
  const { t } = useTranslation(); // Initialize t
  const navigate = useNavigate();
  const location = useLocation();

  // Set initial state based on whether we're editing or creating
  const [title, setTitle] = useState(formData?.title || '');
  const [description, setDescription] = useState(formData?.description || '');
  const [displayPictureId, setDisplayPictureId] = useState(formData?.displayPictureId || '');
  const [selectedImage, setSelectedImage] = useState(location.state?.selectedImage || '');

  // Populate form fields when `formData` changes (for editing)
  useEffect(() => {
    if (formData) {
      setTitle(formData.title || '');
      setDescription(formData.description || '');
      setDisplayPictureId(formData.displayPictureId || '');
    }
  }, [formData]);

  // Check for selectedImage in location.state and upload if necessary
  useEffect(() => {
    const imageFromState = location.state?.selectedImage;
    if (imageFromState && imageFromState !== selectedImage) {
      setSelectedImage(imageFromState);
      handleImageUpload(imageFromState);
    }
  }, [location.state?.selectedImage]);

  // Handle input changes
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    onFormChange({ title: e.target.value, description, displayPictureId });
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    onFormChange({ title, description: e.target.value, displayPictureId });
  };

  // Handle image upload
  const handleImageUpload = async (imageUrl) => {
    try {
      // Fetch the image file from the URL (assuming it's a downloadable URL)
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], "display-picture.jpg", { type: blob.type });

      // Upload the file to the backend
      const uploadedFileId = await uploadFileToBackend(file);
      setDisplayPictureId(uploadedFileId);
      onFormChange({ title, description, displayPictureId: uploadedFileId });
    } catch (error) {
      console.error(t('File upload failed:'), error);
    }
  };

  // Navigate to the image selection screen
  const handleImageClick = () => {
    navigate('/mosaik-calendar/select-image');
  };

  return (
    <form className={styles.eventForm}>
      <label htmlFor="eventName" className={styles.formLabel}>{t('Name of the event')}</label>
      <input
        type="text"
        id="eventName"
        className={styles.formInput}
        value={title}
        onChange={handleTitleChange}
      />

      <label htmlFor="eventDescription" className={styles.formLabel}>{t('Description of the event')}</label>
      <textarea
        id="eventDescription"
        className={styles.formInput}
        value={description}
        onChange={handleDescriptionChange}
      ></textarea>

      <div className={styles.eventDisplayPicture}>
        {selectedImage && (
          <img src={selectedImage} alt={t('Event display')} className={styles.displayPicture} />
        )}
        <span className={styles.eventDisplayText}>{t("Event's Display Picture")}</span>
        <button
          type="button"
          className={styles.uploadButton}
          onClick={handleImageClick}
        >
          <img
            src={require('../../assets/images/attachment-icon.png')}
            alt={t('attachment icon')}
            className={styles.uploadIcon}
          />
        </button>
      </div>
    </form>
  );
};

export default EventForm;
