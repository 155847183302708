import React, { useState, useEffect } from 'react';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import { useParams, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import styles from './CategoryList.module.css';
import { useTranslation } from 'react-i18next';

const CategoryList = () => {
  // Get the URL from .env
  const apiUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();

  const { categoryId, subCategoryName } = useParams();
  const location = useLocation();
  const { subCategory } = location.state || {}; // Retrieve the passed subCategory
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const storedData = localStorage.getItem('user');
        const token = storedData ? JSON.parse(storedData).accessToken : null;

        // Fetch articles or resources for the subcategory
        const response = await axios.get(`${apiUrl}/libraryCategories/${subCategory.id}/articles`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setArticles(response.data);
      } catch (error) {
        console.error(t('Error fetching articles:'), error);
      }
    };

    if (subCategory?.id) {
      fetchArticles();
    }
  }, [subCategory, apiUrl]);

  if (!subCategory) {
    return <p>{t('Loading subcategory details...')}</p>;
  }

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={subCategory.name || subCategoryName} />
      <div className={styles.CategoryListSection}>
        <h1 className={styles.sectionTitle}>{subCategory.name}</h1>

        {/* Show subcategory details */}
        <div className={styles.subCategoryDetails}>
          <p>
            <strong>{t('Subcategory ID')}:</strong> {subCategory.id}
          </p>
          <p>
            <strong>{t('Category Type')}:</strong> {subCategory.categoryType}
          </p>
        </div>

        {/* Display the list of articles */}
        <div className={styles.manualsList}>
          {articles.length > 0 ? (
            articles.map((article) => (
              <Link key={article.id} to={`/mosaik-library/manual/${article.id}`}>
                <div className={styles.manualItem}>
                  <img
                    src={article.image || 'default-image.png'}
                    alt={article.title}
                    className={styles.manualImage}
                  />
                  <div className={styles.manualDetails}>
                    <h3>{article.title}</h3>
                    <p>{article.date}</p>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p>{t('No articles available in this subcategory.')}</p>
          )}
        </div>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default CategoryList;
