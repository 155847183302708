import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './SupportMessage.module.css';

const SupportMessage = ({ avatar, userName, userRole, date, time, message, file }) => {
  const { t } = useTranslation(); // Initialize t
  return (
    <article className={styles.container}>
      <div className={styles.messageWrapper}>
        <div className={styles.messageContent}>
          <header className={styles.messageHeader}>
            <img loading="lazy" src={avatar} alt={`${userName}'s ${t("avatar")}`} className={styles.avatar} />
            <div className={styles.divider} />
            <div className={styles.userInfo}>
              <h2 className={styles.userName}>{userName}</h2>
              <p className={styles.userRole}>{userRole}</p>
            </div>
          </header>
          {file &&(
          <div className={styles.imageContainer}>
            <img
              loading="lazy"
              src={file}
              alt={file.fileName}
              className={styles.uploadedImage}
            />
          </div>
        )}
          <p
            className={styles.messageBody}
            dangerouslySetInnerHTML={{ __html: message }} // Render HTML
          ></p>
          <div className={styles.messageDate}>
            <time className={styles.date}>{date}</time>
            <time className={styles.time}>{time}</time>
          </div>
        </div>
      </div>
    </article>
  );
};

export default SupportMessage;
