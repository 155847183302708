import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './CreateEvent.module.css';

const EventNotes = ({ onNotesChange, notes }) => {
  const { t } = useTranslation(); // Initialize t
  const [noteText, setNoteText] = useState('');

  // Set the initial value of the notes when the component mounts or when the `notes` prop changes
  useEffect(() => {
    if (notes) {
      setNoteText(notes);
    }
  }, [notes]);

  const handleNotesChange = (e) => {
    const value = e.target.value;
    setNoteText(value);
    onNotesChange(value); // Pass notes back to parent component
  };

  return (
    <section className={styles.eventNotes}>
      <h2 className={styles.sectionTitle}>{t("Event's Notes")}</h2>
      <textarea
        className={styles.notesTextarea}
        placeholder={t('Type your notes here...')}
        aria-label={t('Event notes')}
        value={noteText}
        onChange={handleNotesChange}
      ></textarea>
    </section>
  );
};

export default EventNotes;
