import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import MosaikWelcomeScreen from '../MosaikWelcomeScreen';
import welcomeIcon from '../../assets/images/feedback-iconwhite.png';
import welcomeBgimage from '../../assets/images/feedback-bg.jpg';
import searchIcon from '../../assets/images/search-button.png';
import addIcon from '../../assets/images/plus-button.png';
import calendarIcon from '../../assets/images/calendar-iconinverted.png';
import styles from './MosaikFeedback.module.css';
import viewIcon from '../../assets/images/view-icon.png';

const MosaikFeedback = () => {
  const { t } = useTranslation(); // Initialize t
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [openFeedbacks, setOpenFeedbacks] = useState([]);
  const [pastFeedbacks, setPastFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('Resolved'); // Initialize status filter
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeedbacks = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const response = await axios.get(`${apiUrl}/feedbacks`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        const allFeedbacks = response.data;

        // Sort feedbacks by created date
        allFeedbacks.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        // Categorize feedbacks
        const open = allFeedbacks.filter(feedback => feedback.status === 'open');
        const past = allFeedbacks.filter(feedback => ['resolved', 'pending', 'unsolved'].includes(feedback.status));

        setOpenFeedbacks(open);
        setPastFeedbacks(past);
        setLoading(false);
      } catch (error) {
        console.error(t('Error fetching feedbacks:'), error);
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, []);

  const openFeedbackDetail = (ticketId) => {
    navigate(`/feedback-detail/${ticketId}`);
  };
  const goToCreateNewTicket = () => {
    navigate('/create-ticket'); // Link to the "Create a New Ticket" page
  };
  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const goToCreateNewFeedback = () => {
    navigate('/create-feedback');
  };

  const filteredFeedbacks = feedbacks.filter(feedback => {
    const title = feedback.title ? feedback.title.toLowerCase() : '';
    const description = feedback.description ? feedback.description.toLowerCase() : '';
    return title.includes(searchQuery.toLowerCase()) || description.includes(searchQuery.toLowerCase());
  });

  const filteredPastFeedbacks = pastFeedbacks.filter(feedback => feedback.status === statusFilter.toLowerCase());

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t("Feedback")} />

      {/* Open Feedback Section */}
      <div className={`${styles.ticketSection}`}>
        <div className={styles.ticketSectionHeader}>
          <h3>{t("Open Feedback")}</h3>
          <div className={styles.ticketSearchAndAdd}>
            <button onClick={toggleSearchBar}>
              <img src={searchIcon} alt={t("Search")} />
            </button>
            <button onClick={goToCreateNewFeedback}>
              <img src={addIcon} alt={t("Add")} />
            </button>
          </div>
        </div>

        {showSearchBar && (
          <div className={styles.searchField}>
            <input
              type="text"
              placeholder={t("Enter keywords to search...")}
              className={styles.searchInput}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        )}

        {loading ? (
          <p>{t("Loading feedbacks...")}</p>
        ) : (
          openFeedbacks.map(feedback => (
            <div
              key={feedback.id}
              className={styles.ticketItem}
              onClick={() => openFeedbackDetail(feedback.id)}
            >
              <div className={styles.ticketDetails}>
                <div className={styles.ticketDate}>
                  <img src={calendarIcon} alt={t("Date")} className={styles.calendarIcon} />
                  <span>{new Date(feedback.createdAt).toLocaleDateString()}</span>
                </div>
                <h4>{feedback.title || t('No Title')}</h4>
                <p>{feedback.description || t('No Description')}</p>
                <div className={styles.ticketCategory}>
                  <p>{feedback.feedbackCategory ? feedback.feedbackCategory.name : t('No Category')}</p>
                  <button className={styles.viewTicketButton}>{t("View Feedback")}</button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Submit a New Feedback Button */}
      <div className={styles.submitNewTicketButton}>
        <button onClick={goToCreateNewFeedback}>
          <span>+</span> {t("Submit a new")}
        </button>
      </div>

      {/* Past Feedback Section with Status Filter Buttons */}
      <div className={styles.ticketSection}>
        <div className={styles.ticketSectionHeader}>
          <h3>{t("Past Feedback")}</h3>
        </div>
        <div className={styles.categoryButtonContainer}>
          <button
            className={`${styles.categoryButton} ${statusFilter === 'Resolved' ? styles.active : ''}`}
            onClick={() => setStatusFilter('Resolved')}
          >
            {t("Resolved")}
          </button>
          <button
            className={`${styles.categoryButton} ${statusFilter === 'Pending' ? styles.active : ''}`}
            onClick={() => setStatusFilter('Pending')}
          >
            {t("Pending")}
          </button>
          <button
            className={`${styles.categoryButton} ${statusFilter === 'Unsolved' ? styles.active : ''}`}
            onClick={() => setStatusFilter('Unsolved')}
          >
            {t("Unsolved")}
          </button>
        </div>

        {loading ? (
          <p>{t("Loading feedbacks...")}</p>
        ) : (
          filteredPastFeedbacks.map(feedback => (
            <div
              key={feedback.id}
              className={styles.ticketItem}
              onClick={() => openFeedbackDetail(feedback.id)}
            >
              <div className={styles.ticketDetails}>
                <div className={styles.ticketDate}>
                  <img src={calendarIcon} alt={t("Date")} className={styles.calendarIcon} />
                  <span>{new Date(feedback.createdAt).toLocaleDateString()}</span>
                </div>
                <h4>{feedback.title || t('No Title')}</h4>
                <p>{feedback.description || t('No Description')}</p>
                <div className={styles.ticketCategory}>
                  <p>{feedback.feedbackCategory ? feedback.feedbackCategory.name : t('No Category')}</p>
                  <button className={styles.viewTicketButton}>{t("View Feedback")}</button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
   {/* General Feedback Questions */}
   <div className={styles.ticketSection}>
        <div className={styles.ticketSectionHeader}>
          <h3>{t("General Feedback Questions")}</h3>
          <button onClick={toggleSearchBar}>
            <img src={searchIcon} alt={t("Search")} />
          </button>
        </div>
        <div className={styles.feedbackQuestion}>
          <h4>{t("How support works?")}</h4>
          <div className={styles.viewIcon}>
            <img src={viewIcon} alt={t("View")} />
          </div>
        </div>
      </div>
      <div className={styles.submitNewTicketButton}>
        <button onClick={goToCreateNewFeedback}>
          <span>+</span> {t("Submit a new")}
        </button>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default MosaikFeedback;
