// login.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import styles from './Login.module.css';

// Importing images locally
import logo from '../assets/images/logo.png';
import divider from '../assets/images/divider.png';
import emailIcon from '../assets/images/email-icon.png';
import lockIcon from '../assets/images/lock-icon.png';
import visibilityIcon from '../assets/images/visibility-icon.png';
import visibilityOffIcon from '../assets/images/visibility-off-icon.png';

import { AuthContext } from '../context/AuthContext'; // Import AuthContext

function LoginPage() {
  const [emailOrMobile, setEmailOrMobile] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { authState, login, logout } = useContext(AuthContext); // Use authState, login, and logout from AuthContext
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    setMessage(''); // Clear any previous messages

    // Regular expression to check if the input is an email
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailOrMobile);

    // Format mobile number if it's not an email
    let formattedInput = emailOrMobile;
    let loginMethod = 'mobile';

    if (isEmail) {
      loginMethod = 'email';
    }

    try {
      const loginResponse = await axios.post(`${apiUrl}/auth/userlogin`, {
        emailOrMobile: formattedInput,
        password: password,
      });

      // If the login response contains accessToken, handle OTP request
      if (loginResponse.data.accessToken) {
        const tempToken = loginResponse.data.accessToken;

        try {
          // Request OTP after successful login
          const requestOtpResponse = await axios.post(
            `${apiUrl}/auth/request-otp`,
            {
              purpose: 'login',
              method: loginMethod,
              destination: formattedInput,
            },
            {
              headers: {
                Authorization: `Bearer ${tempToken}`,
              },
            }
          );

          if (requestOtpResponse.data.accessToken) {
            // Store the temporary accessToken in authState
            login(requestOtpResponse.data.accessToken, null, null);

            // Navigate to the OTP verification page with email or mobile
            navigate('/loginotp', {
              state: { emailOrMobile: formattedInput, method: loginMethod },
            });
          }
        } catch (otpError) {
          setMessage('Failed to request OTP. Please try again.');
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setMessage(`Unauthorized: Incorrect ${loginMethod} or password`);
        } else {
          setMessage(
            `Login error: ${error.response.data.message || 'An error occurred'}`
          );
        }
      } else {
        setMessage('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false); // End loading
    }
  };

  if (authState.isAuthenticated && authState.user) {
    // User is already logged in
    const user = authState.user;

    return (
      <main className={styles.loginContainer}>
        <img src={logo} alt="Mosaik Logo" className={styles.logo} />
        <section className={styles.loginCard}>
          <h1 className={styles.loginHeading}>
            Hello { user?.email || user?.mobile},
          </h1>
          <img src={divider} alt="" className={styles.divider} />
          <p className={styles.loginSubtext}>You are already signed in!</p>
          <button onClick={handleLogout} className={styles.logoutButton}>
            Logout
          </button>
          <p className={styles.signupPrompt}>
            <Link to="/mosaik-home" className={styles.signupLink}>
              Go back to homepage
            </Link>
          </p>
        </section>
      </main>
    );
  }

  // User is not logged in, show the login form
  return (
    <main className={styles.loginContainer}>
      <img src={logo} alt="Mosaik Logo" className={styles.logo} />
      <section className={styles.loginCard}>
        <h1 className={styles.loginHeading}>Login</h1>
        <img src={divider} alt="" className={styles.divider} />
        <p className={styles.message}>{message}</p>
        <p className={styles.loginSubtext}>
          Login to start using Mosaik instantly!
        </p>
        <form className={styles.formContainer} onSubmit={handleLogin}>
          <div className={styles.inputWrapper}>
            <div className={styles.inputField}>
              <img
                src={emailIcon}
                alt="Email icon"
                className={styles.inputIcon}
              />
              <input
                type="text"
                placeholder="Email or mobile number"
                value={emailOrMobile}
                onChange={(e) => setEmailOrMobile(e.target.value)}
                className={styles.input}
              />
            </div>
            <div className={styles.inputField}>
              <img src={lockIcon} alt="Lock icon" className={styles.inputIcon} />
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={styles.input}
              />
              <img
                src={showPassword ? visibilityOffIcon : visibilityIcon}
                alt="Visibility icon"
                className={styles.visibilityIcon}
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <button
              type="button"
              className={styles.forgotPassword}
              onClick={() => navigate('/forgot-password')}
            >
              Forgot password?
            </button>
          </div>
          <button
  type="submit"
  className={styles.loginButton}
  onClick={handleLogin}
  disabled={loading} // Disable the button when loading
>
  {loading ? (
    <div className={styles.spinner}></div> // Spinner element
  ) : (
    'Login'
  )}
</button>

        </form>
        <p className={styles.signupPrompt}>
          Don't have an account?{' '}
          <Link to="/signup" className={styles.signupLink}>
            Sign up here
          </Link>
        </p>
      </section>
    </main>
  );
}

export default LoginPage;
