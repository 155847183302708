import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './IconGrid.module.css';
import libraryIcon from '../assets/images/library-icon.png';
import tasksIcon from '../assets/images/tasks-icon.png';
import ordersIcon from '../assets/images/orders-icon.png';
import feedbackIcon from '../assets/images/feedback-icon.png';
import repairsIcon from '../assets/images/repairs-icon.png';
import supportIcon from '../assets/images/support-icon.png';
import { useTranslation } from "react-i18next"; // Import the translation hook

const IconGrid = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.gridContainer}>
      <div className={styles.gridItem} onClick={() => navigate('/mosaik-library')}>
        <img src={libraryIcon} alt={t('Library')} />
        <span>{t('Library')}</span>
      </div>
      <div className={styles.gridItem} onClick={() => navigate('/mosaik-tasks')}>
        <img src={tasksIcon} alt={t('Tasks')} />
        <span>{t('Tasks')}</span>
      </div>
      <div className={styles.gridItem} onClick={() => navigate('/mosaik-orders')}>
        <img src={ordersIcon} alt={t('Orders')} />
        <span>{t('Orders')}</span>
      </div>
      <div className={styles.gridItem} onClick={() => navigate('/mosaik-feedback')}>
        <img src={feedbackIcon} alt={t('Feedback')} />
        <span>{t('Feedback')}</span>
      </div>
      <div className={styles.gridItem} onClick={() => navigate('/mosaik-repairs')}>
        <img src={repairsIcon} alt={t('Repairs')} />
        <span>{t('Repairs')}</span>
      </div>
      <div className={styles.gridItem} onClick={() => navigate('/mosaik-support')}>
        <img src={supportIcon} alt={t('Support')} />
        <span>{t('Support')}</span>
      </div>
    </div>
  );
};

export default IconGrid;
