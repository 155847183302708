import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './OtpVerification.module.css'; 
import logo from '../assets/images/logo.png';
import backIcon from '../assets/icons/back-icon.png';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext

const OtpVerificationForLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useContext(AuthContext); // Use the login method from AuthContext
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [error, setError] = useState('');
  const inputRefs = useRef([]); 

  // Redirect to login screen if the email or mobile is not present
  useEffect(() => {
    if (!location.state || !location.state.emailOrMobile) {
      navigate('/login'); 
    }
  }, [location, navigate]);
  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);
  const handleOtpChange = (element, index) => {
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.value && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    setError('');

    // Automatically verify when all OTP fields are filled
    if (newOtp.every((digit) => digit !== '')) {
      handleVerifyOtp(newOtp.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').slice(0, 6); // Get only the first 6 digits
    const newOtp = [...otp];

    pasteData.split('').forEach((char, index) => {
      if (index < 6) {
        newOtp[index] = char;
        inputRefs.current[index].value = char; // Set value to each input field
      }
    });

    setOtp(newOtp);

    // Automatically verify when all digits are pasted
    if (pasteData.length === 6) {
      handleVerifyOtp(newOtp.join(''));
    }
  };

  const handleVerifyOtp = async (otpCode) => {
    const token = localStorage.getItem('accessToken'); // Retrieve stored OTP token

    if (!token) {
      setError('Token not found. Please request OTP again.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify-otp`,
        {
          purpose: "login",
          method: location.state.method, 
          destination: location.state.emailOrMobile, 
          otp: otpCode, // Send the OTP from state or paste
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );

      if (response.data.result === true) {
        const { accessToken, userInfo } = response.data;

        // Store accessToken and userInfo in localStorage
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));        
        localStorage.setItem('rocketchatToken', userInfo.authToken);

        // Update the context with the new token and user info
        login(accessToken, userInfo.authToken, userInfo);

        // Navigate to the home or dashboard
        navigate('/mosaik-home');
      } else {
        setError('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Failed to verify OTP. Please try again.');
    }
  };

  return (
    <main className={styles.signupContainer}>
      <img src={logo} alt="Mosaik Logo" className={styles.logo} />
      <section className={styles.signupCard}>
        <div className={styles.header}>
          <img src={backIcon} alt="Go back" className={styles.backIcon} onClick={() => navigate('/login')} />
          <h1 className={styles.signupHeading}>OTP Verification</h1>
        </div>
        <div className={styles.divider}></div>
        <p className={styles.instructionText}>
          We sent you a six-digit OTP code to {location.state.emailOrMobile}
        </p>
        <div className={styles.otpInputContainer} onPaste={handlePaste}>
          {otp.map((data, index) => (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength="1"
              className={styles.otpInputBox}
              value={data}
              onChange={(e) => handleOtpChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        {error && <p className={styles.errorText}>{error}</p>}
        <button onClick={() => handleVerifyOtp(otp.join(''))} className={styles.verifyButton}>
          Verify
        </button>
      </section>
    </main>
  );
};

export default OtpVerificationForLogin;
