import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './Welcome.module.css';

const Welcome = () => {
    const navigate = useNavigate();

    useEffect(() => {
      const timer = setTimeout(() => {
        navigate('/login');
      }, 3000);
  
      return () => clearTimeout(timer); // Clear the timeout if the component is unmounted
    }, [navigate]);
  return (
<motion.div
      className={styles.welcomeContainer}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: 0, duration: 0 }}
    >
    <motion.img
    src={require('../assets/images/logo.png')}
    alt="Logo"
    className={styles.logoMosaic}
    initial={{ opacity: 0, x: 100 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 0.4, duration: 0.4 }}
    />
      <motion.h1
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.6 }}
      >
        Welcome to mosaik
      </motion.h1>
    </motion.div>
  );
};

export default Welcome;
