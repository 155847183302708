// src/components/ErrorBoundary.js

import React from 'react';
import styles from './ErrorBoundry.module.css';
import logo from '../assets/images/logo.png';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to render fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render any custom fallback UI
      return <div className={styles.errorBoundryWall}>
        <img src={logo} className={styles.logo} />
        <h1>Oops!!</h1>
        <h2>Something went wrong.</h2></div>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
