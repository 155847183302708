import { AiFillBulb } from "react-icons/ai"; 
import { AiTwotoneBook } from "react-icons/ai"; 
import { FaWandSparkles } from "react-icons/fa6";
// src/components/Editor.js
import React, { useState, useMemo, useRef, useEffect, useContext } from "react";
import {
  BlockNoteSchema,
  defaultBlockSpecs,
  defaultInlineContentSpecs,
  insertOrUpdateBlock,
  defaultStyleSpecs,
} from "@blocknote/core";
import {
  useCreateBlockNote,
  SuggestionMenuController,
  DragHandleButton,
  getDefaultReactSlashMenuItems,
  SideMenu,
  SideMenuController,
} from "@blocknote/react";
import {
  BlockColorsItem,
  DragHandleMenu,
  RemoveBlockItem,
} from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css"; // Mantine styles
import "./styles.css"; // Custom styles for your app
import { Mention, fetchUser } from "./Mentions"; // Import Mentions
import { uploadFile } from "./UploadFile"; // Import file upload handler
import { Page } from "./Page"; // Import the Page inline content
import { v4 as uuidv4 } from "uuid"; // Import uuid
import { AuthContext } from "../../context/AuthContext"; // Import AuthContext
import { EditorContext } from "../../context/EditorContext"; // Import EditorContext
import { useParams } from "react-router-dom";
import { CustomRemoveBlockButton } from "./RemoveButton";
import { handleAiPrompt, handleAiSubmit } from "./AiComponent";
import { Youtube } from "./Youtube";
import { FaYoutube } from "react-icons/fa";



// Function to create a new page on the backend
const createNewPage = async (
  name,
  token,
  parentId
) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const newPageData = {
    content: {},
    name: name,
    parentArticle: {
      id: parentId,
    },
  };

  try {
    const response = await fetch(`${apiUrl}/libraryContents`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newPageData),
    });

    if (!response.ok) {
      throw new Error("Failed to create new page");
    }

    const data = await response.json();
    return data; // Return the created page data, including its ID
  } catch (error) {
    console.error("Error creating page:", error);
  }
};


// Function to filter suggestion items based on query
const filterSuggestionItems = (items, query) => {
  return items.filter((item) =>
    item.title.toLowerCase().includes(query.toLowerCase())
  );
};

export default function Editor({ blocks, setBlocks }) {
  const editorViewRef = useRef(null);
  const [userName, setUserName] = useState(null);
  const { id } = useParams(); // Assuming 'id' is a route parameter
  const [aiPromptVisible, setAiPromptVisible] = useState(false); // To control input visibility
  const [aiPromptInput, setAiPromptInput] = useState('');
  const [loading, setLoading] = useState(false); 
  const { authState } = useContext(AuthContext);
  const token = authState.accessToken;


  // Function to trigger page creation and navigate to it
  const handleCreateNewPage = async (editor) => {
    const pageName = prompt("Enter a name for the new page", "Untitled Page");
    if (!pageName) return;
    const newPage = await createNewPage(
      pageName,
      token,
      id
    );

    if (newPage && newPage.id) {
      editor.insertInlineContent([
        {
          type: "page",
          props: {
            blockId: newPage.id,
            title: pageName,
            link: `/mosaik-library/manual/${newPage.id}`,
          },
        },
        " ",
      ]);
    }
  };

  // Function to generate mention menu items
  const getMentionMenuItems = (editor) => {
    if (!userName) return [];
    const users = userName;
    return users?.map((user) => ({
      title: user.firstName,
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: "mention",
            props: {
              user: user.firstName,
            },
          },
          " ", // Add a space after the mention
        ]);
      },
    }));
  };

  // Define the schema
  const schema = useMemo(() => {
    return BlockNoteSchema.create({
      blockContentSpecs: {
        ...defaultBlockSpecs,
      },
      inlineContentSpecs: {
        ...defaultInlineContentSpecs,
        mention: Mention,
        page: Page, 
      },
      styleSpecs: {
        ...defaultStyleSpecs,
      },
      blockSpecs: {
        ...defaultBlockSpecs,
        page: Page,
        youtube: Youtube,
      },
    });
  }, []);
  const insertPage = (editor) => ({
    title: "Page",
    onItemClick: () => {handleCreateNewPage(editor,{type: "page",})},
    group: "Folder",
    icon: <AiTwotoneBook />
  ,
  });
  const insertAi = (editor) => ({
    title: "Write",
    // onItemClick: () => {handleAiPrompt(editor,{type: "ai",})},
    onItemClick: () => {setAiPromptVisible(true)},
    group: "✨Mosaik Ai",
    icon: <FaWandSparkles
    style={{
      color: '#1a73e8', // Set the primary color for the AI icon
      cursor: 'pointer',  // Add a pointer cursor to make it feel clickable
    }}
  />
  ,
  });
  const insertYoutube = (editor) => ({
    title: "Youtube",
    onItemClick: (event) => {
      insertOrUpdateBlock(editor, { type: "youtube" });
    },
    group: "media",
    icon: <FaYoutube />,
  });
  
  const handleAiSubmitClick = async () => {
    setLoading(true); // Show loader
    await handleAiPrompt(editor, aiPromptInput);
    setLoading(false); // Hide loader after submission
    setAiPromptVisible(false); // Hide the input after submitting
    setAiPromptInput(''); // Clear the input field
  };
  // Fetch users for mentions
  useEffect(() => {
    const fetchAndSetUser = async () => {
      const usersData = await fetchUser();
      const users = usersData?.map((user) => ({
        firstName: user.firstName,
        id: user.id,
      }));
      setUserName(users);
    };
    fetchAndSetUser();
  }, []);

  // Initialize the editor instance
  const editor = useCreateBlockNote({
    schema,
    initialContent:
      blocks.length > 0
        ? blocks
        : [
            {
              type: "paragraph",
              content: [""],
            },
          ],
    uploadFile,
  });

  return (
    <EditorContext.Provider value={editor}>
      {" "}
      {/* Provide the editor instance via context */}
      <div className="wrapper">
        <div className="blocknote-container">
        {aiPromptVisible && (
  <div className="ai-popup-backdrop">
    <div className="ai-popup-container">
      <input
        className="ai-input"
        type="text"
        placeholder="How to tie a tie"
        value={aiPromptInput}
        onChange={(e) => setAiPromptInput(e.target.value)} // Set the input value
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAiSubmitClick(); // Auto press Ask button on Enter key press
          }
        }}
      />
      <button
        className={`ai-submit-button ${loading ? 'loading' : ''}`}
        onClick={handleAiSubmitClick}
        disabled={loading}
      >
        {loading ? <div className="loader"></div> : 'Ask'}
      </button>
    </div>
  </div>
)}
          <BlockNoteView
            editor={editor}
            onChange={() => setBlocks(editor.document)}
            ref={editorViewRef}
            data-theming-css-variables-demo
            sideMenu={false}
            slashMenu={false}
          >
            {/* Suggestion menu for mentions */}
            <SuggestionMenuController
              triggerCharacter={"@"}
              getItems={async (query) =>
                filterSuggestionItems(getMentionMenuItems(editor), query)
              }
            />
            {/* Suggestion menu for creating a new page */}
            <SuggestionMenuController
              triggerCharacter={"/"}
              getItems={async (query) =>
                filterSuggestionItems(
                  [...getDefaultReactSlashMenuItems(editor), insertPage(editor),insertYoutube(editor),insertAi(editor)],
                  query
                )
              }
            />
            {/* this is block manue component section  */}
            <SideMenuController
              sideMenu={(props) => (
                <SideMenu
                  {...props}
                  dragHandleMenu={(props) => (
                    <DragHandleMenu {...props}>
                      {/* <RemoveBlockItem {...props}>Delete</RemoveBlockItem> */}
                      <CustomRemoveBlockButton {...props}>
                        Delete
                      </CustomRemoveBlockButton>
                      <BlockColorsItem {...props}>Colors</BlockColorsItem>
                    </DragHandleMenu>
                  )}
                />
              )}
            />
          </BlockNoteView>
        </div>
      </div>
    </EditorContext.Provider>
  );
}
