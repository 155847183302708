import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './PasswordChanged.module.css';
import checkIcon from '../assets/icons/check-icon.png';
import logo from '../assets/images/logo.png';

const PasswordChanged = () => {
  const navigate = useNavigate();

  return (
    <main className={styles.passwordChangedContainer}>
      <img src={logo} alt="Mosaik Logo" className={styles.logo} />
      <section className={styles.passwordChangedCard}>
        <div className={styles.header}>
          <h1 className={styles.heading}>Password Reset</h1>
        </div>
        <div className={styles.divider}></div>
        <img src={checkIcon} alt="Success" className={styles.successIcon} />
        <h1 className={styles.successHeading}>Success!</h1>
        <p className={styles.instructionText}>Your password has been reset successfully.</p>
        <button onClick={() => navigate('/login')} className={styles.returnButton}>
          Return to Login
        </button>
      </section>
    </main>
  );
};

export default PasswordChanged;
