import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'; // Import for translation
import styles from "./OrderDetail.module.css";

const AdminActions = ({ status, onChangeStatus }) => {
  const { t } = useTranslation(); // Translation hook

  // Define the actions based on the current status
  const renderActions = () => {
    switch (status?.toLowerCase()) {
      case "pending":
        return (
          <button
            className={styles.changeStatusButton}
            onClick={() => onChangeStatus("ordered")}
          >
            {t('Place Order')}
          </button>
        );
      case "ordered":
        return (
          <button
            className={styles.changeStatusButton}
            onClick={() => onChangeStatus("delivered")}
          >
            {t('Mark as Arrived')}
          </button>
        );
      case "delivered":
        return (
          <button
            className={styles.changeStatusButton}
            onClick={() => onChangeStatus("completed")}
          >
            {t('Mark as Completed')}
          </button>
        );
      default:
        return null;
    }
  };

  return <div className={styles.adminActionsContainer}>{renderActions()}</div>;
};

// Define PropTypes for better type checking
AdminActions.propTypes = {
  status: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
};

export default AdminActions;
