import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CreateContent.module.css";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import Editor from "../Editor/Editor";
import { extractTextFromBlocks } from "../../utils/extractText";
import { useTranslation } from 'react-i18next';

function CreateResource() {
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);
  const [schema, setschema] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [blocks, setBlocks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSave = async () => {
    const selectedCategory = categories.find(
      (cat) => cat.id === selectedCategoryId
    );

    let resourceTitle = extractTextFromBlocks(blocks);

    if (!resourceTitle) {
      resourceTitle = t("Default Name");
    }

    if (resourceTitle.length > 1000) {
      setError(t("Resource title must be 1000 characters or fewer."));
      return;
    }

    const payload = {
      content: blocks,
      name: resourceTitle,
    };

    try {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        setError(t("Authentication token not found. Please log in."));
        return;
      }

      const response = await fetch(`${apiUrl}/libraryContents`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error(t("Backend Error Response:"), errorData);
        throw new Error(
          errorData.message ||
            `Error: ${response.status} ${response.statusText}`
        );
      }

      setSuccessMessage(t("Resource successfully created!"));
      setError(null);
    } catch (err) {
      console.error(t("Error adding folder:"), err);
      setError(err.message);
      setSuccessMessage(null);
    }
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t("Add Resource")} />
      <main className={styles.createResource}>
        {successMessage && (
          <p className={styles.successText}>{t(successMessage)}</p>
        )}
        {error && <p className={styles.errorText}>{t('Error')}: {t(error)}</p>}
        <div className={styles.editorContainer}>
          <Editor blocks={blocks} setBlocks={setBlocks} schema={schema} />
          <button
            className={styles.mosaikButton}
            onClick={handleSave}
            disabled={!blocks.length}
          >
            {t('Save')}
          </button>
        </div>
      </main>
      <MosaikFooter />
    </div>
  );
}

export default CreateResource;
