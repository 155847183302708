import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './CreateEvent.module.css';
import { crossIcon, plusIcon } from '../../constants';

const EventAgenda = ({ onAgendaChange, agenda }) => {
  const { t } = useTranslation(); // Initialize t
  const [agendaItems, setAgendaItems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [newAgenda, setNewAgenda] = useState({ title: '', startTime: '', endTime: '' });
 

  useEffect(() => {
    if (agenda) {
      console.log('Initial agenda:', agenda);
      
      // Handle both string and object items
      const formattedAgenda = agenda.map((item, index) => {
        console.log(`Agenda item ${index}:`, item);

        // If the item is a string, extract the title, startTime, and endTime
        if (typeof item === 'string') {
          const match = item.match(/(.*)\s\((.*)\s-\s(.*)\)/);
          if (match) {
            return {
              title: match[1].trim(),
              startTime: match[2].trim(),
              endTime: match[3].trim(),
            };
          }
          return { title: item, startTime: '', endTime: '' }; // Fallback if parsing fails
        }

        // If the item is already an object, format times correctly
        return {
          title: item.title,
          startTime: item.startTime ? moment(item.startTime, 'HH:mm').format('HH:mm') : '',
          endTime: item.endTime ? moment(item.endTime, 'HH:mm').format('HH:mm') : '',
        };
      });

      console.log('Formatted agenda:', formattedAgenda); // Debugging formatted agenda
      setAgendaItems(formattedAgenda);
    }
  }, [agenda]);

  const handleAddAgenda = () => {
    setShowPopup(true);
    setNewAgenda({ title: '', startTime: '', endTime: '' }); // Reset form for new agenda
  };

  const handleConfirmAddAgenda = () => {
    const updatedAgendas = [...agendaItems, newAgenda];
    setAgendaItems(updatedAgendas);
    setShowPopup(false);
    setNewAgenda({ title: '', startTime: '', endTime: '' }); // Clear form
    onAgendaChange(updatedAgendas); // Pass updated agenda back to parent
  };

  const handleRemoveAgenda = (index) => {
    const updatedAgendas = agendaItems.filter((_, i) => i !== index);
    setAgendaItems(updatedAgendas);
    onAgendaChange(updatedAgendas); // Update agenda in parent
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAgenda({ ...newAgenda, [name]: value });
  };

  return (
    <section className={styles.eventAgenda}>
      <h2 className={styles.sectionTitle}>{t("Event's Agenda")}</h2>
      <ul className={styles.agendaList}>
        {agendaItems.map((item, index) => (
          <li key={index} className={styles.agendaItem}>
            <img
              onClick={() => handleRemoveAgenda(index)}
              src={crossIcon}
              alt="Agenda item"
              className={styles.agendaIcon}
            />
            <div className={styles.agendaDetails}>
              <span className={styles.agendaTitle}>{item.title}</span>
              <span className={styles.agendaTime}>
                {item.startTime} - {item.endTime}
              </span>
            </div>
          </li>
        ))}
      </ul>
      <button className={styles.addAgendaButton} onClick={handleAddAgenda}>
        <img
          src={plusIcon}
          alt="Add"
          className={styles.addIcon}
        />
        {agendaItems.length === 0 ? t('Add Agenda') : t('Add Another')}
      </button>

      {showPopup && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <h3 className={styles.modalTitle}>{t('Add Agenda Item')}</h3>
            
            <div className={styles.formGroup}>
              <label htmlFor="title" className={styles.label}>{t('Title')}</label>
              <input
                type="text"
                id="title"
                name="title"
                value={newAgenda.title}
                onChange={handleInputChange}
                className={styles.input}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="startTime" className={styles.label}>{t('Start Time')}</label>
              <input
                type="time"
                id="startTime"
                name="startTime"
                value={newAgenda.startTime}
                onChange={handleInputChange}
                className={styles.input}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="endTime" className={styles.label}>{t('End Time')}</label>
              <input
                type="time"
                id="endTime"
                name="endTime"
                value={newAgenda.endTime}
                onChange={handleInputChange}
                className={styles.input}
              />
            </div>
            
            <div className={styles.buttonGroup}>
              <button onClick={() => setShowPopup(false)} className={styles.cancelButton}>
                {t('Cancel')}
              </button>
              <button onClick={handleConfirmAddAgenda} className={styles.confirmButton}>
                {t('Confirm')}
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default EventAgenda;
