import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import styles from "./InvitationsList.module.css"; // Update this to your actual path
import MosaikHeaderWithBack from "./MosaikHeaderWithBack";
import MosaikFooter from "./MosaikFooter";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const InvitationsList = ({ onAccept, onDecline }) => {
  const { t } = useTranslation();
  const [invitations, setInvitations] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { authState, setAuthState } = useContext(AuthContext);
  const token = authState.accessToken;
  const navigate = useNavigate();
  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/invitations/get-received-invitations`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        // Check if data is an array, otherwise, convert it into an array
        const invitationsArray = Array.isArray(data) ? data : [data];
        setInvitations(invitationsArray);
      } catch (error) {
        console.error(t("Error fetching invitations:"), error);
      }
    };

    fetchInvitations();
  }, [apiUrl]);

  const handleAcceptInvitation = async (id) => {
    const userId = authState.user?.id;
    try {
      const inviteResponse = await fetch(
        `${apiUrl}/invitations/accept-invitation/${id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (inviteResponse.ok) {
        alert(t("Invitation Accepted!"));
        // Update the status locally if the API request is successful
        setInvitations((prevInvitations) =>
          prevInvitations.map((inv) =>
            inv.id === id ? { ...inv, status: "Accepted" } : inv
          )
        );
      } else {
        console.error(t("Failed to accept the invitation"));
      }
      const responseData = await inviteResponse.json();
      console.log("Response Data:", responseData);
      const requestBody = { organisationId: responseData.organisationId };
      const switchResponse = await axios.post(
        `${apiUrl}/auth/switch-organisation`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (switchResponse.status === 200 || switchResponse.status === 201) {
        const newAccessToken =
          switchResponse.data.accessToken || switchResponse.data;

        if (!newAccessToken) {
          console.error(t("No accessToken received from the server."));
          return;
        }
        const updatedSession = await axios.post(
          `${apiUrl}/auth/get-session`,
          {},
          {
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
            },
          }
        );

        // Update authState and localStorage
        const updatedAuthState = {
          ...authState,
          accessToken: newAccessToken,
          user: {
            ...updatedSession?.data,
          },
        };
        setAuthState(updatedAuthState);
        // Update localStorage
        localStorage.setItem("accessToken", newAccessToken);
        localStorage.setItem("authState", JSON.stringify(updatedAuthState));
        localStorage.setItem("userInfo", JSON.stringify(updatedSession?.data));
        navigate("/mosaik-home");
      }
    } catch (error) {
      console.error(t("Error accepting invitation:"), error);
    }
  };

  const handleDeclineInvitation = async (id) => {
    try {
      const response = await fetch(
        `${apiUrl}/invitations/cancel-invitation/${id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Update the status locally if the API request is successful
        setInvitations((prevInvitations) =>
          prevInvitations.map((inv) =>
            inv.id === id ? { ...inv, status: "Declined" } : inv
          )
        );
        alert(t("Invitation Declined!"));
        navigate("/mosaik-home");
      } else {
        console.error(t("Failed to decline the invitation"));
      }
    } catch (error) {
      console.error(t("Error declining invitation:"), error);
    }
  };
  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t("Alerts")} />
      <div className={styles.invitationsSection}>
        <h3 className={styles.invitationText}>{t("Invitations")}</h3>
        {invitations.length > 0 ? (
          <ul className={styles.invitationsList}>
            {invitations.map((invitation, index) => (
              <li
                key={invitation.id || index}
                className={styles.invitationItem}
              >
                <p>
                  {t("Invitation from:")} {invitation.invitedByName} (
                  {t("Organization:")} {invitation.organisationName})
                </p>
                <p>
                  {t("Status:")} {t(invitation.status)}
                </p>
                {invitation.status === "Pending" && (
                  <div className={styles.invitationButtons}>
                    <button
                      className={styles.actionButtons}
                      onClick={() =>
                        handleAcceptInvitation(invitation?.invitationId)
                      }
                    >
                      {t("Accept")}
                    </button>
                    <button
                      className={styles.actionButtons}
                      onClick={() =>
                        handleDeclineInvitation(invitation?.invitationId)
                      }
                    >
                      {t("Decline")}
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.noInvitationsMessage}>
            {t("No new invitations")}
          </p>
        )}
        <MosaikFooter /> {/* Pass the alert count */}
      </div>
    </div>
  );
};

export default InvitationsList;
