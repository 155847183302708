 const handleAiSubmit = async (aiPrompt) => {
  if (!aiPrompt) return;

  const apiUrl = process.env.REACT_APP_API_URL;
  const payload = {
    agentName: 'ollama-v1-chat-completions-gemma2:2b',
    agentVersion: 1,
    messages: [
      {
        role: 'user',
        content: aiPrompt,
      },
    ],
  };

  try {
    const response = await fetch(`${apiUrl}/agents/invoke`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch AI response');
    }

    const data = await response.json();
    return data?.response?.choices[0]?.message?.content || 'No response from AI';
  } catch (error) {
    console.error('Error fetching AI response:', error);
  }
};

export const handleAiPrompt = async (editor,aiPromptInput) => {

  // const aiPrompt = prompt("Enter a prompt for the AI", "how to bake a cake");
  const aiPrompt = aiPromptInput;
  if (!aiPrompt) return;

  const AiResponse = await handleAiSubmit(aiPrompt);

  if (AiResponse) {
    // Initialize an array to hold the new blocks
    const newContent = [
      // {
      //   type: 'paragraph',
      //   content: [`Prompt: ${aiPrompt}`], // Start with the prompt as a paragraph
      //   children: [], // Ensure no nested blocks
      // }
    ];

    // Function to detect and replace **bold** with bold text
    const formatBoldText = (text) => {
      const boldRegex = /\*\*(.*?)\*\*/g;
      const parts = [];
      let lastIndex = 0;
      let match;

      while ((match = boldRegex.exec(text)) !== null) {
        if (match.index > lastIndex) {
          parts.push(text.slice(lastIndex, match.index)); // Add normal text before bold
        }
        parts.push({ type: 'text', text: match[1], styles: { bold: true } }); // Add bold text
        lastIndex = boldRegex.lastIndex;
      }

      if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex)); // Add remaining normal text
      }

      return parts;
    };

    // Process the AI response into blocks
    AiResponse.split('\n').forEach((line) => {
      if (line.startsWith('## ')) {
        // Add heading level 2 block (h2)
        newContent.push({
          type: 'heading',
          props: { level: 2 },
          content: [line.replace('## ', '')],
          children: []
        });
      } else if (line.startsWith('# ')) {
        // Add heading level 1 block (h1)
        newContent.push({
          type: 'heading',
          props: { level: 1 },
          content: [line.replace('# ', '')],
          children: []
        });
      }
      else if (line.startsWith('### ')) {
        // Add heading level 1 block (h1)
        newContent.push({
          type: 'heading',
          props: { level: 3 },
          content: [line.replace('### ', '')],
          children: []
        });
      } else if (line.startsWith('** ')) {
        // Add bullet list item block, also handle bold text within the item
        newContent.push({
          type: 'numberedListItem',
          content: formatBoldText(line.replace('** ', '')),
          children: []
        });
      }
      else if (line.startsWith('* ')) {
        // Add bullet list item block, also handle bold text within the item
        newContent.push({
          type: 'bulletListItem',
          content: formatBoldText(line.replace('* ', '')),
          children: []
        });
      } else {
        // Treat all other lines as paragraphs, also handle bold text
        newContent.push({
          type: 'paragraph',
          content: formatBoldText(line),
          children: []
        });
      }
    });

    // Insert the new content at the end of the existing document
    const cursorPosition = editor.getTextCursorPosition();

    // Insert the new content after the block where the caret is
    if (cursorPosition) {
      editor.insertBlocks(newContent, cursorPosition.block, 'after');
    }
  }
};