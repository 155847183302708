// src/components/chats/attachments.js

import React, { useState, useContext, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  IconButton,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
} from '@mui/material';
import {
  AttachFile as AttachFileIcon,
  Link as LinkIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { WebSocketContext } from '../../context/RocketchatContext';
import { AuthContext } from '../../context/AuthContext';
import { uploadFile } from '../../services/mosaikUploadService'; // Named import
import axios from 'axios'; // Ensure axios is installed and imported
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

// Define the endpoints similar to MosaikHome
const endpoints = [
  {
    name: "Events",
    url: "/events",
    searchFields: [
      { field: "title", operator: "contains" },
      { field: "description", operator: "contains" },
      { field: "name", operator: "contains" },
    ],
  },
  // Add other endpoints as needed
];

const Attachments = ({ roomId }) => {
  const { t } = useTranslation();
  const { sendMessage } = useContext(WebSocketContext);
  const { authState } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  
  // Upload File State
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  
  // Share Resource State
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = authState.accessToken;

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Open and Close Dialog
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // Reset states when closing
    setTabIndex(0);
    setSelectedFile(null);
    setSearchTerm('');
    setSearchResults([]);
    setNoResultsFound(false);
  };

  // Handle File Selection
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Handle File Upload using uploadFile from mosaikUploadService.js
  const handleUpload = async () => {
    if (!selectedFile) return;
    setUploading(true);
    try {
      // Use the uploadFile function to upload the selected file
      const uploadResponse = await uploadFile(selectedFile, 'chat', selectedFile.name);
      
      // Extract file URL and type from the upload response
      const { fileUrl, fileType, fileName } = uploadResponse;

      // Determine attachment type based on fileType
      let attachmentType = 'file';
      let attachment = {};

      if (fileType.startsWith('image')) {
        attachmentType = 'image';
        attachment = {
          type: attachmentType,
          title: fileName,
          image_url: fileUrl,
        };
      } else if (fileType.startsWith('audio')) {
        attachmentType = 'audio';
        attachment = {
          type: attachmentType,
          title: fileName,
          audio_url: fileUrl,
        };
      } else if (fileType.startsWith('video')) {
        attachmentType = 'video';
        attachment = {
          type: attachmentType,
          title: fileName,
          video_url: fileUrl,
        };
      } else {
        // For other file types
        attachment = {
          type: attachmentType,
          title: fileName,
          file_url: fileUrl,
        };
      }

      // Send message with attachment using sendMessage from WebSocketContext
      await sendMessage(
        '', // Empty message text
        roomId,
        null,
        null,
        attachment,
        null
      );

      setUploading(false);
      setSelectedFile(null);
      setOpen(false);
    } catch (error) {
      console.error(t("Error uploading file:"), error);
      setUploading(false);
      // Optionally, display an error snackbar or message
    }
  };

  // Debounced Search Function
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.trim() === "") {
        setSearchResults([]);
        setIsSearching(false);
        setNoResultsFound(false);
        return;
      }

      setIsSearching(true);
      setNoResultsFound(false);

      try {
        const searchPromises = endpoints.map(async (endpoint) => {
          const searchFields = endpoint.searchFields;

          const orClauses = searchFields.map(({ field, operator }) => {
            const filter = { mode: "insensitive" };
            if (operator === "contains") {
              filter.contains = query;
            } else if (operator === "equals") {
              filter.equals = query;
            } else {
              filter.contains = query;
            }
            return { [field]: filter };
          });

          const whereClause = {
            organisation: { id: authState.currentOrganisationId },
            OR: orClauses,
          };

          try {
            const response = await axios.get(`${apiUrl}${endpoint.url}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                where: whereClause,
              },
              paramsSerializer: (params) => {
                return qs.stringify(params, { encode: false });
              },
            });

            return {
              type: endpoint.name,
              data: response.data,
            };
          } catch (error) {
            console.error(
              t("Error fetching {{endpoint}}:", { endpoint: endpoint.name }),
              error.response?.data || error.message
            );
            return { type: endpoint.name, data: [] };
          }
        });

        const results = await Promise.all(searchPromises);

        const combinedResults = results.flatMap((result) =>
          result.data.map((item) => ({
            ...item,
            type: result.type,
          }))
        );

        if (combinedResults.length === 0) {
          setNoResultsFound(true);
        }

        setSearchResults(combinedResults);
        setIsSearching(false);
      } catch (error) {
        console.error(t("Error during search:"), error);
        setIsSearching(false);
      }
    }, 500),
    [apiUrl, token, authState.currentOrganisationId, t]
  );

  // Handle Search Input Change
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    debouncedSearch(e.target.value);
  };

  // Handle Sharing Resource as Link
  const handleShareResource = async (resource) => {
    const resourceUrl = `${window.location.origin}/${resource.type.toLowerCase()}/${resource.id}`;
    const messageText = `[${resource.name || resource.title}](${resourceUrl})`; // Markdown link format

    try {
      await sendMessage(
        messageText,
        roomId,
        null,
        null,
        null,
        null
      );
      setOpen(false);
      setSearchTerm('');
      setSearchResults([]);
    } catch (error) {
      console.error(t("Error sharing resource:"), error);
      // Optionally, display an error snackbar or message
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen} aria-label={t("Add Attachment")}>
        <AttachFileIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {t("Add Attachment")}
          <IconButton
            aria-label={t("Close")}
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="attachments tabs">
            <Tab label={t("Upload File")} icon={<AttachFileIcon />} />
            <Tab label={t("Share Resource")} icon={<LinkIcon />} />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {tabIndex === 0 && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<AttachFileIcon />}
                >
                  {t("Select File")}
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {selectedFile && (
                  <Box mt={2} textAlign="center">
                    <Typography variant="body1">{selectedFile.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {Math.round(selectedFile.size / 1024)} KB
                    </Typography>
                  </Box>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                  onClick={handleUpload}
                  disabled={!selectedFile || uploading}
                >
                  {uploading ? <CircularProgress size={24} /> : t("Upload")}
                </Button>
              </Box>
            )}
            {tabIndex === 1 && (
              <Box>
                <Box display="flex" alignItems="center" mb={2}>
                  <SearchIcon color="action" sx={{ mr: 1 }} />
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t("Search resources...")}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                  />
                </Box>
                {isSearching && (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                )}
                {noResultsFound && (
                  <Typography variant="body2" color="textSecondary" align="center">
                    {t("No resources found.")}
                  </Typography>
                )}
                <List>
                  {searchResults.map((result) => (
                    <ListItem
                      key={`${result.type}-${result.id}`}
                      button
                      onClick={() => handleShareResource(result)}
                    >
                      <ListItemText
                        primary={result.name || result.title}
                        secondary={result.description || ''}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Attachments;
