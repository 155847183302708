import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Unauthorized.module.css';
import unauthorizedAnimation from '../assets/animation/unauthorized.webm';

const Unauthorized = () => {
  return (
    <div className={styles.unauthorizedContainer}>
      <div className={styles.unauthorizedCard}>
        <div className={styles.animationContainer}>
          <video
            className={styles.unauthorizedVideo}
            src={unauthorizedAnimation}
            autoPlay
            loop
            muted
            playsInline
          />
        </div>
        <h2 className={styles.unauthorizedTitle}>Access Denied</h2>
        <p className={styles.unauthorizedText}>
          Oops! You don’t have permission to view this page. To access this resource, please register as an organization.
        </p>
        <div className={styles.buttonGroups}>
          <Link to="/mosaik-home" className={styles.homeButton}>
            Back to Home
          </Link>
          <Link to="/memberships" className={styles.membershipButton}>
            Go to Membership
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
