import React, { useEffect, useRef, useState } from 'react';
import * as Y from 'yjs';
import { WebsocketProvider } from 'y-websocket';
import { useCreateBlockNote } from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import { useParams } from 'react-router-dom';
import './CollaborativeEditor.css';

const userColors = [
  { color: '#30bced', light: '#30bced33' },
  { color: '#6eeb83', light: '#6eeb8333' },
  { color: '#ffbc42', light: '#ffbc4233' },
];

const getRandomUserColor = () => {
  return userColors[Math.floor(Math.random() * userColors.length)];
};

const CollaborativeEditor = () => {
  const ydocRef = useRef(new Y.Doc());
  const providerRef = useRef(null);
  const { documentName } = useParams();
  const randomUserName = `User_${Math.floor(Math.random() * 10000)}`;
  const userColor = getRandomUserColor();
  
  const [users, setUsers] = useState({});
  const [cursorPositions, setCursorPositions] = useState({});

  useEffect(() => {
    const initializeProvider = async () => {
      const authToken = localStorage.getItem("accessToken");
      if (!authToken) {
        console.error('Authentication token is required to connect.');
        return;
      }

      const room = 'y-redis-demo-app';
      const provider = new WebsocketProvider(
        `${process.env.REACT_APP_WEBSOCKET_URL}/${room}`,
        documentName,
        ydocRef.current,
        { params: { yauth: authToken } }
      );
      providerRef.current = provider;

      provider.awareness.setLocalStateField('user', {
        name: randomUserName,
        color: userColor.color,
        colorLight: userColor.light,
      });

      provider.awareness.on('change', () => {
        const states = Array.from(provider.awareness.getStates().values());
        const updatedUsers = {};
        states.forEach(state => {
          if (state.user) {
            updatedUsers[state.user.name] = state.user;
          }
        });
        setUsers(updatedUsers);
      });
    };

    initializeProvider();

    return () => {
      if (providerRef.current) providerRef.current.destroy();
      if (ydocRef.current) ydocRef.current.destroy();
    };
  }, [documentName, randomUserName, userColor]);

  const editor = useCreateBlockNote({
    collaboration: {
      provider: providerRef.current,
      fragment: ydocRef.current.getXmlFragment('document-store'),
      user: {
        name: randomUserName,
        color: userColor.color,
      },
    },
  });

  // Update cursor positions based on the current selection
  useEffect(() => {
    const updateCursorPosition = () => {
      const position = editor.getTextCursorPosition();
      if (position) {
        setCursorPositions(prev => ({
          ...prev,
          [randomUserName]: position,
        }));
      }
    };

    const intervalId = setInterval(updateCursorPosition, 100); // Check cursor position every 100ms

    return () => clearInterval(intervalId);
  }, [editor, randomUserName]);

  return (
    <div className="collaborative-editor-container">
      <div className="blocknote-wrapper">
        <BlockNoteView editor={editor} />
        {/* {Object.entries(cursorPositions).map(([userName, position]) => (
          <div
            key={userName}
            style={{
              position: 'absolute',
              left: position.x, // Ensure this is relative to the container
              top: position.y,   // Ensure this is relative to the container
              backgroundColor: userColor.light,
              color: userColor.color,
              padding: '2px 5px',
              borderRadius: '3px',
              pointerEvents: 'none',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {userName}
          </div>
        ))} */}
      </div>
      <button
        type="button"
        onClick={() => {
          if (providerRef.current) {
            providerRef.current.disconnect();
            console.log('Disconnected from collaboration session');
          }
        }}
      >
        Disconnect
      </button>
    </div>
  );
};

export default CollaborativeEditor;
