// src/hooks/useFetchChatInfo.js

import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

export const useFetchChatInfo = (id) => {
  const { authState } = useContext(AuthContext);
  const [chatInfo, setChatInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      setError('No chat room ID provided.');
      setLoading(false);
      return;
    }

    const fetchChatInfo = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/chats/room/${id}`, {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        });
        setChatInfo(response.data);
      } catch (err) {
        console.error('Error fetching chat room info:', err);
        setError(err.response?.data?.message || 'Failed to fetch chat room info.');
      } finally {
        setLoading(false);
      }
    };

    fetchChatInfo();
  }, [id, authState.accessToken]);

  // Ensure we only access `chatInfo.room` if `chatInfo` and `chatInfo.room` are defined
  const isGroupChat = chatInfo?.room && chatInfo.room.fname !== undefined;

  const groupDetails = isGroupChat
    ? {
        members: chatInfo?.members || [],
        name: chatInfo.room.fname || chatInfo.room.name || 'Unnamed Group',
        description: chatInfo.room.customFields?.description || '',
        avatar: chatInfo.room.customFields?.avatar || null,
      }
    : null;

  const directChatDetails = !isGroupChat
    ? {
        participants: chatInfo?.room?.usernames || [],
      }
    : null;

  return { 
    chatInfo,
    groupInfo: groupDetails,
    directChatInfo: directChatDetails,
    loading, 
    error 
  };
};
