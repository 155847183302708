import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './CreateResource.module.css';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';

import AttachmenttIcon from '../../assets/images/attachment-icon.png';
import documentIcon from '../../assets/images/document.png';
import manualIcon from '../../assets/images/manual.png';
import checklistIcon from '../../assets/images/checklist.png';
import meetingProtocolsIcon from '../../assets/images/protocols.png';

import Editor from '../Editor/Editor'; // Assuming this is the BlockNoteJS editor component
import { useTranslation } from 'react-i18next';

function CreateResource() {
  const { t } = useTranslation();

  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [selectedCategoryType, setSelectedCategoryType] = useState('Document');
  const [categoryImage, setCategoryImage] = useState('/assets/images/placeholder.webp'); // Default placeholder image
  const [parentCategory, setParentCategory] = useState(null);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch available categories for subcategory selection
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${apiUrl}/libraryCategories?where%5BisPrivate%5D[equals]=true`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setAvailableCategories(response.data);
      } catch (error) {
        console.error(t('Error fetching categories:'), error);
      }
    };

    fetchCategories();
  }, [apiUrl]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCategoryImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveCategory = async () => {
    const payload = {
      name: categoryName,
      description: categoryDescription,
      categoryType: selectedCategoryType.toLowerCase(),
      structure: blocks, // Assuming blocks are handled by the editor
      enableReadReceipts: true,
      isPrivate: true,
      parentCategory: parentCategory ? { id: parentCategory } : undefined,
    };

    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${apiUrl}/libraryCategories`, payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      navigate('/mosaik-library');
    } catch (error) {
      console.error(t('Error creating category:'), error.response?.data?.message || error.message);
    }
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Add New Resource')} />
      <main className={styles.createResource}>
        <h2>{t('Create New Resource')}</h2>
        {/* Resource Title */}
        <label htmlFor="categoryName">{t('Title')}</label>
        <input
          type="text"
          id="categoryName"
          className={styles.inputField}
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />

        {/* Resource Description */}
        <label htmlFor="categoryDescription">{t('Description')}</label>
        <textarea
          id="categoryDescription"
          className={styles.textAreaField}
          value={categoryDescription}
          onChange={(e) => setCategoryDescription(e.target.value)}
        />

        {/* Image Upload */}
        <div className={styles.iconUpload}>
          <p>{t('Image')}</p>
          <input
            type="file"
            accept="image/*"
            id="categoryImage"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
          <button
            className={styles.uploadButton}
            onClick={() => document.getElementById('categoryImage').click()}
          >
            {categoryImage ? (
              <img
                src={categoryImage}
                alt={t('Category Visual Identity')}
                className={styles.iconPreview}
              />
            ) : (
              <img src={AttachmenttIcon} alt={t('Upload Icon')} className={styles.icon} />
            )}
          </button>
        </div>

        {/* Category Type Selection */}
        <div className={styles.categoryType}>
          <h2>{t('Category Type')}</h2>
          <div className={styles.typeGrid}>
            <button
              className={`${styles.typeButton} ${
                selectedCategoryType === 'Document' ? styles.selected : ''
              }`}
              onClick={() => setSelectedCategoryType('Document')}
            >
              <img src={documentIcon} alt={t('Document')} />
              <p>{t('Document')}</p>
            </button>
            <button
              className={`${styles.typeButton} ${
                selectedCategoryType === 'Manual' ? styles.selected : ''
              }`}
              onClick={() => setSelectedCategoryType('Manual')}
            >
              <img src={manualIcon} alt={t('Manual')} />
              <p>{t('Manual')}</p>
            </button>
            <button
              className={`${styles.typeButton} ${
                selectedCategoryType === 'Checklist' ? styles.selected : ''
              }`}
              onClick={() => setSelectedCategoryType('Checklist')}
            >
              <img src={checklistIcon} alt={t('Checklist')} />
              <p>{t('Checklist')}</p>
            </button>
            <button
              className={`${styles.typeButton} ${
                selectedCategoryType === 'Meeting Protocols' ? styles.selected : ''
              }`}
              onClick={() => setSelectedCategoryType('Meeting Protocols')}
            >
              <img src={meetingProtocolsIcon} alt={t('Meeting Protocols')} />
              <p>{t('Meeting Protocols')}</p>
            </button>
          </div>
        </div>

        {/* Editor for Content */}
        <div className={styles.editorContainer}>
          <h2>{t('Content')}</h2>
          <Editor setBlocks={setBlocks} blocks={blocks} />
        </div>

        {/* Subcategory Selection */}
        <div className={styles.subCategory}>
          <h2>{t('Subcategory')}</h2>
          <div className={styles.subCategoryList}>
            {availableCategories.map((category) => (
              <button
                key={category.id}
                className={`${styles.subCategoryButton} ${
                  parentCategory === category.id ? styles.selected : ''
                }`}
                onClick={() => setParentCategory(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {/* Save Button */}
        <button className={styles.mosaikButton} onClick={handleSaveCategory}>
          {t('Save')}
        </button>
      </main>
      <MosaikFooter />
    </div>
  );
}

export default CreateResource;
