import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import MosaikWelcomeScreen from '../MosaikWelcomeScreen';
import welcomeIcon from '../../assets/images/support-iconwhite.png';
import welcomeBgimage from '../../assets/images/support-bg.jpg';
import searchIcon from '../../assets/images/search-button.png';
import addIcon from '../../assets/images/plus-button.png';
import viewIcon from '../../assets/images/view-icon.png';
import calendarIcon from '../../assets/images/calendar-iconinverted.png';
import styles from './MosaikSupport.module.css';

const MosaikSupport = () => {
  const { t } = useTranslation();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [openTasks, setOpenTasks] = useState([]);
  const [pastTasks, setPastTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('Resolved');
  const navigate = useNavigate();
  const SUPPORT_VEDIO =process.env.REACT_APP_SUPPORT_VEDIO
  // New state variables for categorized tickets
  const [resolvedTasks, setResolvedTasks] = useState([]);
  const [closedTasks, setClosedTasks] = useState([]);
  const [onHoldTasks, setOnHoldTasks] = useState([]);
  const [repliedTasks, setRepliedTasks] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const storedUser = localStorage.getItem('userInfo');
      const user = JSON.parse(storedUser);
      const userId = user.id;

      try {
        const response = await axios.get(`${apiUrl}/supports/${userId}/tasks`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });

        const allTasks = response.data;

        allTasks.sort((a, b) => b.ticketId - a.ticketId);

        setTasks(allTasks);

        const openTasks = allTasks.filter(task => task.status === 'Open');
        const resolvedTasks = allTasks.filter(task => task.status === 'Resolved');
        const closedTasks = allTasks.filter(task => task.status === 'Closed');
        const onHoldTasks = allTasks.filter(task => task.status === 'On Hold');
        const repliedTasks = allTasks.filter(task => task.status === 'Replied');

        setOpenTasks(openTasks);
        setPastTasks([...resolvedTasks, ...closedTasks, ...onHoldTasks, ...repliedTasks]);
        setResolvedTasks(resolvedTasks);
        setClosedTasks(closedTasks);
        setOnHoldTasks(onHoldTasks);
        setRepliedTasks(repliedTasks);
        setLoading(false);
      } catch (error) {
        console.error(t('Error fetching tasks:'), error);
        setLoading(false);
      }
    };

    fetchTasks();
  }, []);

  const openTicketDetail = (ticketId) => {
    navigate(`/ticket-detail/${ticketId}`);
  };

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const goToCreateNewTicket = () => {
    navigate('/create-ticket');
  };

  const filteredOpenTasks = openTasks.filter(
    task =>
      task.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredPastTasks = pastTasks.filter(
    task =>
      task.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const statusMapping = {
    Resolved: ['Resolved'],
    Closed: ['Closed'],
    Pending: ['On Hold'],
    Unsolved: ['Open', 'Replied'],
  };

  const filteredTasksByStatus = filteredPastTasks.filter(task =>
    statusMapping[statusFilter].includes(task.status)
  );

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Support')} />
      <div className={styles.parentTickets}>
      <div className={`${styles.ticketSection}`}>
        <div className={styles.ticketSectionHeader}>
          <h3>{t('Open Tickets')}</h3>
          <div className={styles.ticketSearchAndAdd}>
            <button onClick={toggleSearchBar}>
              <img src={searchIcon} alt={t('Search')} />
            </button>
            <button onClick={goToCreateNewTicket}>
              <img src={addIcon} alt={t('Add')} />
            </button>
          </div>
        </div>
        {showSearchBar && (
          <div className={styles.searchField}>
            <input
              type="text"
              placeholder={t('Enter keywords to search...')}
              className={styles.searchInput}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        )}

        {loading ? (
          <p>{t('Loading tasks...')}</p>
        ) : (
          filteredOpenTasks.map(task => (
            <div
              key={task.ticketId}
              className={styles.ticketItem}
              onClick={() => openTicketDetail(task.ticketId)}
            >
              <div className={styles.ticketDetails}>
                <div className={styles.ticketDate}>
                  <img src={calendarIcon} alt={t('Date')} className={styles.calendarIcon} />
                  <span>{task.status}</span>
                </div>
                <h4>{task.subject}</h4>
                <p>{task.description}</p>
                <div className={styles.ticketCategory}>
                  <button className={styles.viewTicketButton}>{t('View Ticket')}</button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div className={styles.ticketSection}>
        <div className={styles.ticketSectionHeader}>
          <h3>{t('Past Tickets')}</h3>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="Resolved">{t('Resolved')}</option>
            <option value="Closed">{t('Closed')}</option>
            <option value="Pending">{t('Pending')}</option>
            <option value="Unsolved">{t('Unsolved')}</option>
          </select>
        </div>
        <div className={styles.categoryButtonContainer}>
          <button
            className={`${styles.categoryButton} ${statusFilter === 'Resolved' ? styles.active : ''}`}
            onClick={() => setStatusFilter('Resolved')}
          >
            {t('Resolved')}
          </button>
          <button
            className={`${styles.categoryButton} ${statusFilter === 'Closed' ? styles.active : ''}`}
            onClick={() => setStatusFilter('Closed')}
          >
            {t('Closed')}
          </button>
          <button
            className={`${styles.categoryButton} ${statusFilter === 'Pending' ? styles.active : ''}`}
            onClick={() => setStatusFilter('Pending')}
          >
            {t('Pending')}
          </button>
          <button
            className={`${styles.categoryButton} ${statusFilter === 'Unsolved' ? styles.active : ''}`}
            onClick={() => setStatusFilter('Unsolved')}
          >
            {t('Unsolved')}
          </button>
          </div></div>

        {loading ? (
          <p>{t('Loading tasks...')}</p>
        ) : (
          filteredTasksByStatus.map(task => (
            <div
              key={task.ticketId}
              className={styles.ticketItem}
              onClick={() => openTicketDetail(task.ticketId)}
            >
              <div className={styles.ticketDetails}>
                <div className={styles.ticketDate}>
                  <img src={calendarIcon} alt={t('Date')} className={styles.calendarIcon} />
                  <span>{new Date(task.createdAt).toLocaleDateString()}</span>
                </div>
                <h4>{task.subject}</h4>
                <div className={styles.ticketCategory}>
                  <p>{task.supportCategory}</p>
                  <button className={styles.viewTicketButton}>{t('View Ticket')}</button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div className={styles.ticketSection}>
        <div className={styles.ticketSectionHeader}>
          <h3>{t('General Feedback Questions')}</h3>
        </div>
        <div className={styles.feedbackQuestion}>
          <button
            onClick={() => window.open(SUPPORT_VEDIO, '_blank')}
          >
            <h4>How support works?</h4>
          </button>
          <div className={styles.viewIcon}>
            <img src={viewIcon} alt={t('View')} />
          </div>
        </div>
      </div>

      <MosaikFooter />
    </div>
  );
};

export default MosaikSupport;
