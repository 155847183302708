// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import enTranslation from './locales/en/translation.json';
import deTranslation from './locales/de/translation.json';
import nlTranslation from './locales/nl/translation.json';
import esTranslation from './locales/es/translation.json';
import thTranslation from './locales/th/translation.json';
import frTranslation from './locales/fr/translation.json';

// Define the available languages and their corresponding translations
const resources = {
  en: { translation: enTranslation },
  de: { translation: deTranslation },
  nl: { translation: nlTranslation },
  es: { translation: esTranslation },
  fr: { translation: frTranslation },
  th: { translation: thTranslation },
};

// Retrieve the saved language from localStorage, default to 'en' if not set
const savedLanguage = localStorage.getItem('language') || 'en';

// Initialize i18n
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources, // Translation resources
    lng: savedLanguage, // Initial language
    fallbackLng: 'en', // Fallback language if translation is missing
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
    react: {
      useSuspense: true, // Enable suspense for async loading
    },
  });

// Optional: Listen for language changes and update localStorage accordingly
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

export default i18n;
