import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './CreateEvent.module.css';
import { searchIcon, selectedIcon } from '../../constants';
import placeholderImage from "../../assets/images/placeholder.webp"; // Import placeholder image
import { getProfilePictureUrl } from '../../utils/getProfileUrl';


const apiUrl = process.env.REACT_APP_API_URL;

const AttendeeList = ({ onSelectAttendees, selectedAttendees = [] }) => {
  const { t } = useTranslation(); // Initialize t
  const [attendees, setAttendees] = useState([]);
  const [selectedAttendeesState, setSelectedAttendeesState] = useState(selectedAttendees);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [sortOption, setSortOption] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchAttendees = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${apiUrl}/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const attendeeData = await Promise.all(
          response.data.map(async (attendee) => {
            try {
              const profileResponse = await axios.get(`${apiUrl}/users/${attendee.id}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              let profilePhotoUrl = await getProfilePictureUrl(profileResponse.data);
              // Ensure the file URL has https:// prefixed or use placeholder if missing
              // let profilePhotoUrl = profileResponse.data.profilePhotoFile?.fileUrl;
              // if (profilePhotoUrl) {
              //   if (!profilePhotoUrl.startsWith('https://')) {
              //     profilePhotoUrl = `https://${profilePhotoUrl}`;
              //   }
              // } else {
              //   profilePhotoUrl = placeholderImage; // Use placeholder if no image URL
              // }

              return {
                ...attendee,
                profilePhotoUrl,
              };
            } catch (error) {
              console.error(t('Error fetching profile photo for attendee:'), attendee.id, error);
              return {
                ...attendee,
                profilePhotoUrl: placeholderImage, // Fallback to placeholder if there's an error
              };
            }
          })
        );

        setAttendees(attendeeData);
      } catch (error) {
        console.error(t('Error fetching attendees:'), error);
      }
    };

    fetchAttendees();
  }, []);

  // Sync the internal selected attendees with the prop when it changes
  useEffect(() => {
    setSelectedAttendeesState(selectedAttendees);
  }, [selectedAttendees]);

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const handleSortChange = (e) => {
    const option = e.target.value;
    setSortOption(option);

    let sortedAttendees = [...attendees];
    if (option === 'alphabetical') {
      sortedAttendees.sort((a, b) => a.firstName.localeCompare(b.firstName));
    } else if (option === 'department') {
      sortedAttendees.sort((a, b) => a.department.localeCompare(b.department) || a.firstName.localeCompare(b.firstName));
    } else if (option === 'age') {
      sortedAttendees.sort((a, b) => a.age - b.age); // Assuming age is available
    } else if (option === 'gender') {
      sortedAttendees.sort((a, b) => a.gender.localeCompare(b.gender) || a.firstName.localeCompare(b.firstName));
    }

    setAttendees(sortedAttendees);
  };

  const handleSelectAttendee = (attendeeId) => {
    let updatedSelection = [...selectedAttendeesState];
    if (updatedSelection.includes(attendeeId)) {
      updatedSelection = updatedSelection.filter(id => id !== attendeeId);
    } else {
      updatedSelection.push(attendeeId);
    }

    setSelectedAttendeesState(updatedSelection);
    onSelectAttendees(updatedSelection);
  };

  const filteredAttendees = attendees.filter(
    (attendee) =>
      (attendee.firstName && attendee.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (attendee.lastName && attendee.lastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (attendee.department && attendee.department.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <section className={styles.attendeeSection}>
      <header className={styles.attendeeHeader}>
        <h2 className={styles.attendeeTitle}>{t('Add Attendees')}</h2>
        <button className={styles.searchButton} onClick={toggleSearchBar}>
          <img src={searchIcon} alt="Search" className={styles.searchIcon} />
        </button>
      </header>
      {showSearchBar && (
        <div className={styles.searchField}>
          <input
            type="text"
            placeholder={t('Enter keywords to search...')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
        </div>
      )}
      <div className={styles.sortOptions}>
        <select className={styles.sortSelect} value={sortOption} onChange={handleSortChange}>
          <option value="">{t('Sort Options')}</option>
          <option value="alphabetical">{t('Sort Alphabetically')}</option>
          <option value="department">{t('Sort by Department')}</option>
        </select>
      </div>
      <ul className={styles.attendeeList}>
        {filteredAttendees.map((attendee, index) => (
          <li
            key={attendee.id || index}
            className={`${styles.attendeeItem} ${selectedAttendeesState.includes(attendee.id) ? styles.selectedItem : ''}`}
            onClick={() => handleSelectAttendee(attendee.id)}
          >
            <img
              src={attendee.profilePhotoUrl}
              alt={attendee.firstName}
              className={styles.attendeeImage}
            />
            <div className={styles.attendeeInfo}>
              <strong>{attendee.firstName} {attendee.lastName}</strong>
              <span>{attendee.department}</span>
            </div>
            {selectedAttendeesState.includes(attendee.id) && (
              <img
                src={selectedIcon}
                alt="Selected"
                className={styles.selectedIcon}
              />
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default AttendeeList;
