// src/components/chats/NewGroup.js

import React, { useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Avatar,
  InputAdornment,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useFetchUsers } from '../../hooks/useFetchUsers';
import { WebSocketContext } from '../../context/RocketchatContext.js';
import { useWebsocketSubscription } from '../../hooks/useWebsocketSubscription';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import MosaikFooter from "../MosaikFooter";

function NewGroup() {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState('');
  const groupNameWithoutSpaces = groupName.replace(/\s/g, '');
  const [groupDescription, setGroupDescription] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupPicture, setGroupPicture] = useState(null);
  const [groupPictureUrl, setGroupPictureUrl] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' | 'error' | 'warning' | 'info'
  const navigate = useNavigate();

  const { summarizedUsers } = useFetchUsers();

  const handleUserSelect = (user) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(user.id)
        ? prevSelected.filter((id) => id !== user.id)
        : [...prevSelected, user.id]
    );
  };

  const handleGroupPictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const fileURL = URL.createObjectURL(e.target.files[0]);
      setGroupPicture(fileURL);
      uploadGroupPicture(e.target.files[0]);
    }
  };

  const groupChatInfo = {
    name: groupName,
    description: groupDescription,
    avatar: groupPictureUrl,
  };

  const { createGroupChat, getSocket } = useContext(WebSocketContext);

  const handleWebSocketMessage = useCallback((event) => {
    if (event.data) {
      const message = JSON.parse(event.data);

      if (message.msg === 'result' && message.result?.rid && message.id.includes('createPrivateGroup')) {
        navigate(`/chat-details/${message.result.rid}`);
        setSnackbarMessage(t('Group created successfully!'));
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        console.log('Group Created:', message.result.rid);
      }
    }
  }, [navigate, t]);

  const { authState } = useContext(AuthContext);
  const token = authState.accessToken;

  useWebsocketSubscription(getSocket(), handleWebSocketMessage);

  const handleCreateGroup = () => {
    if (groupName.trim() === '') {
      setSnackbarMessage(t('Group name cannot be empty.'));
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return;
    }
    if (selectedUsers.length === 0) {
      setSnackbarMessage(t('Please select at least one member.'));
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return;
    }
    createGroupChat(groupNameWithoutSpaces, selectedUsers, false, groupChatInfo);
  };

  const uploadGroupPicture = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('moduleField', 'profile');
    formData.append('documentTitle', `group-chat-profile_${groupChatInfo.name}-${Date.now()}`);
    formData.append('fileMimeType', file.type);
    formData.append('mediaType', 'image');
    formData.append('userId', authState.user.id);
    formData.append('organisationId', 'null');

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/files/upload`, formData, { headers });
      console.log('Group Picture Uploaded:', response.data);
      setGroupPictureUrl(response.data.fileUrl);
      setSnackbarMessage(t('Group picture uploaded successfully!'));
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error uploading group picture:', error);
      setSnackbarMessage(t('Failed to upload group picture.'));
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const filteredUsers = summarizedUsers.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (user.department && user.department.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{ marginBottom: '85px' }}
    >
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        sx={{ borderBottom: '1px solid #ddd' }}
      >
        <IconButton onClick={() => navigate(-1)} aria-label={t("Back")}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="div">
          {t('New Group')}
        </Typography>
        <IconButton aria-label={t("Menu")}>
          <MoreVertIcon />
        </IconButton>
      </Box>

      {/* Main Content */}
      <Box
        flexGrow={1}
        p={2}
        display="flex"
        flexDirection="column"
        overflow="auto"
      >
        {/* Group Details */}
        <Typography variant="h5" gutterBottom>
          {t('Create Group')}
        </Typography>
        <TextField
          variant="outlined"
          label={t("Name of the group")}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          variant="outlined"
          label={t("Description of the group")}
          value={groupDescription}
          onChange={(e) => setGroupDescription(e.target.value)}
          multiline
          rows={4}
          fullWidth
          sx={{ mb: 2 }}
        />

        {/* Group Picture */}
        <Box
          display="flex"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography variant="subtitle1" sx={{ mr: 2 }}>
            {t("Group's Display Picture")}
          </Typography>
          <Button
            variant="contained"
            component="label"
            startIcon={<AttachmentIcon />}
          >
            {t("Upload")}
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleGroupPictureChange}
            />
          </Button>
          {groupPicture && (
            <Avatar
              src={groupPicture}
              alt={t("Group Picture")}
              sx={{ width: 56, height: 56, ml: 2 }}
            />
          )}
        </Box>

        {/* Group Members */}
        <Box>
          <Typography variant="h6" gutterBottom>
            {t('Group Members')}
          </Typography>
          <TextField
            variant="outlined"
            placeholder={t("Search users...")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <List>
            {filteredUsers.map((user) => (
              <ListItem key={user.id} disablePadding>
                <ListItemButton onClick={() => handleUserSelect(user)}>
                  <Avatar src={user.avatarUrl} alt={user.name} sx={{ mr: 2 }}>
                    {user.name.charAt(0)}
                  </Avatar>
                  <ListItemText
                    primary={user.name}
                    secondary={user.department ? `(${user.department})` : null}
                  />
                  {selectedUsers.includes(user.id) && (
                    <Tooltip title={t("Selected")}>
                      <Typography variant="body2" color="primary">
                        ✓
                      </Typography>
                    </Tooltip>
                  )}
                </ListItemButton>
              </ListItem>
            ))}
            {filteredUsers.length === 0 && (
              <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="body1">
                  {t('No users found.')}
                </Typography>
              </Box>
            )}
          </List>
        </Box>

        {/* Create Group Button */}
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateGroup}
            disabled={groupName.trim() === '' || selectedUsers.length === 0}
          >
            {t('Create Group')}
          </Button>
        </Box>
      </Box>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Footer */}
      <MosaikFooter />
    </Box>
  );

}

export default NewGroup;
