import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import FolderIcon from '../../assets/images/folder-icon.png';
import ForwardIcon from '../../assets/images/right-arrow.png';
import styles from './MosaikLibraryCateogry.module.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const CategoryPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [category, setCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [parentCategory, setParentCategory] = useState(null);
  const [mainCategoryContent, setMainCategoryContent] = useState([]);
  const [subCategoryContents, setSubCategoryContents] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        // Fetch category details
        const categoryResponse = await axios.get(`${apiUrl}/libraryCategories/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setCategory(categoryResponse.data);

        // Fetch content for the main category (prioritizing organization ID)
        const mainCategoryContentResponse = await axios.get(
          `${apiUrl}/libraryContents?where[libraryCategory][id]=${categoryResponse.data.id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setMainCategoryContent(mainCategoryContentResponse.data);

        // Fetch parent category if exists
        if (categoryResponse.data.parentCategory?.id) {
          const parentResponse = await axios.get(`${apiUrl}/libraryCategories/${categoryResponse.data.parentCategory.id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          setParentCategory(parentResponse.data);
        }

        // Fetch subcategories based on parentCategory ID
        const subCategoriesResponse = await axios.get(`${apiUrl}/libraryCategories?where[parentCategory][id]=${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setSubCategories(subCategoriesResponse.data);

        // Fetch content for each subcategory
        const subCategoryContentPromises = subCategoriesResponse.data.map(async (subCategory) => {
          const response = await axios.get(
            `${apiUrl}/libraryContents?where[libraryCategory][id]=${subCategory.id}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          return { subCategoryId: subCategory.id, content: response.data };
        });

        const subCategoryContentsArray = await Promise.all(subCategoryContentPromises);
        const subCategoryContentMap = subCategoryContentsArray.reduce((acc, cur) => {
          acc[cur.subCategoryId] = cur.content;
          return acc;
        }, {});

        setSubCategoryContents(subCategoryContentMap);

      } catch (error) {
        console.error(t('Error fetching category details:'), error);
      }
    };

    fetchCategoryDetails();
  }, [id, apiUrl]);

  if (!category) {
    return <p>{t('Loading...')}</p>;
  }

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={category.name} />
      <div className={styles.CategoryDetailSection}>
        <h1>{category.name}</h1>
        <p>{category.description}</p>

        {/* Main Category Content */}
        {mainCategoryContent.length > 0 && (
          <div>
            <h2>{t('Main Category Content')}</h2>
            <ul className={styles.contentList}>
              {mainCategoryContent.map((contentItem) => (
                <li key={contentItem.id} className={styles.contentItem}>
                  <Link to={`/mosaik-library/manual/${contentItem.id}`} className={styles.contentLink}>
                    <div className={styles.contentCard}>
                      <h4 className={styles.contentTitle}>{contentItem?.content?.name}</h4>
                      <p className={styles.contentExcerpt}>
                        {contentItem?.content?.description || t('No description available.')}
                      </p>
                      <div className={styles.contentMeta}>
                        <p>
                          <strong>{t('Category Type')} :</strong>{' '}
                          {contentItem?.content?.categoryType || t('No Category Type available.')}
                        </p>
                        <p>
                          <strong>{t('Created')}:</strong>{' '}
                          {new Date(contentItem?.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Subcategories */}
        {subCategories.length > 0 && (
          <>
            <h2>{t('Subcategories')}</h2>
            <ul className={styles.subCategoryList}>
              {subCategories.map((subCategory) => (
                <li key={subCategory.id} className={styles.subCategoryItem}>
                  <div className={styles.subCategoryHeader}>
                    <div className={styles.subCategoryDetails}>
                      <img src={FolderIcon} alt={subCategory.name} className={styles.folderIcon} />
                      <Link
                        to={`/mosaik-library/category/${id}/subcategory/${subCategory.id}`}
                        state={{ subCategory }}
                        className={styles.subCategoryLink}
                      >
                        {subCategory.name}
                      </Link>
                    </div>
                    <Link to={`/mosaik-library/category/${id}/subcategory/${subCategory.id}`} state={{ subCategory }}>
                      <img src={ForwardIcon} alt={subCategory.name} className={styles.forwardIcon} />
                    </Link>
                  </div>

                  {/* Subcategory Content */}
                  {subCategoryContents[subCategory.id]?.length > 0 && (
                    <div className={styles.subCategoryContent}>
                      <ul className={styles.contentList}>
                        {subCategoryContents[subCategory.id].map((contentItem) => (
                          <li key={contentItem.id} className={styles.contentItem}>
                            <Link to={`/mosaik-library/manual/${contentItem.id}`} className={styles.contentLink}>
                              <div className={styles.contentCard}>
                                <h4 className={styles.contentTitle}>{contentItem?.content?.name}</h4>
                                <p className={styles.contentExcerpt}>
                                  {contentItem?.content?.description || t('No description available.')}
                                </p>
                                <div className={styles.contentMeta}>
                                  <p>
                                    <strong>{t('Category Type')} :</strong>{' '}
                                    {contentItem?.content?.categoryType || t('No Category Type available.')}
                                  </p>
                                  <p>
                                    <strong>{t('Created')}:</strong>{' '}
                                    {new Date(contentItem.createdAt).toLocaleDateString()}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <MosaikFooter />
    </div>
  );
};

export default CategoryPage;
