import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import styles from './SignupOtp.module.css';
import logo from '../assets/images/logo.png';
import backIcon from '../assets/icons/back-icon.png';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext

const SignupOtpMobile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState, login } = useContext(AuthContext); // Access authState and login function from context

  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [error, setError] = useState('');
  const inputRefs = useRef([]);
  const phone = location.state?.phone;

  // Focus on the first OTP input field when the component mounts
  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleOtpChange = (element, index) => {
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.value && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    setError(''); // Clear error on OTP input

    // Automatically verify when all OTP fields are filled
    if (newOtp.every((digit) => digit !== '')) {
      handleVerifyOtp(newOtp.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').slice(0, 6); // Get only the first 6 digits
    const newOtp = [...otp];

    pasteData.split('').forEach((char, index) => {
      if (index < 6) {
        newOtp[index] = char;
        inputRefs.current[index].value = char; // Set value to each input field
      }
    });

    setOtp(newOtp);

    // Automatically verify when all digits are pasted
    if (pasteData.length === 6) {
      handleVerifyOtp(newOtp.join(''));
    }
  };

  const handleVerifyOtp = async (otpCode) => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      // Verify if we have the token in localStorage
      if (!accessToken) {
        setError('No access token found. Please request OTP again.');
        return;
      }

      // Make the API request to verify the OTP
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify-otp`,
        {
          purpose: 'signup',
          method: 'mobile',
          destination: phone,
          otp: otpCode, // Join OTP array to string
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Use the token from context
          },
        }
      );

      // If OTP verification is successful
      if (response.data.result === true) {
        if (response.data.accessToken) {
          login(response.data.accessToken, { phone }); // Update the token and user data in context
        }

        localStorage.setItem('verifiedPhone', phone); // Optionally store the verified phone

        alert('Phone number verified successfully!');
        navigate('/signup', { state: { phoneVerified: true, phone } }); // Navigate back to signup
      } else {
        setError('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Failed to verify OTP:', error?.response?.data || error.message);
      setError('Failed to verify OTP. Please try again.');
    }
  };

  return (
    <main className={styles.signupContainer} onPaste={handlePaste}>
      <img src={logo} alt="Mosaik Logo" className={styles.logo} />
      <section className={styles.signupCard}>
        <div className={styles.header}>
          <img src={backIcon} alt="Go back" className={styles.backIcon} onClick={() => navigate('/signup')} />
          <h1 className={styles.signupHeading}>Mobile Verification</h1>
        </div>
        <div className={styles.divider}></div>
        <p className={styles.instructionText}>
          The six-digit OTP code was sent to your phone number: {phone}
        </p>
        <div className={styles.otpInputContainer}>
          {otp.map((data, index) => (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength="1"
              className={styles.otpInputBox}
              value={data}
              onChange={(e) => handleOtpChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        {error && <p className={styles.errorText}>{error}</p>}
        <button onClick={() => handleVerifyOtp(otp.join(''))} className={styles.verifyButton}>
          Verify
        </button>
      </section>
    </main>
  );
};

export default SignupOtpMobile;
