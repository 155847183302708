// MosaikHome.js
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MosaikHeader from "./MosaikHeader";
import ActionsCarousel from "./ActionsCarousel";
import IconGrid from "./IconGrid";
import MosaikFooter from "./MosaikFooter";
import ProfileCompletion from "./ProfileCompletion";
import ProfileCompletionModal from "./ProfileCompletionModal";
import { AuthContext } from "../context/AuthContext";
import styles from "./MosaikHome.module.css";
import searchIcon from "../assets/images/search-icon.png";
import spinnerGif from "../assets/images/spinner.gif"; // Import spinner GIF
import axios from "axios";
import debounce from "lodash.debounce";
import qs from "qs";
import InvitationPopup from "./InvitationPopup";
import { useTranslation } from "react-i18next"; // Import the translation hook

const MosaikHome = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const [isModalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [hasShownPopup, setHasShownPopup] = useState(false);
  const [invitations, setInvitations] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
  const { authState, setAuthState } = useContext(AuthContext);
  const token = authState.accessToken;

  const currentOrganisationId = authState.currentOrganisationId;

  const handleProfileClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Check if the user is authenticated or if the token is present in local storage
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(t("Token:"), token);
    console.log(t("User Email:"), userInfo?.email);

    // If the user is not authenticated and no token is found in localStorage, redirect to login
    if (!token) {
      navigate("/login");
    }
  }, [authState, navigate, t]);

  useEffect(() => {
    const fetchInvitations = async () => {
      console.log("AUTH STATE", authState);
      if (!hasShownPopup)
        try {
          const response = await fetch(
            `${apiUrl}/invitations/get-received-invitations`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          // Check if data is an array, otherwise, convert it into an array
          const invitationsArray = Array.isArray(data) ? data : [data];

          setInvitations(invitationsArray);
          const popupShown = localStorage.getItem("hasShownPopup");
          if (
            !popupShown &&
            invitationsArray.length > 0 &&
            invitationsArray[0].organisationId
          ) {
            setPopupVisible(true); // Show the popup if there are invitations
            setHasShownPopup(true);
            localStorage.setItem("hasShownPopup", "true"); // Mark that the popup has been shown
          }
        } catch (error) {
          console.error(t("Error fetching invitations:"), error);
        }
    };
    fetchInvitations();
  }, [apiUrl]);

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.trim() === "") {
        setSearchResults([]);
        setIsSearching(false);
        setNoResultsFound(false);
        return;
      }

      setIsSearching(true);
      setNoResultsFound(false);

      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const endpoints = [
          {
            name: "Events",
            url: `${apiUrl}/events`,
            searchFields: [
              { field: "title", operator: "contains" },
              { field: "description", operator: "contains" },
              { field: "name", operator: "contains" },
            ],
          },
          {
            name: "Tasks",
            url: `${apiUrl}/tasks`,
            searchFields: [
              { field: "description", operator: "contains" },
              { field: "name", operator: "contains" },
            ],
          },
          {
            name: "Groups",
            url: `${apiUrl}/groups`,
            searchFields: [{ field: "name", operator: "contains" }],
          },
          {
            name: "Orders",
            url: `${apiUrl}/orders`,
            searchFields: [{ field: "productRefNo", operator: "equals" }],
          },
          {
            name: "Feedbacks",
            url: `${apiUrl}/feedbacks`,
            searchFields: [{ field: "title", operator: "contains" }],
          },
          {
            name: "Repairs",
            url: `${apiUrl}/repairs`,
            searchFields: [{ field: "repairRefNo", operator: "equals" }],
          },
          {
            name: "Library Categories",
            url: `${apiUrl}/libraryCategories`,
            searchFields: [{ field: "name", operator: "contains" }],
          },
          {
            name: "Library Articles",
            url: `${apiUrl}/libraryContents`,
            searchFields: [{ field: "name", operator: "contains" }],
          },
        ];

        const searchPromises = endpoints.map(async (endpoint) => {
          const searchFields = endpoint.searchFields;

          const orClauses = searchFields.map(({ field, operator }) => {
            const filter = { mode: "insensitive" }; // Add mode for case-insensitivity
            if (operator === "contains") {
              filter.contains = query;
            } else if (operator === "equals") {
              filter.equals = query;
            } else {
              filter.contains = query;
            }
            return { [field]: filter };
          });

          const whereClause = {
            organisation: { id: currentOrganisationId },
            OR: orClauses,
          };

          try {
            const response = await axios.get(endpoint.url, {
              headers,
              params: {
                where: whereClause,
              },
              paramsSerializer: (params) => {
                return qs.stringify(params, { encode: false });
              },
            });

            return {
              type: endpoint.name,
              data: response.data,
            };
          } catch (error) {
            console.error(
              t("Error fetching {{endpoint}}:", { endpoint: endpoint.name }),
              error.response?.data || error.message
            );
            return { type: endpoint.name, data: [] };
          }
        });

        const results = await Promise.all(searchPromises);

        const combinedResults = results.flatMap((result) =>
          result.data.map((item) => ({
            ...item,
            type: result.type,
          }))
        );

        if (combinedResults.length === 0) {
          setNoResultsFound(true);
        }

        setSearchResults(combinedResults);
        setIsSearching(false);
      } catch (error) {
        console.error(t("Error during search:"), error);
        setIsSearching(false);
      }
    }, 500),
    [apiUrl, token, currentOrganisationId, t]
  );

  const handleAcceptInvitation = async (invitationId) => {
    try {
      const response = await fetch(
        `${apiUrl}/invitations/accept-invitation/${invitationId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setInvitations((prevInvitations) =>
          prevInvitations.map((inv) =>
            inv.invitationId === invitationId
              ? { ...inv, status: "Accepted" }
              : inv
          )
        );
      } else {
        console.error(t("Failed to accept the invitation"));
      }
      const responseData = await response.json();
      console.log("Response Data:", responseData);
      const requestBody = { organisationId: responseData.organisationId };
      const switchResponse = await axios.post(
        `${apiUrl}/auth/switch-organisation`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (switchResponse.status === 200 || switchResponse.status === 201) {
        const newAccessToken =
          switchResponse.data.accessToken || switchResponse.data;

        if (!newAccessToken) {
          console.error(t("No accessToken received from the server."));
          return;
        }
        const updatedSession = await axios.post(
          `${apiUrl}/auth/get-session`,
          {},
          {
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
            },
          }
        );
        // Update the authState with the new access token
        // Update authState and localStorage
        const updatedAuthState = {
          ...authState,
          accessToken: newAccessToken,
          user: {
            ...updatedSession?.data,
          },
        };
        setAuthState(updatedAuthState);
        // Update localStorage
        localStorage.setItem("accessToken", newAccessToken);
        localStorage.setItem("authState", JSON.stringify(updatedAuthState));
        localStorage.setItem("userInfo", JSON.stringify(updatedSession?.data));
        window.location.reload();
      }
    } catch (error) {
      console.error(t("Error accepting invitation:"), error);
    }
  };

  const handleDeclineInvitation = async (invitationId) => {
    try {
      const response = await fetch(
        `${apiUrl}/invitations/cancel-invitation/${invitationId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setInvitations((prevInvitations) =>
          prevInvitations.map((inv) =>
            inv.invitationId === invitationId
              ? { ...inv, status: "Declined" }
              : inv
          )
        );
        window.location.reload();
      } else {
        console.error(t("Failed to decline the invitation"));
      }
    } catch (error) {
      console.error(t("Error declining invitation:"), error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    debouncedSearch(e.target.value);
  };

  const navigateToResource = (result) => {
    let path = "";

    switch (result.type) {
      case "Library Categories":
        path = `/mosaik-library/category/${result.id}`;
        break;
      case "Events":
        path = `/events/${result.id}`;
        break;
      case "Tasks":
        path = `/tasks/${result.id}`;
        break;
      case "Groups":
        path = `/group/${result.id}`;
        break;
      case "Orders":
        path = `/orders/${result.id}`;
        break;
      case "Feedbacks":
        path = `/feedbacks/${result.id}`;
        break;
      case "Repairs":
        path = `/repairs/${result.id}`;
        break;
      default:
        path = "/";
    }

    navigate(path);
  };

  const groupedResults = searchResults.reduce((acc, result) => {
    if (!acc[result.type]) {
      acc[result.type] = [];
    }
    acc[result.type].push(result);
    return acc;
  }, {});

  return (
    <div>
      <MosaikHeader showProfileProgress={true} profileCompletion={25} />
      <div onClick={handleProfileClick}>
        <ProfileCompletion />
      </div>
      <ProfileCompletionModal isOpen={isModalOpen} onClose={handleCloseModal} />
      <div className={styles.mosaikContainer}>
        <ActionsCarousel />
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder={t("Search Groups, Tasks, Resources...")}
            className={styles.searchInput}
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
          <button className={styles.searchButton}>
            <img
              src={searchIcon}
              alt={t("Search")}
              className={styles.searchIcon}
            />
          </button>
        </div>

        {isSearching && (
          <div className={styles.loadingSpinner}>
            <img src={spinnerGif} alt={t("Loading...")} />
          </div>
        )}

        {noResultsFound && (
          <p className={styles.noResultsText}>{t("No results found.")}</p>
        )}

        {searchResults.length > 0 && (
          <div className={styles.searchResultsContainer}>
            <h3 className={styles.searchResultsTitle}>{t("Search Results")}</h3>
            {Object.entries(groupedResults).map(([resourceType, results]) => (
              <div key={resourceType} className={styles.searchResultsGroup}>
                <h4 className={styles.resourceHeading}>{t(resourceType)}</h4>
                {results.map((result) => (
                  <div
                    key={`${result.type}-${result.id}`}
                    className={styles.searchResultItem}
                    onClick={() => navigateToResource(result)}
                  >
                    <h5 className={styles.resultTitle}>
                      {result.title || result.name}
                    </h5>
                    <p className={styles.resultDescription}>
                      {result.description ||
                        result.notes ||
                        t("No description available.")}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}

        <IconGrid />
      </div>
      <MosaikFooter />
      {popupVisible && (
        <InvitationPopup
          invitations={invitations}
          onClose={() => setPopupVisible(false)}
          onAccept={handleAcceptInvitation}
          onDecline={handleDeclineInvitation}
        />
      )}
    </div>
  );
};

export default MosaikHome;
