import { createReactBlockSpec } from "@blocknote/react";
import React from "react";
import YoutubeBlock from "./YoutubeBlock";

export const Youtube = createReactBlockSpec(
  {
    type: "youtube",
    propSchema: {
      url: {
        default: "",
      },
    },
    content: "none",
  },
  {
    render: (props) => <YoutubeBlock {...props} />,
  }
);
