// Memberships.js
import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import styles from "./Memberships.module.css";
import MosaikFooter from "../MosaikFooter";
import MosaikWelcomeScreen from "../MosaikWelcomeScreen";
import welcomeIcon from "../../assets/images/memberships-icon.png";
import welcomeBgimage from "../../assets/images/memberships-bg.png";
import SuccessImage from "../../assets/images/success-image.png";
import { AuthContext } from "../../context/AuthContext"; // Import AuthContext
import { useNavigate } from "react-router-dom";
import HeaderWithoutRoles from "../HeaderWithoutRoles";
import { useTranslation } from "react-i18next"; // Import useTranslation

// Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Custom Previous Arrow Component
const PrevArrow = (props) => {
  const { t } = useTranslation(); // Initialize t
  const { className, style, onClick } = props;
  return (
    <button
      className={`${styles.customArrow} ${styles.prevArrow}`}
      onClick={onClick}
      aria-label={t("Previous Slide")}
    >
      &#8592;
    </button>
  );
};

// Custom Next Arrow Component
const NextArrow = (props) => {
  const { t } = useTranslation(); // Initialize t
  const { className, style, onClick } = props;
  return (
    <button
      className={`${styles.customArrow} ${styles.nextArrow}`}
      onClick={onClick}
      aria-label={t("Next Slide")}
    >
      &#8594;
    </button>
  );
};

const Memberships = () => {
  const { t } = useTranslation(); // Initialize t
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationCreated, setOrganizationCreated] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [isCreatingOrganization, setIsCreatingOrganization] = useState(false); // New state variable
  const apiUrl = process.env.REACT_APP_API_URL;
  const { authState } = useContext(AuthContext); // Use authState from AuthContext
  const navigate = useNavigate();

  useEffect(() => {
    fetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("Selected plan updated:", selectedPlan);
  }, [selectedPlan]);

  useEffect(() => {
    // Fetch user subscriptions and check if a subscription exists
    const fetchUserSubscription = async () => {
      try {
        const token = authState.accessToken;
        const userId = authState.user?.id;
        const response = await axios.get(
          `${apiUrl}/users/${userId}/subscriptionsDetails`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const subscriptionData = response.data;

        if (subscriptionData && subscriptionData.length > 0) {
          setSubscriptionExists(true);
        } else {
          setSubscriptionExists(false);
        }
      } catch (error) {
        console.error(t("Error fetching user subscriptions:"), error);
        setSubscriptionExists(false);
      } finally {
        setLoading(false);
      }
    };

    fetchUserSubscription();
  }, [authState, apiUrl]);

  const handleCheckoutSession = async (plan) => {
    handleSelectPlan(plan);
    console.log("SELECTED PLAN DATA", plan);
    const userId = authState.user?.id;
    try {
      const customer = await axios.post(
        `${apiUrl}/subscriptions/createCustomer`,
        {
          userId: userId,
        },
        {
          headers: { Authorization: `Bearer ${authState.accessToken}` },
        }
      );
      console.log("PLAN DATA", plan);

      const customerId = customer.data.id; // Get the Stripe customer ID
      console.log("CUSTOMER ID", customerId);
      const response = await axios.post(
        `${apiUrl}/subscriptions/create-checkout-session`,
        {
          planId: plan.planId, // Send the selected plan ID to the backend
          customer: customerId,
        },
        {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        }
      );
      console.log("RESPONSE", response);
      if (response && response.data.url) {
        window.location = response.data.url;
      } else if (response && response.data.true) {
        navigate("/OrganisationPage");
      } else {
        console.error(t("Error creating checkout session:"));
      }
    } catch (error) {
      console.error(t("Error creating checkout session:"), error);
    }
  };
  const handleSubscriptionClick = () => {
    if (subscriptionExists) {
      // Navigate to the subscription details page
      navigate("/memberships/SubscriptionDetails");
      console.log("Navigating to subscription details page...");
    }
  };

  const fetchPlans = async () => {
    try {
      const token = authState.accessToken;

      const plansResponse = await axios.get(`${apiUrl}/plans`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const pricesResponse = await axios.get(`${apiUrl}/prices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const plansWithPrices = plansResponse.data.map((plan) => {
        const matchingPrice = pricesResponse.data.find(
          (price) => price.plan.id === plan.id
        );
        return {
          ...plan,
          pricePerMonth: matchingPrice ? matchingPrice.pricePerMonth : 0,
          currency: matchingPrice ? matchingPrice.currencySymbol : "",
        };
      });

      setPlans(plansWithPrices);
    } catch (error) {
      console.error("Error fetching plans or prices:", error);
    }
  };

  const handleSelectPlan = async (plan) => {
    if (plan.maxEmployees === -1) {
      window.location.href =
        "mailto:business@justmosaik.net?subject=Inquiry about plan&body=I would like to inquire about your services.";
      return; // If the maxEmployees is -1, trigger the email and exit
    }

    setSelectedPlan(plan);
    console.log("Selected plan", plan);
    setProcessingPayment(true);

    const token = authState.accessToken;

    try {
      const amountInCents = plan.pricePerMonth * 100;

      const response = await axios.post(
        `${apiUrl}/payments/create-payment-intent`,
        {
          amount: amountInCents,
          currency: "eur",
          planId: plan.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStripeClientSecret(response.data.clientSecret);
      setProcessingPayment(false);
    } catch (error) {
      console.error(t("Error creating payment intent:"), error);
      setProcessingPayment(false);
    }
  };

  const handleOrganizationCreation = async () => {
    const token = authState.accessToken;
    const userId = authState.user?.id;

    setIsCreatingOrganization(true); // Disable the button

    try {
      // Step 1: Create the organization
      // const orgResponse = await axios.post(
      //   `${apiUrl}/organisations`,
      //   {
      //     name: organizationName,
      //     // Include additional fields if necessary
      //   },
      //   {
      //     headers: { Authorization: `Bearer ${token}` },
      //   }
      // );
      // const organizationId = orgResponse.data.id;
      // setOrgId(organizationId);
      // Step 2: Create the subscription
      // const startDate = new Date();
      // const endDate = new Date(startDate.getMonth() + 1).toISOString();
      // const subscriptionResponse = await axios.post(
      //   `${apiUrl}/subscriptions`,
      //   {
      //     addons: {
      //       connect: [], // Include any addon IDs if applicable
      //     },
      //     organisation: {
      //       id: organizationId,
      //     },
      //     plan: {
      //       id: selectedPlan.id,
      //     },
      //     user: {
      //       id: userId,
      //     },
      //     startDate: startDate.toISOString(), // Set current date as start date
      //     endDate: endDate, // Set null or calculate based on plan duration
      //   },
      //   {
      //     headers: { Authorization: `Bearer ${token}` },
      //   }
      // );
      // console.log("SUBSCRIPTION RESPONSE", subscriptionResponse);
      // Step 3: Create user organization role
      // const userOrgRoleResponse = await axios.post(
      //   `${apiUrl}/userOrganisationRoles`,
      //   {
      //     organisation: {
      //       id: organizationId,
      //     },
      //     roles: ["user", "admin"], // Assign both user and admin roles
      //     user: {
      //       id: userId,
      //     },
      //     // Include additional fields if necessary
      //   },
      //   {
      //     headers: { Authorization: `Bearer ${token}` },
      //   }
      // );
      // setOrganizationCreated(true);
      // setIsCreatingOrganization(false); // Re-enable the button
    } catch (error) {
      console.error(
        t("Error creating organization, subscription, or assigning admin:"),
        error
      );
      setIsCreatingOrganization(false); // Re-enable the button on error
    }
  };

  if (paymentSuccess) {
    return (
      <div className={styles.mosaikContainer}>
        <HeaderWithoutRoles pageTitle={t("Membership Plans")} />
        <div className={styles.successScreen}>
          <h2>{t("Congratulations!")}</h2>
          <p>
            {t("You have been subscribed to {{planName}}", {
              planName: selectedPlan.name,
            })}
          </p>
          <img
            src={SuccessImage}
            alt={t("Success")}
            className={styles.successImage}
          />
          {!organizationCreated ? (
            <div className={styles.NewOrgfields}>
              <p>{t("Now let's give your organization a name.")}</p>
              <input
                type="text"
                placeholder={t("Organization Name")}
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
                className={styles.orgInput}
              />
              <button
                className={styles.saveButton}
                onClick={handleOrganizationCreation}
                disabled={!organizationName.trim() || isCreatingOrganization}
              >
                {isCreatingOrganization ? t("Saving...") : t("Save")}
              </button>
            </div>
          ) : (
            <div>
              <p>
                {t("Organization {{organizationName}} created successfully!", {
                  organizationName,
                })}
              </p>
              <p>{t("You can now start using the services.")}</p>
            </div>
          )}
        </div>
        <MosaikFooter />
      </div>
    );
  }

  // Slider settings for react-slick with custom arrows
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // 3 slides per row on large screens
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Medium screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768, // Small screens
        settings: {
          slidesToShow: 1, // 1 slide per row
          slidesToScroll: 1,
          arrows: false, // Hide arrows on small screens
          dots: true,
        },
      },
    ],
  };

  return (
    <div className={styles.mosaikContainer}>
      <HeaderWithoutRoles pageTitle={t("Membership Plans")} />
      <MosaikWelcomeScreen
        welcomeTitle={t("Membership plans")}
        welcomeDescription={t(
          "Membership offers employees the ability to get all the free and premium features of Mosaik services."
        )}
        welcomeIcon={welcomeIcon}
        welcomeBgimage={welcomeBgimage}
      />
      <div className={styles.subscriptionContainer}>
        <div>
          {loading ? (
            <div>{t("Loading...")}</div>
          ) : (
            <button
              className={styles.subscriptionButton}
              onClick={handleSubscriptionClick}
              disabled={!subscriptionExists} // Disable button if subscription does not exist
              style={{ display: subscriptionExists ? "inline-block" : "none" }} // Hide button if subscription does not exist
            >
              {t("View Plan Details")}
            </button>
          )}
        </div>
      </div>
      {processingPayment ? (
        <div className={styles.processingScreen}>
          <h3>{t("Processing Payment...")}</h3>
        </div>
      ) : selectedPlan ? (
        <Elements stripe={stripePromise}>
          <PaymentForm
            selectedPlan={selectedPlan}
            stripeClientSecret={stripeClientSecret}
            onPaymentSuccess={() => setPaymentSuccess(true)}
          />
        </Elements>
      ) : (
        <div className={styles.slider}>
          {plans.length > 0 ? (
            <Slider {...sliderSettings}>
              {plans.map((plan) => (
                <div key={plan.id} className={styles.membershipCardWrapper}>
                  <div className={styles.membershipCard}>
                    <div className={styles.membershipHeader}>
                      <div className={styles.headerText}>
                        <h3>{plan.name}</h3>
                        {plan.maxEmployees !== -1 && (
                          <h5>
                            {t("Max Employees")}: {plan.maxEmployees}
                          </h5>
                        )}
                        {plan.maxEmployees === -1 && (
                          <h5>
                            {t("Max Employees")}: ∞ ({t("Unlimited")})
                          </h5>
                        )}
                        {plan.maxEmployees !== -1 ? (
                          <span className={styles.priceText}>
                            {plan.currency}
                            {plan.pricePerMonth}
                          </span>
                        ) : (
                          <span className={styles.contactPricingText}>
                            €{plan.pricePerMonth}
                          </span>
                        )}
                      </div>
                      {plan.image && (
                        <img
                          src={plan.image}
                          alt={`${plan.name} ${t("plan")}`}
                          className={styles.planImage}
                        />
                      )}
                    </div>
                    <p>{plan.description}</p>
                    <div className={styles.membershipActions}>
                      {plan.maxEmployees === -1 ? (
                        <button
                          className={styles.contactButton}
                          onClick={() => handleSelectPlan(plan)}
                        >
                          {t("Contact us for pricing")}
                        </button>
                      ) : (
                        <button
                          className={styles.buyButton}
                          onClick={() => handleCheckoutSession(plan)}
                        >
                          {t("Subscribe to Membership Plan")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <p>{t("Loading plans...")}</p>
          )}
        </div>
      )}

      <MosaikFooter />
    </div>
  );
};

const PaymentForm = ({
  selectedPlan,
  stripeClientSecret,
  onPaymentSuccess,
}) => {
  const { t } = useTranslation(); // Initialize t
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false); // New state variable

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !stripeClientSecret) {
      setErrorMessage(
        t("Payment details are not ready yet. Please try again.")
      );
      return;
    }

    setIsProcessingPayment(true); // Disable the button
    setErrorMessage(null); // Clear previous errors

    const cardElement = elements.getElement(CardElement);
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      stripeClientSecret,
      {
        payment_method: {
          card: cardElement,
        },
      }
    );

    if (error) {
      setErrorMessage(error.message);
      setIsProcessingPayment(false); // Re-enable the button on error
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      onPaymentSuccess();
    } else {
      // Handle other statuses if necessary
      setIsProcessingPayment(false); // Re-enable the button
    }
  };

  return (
    <form onSubmit={handlePaymentSubmit} className={styles.StripePaymentForm}>
      <h2>{t("Payment Details")}</h2>
      <p>{t("Pay online securely using Credit/Debit cards via Stripe!")}</p>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "#32325d",
              "::placeholder": {
                color: "#a0aec0",
              },
              backgroundColor: "#f7f7f7",
              padding: "10px",
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          },
          hidePostalCode: true,
        }}
        className={styles.cardElement}
      />
      <button
        type="submit"
        className={styles.payButton}
        disabled={!stripe || isProcessingPayment}
      >
        {isProcessingPayment
          ? t("Processing...")
          : `Pay ${selectedPlan.currency}${selectedPlan.pricePerMonth.toFixed(
              2
            )}`}
      </button>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </form>
  );
};

export default Memberships;
