import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './ChatMedia.module.css';
import backArrowIcon from '../../assets/icons/arrow-back.png';
import MediaImage from '../../assets/images/media-image.jpg'; // Replace with actual media paths or data

const GroupChatMedia = () => {
  const { t } = useTranslation(); // Initialize t
  const navigate = useNavigate();
  const [selectedMedia, setSelectedMedia] = useState(null); // State for modal view
  const [searchTerm, setSearchTerm] = useState(''); // State for search input

  // Sample media items array; replace with actual media data and paths
  const mediaItems = [
    { src: MediaImage, name: 'test-image1.png' },
    { src: MediaImage, name: 'test-image2.png' },
    { src: MediaImage, name: 'test-video1.mp4' },
    { src: MediaImage, name: 'test-image3.png' },
    { src: MediaImage, name: 'test-video2.mp4' },
    { src: MediaImage, name: 'sample-photo.jpg' },
    { src: MediaImage, name: 'document.pdf' },
    { src: MediaImage, name: 'presentation.pptx' },
    { src: MediaImage, name: 'another-image.png' },
    { src: MediaImage, name: 'photo-2024.jpg' },
  ];

  // Filtered and sorted media items based on the search term
  const filteredMediaItems = mediaItems
    .filter((media) =>
      media.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (searchTerm && a.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return -1; // Move matching items to the top
      }
      return 0;
    });

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleMediaClick = (media) => {
    setSelectedMedia(media); // Set the selected media to open in the modal
  };

  const closeModal = () => {
    setSelectedMedia(null); // Close the modal by setting selected media to null
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term state
  };

  return (
    <div className={styles.chatMediaPage}>
      <header className={styles.headerContainer}>
        <div className={styles.headerLeft} onClick={handleGoBack}>
          <img src={backArrowIcon} alt={t("Back")} className={styles.backArrow} />
        </div>
        <div className={styles.headerTitle}>
          <h2>{t("Group's Media")}</h2>
        </div>
      </header>

      <div className={styles.searchBarContainer}>
        <input
          type="text"
          placeholder={t("Search media...")}
          value={searchTerm}
          onChange={handleSearchChange}
          className={styles.searchBar}
        />
      </div>

      <div className={styles.mediaGrid}>
        {filteredMediaItems.map((media, index) => (
          <div
            key={index}
            className={`${styles.mediaItem} ${
              media.name.toLowerCase().includes(searchTerm.toLowerCase())
                ? styles.highlight
                : ''
            }`}
            onClick={() => handleMediaClick(media.src)}
          >
            <img src={media.src} alt={media.name} className={styles.mediaImage} />
            <p className={styles.mediaName}>{media.name}</p>
          </div>
        ))}
      </div>

      {/* Modal for displaying full-resolution media */}
      {selectedMedia && (
        <div className={styles.modal} onClick={closeModal}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <button className={styles.closeButton} onClick={closeModal}>
              ×
            </button>
            <img src={selectedMedia} alt={t("Full resolution")} className={styles.fullImage} />
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupChatMedia;
