// src/utils/ChatSorterUtility.js

export const sortChats = (chats) => {
  return chats.sort((a, b) => {
    // Pinned chats come first
    if (a.isPinned && !b.isPinned) return -1;
    if (!a.isPinned && b.isPinned) return 1;

    // For non-pinned chats, sort by timestamp descending
    const dateA = new Date(a.timestamp);
    const dateB = new Date(b.timestamp);

    return dateB - dateA; // Most recent first
  });
};
