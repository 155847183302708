import styles from "./InvitationPopup.module.css"; // Ensure you have the right path
import ReactModal from "react-modal";
import { useTranslation } from 'react-i18next';

const InvitationPopup = ({ invitations, onClose, onAccept, onDecline }) => {
  const { t } = useTranslation();
  return (
    <ReactModal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel={t('Invitations Modal')}
      className={styles.popupContent}
      overlayClassName={styles.popupOverlay}
      ariaHideApp={false} // This can be set to false for testing purposes but it's recommended to set an app element
    >
      <button className={styles.closeButton} onClick={onClose}>
        &times; {/* Close button */}
      </button>
      <h3>{t('Invitations')}</h3>
      <div className={styles.invitationContainer}>
        <ul className={styles.invitationList}>
          {invitations.map((invitation) => (
            <li key={invitation.invitationId} className={styles.invitationItem}>
              <p>
                {t('Invitation from:')} {invitation.invitedByName} ({t('Organization:')}{" "}
                {invitation.organisationName})
              </p>
              <p>{t('Status:')} {t(invitation.status)}</p>
              {invitation.status === "Pending" && (
                <div className={styles.invitationButtons}>
                  <button onClick={() => onAccept(invitation.invitationId)}>
                    {t('Accept')}
                  </button>
                  <button onClick={() => onDecline(invitation.invitationId)}>
                    {t('Decline')}
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </ReactModal>
  );
};

export default InvitationPopup;
