// MosaikLibrary.js
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import MosaikWelcomeScreen from "../MosaikWelcomeScreen";
import welcomeIcon from "../../assets/images/library-iconinverted.png";
import welcomeBgimage from "../../assets/images/library-bg.jpg";
import searchIcon from "../../assets/images/search-button.png";
import addIcon from "../../assets/images/plus-button.png";
import styles from "./MosaikLibrary.module.css";
import ManualItem from "./ManualItem";
import spinner from "../../assets/images/spinner.gif"; // Import spinner image
import { AuthContext } from "../../context/AuthContext";
import HeaderWithoutRoles from "../HeaderWithoutRoles";
import { useTranslation } from 'react-i18next';
import { getLibraryContentImageUrl } from "../../utils/getFileUrl";
import { v4 as uuidv4 } from 'uuid';
function MosaikLibrary() {
  const { t } = useTranslation();
  const [isGridView, setIsGridView] = useState(true);
  const [selectedCategoryType, setSelectedCategoryType] = useState('document'); // default category type
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [showCategorySearchBar, setShowCategorySearchBar] = useState(false);
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const { authState } = useContext(AuthContext);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Loading state
  const [isLoading, setIsLoading] = useState(true);

  // State to hold fetched data
  const [categories, setCategories] = useState([]);
  const [manuals, setManuals] = useState([]);
  const [manualsWithImages, setManualsWithImages] = useState([]); 
  const [accessToken, setAccessToken] = useState("");
  const navigate = useNavigate();
  const DEFAULT_NAME = process.env.REACT_APP_DEFAULT_NAME;
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = authState.accessToken;
  const userId = authState.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      const storedData = localStorage.getItem("user");
      const token = localStorage.getItem("accessToken");
      const id = storedData ? JSON.parse(storedData).id : null;
  
      setAccessToken(token);
      if (token) {
        try {
          // Fetch categories
          const categoriesResponse = await axios.get(
            `${apiUrl}/libraryCategories`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setCategories(categoriesResponse.data);
        } catch (error) {
          console.error("Error fetching categories:", error);
          if (error.response && error.response.status === 401) {
            console.error("Unauthorized. Please check your token.");
            navigate('/login'); // Redirect to login on unauthorized
          }
        }
  
        try {
          // Fetch all manuals
          const manualsResponse = await axios.get(`${apiUrl}/libraryContents`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              // Add any necessary filters based on selectedCategory and selectedCategoryType
              // For example:
              // contentCategory: selectedCategoryType,
              // category: selectedCategory !== "All" ? selectedCategory : undefined,
            },
          });
          const permissionsResponse = await axios.get(
            `${apiUrl}/users/${userId}/permissionUserGroups`,
            {
              appModule: "libraryContent",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const userPermissions = permissionsResponse.data;
  
          // Step 2: Extract the libraryContent IDs from the user's permissions
          const accessibleManualIds = userPermissions
            .map((permission) => permission.libraryContent?.id)
            .filter((id) => id !== undefined);
  
          const allManuals = manualsResponse.data;
  
          // Step 4: Filter manuals based on the accessible manual IDs
          const filteredManuals = allManuals.filter((manual) =>
            accessibleManualIds.includes(manual.id)
          );
  
          // **Sort the filtered manuals by createdAt in descending order**
          const sortedManuals = filteredManuals.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB - dateA; // Descending order
          });
  
          // Fetch image URLs for each manual
          const manualsWithImagesPromises = sortedManuals.map(async (manual) => {
            const imageUrl = await getLibraryContentImageUrl(manual);
            return { ...manual, imageUrl };
          });
  
          const sortedManualsWithImages = await Promise.all(manualsWithImagesPromises);
  
          // Set the sorted manuals with images to state
          setManualsWithImages(sortedManualsWithImages);
        } catch (error) {
          console.error("Error fetching manuals:", error);
        } finally {
          setIsLoading(false); // Set to false after fetching completes
        }
      } else {
        setIsLoading(false); // If no token, stop loading
      }
    };
  
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl]);
  

  const handlePlusClick = () => {
    navigate("/mosaik-library/create-resource");
  };

  const handleContentClick = async () => {
    const payload = {
      content: {},
      name: DEFAULT_NAME,
    };

    try {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        console.log(t('Authentication token not found. Please log in.'));
        return;
      }

      setIsLoading(true); // Show spinner while adding
      const response = await fetch(`${apiUrl}/libraryContents`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Backend Error Response:', errorData);
        throw new Error(
          errorData.message ||
            `Error: ${response.status} ${response.statusText}`
        );
      }
      const newManual = await response.json();
      setIsLoading(false);
      navigate(`/mosaik-library/manual/${newManual?.id}`);
      await axios.post(
        `${apiUrl}/permissionUserGroups`,
        {
          appModule: "libraryContent",
          permission: "owner",
          user: {
            id: userId,
          },
          libraryContent: {
            id: newManual?.id,
          },

          // permissionSet:
          //   role === "owner"
          //     ? ["create", "Read", "Update", "Delete"]
          //     : role === "write"
          //     ? ["create", "Update"]
          //     : ["read"],
          // scope: "all",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.error('Error adding folder:', err);
      setIsLoading(false);
    }
  };



  // const handleContentClick = () => {
  //   const newUuid = uuidv4();
  //   // Navigate to the editor page with the new UUID
  //   navigate(`/mosaik-library/manual/${newUuid}`);
  // };
  const toggleView = () => {
    setIsGridView(!isGridView);
  };

  const toggleCategorySearchBar = () => {
    setShowCategorySearchBar(!showCategorySearchBar);
  };

  // Filter categories for manuals
  const filteredCategoryForManuals = categories.filter(
    (category) => category.categoryType === selectedCategoryType
  );

  // **Optionally, sort filteredManuals here if not sorted during fetch**
  const filteredManuals = manualsWithImages
    .filter((manual) =>
      manual?.name?.toLowerCase().includes(categorySearchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA; // Descending order
    });

  // Get current manuals for pagination
  const indexOfLastManual = currentPage * itemsPerPage;
  const indexOfFirstManual = indexOfLastManual - itemsPerPage;
  const currentManuals = filteredManuals.slice(
    indexOfFirstManual,
    indexOfLastManual
  );

  const totalPages = Math.ceil(filteredManuals.length / itemsPerPage);

  // Function to delete a manual
  const deleteManual = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error("No access token found. Please log in.");
        return;
      }

      setIsLoading(true); // Show spinner while deleting
      await axios.delete(`${apiUrl}/libraryContents/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the manuals state by removing the deleted manual
      setManuals((prevManuals) =>
        prevManuals.filter((manual) => manual.id !== id)
      );
      setIsLoading(false);
      console.log(t('Manual with ID {{id}} deleted successfully.', { id }));
    } catch (error) {
      console.error("Error deleting manual:", error);
      setIsLoading(false);
      // Optionally, handle specific error cases (e.g., show a notification)
    }
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  return (
    <div>
      <HeaderWithoutRoles pageTitle={t('Library')} />
      <main className={styles.libraryWelcome}>
        <section className={styles.manualsSection}>
          <header className={styles.sectionHeader}>
            <h2 className={styles.sectionTitle}>{t('My Manuals')}</h2>
            <div className={styles.headerActions}>
              <button
                className={styles.searchButton}
                aria-label={t('Search')}
                onClick={toggleCategorySearchBar}
              >
                <img src={searchIcon} alt={t('Search')} />
              </button>
              <button
                className={styles.plusButton}
                aria-label={t('Add Manual')}
                onClick={handleContentClick}
              >
                <img src={addIcon} alt={t('Add Manual')} />
              </button>
            </div>
          </header>
          {showCategorySearchBar && (
            <div className={styles.searchField}>
              <input
                type="text"
                placeholder={t('Search categories...')}
                className={styles.searchInput}
                value={categorySearchTerm}
                onChange={(e) => setCategorySearchTerm(e.target.value)}
              />
            </div>
          )}

          {/* Loading Spinner */}
         {/* Loading Spinner */}
{isLoading ? (
  <div className={styles.spinnerContainer}>
    <img src={spinner} alt={t('Loading...')} className={styles.spinner} />
  </div>
) : (
  <div className={styles.manualsList}>
    {currentManuals.length > 0 ? (
      currentManuals.map((manual) => (
        <ManualItem
          key={manual.id}
          {...manual}
          imageUrl={manual.imageUrl} // Pass the fetched imageUrl
          onDelete={() => deleteManual(manual.id)}
        />
      ))
    ) : (
      <p>{t('No manuals found.')}</p>
    )}
  </div>
)}


          {/* Pagination Controls */}
          {/* Pagination Controls */}
          <div className={styles.pagination}>
            <button
              className={styles.paginationButton}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || isLoading}
            >
              {t('Previous')}
            </button>
            <span className={styles.pageInfo}>
             {t('Page')} {currentPage} of {totalPages}
            </span>
            <button
              className={styles.paginationButton}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || isLoading}
            >
              {t('Next')}
            </button>
          </div>

          <button className={styles.viewAllButton}>{t('View all')}</button>
        </section>
      </main>
      <MosaikFooter />
    </div>
  );
}

export default MosaikLibrary;
