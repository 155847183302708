// src/components/ImageWithFallback/ImageWithFallback.js

import React from 'react';
import PropTypes from 'prop-types';
import placeholderImage from "../../assets/images/placeholder.webp"; // Adjust the path as necessary

const ImageWithFallback = ({ src, alt, className }) => {
  const handleError = (e) => {
    e.target.onerror = null; // Prevent infinite loop
    e.target.src = placeholderImage; // Fallback image
  };

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      loading="lazy"
      onError={handleError}
    />
  );
};

ImageWithFallback.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ImageWithFallback.defaultProps = {
  className: '',
};

export default ImageWithFallback;
