// src/components/ProfileSettings.js

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import HeaderWithoutRoles from "../HeaderWithoutRoles";
import MosaikFooter from "../MosaikFooter";
import defaultOrganisationPic from "../../assets/images/placeholder.webp"; // Using the placeholder image
import ConfirmationImage from "../../assets/icons/check-icon.png";
import styles from "./ProfileSettings.module.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext"; // Import AuthContext
import { uploadFile, getFileUrl } from "../../services/mosaikUploadService"; // Assuming you have this service
import i18n from "../../i18n";

const ProfileSettings = () => {
  const { t } = useTranslation();
  const { authState } = useContext(AuthContext); // Get authState from context
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [showMembershipOptions, setShowMembershipOptions] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showColorSchemeModal, setShowColorSchemeModal] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const userId = authState?.user?.id;

  const handleAccordionToggle = (accordionName) => {
    setActiveAccordion(
      activeAccordion === accordionName ? null : accordionName
    );
  };

  const handleSaveSettings = () => {
    alert(t("Settings saved!"));
    setShowMembershipOptions(false);
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    if (plan !== t("Basic Plan")) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  // const handleLanguageChange = (e) => {
  //   i18n.changeLanguage(e.target.value);
  // };
  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    if (i18n && typeof i18n.changeLanguage === 'function') {
      i18n.changeLanguage(selectedLanguage);
    } else {
      console.error('changeLanguage is not a function on i18n:', i18n);
    }
  };

  useEffect(() => {
    const fetchOrganisationsData = async () => {
      const token = authState.accessToken;
      if (!token) {
        console.error("Missing access token.");
        return;
      }

      try {
        const response = await axios.get(
          `${apiUrl}/organisations/fetchByUserId`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Fetch Organisations Response:", response.data); // Debug log

        const subscriptions = response.data.subscriptions;

        if (!subscriptions || subscriptions.length === 0) {
          console.log("No subscriptions found.");
          setOrganisations([]);
          return;
        }

        const updatedOrganisations = await Promise.all(
          subscriptions.map(async (subscription) => {
            const orgId = subscription.organisation.id;

            // Fetch roles
            let isOwner = false;
            try {
              const rolesResponse = await axios.get(
                `${apiUrl}/organisations/${orgId}/userOrganisationRoles`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              console.log(`Roles for org ${orgId}:`, rolesResponse.data); // Debug log

              isOwner = rolesResponse.data.some(
                (role) =>
                  role.user.id === userId && role.roles.includes("owner")
              );

              console.log(`Is Owner for org ${orgId}:`, isOwner); // Debug log
            } catch (error) {
              console.error(
                `Error fetching roles for organisation ${orgId}:`,
                error
              );
            }

            // Fetch files and get URL using getFileUrl
            let profilePhotoUrl = null;
            try {
              const filesResponse = await axios.get(
                `${apiUrl}/organisations/${orgId}/files`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              const profileFiles = filesResponse.data.filter(
                (file) => file.moduleField === "profile"
              );

              if (profileFiles.length > 0) {
                // Sort by createdAt descending and pick the latest file
                profileFiles.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                const latestProfileFile = profileFiles[0];
                profilePhotoUrl = await getFileUrl(latestProfileFile.id); // Fetch URL using getFileUrl
                console.log(`Profile Photo URL for org ${orgId}:`, profilePhotoUrl); // Debug log
              } else {
                console.log(`No profile files found for org ${orgId}.`);
              }
            } catch (error) {
              console.error(
                `Error fetching files for organisation ${orgId}:`,
                error
              );
            }

            return {
              ...subscription,
              isOwner,
              profilePhotoUrl, // Store the URL directly
            };
          })
        );

        console.log("Updated Organisations:", updatedOrganisations); // Debug log

        setOrganisations(updatedOrganisations);
      } catch (error) {
        console.error("Error fetching organisations:", error);
      }
    };

    fetchOrganisationsData();
  }, [authState.accessToken, userId, apiUrl]);

  const handleChangeOrganisationPicture = (organisationId) => {
    // Open a file input dialog
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        try {
          // Start uploading
          const fileData = await uploadFile(
            file,
            "profile",
            "Organisation Picture"
          );

          // Assign the new photo to the organisation
          await assignOrganisationFile(organisationId, fileData);
          const latestPictureUpload = await getFileUrl(fileData.id);
          // Update the state to reflect the new picture
          setOrganisations((prevOrganisations) =>
            prevOrganisations.map((subscription) => {
              if (subscription.organisation.id === organisationId) {
                return { ...subscription, profilePhotoUrl: latestPictureUpload }; // Corrected
              }
              return subscription;
            })
          );
          alert(t("Organisation picture updated successfully!"));
        } catch (error) {
          console.error("Error updating organisation picture:", error);
          alert(t("Failed to update organisation picture."));
        }
      }
    };
    input.click();
  };

  const assignOrganisationFile = async (organisationId, fileData) => {
    const token = authState.accessToken;
    try {
      await axios.post(
        `${apiUrl}/organisations/${organisationId}/files`,
        {
          id: fileData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error assigning organisation file:", error);
      throw error;
    }
  };

  const renderMembershipOptions = () => (
    <div className={styles.membershipOptions}>
      <h2>{t("Select Your Membership Plan")}</h2>
      <button
        onClick={() => handleSelectPlan(t("Basic Plan"))}
        className={styles.planButton}
      >
        {t("Basic Plan")} - {t("Free")}
      </button>
      <button
        onClick={() => handleSelectPlan(t("Business Plan"))}
        className={styles.planButton}
      >
        {t("Business Plan")} €198.00 / {t("Month")}
      </button>
      <button
        onClick={() => handleSelectPlan(t("Enterprise Plan"))}
        className={styles.planButton}
      >
        {t("Enterprise Plan")} €399.00 / {t("Month")}
      </button>
    </div>
  );

  const renderThankYouMessage = () => (
    <div className={styles.thankYouMessage}>
      <img src={ConfirmationImage} alt={t("Payment Confirmation")} />
      {loading
        ? t("Loading Payment Screen...")
        : t("Thank you for your membership!")}
    </div>
  );

  const renderAboutModal = () => (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h3>{t("About Mosaik App")}</h3>
        <p>{t("App Version")}: 3.8 Beta</p>
        <p>{t("Used Space")}: 12.5 GB</p>
        <p>MoID: 7899477</p>
        <p>
          <a href="/privacy-policy">{t("Data Privacy Agreement")}</a>
        </p>
        <p>
          <a href="/terms-conditions">{t("Terms & Conditions")}</a>
        </p>
        <button
          onClick={() => setShowAboutModal(false)}
          className={styles.closeButton}
        >
          {t("Close")}
        </button>
      </div>
    </div>
  );

  const renderColorSchemeModal = () => (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h3>{t("Choose Your Color Scheme")}</h3>
        <div className={styles.colorSchemeOptions}>
          <div className={styles.colorSchemeOption}>
            <span>{t("Mosaik Default Colors")}</span>
            <div
              style={{
                backgroundColor: "#094F54",
                width: 20,
                height: 20,
                borderRadius: "50%",
              }}
            ></div>
          </div>
          <div className={styles.colorSchemeOption}>
            <span>{t("Dark Mode")}</span>
            <div
              style={{
                backgroundColor: "#000",
                width: 20,
                height: 20,
                borderRadius: "50%",
              }}
            ></div>
          </div>
          <div className={styles.colorSchemeOption}>
            <span>{t("Light Mode")}</span>
            <div
              style={{
                backgroundColor: "#C7D7D7",
                width: 20,
                height: 20,
                borderRadius: "50%",
              }}
            ></div>
          </div>
        </div>
        <button
          onClick={() => setShowColorSchemeModal(false)}
          className={styles.closeButton}
        >
          {t("Close")}
        </button>
      </div>
    </div>
  );

  if (showMembershipOptions) {
    return (
      <div>
        <HeaderWithoutRoles pageTitle={t("Profile Settings")} />
        <div className={styles.mosaikContainer}>
          <div className={styles.membershipContainer}>
            {selectedPlan ? renderThankYouMessage() : renderMembershipOptions()}
          </div>
        </div>
        <MosaikFooter />
      </div>
    );
  }

  return (
    <div className={styles.mosaikContainer}>
      <HeaderWithoutRoles pageTitle={t("Profile Settings")} />
      {showAboutModal && renderAboutModal()}
      {showColorSchemeModal && renderColorSchemeModal()}

      {/* Existing Accordion Sections */}
      <div className={styles.accordionSection}>
        <div
          className={styles.accordionHeader}
          onClick={() => handleNavigation("/personal-information")}
        >
          {t("Personal Information")}
          <span className={styles.accordionToggle}>▶</span>
        </div>

        <div
          className={styles.accordionHeader}
          onClick={() => handleNavigation("/memberships")}
        >
          {t("Membership Plans")}
          <span className={styles.accordionToggle}>▶</span>
        </div>
        <div
          className={styles.accordionHeader}
          onClick={() => handleNavigation("/fobi")}
        >
          {t("Fobi")}
          <span className={styles.accordionToggle}>▶</span>
        </div>
        <div
          className={styles.accordionHeader}
          onClick={() => handleNavigation("/payments")}
        >
          {t("Payments")}
          <span className={styles.accordionToggle}>▶</span>
        </div>

        <div className={styles.accordionHeader}>
          <label className={styles.languageSelectLabel}>{t("Language")}</label>
          <select
            className={styles.languageSelect}
            value={i18n.language}
            onChange={handleLanguageChange}
          >
            <option value="en">{t("English")}</option>
            <option value="de">{t("German")}</option>
            <option value="nl">{t("Dutch")}</option>
            <option value="es">{t("Spanish")}</option>
            <option value="fr">{t("French")}</option>
            <option value="th">{t("Thai")}</option>
          </select>
        </div>
        {/* comment this section becuse issue #238 -> color sceme is not needed in my opinion right now #238  */}
        {/* 
        <div className={styles.accordionHeader} onClick={() => setShowColorSchemeModal(true)}>
          {t('Color Scheme')}
          <span className={styles.accordionToggle}>▶</span>
        </div> */}

        <div
          className={styles.accordionHeader}
          onClick={() => setShowAboutModal(true)}
        >
          {t("About")}
          <span className={styles.accordionToggle}>▶</span>
        </div>

        <div
          className={styles.accordionHeader}
          onClick={() => handleNavigation("/mosaik-alerts")}
        >
          {t("Alerts Settings")}
          <span className={styles.accordionToggle}>▶</span>
        </div>
      </div>
      {/* My Organisations Section */}
      {organisations.length > 0 &&
      <div className={styles.myOrganisations}>
        <h2>{t("My Organisations")}</h2>
        <div className={styles.organisationList}>
          {organisations.length > 0 ? (
            organisations.map((subscription) => (
              <div
                key={subscription.organisation.id}
                className={styles.organisationItem}
              >
                <img
                  src={subscription.profilePhotoUrl || defaultOrganisationPic}
                  alt={subscription.organisation.name}
                  className={styles.organisationImage}
                />
                <span>{subscription.organisation.name}</span>
                {subscription.isOwner && (
                  <button
                    onClick={() =>
                      handleChangeOrganisationPicture(
                        subscription.organisation.id
                      )
                    }
                    className={styles.changePictureButton}
                  >
                    {t("Change Picture")}
                  </button>
                )}
              </div>
            ))
          ) : (
            <p>{t("No Organisations Own by you")}</p>
          )}
        </div>
      </div>}
      <button className={styles.saveButton} onClick={handleSaveSettings}>
        {t("Save Settings")}
      </button>
      <MosaikFooter />
    </div>
  );
};

export default ProfileSettings;
