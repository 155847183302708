import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const getFileApiUrl = process.env.REACT_APP_ERPNEXT_API_URL;
const apiToken = process.env.REACT_APP_ERPNEXT_API_TOKEN;

const FileDisplay = ({ fileUrl, fileName }) => {
  const { t } = useTranslation();
  const [blobUrl, setBlobUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mimeType, setMimeType] = useState(null);

  const fullFileUrl = fileUrl?.startsWith("http") ? fileUrl : `${getFileApiUrl}${fileUrl}`;

  useEffect(() => {
    if (!fileUrl || !fileName) {
      setError(t('Invalid file details provided.'));
      setLoading(false);
      return;
    }

    const fetchFile = async () => {
      try {
        const response = await axios.get(fullFileUrl, {
          headers: {
            Authorization: `token ${apiToken}`,
          },
          responseType: "blob",
        });

        const contentType = response.headers["content-type"] || "";
        setMimeType(contentType);

        const blob = new Blob([response.data], { type: contentType });
        const url = URL.createObjectURL(blob);

        setBlobUrl(url);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching file:", err);
        setError(t("Failed to load file. Please try again later."));
        setLoading(false);
      }
    };

    fetchFile();
  }, [fullFileUrl]);

  const isImageFile = (mimeType) => /^image\/(jpeg|jpg|png|gif|bmp)$/i.test(mimeType);
  const isPDFFile = (mimeType) => mimeType === "application/pdf";

  if (loading) return <p>{t('Loading file...')}</p>;

  if (error) {
    return (
      <div>
        <p>{error}</p>
        <a href={fullFileUrl} target="_blank" rel="noopener noreferrer">
          {fileName}
        </a>
      </div>
    );
  }

  return (
    <div>
      {blobUrl && (
        <div>
          {isImageFile(mimeType) ? (
            <img
              src={blobUrl}
              alt={fileName}
              style={{ maxWidth: "100%", height: "auto" }}
              onError={() => setError(t("Failed to load image."))}
            />
          ) : isPDFFile(mimeType) ? (
            <iframe
              src={blobUrl}
              title={fileName}
              style={{ width: "100%", height: "500px", border: "none" }}
            />
          ) : (
            <a href={blobUrl} download={fileName} target="_blank" rel="noopener noreferrer">
              {t('Download')} {fileName}
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default FileDisplay;
