import React, { useState } from "react";
import axios from "axios";
import profilePicPlaceholder from "..//../assets/images/placeholder.webp";
import { useNavigate } from "react-router-dom"; // For redirection
import { useTranslation } from "react-i18next"; // Import useTranslation
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import styles from "./FeedbackNew.module.css";
import attachmentIcon1 from "../../assets/images/placeholder.webp";
import arrowDownIcon from "../../assets/images/downarrow.png";
import { uploadFile } from "../../services/mosaikUploadService"; // Import the centralized uploadFile

const FeedbackNew = () => {
  const { t } = useTranslation(); // Initialize t
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState(t("Very Urgent"));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [file, setFile] = useState(null); // Single file state
  const [previewImage, setPreviewImage] = useState(null); // For previewing uploaded image
  const [uploadError, setUploadError] = useState("");
  const navigate = useNavigate(); // For redirection after feedback submission

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePriorityChange = (selectedPriority) => {
    setPriority(selectedPriority);
    setIsDropdownOpen(false);
  };

  // File selection and preview logic (without uploading)
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Set the selected file
      // Preview the image or document (if it's an image)
      if (selectedFile.type.includes("image")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result); // Set preview image
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null); // Reset preview for non-image files
      }
    }
  };

  // Handle feedback submission and file upload
  const handleSubmitFeedback = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const storedUser = localStorage.getItem("userInfo");
    const user = storedUser ? JSON.parse(storedUser) : null;

    if (!user) {
      alert(t("User information is missing."));
      return;
    }

    const { id: userId, organisationId } = user;

    // Step 1: Upload the file (if any) and get the file ID
    let fileId = null;
    if (file) {
      try {
        const uploadResponse = await uploadFile(file, "feedback", file.name);
        if (uploadResponse && uploadResponse.id) {
          fileId = uploadResponse.id; // Extract only the file ID
        } else {
          throw new Error(t("Invalid upload response structure."));
        }
      } catch (error) {
        console.error(t("File upload failed:"), error);
        setUploadError(t("Failed to upload the file. Please try again."));
        return; // Halt submission if file upload fails
      }
    }

    // Step 2: Prepare the feedback payload
    const feedbackPayload = {
      title: title.trim() || t("No Title Provided"),
      description: description.trim() || t("No Description Provided"),
      status: "open",
      resolved: false,
      rating: 0,
      ...(fileId && { files: { connect: [{ id: fileId }] } }), // Conditionally include files
    };

    try {
      // Step 3: Submit the feedback
      const feedbackResponse = await axios.post(
        `${apiUrl}/feedbacks/add`,
        feedbackPayload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      const feedbackId = feedbackResponse.data.id;
      console.log(t("Feedback successfully created with ID:"), feedbackId);

      // Step 4: Notify the user and redirect
      alert(t("Feedback successfully sent!"));
      navigate("/mosaik-feedback", { state: { feedbackIds: [feedbackId] } }); // Redirect with feedback ID
    } catch (error) {
      console.error(t("Feedback submission failed:"), error);
      alert(t("Failed to submit feedback. Please try again."));
    }
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t("New Feedback")} />
      <div className={styles.ticketNewContainer}>
        <div className={styles.div}>
          <div className={styles.newTicket}>{t("New Feedback")}</div>

          {/* Title Input */}
          <div className={styles.div2}>
            <div>{t("Title")}</div>
            <input
              type="text"
              className={styles.inputField}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t("Enter feedback title")}
              required
            />
          </div>

          {/* Description Input */}
          <div className={styles.div4}>
            <div>{t("Description")}</div>
            <textarea
              className={styles.textareaField}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t("Enter feedback description")}
              required
            ></textarea>
          </div>

          {/* File Upload Section */}
          <div className={styles.div6}>
            <img
              src={previewImage || profilePicPlaceholder}
              alt={t("Attachment")}
              className={styles.img}
            />
            <input
              type="file"
              onChange={handleFileSelection}
              className={styles.uploadInput}
              accept="image/*,application/pdf" // Restrict file types as needed
            />
            {uploadError && <p className={styles.errorText}>{uploadError}</p>}
            {/* {file && (
              <p className={styles.fileName}>
                {t("Selected File")}: {file.name}
              </p>
            )} */}
          </div>

          {/* Priority Dropdown */}
          <div className={styles.issuePriority}>{t("Issue Priority")}</div>
          <div className={styles.div13}>
            <div className={styles.div14} onClick={handleDropdownClick}>
              <div className={styles.veryUrgent}>{priority}</div>
              <img
                src={arrowDownIcon}
                alt={t("Arrow down")}
                className={styles.img4}
              />
            </div>
            {isDropdownOpen && (
              <div className={styles.dropdown}>
                {[t("Low"), t("Medium"), t("High"), t("Very Urgent")].map(
                  (level) => (
                    <div
                      key={level}
                      className={styles.dropdownItem}
                      onClick={() => handlePriorityChange(level)}
                    >
                      {level}
                    </div>
                  )
                )}
              </div>
            )}
          </div>

          {/* Submit Feedback Button */}
          <button className={styles.button} onClick={handleSubmitFeedback}>
            {t("Submit Feedback")}
          </button>
        </div>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default FeedbackNew;
