// src/hooks/useRepairAdminMode.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useRepairAdminMode = () => {
  const [repairAdminMode, setRepairAdminMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        console.error('No access token found in localStorage.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          `${apiUrl}/auth/get-loggedin-user`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const userInfo = response.data;
        const roles = userInfo.roles || [];

        if (roles.includes('admin') || roles.includes('repairManager') || roles.includes('owner')) {
          setRepairAdminMode(true);
        } else {
          setRepairAdminMode(false);
        }
      } catch (err) {
        console.error('Error fetching user info:', err);
        setError(err);
        setRepairAdminMode(false);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [apiUrl]);

  return { repairAdminMode, loading, error };
};

export default useRepairAdminMode;
