// src/context/RocketchatContext.js

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { AuthContext } from './AuthContext';
import EventEmitter from 'events';

// Create the WebSocket Context
export const WebSocketContext = createContext(null);

// Initialize EventEmitter
const eventEmitter = new EventEmitter();

// Placeholder Avatar URL (Ensure this path is correct)
const placeholderAvatar = '/assets/avatars/placeholder.png'; // Update this path as needed

// WebSocket Provider Component
export const WebSocketProvider = ({ children }) => {
  const { authState } = useContext(AuthContext);
  const socketRef = useRef(null);
  const [socketIsReady, setSocketIsReady] = useState(false);
  const [roomSubscriptions, setRoomSubscriptions] = useState([]);
  const roomSubscriptionsRef = useRef(roomSubscriptions);

  // Safely extract user ID and tokens
  const userId = authState.user?.id;
  const rocketchatToken = authState.rocketchatToken;

  const isSocketOpenAndReady = useMemo(() => {
    return (
      socketIsReady &&
      socketRef.current &&
      socketRef.current.readyState === WebSocket.OPEN
    );
  }, [socketIsReady]);

  // Keep roomSubscriptionsRef updated
  useEffect(() => {
    roomSubscriptionsRef.current = roomSubscriptions;
  }, [roomSubscriptions]);

  // Object to keep track of method call promises
  const methodCallPromises = useRef({});

  // Handle incoming WebSocket messages
  const handleSocketMessage = useCallback(
    (event) => {
      if (!event.data) return;

      try {
        const data = JSON.parse(event.data);
        //console.log('🚀 Received WebSocket message:', data);

        // Handle login result
        if (
          data.msg === 'result' &&
          data.id &&
          data.id.startsWith(`auth_${userId}`)
        ) {
          if (data.error) {
            console.error('🔴 WebSocket login error:', data.error);
            setSocketIsReady(false);
            // Optionally, notify the user or trigger logout
            return;
          }

          console.log('✅ WebSocket login successful.');
          setSocketIsReady(true);

          // Subscribe to user notifications
          socketRef.current?.send(
            JSON.stringify({
              msg: 'sub',
              id: `user_notifications_${userId}`,
              name: 'stream-notify-user',
              params: [`${userId}/notification`, false],
            })
          );
          console.log(`Subscribed to user notifications for userId: ${userId}`);

          // Subscribe to rooms after login
          roomSubscriptionsRef.current.forEach((roomId) => {
            // Subscribe to stream-room-messages
            socketRef.current?.send(
              JSON.stringify({
                msg: 'sub',
                id: `stream-room-messages_${roomId}`,
                name: 'stream-room-messages',
                params: [roomId, false],
              })
            );
            console.log(
              `Subscribed to stream-room-messages for roomId: ${roomId}`
            );

            // Subscribe to stream-notify-room for typing notifications only
            socketRef.current?.send(
              JSON.stringify({
                msg: 'sub',
                id: `stream-notify-room_${roomId}`,
                name: 'stream-notify-room',
                params: [`${roomId}/userTyping`, false],
              })
            );
            console.log(
              `Subscribed to stream-notify-room for typing notifications in roomId: ${roomId}`
            );
          });

          // Clear pending room subscriptions
          setRoomSubscriptions([]);
          return;
        }

        // Handle method call responses
        if (data.msg === 'result' && data.id) {
          const methodId = data.id;
          if (methodCallPromises.current[methodId]) {
            const { resolve, reject } = methodCallPromises.current[methodId];
            delete methodCallPromises.current[methodId];

            if (data.error) {
              //console.error(`🔴 Error in method ${methodId}:`, data.error);
              reject(data.error);
            } else {
              //console.log(`✅ Method ${methodId} succeeded with result:`, data.result);
              resolve(data.result);
            }
          }
          return;
        }

        // Handle subscription data (e.g., new messages, typing)
        if (data.msg === 'changed') {
          if (data.collection === 'stream-room-messages') {
            const messageData = data.fields.args[0];
            console.log('💬 New message received:', messageData);
            eventEmitter.emit('newMessage', messageData);
          }

          if (data.collection === 'stream-notify-room') {
            const eventName = data.fields?.eventName;
            const args = data.fields?.args;

            if (eventName === 'userTyping') {
              const typingData = {
                username: args[0],
                flag: args[1],
                rid: args[2], // roomId
              };
              console.log('📝 Typing event:', typingData);
              eventEmitter.emit('typing', typingData);
            }

            // Handle other event types like reactions, edits, etc., if needed
          }
        }
      } catch (error) {
        console.error('❌ Failed to parse WebSocket message:', error);
      }
    },
    [userId]
  );

  // Initialize WebSocket connection
  const initializeSocket = useCallback(() => {
    if (!userId || !rocketchatToken) {
      console.log(
        '🚀 No user ID or Rocket.Chat token. Cannot initialize WebSocket.'
      );
      return;
    }

    if (
      socketRef.current &&
      (socketRef.current.readyState === WebSocket.OPEN ||
        socketRef.current.readyState === WebSocket.CONNECTING)
    ) {
      console.log('🚀 WebSocket is already open or connecting.');
      return;
    }

    // Initialize ReconnectingWebSocket with exponential backoff and max retries
    const ws = new ReconnectingWebSocket(
      `${process.env.REACT_APP_ROCKETCHAT_URL}/websocket`,
      [],
      {
        // Reconnection options
        maxRetries: 10, // Limit the number of reconnection attempts
        reconnectInterval: 1000, // Start with 1 second
        maxReconnectInterval: 30000, // Max 30 seconds
        minReconnectInterval: 1000, // Min 1 second
        connectionTimeout: 4000, // 4 seconds timeout
      }
    );

    console.log('🚀 Initializing WebSocket connection...');
    socketRef.current = ws;

    // Add event listeners
    ws.addEventListener('message', handleSocketMessage);

    ws.addEventListener('open', () => {
      console.log('🚀 WebSocket connection opened.');
      ws.send(
        JSON.stringify({
          msg: 'connect',
          version: '1',
          support: ['1'],
        })
      );

      if (rocketchatToken) {
        console.log('🚀 Sending login method to WebSocket server...');
        ws.send(
          JSON.stringify({
            msg: 'method',
            method: 'login',
            id: `auth_${userId}`,
            params: [
              {
                resume: rocketchatToken,
              },
            ],
          })
        );
      }
    });

    ws.addEventListener('close', (event) => {
      setSocketIsReady(false);
      console.log(
        `🚀 WebSocket closed with code: ${event.code}, reason: ${event.reason}`
      );

      // Handle specific close codes if needed
      if (event.code === 1006) { // Abnormal closure
        console.warn('🚨 WebSocket connection closed abnormally.');
      }
    });

    ws.addEventListener('error', (error) => {
      console.error('🚨 WebSocket error:', error);
      // Optionally, implement additional error handling or user notifications
    });

    // Handle reconnection attempts and limits
    ws.addEventListener('reconnect_attempt', (attemptNumber) => {
      console.log(`🔄 WebSocket reconnection attempt #${attemptNumber}`);
    });

    ws.addEventListener('reconnect_failed', () => {
      console.error('🚨 WebSocket failed to reconnect after maximum attempts.');
      // Optionally, notify the user or trigger a fallback mechanism
    });

    return ws;
  }, [userId, rocketchatToken, handleSocketMessage]);

  // Effect to initialize and clean up WebSocket connection
  useEffect(() => {
    if (!rocketchatToken || !userId) {
      console.log('🚀 No Rocket.Chat token or user ID. Closing socket.');
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
        setSocketIsReady(false);
      }
      return;
    }

    initializeSocket();

    return () => {
      if (socketRef.current) {
        console.log('🚀 Cleaning up and closing WebSocket connection.');
        socketRef.current.close();
        socketRef.current = null;
        setSocketIsReady(false);
      }
    };
  }, [rocketchatToken, userId, initializeSocket]);

  // Subscribe to rooms
  const subscribeToRooms = useCallback(
    (roomIds) => {
      if (!userId) {
        console.warn('🚀 User ID is not available. Cannot subscribe to rooms.');
        return;
      }

      roomIds.forEach((roomId) => {
        if (
          socketRef.current &&
          socketRef.current.readyState === WebSocket.OPEN
        ) {
          // Subscribe to stream-room-messages
          socketRef.current.send(
            JSON.stringify({
              msg: 'sub',
              id: `stream-room-messages_${roomId}`,
              name: 'stream-room-messages',
              params: [roomId, false],
            })
          );
          console.log(
            `Subscribed to stream-room-messages for roomId: ${roomId}`
          );

          // Subscribe to stream-notify-room for typing notifications only
          socketRef.current.send(
            JSON.stringify({
              msg: 'sub',
              id: `stream-notify-room_${roomId}`,
              name: 'stream-notify-room',
              params: [`${roomId}/userTyping`, false],
            })
          );
          console.log(
            `Subscribed to stream-notify-room for typing notifications in roomId: ${roomId}`
          );
        } else {
          // If socket is not ready, add to subscriptions to subscribe after login
          setRoomSubscriptions((prev) => [...prev, roomId]);
          console.log(
            `🚀 WebSocket not ready. Added roomId: ${roomId} to pending subscriptions.`
          );
        }
      });
    },
    [userId]
  );

  // Unsubscribe from rooms
  const unsubscribeFromRooms = useCallback((roomIds) => {
    roomIds.forEach((roomId) => {
      const subId = `stream-room-messages_${roomId}`;
      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        socketRef.current.send(
          JSON.stringify({
            msg: 'unsub',
            id: subId,
          })
        );
        console.log(`Unsubscribed from stream-room-messages for roomId: ${roomId}`);

        // Unsubscribe from stream-notify-room
        const notifySubId = `stream-notify-room_${roomId}`;
        socketRef.current.send(
          JSON.stringify({
            msg: 'unsub',
            id: notifySubId,
          })
        );
        console.log(`Unsubscribed from stream-notify-room for roomId: ${roomId}`);
      }
    });
  }, []);

  // Get user subscriptions
  const getSubscriptions = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (!isSocketOpenAndReady || !socketRef.current) {
        console.error("🚀 Socket is not ready. Can't get subscriptions.");
        return reject('Socket not ready');
      }

      const methodId = `getSubscriptions_${Date.now()}`;

      methodCallPromises.current[methodId] = { resolve, reject };

      socketRef.current.send(
        JSON.stringify({
          msg: 'method',
          method: 'subscriptions/get',
          id: methodId,
          params: [],
        })
      );
      console.log(`Requested subscriptions with methodId: ${methodId}`);
    });
  }, [isSocketOpenAndReady]);

  // Get rooms with optional lastUpdate parameter
  const getRooms = useCallback(
    (lastUpdate = 0) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't get rooms.");
          return reject('Socket not ready');
        }

        const methodId = `getRooms_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'rooms/get',
            id: methodId,
            params: [{ $date: lastUpdate }],
          })
        );
        console.log(`Requested rooms with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Get room details by room ID
  const getRoomById = useCallback(
    (roomId) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't get room details.");
          return reject('Socket not ready');
        }

        const methodId = `getRoomById_${roomId}_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'getRoomById',
            id: methodId,
            params: [roomId],
          })
        );
        console.log(`Requested room details for roomId: ${roomId} with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Mute chat
  const muteChat = useCallback(
    (roomId, mute) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't mute chat.");
          return reject('Socket not ready');
        }

        const methodId = `muteChat_${roomId}_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'saveNotificationSettings',
            id: methodId,
            params: [roomId, 'desktopNotifications', mute ? 'nothing' : 'default'],
          })
        );
        console.log(`Sent muteChat request for roomId: ${roomId} with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Pin or unpin chat
  const pinChat = useCallback(
    (roomId, pin) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't pin chat.");
          return reject('Socket not ready');
        }

        const methodId = `pinChat_${roomId}_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'toggleFavorite',
            id: methodId,
            params: [roomId, pin],
          })
        );
        console.log(`Sent pinChat request for roomId: ${roomId} with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Delete chat
  const deleteChat = useCallback(
    (roomId) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't delete chat.");
          return reject('Socket not ready');
        }

        const methodId = `deleteChat_${roomId}_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'eraseRoom',
            id: methodId,
            params: [roomId],
          })
        );
        console.log(`Sent deleteChat request for roomId: ${roomId} with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Send a message
  const sendMessage = useCallback(
    (
      messageText,
      roomId,
      replyToMessageId = null,
      replyText = null,
      attachment = null,
      type = null
    ) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't send message.");
          return reject('Socket not ready');
        }

        const methodId = `sendMessage_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        const messageObject = {
          rid: roomId,
          msg: messageText,
        };

        if (replyToMessageId) {
          messageObject.tmid = replyToMessageId; // Associate the message with the thread
          messageObject.attachments = [
            {
              message_link: replyToMessageId,
              text: replyText || '', // Optional reply text
            },
          ];
          console.log(`📤 Sending reply to messageId: ${replyToMessageId}`);
        }

        if (attachment) {
          messageObject.attachments = messageObject.attachments || [];
          messageObject.attachments.push({
            type: attachment.type || 'file',
            title: attachment.title,
            image_url: attachment.image_url || null,
            file_url: attachment.file_url || null,
          });
        }

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'sendMessage',
            id: methodId,
            params: [messageObject],
          })
        );
        console.log(`📤 Sent sendMessage request with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Load message history
  const loadHistoryMethod = useCallback(
    (
      roomId,
      oldestMessageTimestamp = null,
      limit = 2,
      ls,
      methodIdSuffix = ''
    ) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          //console.error("🚀 Socket is not ready. Can't load history.");
          return reject('Socket not ready');
        }

        const methodId = `loadHistory_${roomId}_${Date.now()}${methodIdSuffix}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'loadHistory',
            id: methodId,
            params: [
              roomId,
              oldestMessageTimestamp ? { $date: oldestMessageTimestamp } : null,
              limit,
              ls,
            ],
          })
        );
       // console.log(`📥 Sent loadHistory request for roomId: ${roomId} with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Create a new direct message chat
  const createNewChat = useCallback(
    (username) => {
      if (!isSocketOpenAndReady || !socketRef.current) {
        console.error("🚀 Socket is not ready. Can't create direct message.");
        return;
      }

      const methodId = `createDirectMessage_${username}_${Date.now()}`;

      methodCallPromises.current[methodId] = {
        resolve: () => {},
        reject: () => {},
      };

      socketRef.current.send(
        JSON.stringify({
          msg: 'method',
          method: 'createDirectMessage',
          id: methodId,
          params: [username],
        })
      );
      console.log(`📤 Sent createDirectMessage request for username: ${username} with methodId: ${methodId}`);
    },
    [isSocketOpenAndReady]
  );

  // Create a new group chat
  const createGroupChat = useCallback(
    (groupName, usernames, extraData = {}, readOnly = false) => {
      if (!isSocketOpenAndReady || !socketRef.current) {
        console.error("🚀 Socket is not ready. Can't create private group.");
        return;
      }

      const methodId = `createPrivateGroup_${usernames.join('_')}_${Date.now()}`;

      methodCallPromises.current[methodId] = {
        resolve: () => {},
        reject: () => {},
      };

      socketRef.current.send(
        JSON.stringify({
          msg: 'method',
          method: 'createPrivateGroup',
          id: methodId,
          params: [groupName, usernames, undefined, readOnly, extraData],
        })
      );
      console.log(`📤 Sent createPrivateGroup request for groupName: ${groupName} with methodId: ${methodId}`);
    },
    [isSocketOpenAndReady]
  );

  // Delete a message
  const deleteMessage = useCallback(
    (messageId) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't delete message.");
          return reject('Socket not ready');
        }

        const methodId = `deleteMessage_${messageId}_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'deleteMessage',
            id: methodId,
            params: [{ _id: messageId }],
          })
        );
        console.log(`📤 Sent deleteMessage request for messageId: ${messageId} with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Set or remove a reaction on a message
  const setReaction = useCallback(
    (messageId, emoji, shouldUnreact = false) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't set reaction.");
          return reject('Socket not ready');
        }

        const methodId = `setReaction_${messageId}_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: shouldUnreact ? 'removeReaction' : 'setReaction',
            id: methodId,
            params: [emoji, messageId],
          })
        );
        console.log(`📤 Sent ${shouldUnreact ? 'removeReaction' : 'setReaction'} request for messageId: ${messageId} with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Update a message's text
  const updateMessage = useCallback(
    (roomId, messageId, newText) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't update message.");
          return reject('Socket not ready');
        }

        const methodId = `updateMessage_${messageId}_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        const messageObject = {
          _id: messageId,
          rid: roomId,
          msg: newText,
        };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'updateMessage',
            id: methodId,
            params: [messageObject],
          })
        );
        console.log(`📤 Sent updateMessage request for messageId: ${messageId} with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // Send typing notifications
  const sendTypingNotificationMethod = useCallback(
    (roomId, username, isTyping) => {
      if (!isSocketOpenAndReady || !socketRef.current) {
        console.error("🚀 Socket is not ready. Can't send typing notification.");
        return;
      }

      const methodId = `sendTyping_${roomId}_${Date.now()}`;

      methodCallPromises.current[methodId] = {
        resolve: () => {},
        reject: () => {},
      };

      const payload = {
        msg: 'method',
        method: 'stream-notify-room',
        id: methodId,
        params: [`${roomId}/typing`, username, isTyping],
      };

      socketRef.current.send(JSON.stringify(payload));
      console.log(
        `🚀 Sent typing notification for roomId: ${roomId}, username: ${username}, isTyping: ${isTyping} with methodId: ${methodId}`
      );
    },
    [isSocketOpenAndReady]
  );

  // **Implementing getSingleMessage**
  const getSingleMessage = useCallback(
    (messageId) => {
      return new Promise((resolve, reject) => {
        if (!isSocketOpenAndReady || !socketRef.current) {
          console.error("🚀 Socket is not ready. Can't get single message.");
          return reject('Socket not ready');
        }

        const methodId = `getSingleMessage_${messageId}_${Date.now()}`;

        methodCallPromises.current[methodId] = { resolve, reject };

        socketRef.current.send(
          JSON.stringify({
            msg: 'method',
            method: 'getSingleMessage',
            id: methodId,
            params: [messageId],
          })
        );
        console.log(`📤 Sent getSingleMessage request for messageId: ${messageId} with methodId: ${methodId}`);
      });
    },
    [isSocketOpenAndReady]
  );

  // **Subscribe to Rooms Upon Request**
  useEffect(() => {
    if (isSocketOpenAndReady && roomSubscriptions.length > 0) {
      subscribeToRooms(roomSubscriptions);
      setRoomSubscriptions([]);
    }
  }, [isSocketOpenAndReady, roomSubscriptions, subscribeToRooms]);

  // Define context value with all methods and EventEmitter
  const contextValue = useMemo(
    () => ({
      socketRef,
      isSocketOpenAndReady,
      subscribeToRooms,
      unsubscribeFromRooms,
      getSubscriptions,
      getRooms,
      getRoomById, // Exposed getRoomById
      muteChat,
      pinChat,
      deleteChat,
      getSingleMessage, // Exposed getSingleMessage
      createNewChat,
      createGroupChat,
      sendMessage,
      loadHistory: loadHistoryMethod, // Exposed loadHistory with proper naming
      deleteMessage,
      setReaction,
      updateMessage,
      sendTypingNotification: sendTypingNotificationMethod, // Exposed sendTypingNotification
      eventEmitter, // Expose EventEmitter for components to listen to events
    }),
    [
      isSocketOpenAndReady,
      subscribeToRooms,
      unsubscribeFromRooms,
      getSubscriptions,
      getRooms,
      getRoomById,
      muteChat,
      pinChat,
      deleteChat,
      getSingleMessage,
      createNewChat,
      createGroupChat,
      sendMessage,
      loadHistoryMethod,
      deleteMessage,
      setReaction,
      updateMessage,
      sendTypingNotificationMethod,
      eventEmitter,
    ]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
