import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './CreateEvent.module.css';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import EventForm from './EventForm';
import AttendeeList from './AttendeeList';
import EventAgenda from './EventAgenda';
import EventNotes from './EventNotes';
import EventDateTime from './EventDateTime ';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Define your API URL (e.g., process.env.REACT_APP_API_URL)
const apiUrl = process.env.REACT_APP_API_URL;

const CreateEvent = ({ 
  initialData = {}, // Prop to pass initial data for edit
  isEdit = false, // Prop to determine create or edit mode
  onSubmit, // Function to handle form submission
}) => {
  const { t } = useTranslation(); // Initialize t
  const navigate = useNavigate();

  // Use default values or initialData when editing
  const [formData, setFormData] = useState({
    title: initialData.title || '',
    description: initialData.description || '',
    displayPictureId: initialData.displayPictureId || '',
  });

  const [selectedAttendees, setSelectedAttendees] = useState(initialData.attendees || []);
  const [agenda, setAgenda] = useState(initialData.agenda || []);
  const [notes, setNotes] = useState(initialData.notes || '');
  const [dateTimeData, setDateTimeData] = useState({
    startDate: initialData.startDate || moment().format('YYYY-MM-DD'),
    startTime: initialData.startTime || moment().format('HH:mm'),
    endDate: initialData.endDate || moment().format('YYYY-MM-DD'),
    endTime: initialData.endTime || moment().add(1, 'hours').format('HH:mm'),
    repeatOption: initialData.repeatOption || 'none',
    selectedDays: initialData.selectedDays || [],
  });

  // Handle change in form
  const handleFormChange = (data) => {
    setFormData(data);
  };

  // Handle attendee selection
  const handleAttendeeSelect = (attendeeIds) => {
    setSelectedAttendees(attendeeIds);
  };

  // Handle date/time change
  const handleDateTimeChange = (data) => {
    setDateTimeData({
      ...dateTimeData,
      startDate: data.startDate,
      startTime: data.startTime,
      endDate: data.endDate,
      endTime: data.endTime,
      repeatOption: data.repeatOption,
      selectedDays: data.selectedDays,
    });
  };

  // Handle agenda change
  const handleAgendaChange = (newAgenda) => {
    setAgenda(newAgenda);
  };

  // Handle notes change
  const handleNotesChange = (newNotes) => {
    setNotes(newNotes);
  };

  // Construct the event data to be sent
  const constructEventData = () => {
    return {
      title: formData?.title,
      description: formData?.description,
      agenda: {
        topics: agenda.map((item) => `${item.title} (${item.startTime} - ${item.endTime})`),
      },
      startDate: new Date(`${dateTimeData.startDate}T${dateTimeData.startTime}`), // Ensure Date object
      startTime: new Date(`${dateTimeData.startDate}T${dateTimeData.startTime}`), // Ensure Date object
      endDate: new Date(`${dateTimeData.endDate}T${dateTimeData.endTime}`), // Ensure Date object
      endTime: new Date(`${dateTimeData.endDate}T${dateTimeData.endTime}`), // Ensure Date object
      eventStatus: 'draft',
      eventType: 'appointment',
      eventsDisplayPicture: formData.displayPictureId ? { id: formData.displayPictureId } : null,
      invitees: {
        attendees: selectedAttendees.map((id) => ({ id })),
      },
      location: 'Conference Room 1', // Replace with actual location
      notes,
      // organiser: {
      //   id: '66fe42f3fc7f531089604f5c', // Replace with actual organiser ID
      // },
      recurrence: dateTimeData.repeatOption,
      recurrenceCustomDays:
        dateTimeData.repeatOption === 'custom'
          ? dateTimeData.selectedDays.map((day) => {
              switch (day) {
                case 'SU': return 'SU';
                case 'MO': return 'MO';
                case 'TU': return 'TU';
                case 'WE': return 'WE';
                case 'TH': return 'TH';
                case 'FR': return 'FR';
                case 'SA': return 'SA';
                default: return '';
              }
            })
          : [],
    };
  };

  // Handle form submission (create or update)
  const handleSubmit = async () => {
    const eventPayload = constructEventData();

    if (isEdit && onSubmit) {
      // Call the provided onSubmit function to update
      await onSubmit(eventPayload);
    } else {
      // Create event logic goes here
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.post(`${apiUrl}/events`, eventPayload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status !== 201) {
          throw new Error(t('Failed to create event'));
        }

        console.log(t('Event created:'), response.data);
        // Navigate to the calendar page or event details page
        navigate('/mosaik-calendar');
      } catch (error) {
        console.error(t('Error creating event:'), error);
      }
    }
  };

  return (
    <main className={styles.createEvent}>
      <MosaikHeaderWithBack pageTitle={isEdit ? t('Edit Event') : t('Create Event')} />
      <div className={styles.CreateEventContainer}>
        {/* Form fields */}
        <EventForm onFormChange={handleFormChange} formData={formData} />
        <AttendeeList onSelectAttendees={handleAttendeeSelect} selectedAttendees={selectedAttendees} />
        <EventDateTime onDateTimeChange={handleDateTimeChange} dateTimeData={dateTimeData} />
        <EventAgenda onAgendaChange={handleAgendaChange} agenda={agenda} />
        <EventNotes onNotesChange={handleNotesChange} notes={notes} />

        {/* Submit Button */}
        <button className={styles.createButton} onClick={handleSubmit}>
          {isEdit ? t('Update Event') : t('Create Event')}
        </button>
      </div>
      <MosaikFooter />
    </main>
  );
};

export default CreateEvent;
