import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Tesseract from "tesseract.js";
import MosaikFooter from "../MosaikFooter";
import profilePic from "../../assets/images/placeholder.webp";
import spinner from "../../assets/images/spinner.gif"; // Loader spinner image
import ConfirmationImage from "../../assets/icons/check-icon.png";
import attachmentIcon from "../../assets/images/attachment-icon.png";
import AiScan from "../../assets/images/ai-scan.png";
import styles from "./PersonalInformation.module.css";
import profilePicPlaceholder from "../../assets/images/placeholder.webp";
import pencilIcon from "../../assets/images/edit-icon.png";
import {
  uploadFile,
  renderFile,
  deleteFile,
} from "../../services/mosaikUploadService";
import { AuthContext } from "../../context/AuthContext"; // Import AuthContext
import HeaderWithoutRoles from "../HeaderWithoutRoles";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { getProfilePictureUrl } from "../../utils/getProfileUrl";

const apiUrl = process.env.REACT_APP_API_URL;
const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle empty case

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Return the formatted date without time
  return `${year}-${month}-${day}`;
};
const PersonalInformation = () => {
  const { t } = useTranslation(); // Initialize the translation function
  const { setAuthState, authState } = useContext(AuthContext);
  const [isUploading, setIsUploading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(profilePic);
  const [currentProfilePictureId, setCurrentProfilePictureId] = useState(null); // Track the current profile picture ID
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [basicInfo, setBasicInfo] = useState({
    dateOfBirth: "",
    address: "",
    nationality: "",
    religion: "",
    maritalStatus: "",
    socialSecurityNumber: "",
    healthInsuranceNumber: "",
    lanrLaznr: "",
    insuranceConfirmation: "",
    additionalHealthInsurance: "",
    taxId: "",
    iban: "",
    bic: "",
  });
  // Fetch the user profile on component mount to check for profile photo
  const fetchUserProfile = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const userInfo = localStorage.getItem("userInfo");
      const userman = JSON.parse(userInfo);
      const userId = userman.id; // Retrieve the user ID

      // Fetch the user details including profile photo
      const response = await axios.get(`${apiUrl}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const user = response.data;
      console.log("user info", user);
      if (user) {
        const fullName = user.firstName + " " + user.lastName;
        setUserName(fullName);
      }
      if (user.profilePhotoFile && user.profilePhotoFile.fileUrl) {
        // const profilePicUrl = user.profilePhotoFile.fileUrl.startsWith("https")
        //   ? user.profilePhotoFile.fileUrl
        //   : `https://${user.profilePhotoFile.fileUrl}`;
        let profilePicUrl = await getProfilePictureUrl(user);

        setProfilePicture(profilePicUrl);
        setCurrentProfilePictureId(user.profilePhotoFile.id); // Track the current profile picture ID
      }
    } catch (error) {
      console.error(t("Error fetching user profile:"), error);
    }
  };

  // Fetch the profile data when the component mounts
  useEffect(() => {
    fetchUserProfile(); // Call the function to fetch user profile
  }, []);

  const [
    additionalInsuranceConfirmations,
    setAdditionalInsuranceConfirmations,
  ] = useState([]);
  const [optionalDocuments, setOptionalDocuments] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [sharedWithCompanies, setSharedWithCompanies] = useState([]);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAiOptions, setShowAiOptions] = useState(false);
  const [extractedTextLines, setExtractedTextLines] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [textMappings, setTextMappings] = useState({});
  const [profileId, setProfileId] = useState(null); // Store the profile ID
  const [userId, setUserId] = useState(null); // Store the logged-in user ID
  const [userName, setUserName] = useState("User"); // Store the logged-in user ID

  // Fetch user profile using the correct `where` query parameter
  const fetchProfile = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const userInfo = localStorage.getItem("userInfo");
      const userman = JSON.parse(userInfo);
      const userId = userman.id; // Replace with actual user ID
      const organisationsdata = JSON.parse(userInfo);
      const organisationRoles = organisationsdata.organisationRoles;
      const response = await axios.get(
        `${apiUrl}/profiles?where[user][id]=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const profileData = response.data[0]; // Assuming the response is an array of profiles
      if (profileData) {
        setProfileId(profileData.id); // Store the profile ID
        setOptionalDocuments(profileData.optionalFiles || {});
        if (profileData.profilePhotoFile) {
          const profileImageUrl = await getProfilePictureUrl(profileData);
          setProfilePicture(profileImageUrl);
        }
      }

      // Format the dateOfBirth to 'YYYY-MM-DD' for UI display
      const formattedDateOfBirth = profileData.dateOfBirth
        ? formatDate(profileData.dateOfBirth)
        : "";

      setBasicInfo({
        dateOfBirth: formattedDateOfBirth, // Use formatted date here
        address: profileData.address || "",
        nationality: profileData.nationality || "",
        religion: profileData.religion || "",
        maritalStatus: profileData.civilStatus || "",
        socialSecurityNumber: profileData.socialSecurityNumber || "",
        healthInsuranceNumber: profileData.healthInsuranceNumber || "",
        lanrLaznr: profileData.laNr_lazNr || "",
        taxId: profileData.taxId || "",
        iban: profileData.iban || "",
        bic: profileData.bic || "",
      });
    } catch (error) {
      console.error(t("Error fetching profile:"), error);
    }
  };

  // Fetch profile data on component mount
  useEffect(() => {
    fetchProfile();
  }, []); // Empty dependency array ensures this runs once

  // Function to handle profile picture upload and delete old profile picture
  const handleProfilePictureChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setIsUploading(true); // Start the loader
      try {
        const token = localStorage.getItem("accessToken");
        const fileData = await uploadFile(file, "profile", "Profile Picture");

        // Set profile picture preview locally
        const userInfo = localStorage.getItem("userInfo");
        const userman = JSON.parse(userInfo);
        const userId = userman.id; // Retrieve the user ID

        // Update the user profile with the new profile picture
        const updateResponse = await axios.patch(
          `${apiUrl}/users/${userId}`,
          {
            profilePhotoFile: {
              id: fileData.id,
              fileUrl: fileData.fileUrl,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const profileImageUrl = await getProfilePictureUrl(updateResponse.data);
        setProfilePicture(profileImageUrl);

        // Delete the old profile picture if available
        if (currentProfilePictureId) {
          await deleteFile(currentProfilePictureId); // Call the deleteFile service
        }

        // Update authState with the new profile picture
        setAuthState((prevState) => ({
          ...prevState,
          user: {
            ...prevState.user,
            profilePicture: profileImageUrl, // Update the profile picture in the global state
          },
        }));

        setCurrentProfilePictureId(fileData.id); // Update the current profile picture ID
        console.log(
          "Profile picture updated and old picture deleted successfully!"
        );
      } catch (error) {
        console.error(t("Error uploading profile picture:"), error);
      } finally {
        setIsUploading(false); // Stop the loader after the upload
      }
    }
  };

  // Function to handle file uploads and link to backend
  const handleFileUpload = async (e, fieldKey) => {
    const file = e.target.files[0];

    if (file) {
      try {
        // Upload file using mosaikUploadService
        const fileData = await uploadFile(file, "profile", fieldKey);

        // Ensure that fileId is saved along with the uploaded file metadata
        setOptionalDocuments((prev) => ({
          ...prev,
          [fieldKey]: {
            fileId: fileData.id, // Store the correct file ID
            fileName: fileData.fileName,
            fileUrl: fileData.fileUrl,
          },
        }));

        // Once uploaded, update the profile with the file metadata
        await updateProfileWithOptionalFiles(fieldKey, {
          fileId: fileData.id, // Ensure the correct fileId is passed
          fileUrl: fileData.fileUrl,
        });

        console.log(
          `${fieldKey} file uploaded and profile updated successfully`
        );
      } catch (error) {
        console.error(`Error uploading ${fieldKey} file:`, error);
      }
    }
  };

  const renderFilePreview = (key, label) => {
    const file = uploadedFiles[key]; // Check if the file exists

    return (
      <div className={styles.uploadField}>
        <label htmlFor={key} className={styles.uploadLabel}>
          {t(label)}
        </label>
        <input
          type="file"
          id={key}
          onChange={(e) => handleFileUpload(e, key)}
        />
        {file ? (
          <div className={styles.filePreview}>
            <span>{file.fileName || t("Uploaded File")}</span>
            <div
              dangerouslySetInnerHTML={{
                __html: renderFile(file.fileUrl, file.fileType),
              }}
            />
          </div>
        ) : (
          <span>{t("No file uploaded yet")}</span>
        )}
      </div>
    );
  };

  const handleAdditionalHealthInsuranceChange = (e) => {
    const value = e.target.value;
    setBasicInfo({ ...basicInfo, additionalHealthInsurance: value });
    if (value === "Yes" && additionalInsuranceConfirmations.length === 0) {
      setAdditionalInsuranceConfirmations([""]);
    } else if (value === "No") {
      setAdditionalInsuranceConfirmations([]);
    }
  };

  const handleSaveSettings = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const userInfo = localStorage.getItem("userInfo");
      const userman = JSON.parse(userInfo);
      const userId = userman.id; // Replace with actual user ID

      // Fetch the profile ID first
      const profileResponse = await axios.get(
        `${apiUrl}/profiles?where[user][id]=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const profileId = profileResponse.data[0]?.id;
      if (!profileId) {
        console.error("Profile not found");
        return;
      }

      // Format the dateOfBirth to 'YYYY-MM-DD'
      const formattedDateOfBirth = formatDate(basicInfo.dateOfBirth);

      const updateProfileData = {
        additionalHealthInsurance:
          basicInfo.additionalHealthInsurance === "Yes",
        address: basicInfo.address,
        bic: basicInfo.bic,
        civilStatus: basicInfo.maritalStatus,
        dateOfBirth: formattedDateOfBirth, // Use the formatted date
        healthInsuranceNumber: basicInfo.healthInsuranceNumber,
        iban: basicInfo.iban,
        insuranceConfirmation: {}, // Adjust this based on file upload
        laNr_lazNr: basicInfo.lanrLaznr,
        nationality: basicInfo.nationality,
        // optionalFiles: {}, // Adjust this based on file upload
        profileCompletionPercentage: 25, // Adjust if necessary
        religion: basicInfo.religion,
        socialSecurityNumber: basicInfo.socialSecurityNumber,
        taxId: basicInfo.taxId,
        //userId: userId,
        // sharedWith: {
        // fields: sharedWithCompanies.map((company) => company.name), // Use organization names
        // },
        user: {
          id: userId,
        },
      };

      await axios.patch(`${apiUrl}/profiles/${profileId}`, updateProfileData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert(t("Profile updated successfully!"));
    } catch (error) {
      console.error(t("Error updating profile:"), error);
      alert(t("Failed to update profile. Please try again."));
    }
  };

  const handleAccordionToggle = (accordionName) => {
    setActiveAccordion(
      activeAccordion === accordionName ? null : accordionName
    );
  };

  const handleAiScan = () => {
    setShowAiOptions(true);
  };
  const handleSharedWithToggle = (company) => {
    if (sharedWithCompanies.includes(company)) {
      setSharedWithCompanies(sharedWithCompanies.filter((c) => c !== company));
    } else {
      setSharedWithCompanies([...sharedWithCompanies, company]);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Tesseract.recognize(file, "eng", {
        logger: (m) => console.log(m),
      }).then(({ data: { text } }) => {
        const skipValues = ["Date of Birth", "Address"];

        const lines = text
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line !== "" && !skipValues.includes(line));

        setExtractedTextLines(lines);
        setShowModal(true);
      });
    }
  };

  const handleTextMappingChange = (line, field) => {
    setTextMappings((prev) => ({ ...prev, [line]: field }));
  };

  const applyTextMappings = () => {
    const updatedInfo = { ...basicInfo };
    for (const [line, field] of Object.entries(textMappings)) {
      if (field && updatedInfo.hasOwnProperty(field)) {
        updatedInfo[field] = line;
      }
    }
    setBasicInfo(updatedInfo);
    setShowModal(false);
  };
  // Fetch user organizations based on the roles from the organisationId
  const fetchOrganizations = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      const organisationRoles = userInfo.organisationRoles || [];
      const organisationPromises = organisationRoles.map(async (role) => {
        if (role.organisationId) {
          const response = await axios.get(
            `${apiUrl}/organisations/${role.organisationId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          return response.data;
        }
        return null;
      });

      const organizations = await Promise.all(organisationPromises);
      setAvailableCompanies(organizations.filter(Boolean)); // Filter out null values
    } catch (error) {
      console.error(t("Error fetching organizations:"), error);
    }
  };
  useEffect(() => {
    fetchOrganizations(); // Fetch organizations on component mount
  }, []);
  const renderModal = () => (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h3>{t("Map Extracted Text to Fields")}</h3>
        {extractedTextLines.map((line, index) => (
          <div key={index} className={styles.textMappingRow}>
            <span>{line}</span>
            <select
              onChange={(e) => handleTextMappingChange(line, e.target.value)}
              className={styles.textMappingSelect}
            >
              <option value="">{t("Select Field")}</option>
              {Object.keys(basicInfo).map((field) => (
                <option key={field} value={field}>
                  {t(field.replace(/([A-Z])/g, " $1"))}
                </option>
              ))}
            </select>
          </div>
        ))}
        <button onClick={applyTextMappings} className={styles.applyButton}>
          {t("Apply to Fields")}
        </button>
        <button
          onClick={() => setShowModal(false)}
          className={styles.cancelButton}
        >
          {t("Cancel")}
        </button>
      </div>
    </div>
  );
  const handleDateChange = (e) => {
    const { value } = e.target;
    // Basic validation to ensure correct format YYYY-MM-DD
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (regex.test(value) || value === "") {
      setBasicInfo({ ...basicInfo, dateOfBirth: value });
    }
  };
  // Function to update the profile's optionalFiles section
  const updateProfileWithOptionalFiles = async (fieldKey, fileMetadata) => {
    try {
      const token = localStorage.getItem("accessToken");
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const userId = userInfo.id;

      const payload = {
        optionalFiles: {
          ...optionalDocuments, // Include previously uploaded files
          [fieldKey]: fileMetadata, // Update the specific field with the new file metadata
        },
      };

      // Fetch the profile ID first
      const profileResponsetwo = await axios.get(
        `${apiUrl}/profiles?where[user][id]=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const profileIdtwo = profileResponsetwo.data[0]?.id;
      if (!profileIdtwo) {
        console.error("Profile not found");
        return;
      }

      await axios.patch(`${apiUrl}/profiles/${profileIdtwo}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error(t("Error updating profile with uploaded file:"), error);
    }
  };

  // Modify the handleFileDelete function to use the deleteFile service function
  const handleFileDelete = async (fieldKey, fileId) => {
    if (!fileId) {
      console.error(t("File ID for {{fieldKey}} is missing", { fieldKey }));
      return;
    }

    try {
      const token = localStorage.getItem("accessToken");
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const userId = userInfo.id;

      // Step 1: Delete the file from the server
      await deleteFile(fileId);

      // Step 2: Patch the user profile to remove the reference to the deleted file in `optionalFiles`
      // Fetch the latest profile data to update it correctly
      const profileResponse = await axios.get(
        `${apiUrl}/profiles?where[user][id]=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const profile = profileResponse.data[0]; // Assuming the response is an array of profiles
      if (!profile) {
        throw new Error("Profile not found");
      }

      const updatedOptionalFiles = { ...profile.optionalFiles };
      delete updatedOptionalFiles[fieldKey]; // Remove the specific file entry

      // Step 3: Send the patch request to update the profile
      await axios.patch(
        `${apiUrl}/profiles/${profile.id}`,
        {
          optionalFiles: updatedOptionalFiles,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Step 4: Update the state in the UI
      setOptionalDocuments((prev) => {
        const updatedDocuments = { ...prev };
        delete updatedDocuments[fieldKey]; // Remove the field's file from the state
        return updatedDocuments;
      });

      console.log(`${fieldKey} file deleted and profile updated successfully`);
    } catch (error) {
      console.error(
        t("Error deleting {{fieldKey}} file:", { fieldKey }),
        error
      );
    }
  };

  // Render the file upload or preview
  const renderFileUpload = (key, label) => {
    const file = optionalDocuments[key];

    return (
      <div className={styles.uploadField} key={key}>
        <label htmlFor={key} className={styles.uploadLabel}>
          {t(label)}
        </label>
        {file ? (
          <div className={styles.filePreview}>
            <div
              dangerouslySetInnerHTML={{
                __html: renderFile(file.fileUrl, "image"),
              }}
            />
            <button
              onClick={() => handleFileDelete(key, file.fileId)}
              className={styles.removeButton}
            >
              {t("Remove")}
            </button>
          </div>
        ) : (
          <input
            type="file"
            id={key}
            onChange={(e) => handleFileUpload(e, key)}
          />
        )}
      </div>
    );
  };
  return (
    <div className={styles.mosaikContainer}>
      <HeaderWithoutRoles pageTitle={t("Personal Information")} />
      {showModal && renderModal()}
      <div className={styles.profileSection}>
        <label className={styles.profilePictureLabel}>
          {isUploading ? ( // Show the loader if the picture is being uploaded
            <img
              src={spinner}
              alt={t("Loading...")}
              className={styles.spinner}
            />
          ) : (
           <div className={styles.profilePictureDiv}>
             <img
              src={profilePicture || profilePicPlaceholder}
              alt={t("Profile")}
              className={styles.profilePicture}
            />
            <div className={styles.editIcon}>
            <img src={pencilIcon} alt={t('Edit')} className={styles.pencilIcon} />
          </div>
           </div>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleProfilePictureChange}
            className={styles.fileInput}
          />
          {/* {t("Change Profile Picture")} */}
          {userName}
          <img src={attachmentIcon} alt={t("Profile image")} />
        </label>
      </div>

      <div className={styles.accordionSection}>
        <div
          className={styles.accordionHeader}
          onClick={() => handleAccordionToggle("basic")}
        >
          {t("Basic Information")}
          <span className={styles.accordionToggle}>
            {activeAccordion === "basic" ? "▼" : "▶"}
          </span>
        </div>
        {activeAccordion === "basic" && (
          <div className={styles.accordionContent}>
            <button onClick={handleAiScan} className={styles.aiScanButton}>
              <img src={AiScan} alt={t("AI image upload")} />
            </button>
            {showAiOptions && (
              <div className={styles.aiOptions}>
                <label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  {t("Upload image and scan")}
                </label>
              </div>
            )}
            <label htmlFor="dateOfBirth" className={styles.fieldLabel}>
              {t("Date of Birth")}
            </label>
            <input
              type="date" // Change to date input type
              placeholder={t("Date of Birth (YYYY-MM-DD)")}
              value={basicInfo.dateOfBirth} // Keep the formatted date
              onChange={handleDateChange} // Use the same change handler to update the state
              pattern="\d{4}-\d{2}-\d{2}" // Ensure it follows the pattern
            />
            <label htmlFor="address" className={styles.fieldLabel}>
              {t("Address")}
            </label>
            <textarea
              placeholder={t("Address")}
              value={basicInfo.address}
              onChange={(e) =>
                setBasicInfo({ ...basicInfo, address: e.target.value })
              }
            />
            <label htmlFor="nationality" className={styles.fieldLabel}>
              {t("Nationality")}
            </label>
            <select
              value={basicInfo.nationality}
              onChange={(e) =>
                setBasicInfo({ ...basicInfo, nationality: e.target.value })
              }
            >
              <option value="">{t("Select Nationality")}</option>
              <option value="DE">{t("Germany")}</option>
              <option value="NL">{t("Netherlands")}</option>
            </select>
            <label htmlFor="religion" className={styles.fieldLabel}>
              {t("Religion")}
            </label>
            <select
              value={basicInfo.religion}
              onChange={(e) =>
                setBasicInfo({ ...basicInfo, religion: e.target.value })
              }
            >
              <option value="">{t("Select Religion")}</option>
              <option value="CH">{t("Christianity")}</option>
              <option value="IS">{t("Islam")}</option>
              <option value="HI">{t("Hinduism")}</option>
              <option value="BU">{t("Buddhism")}</option>
              <option value="SI">{t("Sikhism")}</option>
              <option value="JU">{t("Judaism")}</option>
              <option value="SH">{t("Shinto")}</option>
              <option value="CO">{t("Confucianism")}</option>
              <option value="AT">{t("Atheism")}</option>
              <option value="OT">{t("Other")}</option>
            </select>
            <label htmlFor="maritalStatus" className={styles.fieldLabel}>
              {t("Marital Status")}
            </label>
            <select
              value={basicInfo.maritalStatus}
              onChange={(e) =>
                setBasicInfo({ ...basicInfo, maritalStatus: e.target.value })
              }
            >
              <option value="">{t("Select Marital Status")}</option>
              <option value="S">{t("Single")}</option>
              <option value="M">{t("Married")}</option>
              <option value="D">{t("Divorced")}</option>
              <option value="SP">{t("Separated")}</option>
              <option value="RP">{t("Registered Partnership")}</option>
              <option value="DP">{t("Dissolved Partnership")}</option>
            </select>
            <label htmlFor="socialSecurityNumber" className={styles.fieldLabel}>
              {t("Social Security Number")}
            </label>
            <input
              type="text"
              placeholder={t("Social Security Number")}
              value={basicInfo.socialSecurityNumber}
              onChange={(e) =>
                setBasicInfo({
                  ...basicInfo,
                  socialSecurityNumber: e.target.value,
                })
              }
            />
            <label
              htmlFor="healthInsuranceNumber"
              className={styles.fieldLabel}
            >
              {t("Health Insurance Number")}
            </label>
            <input
              type="text"
              placeholder={t("Health Insurance Number")}
              value={basicInfo.healthInsuranceNumber}
              onChange={(e) =>
                setBasicInfo({
                  ...basicInfo,
                  healthInsuranceNumber: e.target.value,
                })
              }
            />
            <label htmlFor="lanrLaznr" className={styles.fieldLabel}>
              {t("LANR / LAZNR")}
            </label>
            <input
              type="text"
              placeholder={t("LANR / LAZNR")}
              value={basicInfo.lanrLaznr}
              onChange={(e) =>
                setBasicInfo({ ...basicInfo, lanrLaznr: e.target.value })
              }
            />
            <label htmlFor="taxId" className={styles.fieldLabel}>
              {t("Tax ID")}
            </label>
            <input
              type="text"
              placeholder={t("Tax ID")}
              value={basicInfo.taxId}
              onChange={(e) =>
                setBasicInfo({ ...basicInfo, taxId: e.target.value })
              }
            />
            <label htmlFor="iban" className={styles.fieldLabel}>
              {t("IBAN")}
            </label>
            <input
              type="text"
              placeholder={t("IBAN")}
              value={basicInfo.iban}
              onChange={(e) =>
                setBasicInfo({ ...basicInfo, iban: e.target.value })
              }
            />
            <label htmlFor="bic" className={styles.fieldLabel}>
              {t("BIC")}
            </label>
            <input
              type="text"
              placeholder={t("BIC")}
              value={basicInfo.bic}
              onChange={(e) =>
                setBasicInfo({ ...basicInfo, bic: e.target.value })
              }
            />
            <label
              htmlFor="additionalHealthInsurance"
              className={styles.fieldLabel}
            >
              {t("Additional Health Insurance?")}
            </label>
            <select
              value={basicInfo.additionalHealthInsurance}
              onChange={handleAdditionalHealthInsuranceChange}
            >
              <option value="">{t("Additional Health Insurance?")}</option>
              <option value="Yes">{t("Yes")}</option>
              <option value="No">{t("No")}</option>
            </select>
            {additionalInsuranceConfirmations.map((_, index) => (
              <div key={index} className={styles.uploadField}>
                <label
                  htmlFor={`additionalInsurance${index}`}
                  className={styles.uploadLabel}
                >
                  {t("Upload additional insurance confirmation")}
                </label>
                <input
                  type="file"
                  id={`additionalInsurance${index}`}
                  onChange={(e) => {
                    const newConfirmations = [
                      ...additionalInsuranceConfirmations,
                    ];
                    newConfirmations[index] = e.target.files[0];
                    setAdditionalInsuranceConfirmations(newConfirmations);
                  }}
                />
              </div>
            ))}
            <div className={styles.sharedWith}>
              <h4>{t("Shared with:")}</h4>
              {availableCompanies.map((company, index) => (
                <div key={index} className={styles.companyItem}>
                  <input
                    type="checkbox"
                    checked={sharedWithCompanies.includes(company)}
                    onChange={() => handleSharedWithToggle(company)}
                  />
                  <span>{company.name}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        <div
          className={styles.accordionHeader}
          onClick={() => handleAccordionToggle("optional")}
        >
          {t("Optional Information")}
          <span className={styles.accordionToggle}>
            {activeAccordion === "optional" ? "▼" : "▶"}
          </span>
        </div>
        {activeAccordion === "optional" && (
          <div className={styles.accordionContent}>
            {renderFileUpload("approvalCertificate", t("Approval Certificate"))}
            {renderFileUpload(
              "promotionCertificate",
              t("Promotion Certificate")
            )}
            {renderFileUpload(
              "medicalExaminationResult",
              t("Medical Examination Result")
            )}
            {renderFileUpload("xrayCertificate", t("Xray Certificate"))}
            {renderFileUpload(
              "vaccinationCertificate",
              t("Vaccination Certificate")
            )}
            {renderFileUpload(
              "capitalFormingBenefits",
              t("Capital Forming Benefits")
            )}
            {renderFileUpload(
              "additionalQualifications",
              t("Additional Qualifications")
            )}
          </div>
        )}
      </div>

      <button className={styles.saveButton} onClick={handleSaveSettings}>
        {t("Save Settings")}
      </button>
      <MosaikFooter />
    </div>
  );
};

export default PersonalInformation;
