// HeaderWithoutRoles.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './MosaikHeaderWithBack.module.css';
import profilePicPlaceholder from '../assets/images/placeholder.webp';
import backArrowIcon from '../assets/icons/arrow-back.png';
import ProfileManager from './ProfileManager';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { getFileUrl } from '../services/mosaikUploadService';

const HeaderWithoutRoles = ({ pageTitle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isProfileManagerVisible, setIsProfileManagerVisible] = useState(false);
  const [organisationLogo, setOrganisationLogo] = useState(null); // New state for organization logo
  const { authState, setAuthState } = useContext(AuthContext);
  const token = authState.accessToken;
  const loggedUserId = authState.user?.id;

  // Fetch user and organization details from the API
  useEffect(() => {
    if (loggedUserId && token) {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${loggedUserId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const userData = response.data;
          // const profilePictureUrl = userData.profilePhotoFile?.fileUrl
          //   ? userData.profilePhotoFile.fileUrl.startsWith('https')
          //     ? userData.profilePhotoFile.fileUrl
          //     : `https://${userData.profilePhotoFile.fileUrl}`
          //   : profilePicPlaceholder;
          const fileId = userData.profilePhotoFile?.id;

          // Use getFileUrl to fetch the actual URL
          let profilePictureUrl = profilePicPlaceholder;
          if (fileId) {
            const fetchedUrl = await getFileUrl(fileId);
            if (fetchedUrl) {
              profilePictureUrl = fetchedUrl;
            }
          }
          setAuthState((prevState) => ({
            ...prevState,
            user: {
              ...prevState.user,
              id: userData.id,
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              mobile: userData.mobile,
              profilePicture: profilePictureUrl,
              status: userData.status,
            },
          }));
        } catch (error) {
          console.error(t('Error fetching user details:'), error);
        }
      };

      const fetchOrganisationLogo = async () => {
        if (authState.currentOrganisationId) {
          try {
            let orgLogo = null;
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/organisations/${authState.currentOrganisationId}/files`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            const profileFiles = response.data.filter(
              (file) => file.moduleField === "profile"
            );

            if (profileFiles.length > 0) {
              // Sort by createdAt descending and pick the latest file
              profileFiles.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              );
              const latestProfileFile = profileFiles[0];
              orgLogo = await getFileUrl(latestProfileFile.id); // Fetch URL using getFileUrl
              console.log( orgLogo); // Debug log
            } else {
              console.log(`No profile files found for org`);
            }
            // const orgLogo = await getFileUrl(response.data?.files?.[0]?.id) || profilePicPlaceholder;

            // const orgLogo = response.data?.files?.[0]?.fileUrl
            //   ? `https://${response.data.files[0].fileUrl}`
            //   : profilePicPlaceholder;
            setOrganisationLogo(orgLogo);
          } catch (error) {
            console.error(t("Error fetching organization logo:"), error);
          }
        }
      };

      fetchUserDetails();
      fetchOrganisationLogo();
    }
  }, [loggedUserId, token]);

  const toggleProfileManager = () => {
    setIsProfileManagerVisible(!isProfileManagerVisible);
  };

  return (
    <>
      <header className={styles.headerContainer}>
        <div className={styles.headerLeft} onClick={() => navigate(-1)}>
          <img src={backArrowIcon} alt={t('Back')} className={styles.backArrow} />
        </div>
        <div className={styles.headerTitle}>
          <h2>{pageTitle}</h2>
        </div>
        <div className={styles.headerRight} onClick={toggleProfileManager}>
          <div className={styles.profilePicContainer}>
            <img
              src={authState.user?.profilePicture || profilePicPlaceholder}
              alt={t('Profile')}
              className={styles.profilePic}
            />
            {authState.user?.status === 'online' && <span className={styles.onlineIndicator}></span>}
          </div>
          <span>{authState.user?.firstName || t('Guest')}</span>
        </div>
        <div
          className={styles.mosaikSelectedOrg} // New div for selected organization logo
          style={{
            backgroundImage: `url(${organisationLogo || profilePicPlaceholder})`,
          }}
        ></div>
      </header>
      {isProfileManagerVisible && (
        <div className={styles.profileManagerOverlay} onClick={toggleProfileManager}>
          <ProfileManager closeProfile={toggleProfileManager} />
        </div>
      )}
    </>
  );
};

export default HeaderWithoutRoles;
