// src/components/alerts/MosaikAlerts.js

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './MosaikAlerts.module.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { NotificationContext } from '../../context/NotificationContext';
import profilePicPlaceholder from '../../assets/images/placeholder.webp'; // Import placeholder image
import { v4 as uuidv4 } from 'uuid'; // Import uuid

const MosaikAlerts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { alerts, addAlert, removeAlert, clearAlerts } = useContext(NotificationContext) || {}; // Destructure context

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('accessToken');

  // Fetch logged-in user's info from localStorage
  const storedUserInfo = localStorage.getItem('userInfo');
  const userInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;

  const handleAlertClick = (link) => {
    navigate(link);
    // Optionally, remove the alert after navigating
    // const alertToRemove = alerts.find((alert) => alert.link === link);
    // if (alertToRemove) removeAlert(alertToRemove.id);
  };

  const handleInvitationsClick = () => {
    navigate(`/mosaik-alerts/InvitationsList`);
  };

  const handleSettingsClick = () => {
    navigate('/alerts-settings');
  };

  const clearNotifications = async () => {
    try {
      await axios.delete(`${apiUrl}/notifications/clear`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      clearAlerts(); // Clear alerts in the context state
      alert(t('Notifications cleared successfully.'));
    } catch (error) {
      console.error(t('Error clearing notifications:'), error);
      alert(t('Failed to clear notifications.'));
    }
  };

  // Function to delete a single notification
  const deleteNotification = async (notificationId) => {
    try {
      await axios.delete(`${apiUrl}/notifications/${notificationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Remove the notification from the state
      removeAlert(notificationId);
    } catch (error) {
      console.error(t('Error deleting notification:'), error);
    }
  };

  // Function to trigger a fake alert
  const triggerFakeAlert = () => {
    const fakeAlert = {
      id: uuidv4(),
      user: 'Fake User',
      action: 'sent you a fake message.',
      time: new Date().toLocaleTimeString(),
      image: profilePicPlaceholder,
      link: '/fake-link',
      type: 'chat', // Or 'task' depending on the type
      count: 1,
      timestamp: Date.now(),
    };

    addAlert(fakeAlert);
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Alerts')} />
      <div className={styles.mosaikContainer}>
        <div className={styles.header}>
          <h2>{t('Alerts')}</h2>
          <button className={styles.clearButton} onClick={clearNotifications}>
            {t('Clear Notifications')}
          </button>
        </div>

        {/* Demo Button to Trigger Fake Alert */}
        <button className={styles.demoButton} onClick={triggerFakeAlert}>
          {t('Trigger Fake Alert')}
        </button>

        <ul className={styles.alertList}>
          {alerts.length > 0 ? (
            alerts.map((alert) => (
              <li key={alert.id} className={styles.alertItem}>
                <div
                  className={styles.alertContentWrapper}
                  onClick={() => handleAlertClick(alert.link)}
                  style={{ cursor: 'pointer' }} // Make the whole content clickable
                >
                  <img
                    src={alert.image}
                    alt={alert.user}
                    className={styles.alertImage}
                  />
                  <div className={styles.alertContent}>
                    <span className={styles.alertUser}>{alert.user}</span>
                    <span className={styles.alertAction}>{alert.action}</span>
                    <span className={styles.alertTime}>{alert.time}</span>
                  </div>
                </div>
                <button
                  className={styles.deleteButton}
                  onClick={() => deleteNotification(alert.id)}
                >
                  ✕
                </button>
              </li>
            ))
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <p>{t('No alerts to display.')}</p>
            </div>
          )}
        </ul>

        <div className={styles.invitationsSection}>
          <h3>{t('Invitations')}</h3>
          <button
            className={styles.invitationButton}
            onClick={handleInvitationsClick}
          >
            {t('View Invitations')}
          </button>
        </div>

        <button className={styles.settingsButton} onClick={handleSettingsClick}>
          {t('Alerts Settings')}
        </button>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default MosaikAlerts;
