import React, { useState, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styles from './TermsConditions.module.css';
import logo from '../assets/images/logo.png';
import backIcon from '../assets/icons/back-icon.png';
import profileIcon from '../assets/icons/profile-icon.png';
import cameraIcon from '../assets/icons/profile-camera.png';
import emailIcon from '../assets/icons/email-icon.png';
import phoneIcon from '../assets/icons/phone-icon.png';
import lockIcon from '../assets/icons/lock-icon.png';
import visibilityIcon from '../assets/images/visibility-icon.png';
import CountryCodeDropdown from './CountryCodeDropdown';

const TermsConditions = () => {
    const location = useLocation();
    const { profileImage } = location.state || {};


  return (
    <main className={styles.signupContainer}>
      <section className={styles.signupCard}>
        <div className={styles.header}>
        <Link to="/signup" className={styles.backIcon}>
          <img src={backIcon} alt="Go back" />
        </Link>
        <h4 className={styles.headingBar}>Terms & Conditions</h4>
        {profileImage && <img src={profileImage} alt="Profile" className={styles.profileIcon} />}
      </div>
      </section>
      <section className={styles.mosaikcontent}>
        <h1>Terms & Conditions</h1>
        <div className={styles.mosaiktextstyles}>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec interdum ex a tellus porttitor aliquam. Vivamus laoreet ligula id lacus consequat, vitae malesuada dui vulputate.</p>
            <p>Duis lacinia enim nec lorem egestas, sed aliquet nulla vestibulum. Pellentesque aliquet tincidunt posuere. Duis ac pretium risus. Vestibulum nulla odio, eleifend vitae quam eu, faucibus ultrices augue. Nulla condimentum eget velit vehicula molestie. </p>
            <p>Maecenas blandit luctus enim, vitae imperdiet diam viverra a. Nam vel lorem at lectus dignissim dictum vel vitae dolor. Quisque non purus euismod, varius massa sit amet, sollicitudin ante. Pellentesque id dui eu neque congue accumsan. Nunc ac nunc auctor, tincidunt augue sed</p>
            <p>Maecenas blandit luctus enim, vitae imperdiet diam viverra a. Nam vel lorem at lectus dignissim dictum vel vitae dolor. Quisque non purus euismod, varius massa sit amet, sollicitudin ante. Pellentesque id dui eu neque congue accumsan. Nunc ac nunc auctor, tincidunt augue sed.</p>
            <p>Maecenas blandit luctus enim, vitae imperdiet diam viverra a. Nam vel lorem at lectus dignissim dictum vel vitae dolor. Quisque non purus euismod, varius massa sit amet, sollicitudin ante. Pellentesque id dui eu neque congue accumsan. Nunc ac nunc auctor, tincidunt augue sed.</p>
        </div>
      </section>
    </main>
  );
};

export default TermsConditions;
