import React, { useState } from 'react';
import { uploadFile } from '../../services/mosaikUploadService'; // Ensure the correct path
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import styles from './CreateSupplier.module.css'; // Import CSS Module

const CreateSupplier = ({ onSuccess }) => { // Accept onSuccess callback
  const { t } = useTranslation(); // Destructure t for translations
  const [formData, setFormData] = useState({
    address: '',
    contactEmail: '',
    contactName: '',
    contactPhone: '',
    email: '',
    name: '',
  });

  const [fileId, setFileId] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploading(true);
      setUploadError(null);
      try {
        const response = await uploadFile(file, 'feedback', file.name);
        if (response && response.id) {
          setFileId(response.id);
        } else {
          throw new Error(t('Invalid upload response structure.'));
        }
      } catch (err) {
        console.error(t('File upload failed:'), err);
        setUploadError(t('Failed to upload the file. Please try again.'));
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(null);

    // Validate that the image has been uploaded
    if (!fileId) {
      setSubmitError(t('Please upload an image file before submitting.'));
      return;
    }

    const payload = {
      address: formData.address,
      contactEmail: formData.contactEmail,
      contactName: formData.contactName,
      contactPhone: formData.contactPhone,
      email: formData.email,
      name: formData.name,
      imageFile: { id: fileId },
    };

    try {
      setIsSubmitting(true);
      const token = localStorage.getItem('accessToken');
      await axios.post(`${apiUrl}/suppliers`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      // Notify parent component of successful submission
      onSuccess();
    } catch (err) {
      console.error(t('Error creating supplier:'), err);
      setSubmitError(t('Failed to create supplier. Please try again.'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.orderForm}>
        <h1 className={styles.title}>{t('Create New Supplier')}</h1>
        <form onSubmit={handleSubmit} className={styles.form}>
          <label className={styles.label}>
            {t('Name')}:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </label>

          <label className={styles.label}>
            {t('Address')}:
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </label>

          <label className={styles.label}>
            {t('Contact Name')}:
            <input
              type="text"
              name="contactName"
              value={formData.contactName}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </label>

          <label className={styles.label}>
            {t('Contact Email')}:
            <input
              type="email"
              name="contactEmail"
              value={formData.contactEmail}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </label>

          <label className={styles.label}>
            {t('Contact Phone')}:
            <input
              type="tel"
              name="contactPhone"
              value={formData.contactPhone}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </label>

          <label className={styles.label}>
            {t('Email')}:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </label>

          <label className={styles.label}>
            {t('Image File')}:
            <input
              type="file"
              name="imageFile"
              accept="image/*"
              onChange={handleFileChange}
              required
              className={styles.input}
            />
          </label>

          {isUploading && <p className={styles.info}>{t('Uploading file...')}</p>}
          {uploadError && <p className={styles.error}>{uploadError}</p>}
          {submitError && <p className={styles.error}>{submitError}</p>}

          <div className={styles.buttons}>
            <button
              type="submit"
              disabled={isUploading || isSubmitting}
              className={styles.submitButton}
            >
              {isSubmitting ? t('Submitting...') : t('Create Supplier')}
            </button>
            <button
              type="button"
              onClick={onSuccess} // Use onSuccess to close the modal
              disabled={isUploading || isSubmitting}
              className={styles.cancelButton}
            >
              {t('Cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateSupplier;
