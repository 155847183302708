import repairPicPlaceholder from "../assets/images/repair-1.jpg";
import orderPicPlaceholder from "../assets/images/receipt-icon.png";
import feedbackPicPlaceholder from "../assets/images/receipt-icon.png";
import libraryPicPlaceholder from "../assets/images/repair-1.jpg";
import { getFileUrl } from "../services/mosaikUploadService";

export async function getRepairImageUrl(repair) {
  const fileId = repair.files?.[0]?.id;

  if (fileId) {
    try {
      const fetchedUrl = await getFileUrl(fileId);
      return fetchedUrl || repairPicPlaceholder;
    } catch (error) {
      console.error("Error fetching repair image URL:", error);
      return repairPicPlaceholder;
    }
  }

  return repairPicPlaceholder;
}

export async function getOrderImageUrl(order) {
    const fileId = order.file?.id;
  
    if (fileId) {
      try {
        const fetchedUrl = await getFileUrl(fileId);
        return fetchedUrl || orderPicPlaceholder;
      } catch (error) {
        console.error("Error fetching order image URL:", error);
        return orderPicPlaceholder;
      }
    }
  
    return orderPicPlaceholder;
  }

  // src/utils/getFeedbackImageUrl.js
  export async function getFeedbackImageUrls(feedback) {
    const files = feedback.files || [];
  
    if (files.length > 0) {
      try {
        const fetchPromises = files.map((file) => getFileUrl(file.id));
        const fetchedUrls = await Promise.all(fetchPromises);
        return fetchedUrls.map((url) => url || feedbackPicPlaceholder);
      } catch (error) {
        console.error("Error fetching feedback image URLs:", error);
        // In case of error, return placeholders for all files
        return files.map(() => feedbackPicPlaceholder);
      }
    }
  
    // If no files, return an array with a single placeholder
    return [feedbackPicPlaceholder];
  }
  
 
  export async function getFeedbackReplyImageUrl(reply) {
    const file = reply.file;
  
    if (file) {
      try {
        const fetchedUrl = await getFileUrl(file.id);
        return fetchedUrl || feedbackPicPlaceholder;
      } catch (error) {
        console.error("Error fetching feedback reply image URL:", error);
        return feedbackPicPlaceholder;
      }
    }
  
    // If no file, return the placeholder
    return null;
  }

  export async function getLibraryContentImageUrl(libraryContent) {
    const file = libraryContent.file;
  
    if (file) {
      try {
        const fetchedUrl = await getFileUrl(file.id);
        return fetchedUrl || libraryPicPlaceholder;
      } catch (error) {
        console.error("Error fetching library content image URL:", error);
        return libraryPicPlaceholder;
      }
    }
  
    return libraryPicPlaceholder;
  }