import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './ReplyComponent.module.css';
import supportAgent from '../../assets/images/support-agent.png';
import ratingStars from '../../assets/images/rating-stars.png';
import plusIcon from '../../assets/images/plus.png';

const FeedbackSection = ({ feedbackId, feedbackStatus, onReplySuccess }) => {
  const { t } = useTranslation(); // Initialize t
  const [isResolving, setIsResolving] = useState(false); // State for resolving feedback
  const [resolveError, setResolveError] = useState(""); // State for resolving errors

  const handleResolveClick = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      setIsResolving(true); // Start resolving
      await axios.patch(`${apiUrl}/feedbacks/${feedbackId}`, { status: "resolved" }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
      });
      alert(t('Feedback marked as resolved.'));
      onReplySuccess(); // Refresh feedback details
    } catch (error) {
      console.error(t('Error marking feedback as resolved:'), error);
      setResolveError(t("Failed to mark feedback as resolved. Please try again."));
    } finally {
      setIsResolving(false); // End resolving
    }
  };

  return (
    <section className={styles.feedbackSection}>
      <div className={styles.feedbackContent}>
        <img loading="lazy" src={supportAgent} alt={t("Support Agent")} className={styles.feedbackAvatar} />
        
        {/* Existing commented code remains unchanged */}
        {/* <div className={styles.feedbackText}>
          <h2 className={styles.feedbackQuestion}>Satisfied with the support's response?</h2>
          <p className={styles.rateSupport}>Rate our support:</p>
        </div> */}
        {/* <img loading="lazy" src={ratingStars} alt="Rating Stars" className={styles.ratingStars} /> */}
        
        {/* "Mark as Resolved" Button */}
        {feedbackStatus !== 'resolved' ? (
          <button 
            className={styles.resolveButton} 
            onClick={handleResolveClick}
            disabled={isResolving}
          >
            <div className={styles.resolveButtonContent}>
              <img loading="lazy" src={plusIcon} alt={t("Resolve Icon")} className={styles.resolveIcon} />
              <span>{t("Mark as Resolved")}</span>
            </div>
          </button>
        ) : (
          <p className={styles.resolvedText}>{t("This feedback has been marked as resolved.")}</p>
        )}

        {/* Display Resolve Error Message */}
        {resolveError && <p className={styles.errorText}>{resolveError}</p>}
      </div>
    </section>
  );
};

export default FeedbackSection;
