// EditTask.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import styles from "./EditTask.module.css"; // Ensure CSS is properly linked
import { useTranslation } from "react-i18next";

const EditTask = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams(); // Capture the task ID from the URL

  // State variables
  const [taskData, setTaskData] = useState(null);
  const [repeatDays, setRepeatDays] = useState([]);
  const [taskFrequency, setTaskFrequency] = useState("none");
  const [taskDate, setTaskDate] = useState("");
  const [priority, setPriority] = useState("normal");
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checklist, setChecklist] = useState([]);

  // Environment variables and token
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("accessToken");

  // Fetch task data on component mount
  useEffect(() => {
    const fetchTask = async () => {
      if (!token) {
        console.error(t("Access token is missing."));
        alert(t("Authentication required. Please log in."));
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/tasks/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedTask = response.data;
        console.log("Task fetched", response.data);

        // Initialize checklist from fetched data
        const initialChecklist =
          fetchedTask.checklistTemplate?.items?.map((item) => ({
            itemName: item.itemName || "",
            checked: item.checked === "true" || false, // Convert to boolean
          })) || [];

        setTaskData(fetchedTask);
        setTaskFrequency(fetchedTask.recurrence || "none");
        setTaskDate(
          fetchedTask.endDate ? fetchedTask.endDate.split("T")[0] : ""
        );
        setPriority(fetchedTask.priority || "normal");
        setRepeatDays(
          Array.isArray(fetchedTask.recurrenceCustomDays)
            ? fetchedTask.recurrenceCustomDays
            : []
        );
        setChecklist(initialChecklist);
        setIsLoading(false);
      } catch (error) {
        console.error(
          t("Error fetching task data:"),
          error.response?.data?.message || error.message
        );
        alert(t("Failed to load task data."));
        navigate("/mosaik-tasks");
      }
    };

    fetchTask();
  }, [id, apiUrl, navigate, token]);

  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Toggle repeat days
  const handleRepeatToggle = (day) => {
    setRepeatDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  // Handle checklist item changes
  const handleChecklistChange = (index, value) => {
    const updatedChecklist = [...checklist];
    updatedChecklist[index].itemName = value;
    setChecklist(updatedChecklist);
  };

  // Add a new checklist item
  const handleAddChecklist = () => {
    setChecklist([...checklist, { itemName: "", checked: false }]);
  };

  // Remove a checklist item
  const handleRemoveChecklist = (index) => {
    const updatedChecklist = [...checklist];
    updatedChecklist.splice(index, 1);
    setChecklist(updatedChecklist);
  };

  // Toggle checklist item checked state
  const handleToggleChecked = (index) => {
    const updatedChecklist = [...checklist];
    updatedChecklist[index].checked = !updatedChecklist[index].checked;
    setChecklist(updatedChecklist);
  };

  // Handle saving changes
  const handleSaveChanges = async () => {
    if (!token) {
      console.error(t("Access token is missing."));
      alert(t("Authentication required. Please log in."));
      navigate("/login");
      return;
    }

    // Basic form validation
    if (!taskData.name.trim()) {
      alert(t("Task name is required."));
      return;
    }

    if (!taskData.description.trim()) {
      alert(t("Task description is required."));
      return;
    }

    if (taskFrequency === "none" && !taskDate) {
      alert(t("Task date is required for one-time tasks."));
      return;
    }

    setIsSaving(true);
    try {
      const { id, ...taskDataWithoutId } = taskData; // Destructure to exclude 'id'
      const updatedTask = {
        ...taskDataWithoutId,
        recurrence: taskFrequency,
        endDate:
          taskFrequency !== "none" ? new Date(taskDate).toISOString() : null,
        priority: priority.toLowerCase(),
        recurrenceCustomDays: taskFrequency === "weekly" ? repeatDays : [],
        checklistTemplate: {
          items: checklist.map((item) => ({
            itemName: item.itemName,
            checked: item.checked.toString(), // Convert back to string if API expects
          })),
        },
      };

      await axios.patch(`${apiUrl}/tasks/${id}`, updatedTask, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      alert(t("Task changes saved successfully!"));
      navigate("/mosaik-tasks"); // Redirect to tasks list
    } catch (error) {
      console.error(
        t("Error saving task changes:"),
        error.response?.data?.message || error.message
      );
      alert(t("Failed to save task changes."));
    } finally {
      setIsSaving(false);
    }
  };

  // Render loading state
  if (isLoading) {
    return <div className={styles.loading}>{t("Loading...")}</div>;
  }

  return (
    <div className={styles.container}>
      <MosaikHeaderWithBack pageTitle={t("Edit Task")} />
      <div className={styles.EditTaskMaster}>
        <h2>{t("Edit Task")}</h2>

        {/* Task Name */}
        <div className={styles.formGroup}>
          <label htmlFor="name">{t("Name of the Task")}</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder={t("Name of the Task")}
            value={taskData.name || ""}
            onChange={handleInputChange}
            className={styles.inputField}
            disabled={isSaving}
          />
        </div>

        {/* Task Description */}
        <div className={styles.formGroup}>
          <label htmlFor="description">{t("Task's Description")}</label>
          <textarea
            id="description"
            name="description"
            placeholder={t("Task's Description")}
            value={taskData.description || ""}
            onChange={handleInputChange}
            className={styles.textAreaField}
            disabled={isSaving}
          ></textarea>
        </div>

        {/* Task Frequency */}
        <div className={styles.formGroup}>
          <label htmlFor="frequency">{t("Task Frequency")}</label>
          <select
            id="frequency"
            value={taskFrequency}
            onChange={(e) => setTaskFrequency(e.target.value)}
            className={styles.inputField}
            disabled={isSaving}
          >
            <option value="none">{t("One-time task")}</option>
            <option value="weekdaily">{t("Daily")}</option>
            <option value="weekly">{t("Weekly")}</option>
            <option value="monthly">{t("Monthly")}</option>
          </select>
        </div>

        {/* Task Date (only for One-time tasks) */}
        {taskFrequency === "none" && (
          <div className={styles.formGroup}>
            <label htmlFor="date">{t("Task's Date")}</label>
            <input
              type="date"
              id="date"
              value={taskDate}
              onChange={(e) => setTaskDate(e.target.value)}
              className={styles.inputField}
              disabled={isSaving}
            />
          </div>
        )}

        {/* Repeat Days (only for Weekly frequency) */}
        {taskFrequency === "weekly" && (
          <div className={styles.formGroup}>
            <label>{t("Repeat")}</label>
            <div className={styles.repeatDays}>
              {["SU", "MO", "TU", "WE", "TH", "FR", "SA"].map((day, index) => (
                <button
                  key={index}
                  type="button"
                  className={
                    repeatDays.includes(day)
                      ? styles.activeDay
                      : styles.dayButton
                  }
                  onClick={() => handleRepeatToggle(day)}
                  disabled={isSaving}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Task Priority */}
        <div className={styles.formGroup}>
          <label htmlFor="priority">{t("Priority")}</label>
          <select
            id="priority"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            className={styles.inputField}
            disabled={isSaving}
          >
            <option value="low">{t("Low")}</option>
            <option value="medium">{t("Medium")}</option>
            <option value="urgent">{t("Urgent")}</option>
          </select>
        </div>

        {/* Checklist */}
        <div className={styles.formGroup}>
          <label>{t("Task's Checklist")}</label>
          {checklist.map((item, index) => (
            <div className={styles.checklistItem}>
              <div className={styles.inputBox}>
                <input
                  type="text"
                  value={item.itemName}
                  onChange={(e) => handleChecklistChange(index, e.target.value)}
                  placeholder={t("Checklist item")}
                  className={styles.checklistInput}
                  disabled={isSaving}
                />
                <div className={styles.toggleSwitch}>
                  <input
                    type="checkbox"
                    id={`toggle-${index}`}
                    checked={item.checked}
                    onChange={() => handleToggleChecked(index)}
                    disabled={isSaving}
                  />
                  <label htmlFor={`toggle-${index}`}></label>
                </div>
              </div>
              <button
                type="button"
                onClick={() => handleRemoveChecklist(index)}
                className={styles.removeChecklistButton}
                disabled={isSaving}
              >
                &times;
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddChecklist}
            className={styles.addChecklistButton}
            disabled={isSaving}
          >
            {t("+ Add Checklist Item")}
          </button>
        </div>

        {/* Save Changes Button */}
        <button
          className={styles.saveChangesButton}
          onClick={handleSaveChanges}
          disabled={isSaving}
        >
          {isSaving ? t("Saving...") : t("Save Changes")}
        </button>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default EditTask;
