// src/components/ProfileManager.js
import React, { useState, useEffect, useContext } from "react";
import profilePicPlaceholder from "../assets/images/placeholder.webp";
import companyBackdrop from "../assets/images/mosaik-backdrop.png";
import profilesettingsicon from "../assets/images/profile-settings.png";
import logoutIcon from "../assets/images/logout-icon.png";
import profilesettingsforward from "../assets/images/smallarrow-right.png";
import preloader from "../assets/images/spinner.gif"; // Import preloader image
import styles from "./ProfileManager.module.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getFileUrl } from "../services/mosaikUploadService";
import { getProfilePictureUrl } from "../utils/getProfileUrl";

const ProfileManager = ({ closeProfile }) => {
  const { t } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [organisationList, setOrganisationList] = useState([]);
  const [showCompanySelect, setShowCompanySelect] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state for preloader
  const { authState, setAuthState } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  // Fetch the user's organisation roles
  const fetchUserOrganisationRoles = async () => {
    try {
      const token = authState.accessToken;
      const userId = authState.user?.id;
      if (userId && token) {
        const response = await axios.get(
          `${apiUrl}/users/${userId}/userOrganisationRoles`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const organisationRoles = response.data;

        // Fetch organisation details for each role
        const organisations = await Promise.all(
          organisationRoles.map(async (orgRole) => {
            const orgId = orgRole.organisation?.id;
            if (orgId) {
              const orgResponse = await axios.get(
                `${apiUrl}/organisations/${orgId}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );

              let logoUrl = profilePicPlaceholder;
              const response = await axios.get(
                `${apiUrl}/organisations/${orgId}/files`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
  
              const profileFiles = response.data.filter(
                (file) => file.moduleField === "profile"
              );
  
              if (profileFiles.length > 0) {
                // Sort by createdAt descending and pick the latest file
                profileFiles.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                const latestProfileFile = profileFiles[0];
                logoUrl = await getFileUrl(latestProfileFile.id); // Fetch URL using getFileUrl
                console.log( logoUrl); // Debug log
              } else {
                console.log(`No profile files found for org`);
              }
              console.log("fetched file", orgResponse);

              return {
                ...orgRole,
                organisation: {
                  id: orgId,
                  name:
                    orgResponse.data.name ||
                    t("Organisation ID: {{orgId}}", { orgId }),
                  logo: logoUrl,
                },
              };
            }
            return null;
          })
        );

        const validOrganisations = organisations.filter((org) => org !== null);
        setOrganisationList(validOrganisations);

        // Determine the currently selected organisation
        const currentOrganisationId =
          authState.currentOrganisationId ||
          validOrganisations[0]?.organisation.id;
        const currentOrganisation = validOrganisations.find(
          (org) => org.organisation.id === currentOrganisationId
        );
        setSelectedCompany(
          currentOrganisation?.organisation ||
            validOrganisations[0]?.organisation
        );

        // If no current organisation is set, set the first one as default
        if (!authState.currentOrganisationId && validOrganisations.length > 0) {
          const updatedAuthState = {
            ...authState,
            currentOrganisationId: validOrganisations[0].organisation.id,
          };
          setAuthState(updatedAuthState);
          localStorage.setItem("authState", JSON.stringify(updatedAuthState));
        }
      }
    } catch (error) {
      console.error(t("Error fetching user organisation roles:"), error);
    } finally {
      setLoading(false); // Stop loading after data fetch
    }
  };

  // Load organisations when component mounts
  useEffect(() => {
    fetchUserOrganisationRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains(styles.profileManagerOverlay)) {
      closeProfile();
    }
  };

  const handleProfileSettingsClick = () => {
    navigate("/profile-settings");
  };

  const handleSwitchButtonClick = (e) => {
    e.stopPropagation();
    setShowCompanySelect(!showCompanySelect);
  };

  const handleCompanyChange = async (e) => {
    const selectedOrgId = e.target.value;
    const selectedOrg = organisationList.find(
      (org) => org.organisation.id === selectedOrgId
    );

    if (!selectedOrg || selectedOrg.organisation.id === selectedCompany?.id) {
      setShowCompanySelect(false);
      return;
    }

    try {
      const token = authState.accessToken;
      const requestBody = { organisationId: selectedOrg.organisation.id };

      // POST request to switch organisation
      const switchResponse = await axios.post(
        `${apiUrl}/auth/switch-organisation`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (switchResponse.status === 200 || switchResponse.status === 201) {
        const newAccessToken =
          switchResponse.data.accessToken || switchResponse.data;

        if (!newAccessToken) {
          console.error(t("No accessToken received from the server."));
          return;
        }

        // Update the authState with the new access token
        const updatedAuthState = {
          ...authState,
          accessToken: newAccessToken,
          currentOrganisationId: selectedOrg.organisation.id,
        };
        setAuthState(updatedAuthState);

        // Update localStorage
        localStorage.setItem("accessToken", newAccessToken);
        localStorage.setItem("authState", JSON.stringify(updatedAuthState));

        // Update the UI to show the selected company
        setSelectedCompany(selectedOrg.organisation);

        // Fetch user info with the new access token
        await fetchUserInfo();

        // Show alert to the user
        alert(
          t("You switched to {{orgName}} with a new access", {
            orgName: selectedOrg.organisation.name,
          })
        );

        // Refresh the page after alert
        // window.location.reload();
      } else {
        console.error(
          t("Failed to switch organization:"),
          switchResponse.statusText
        );
      }
    } catch (error) {
      console.error(t("Error switching organisation:"), error);
    }

    setShowCompanySelect(false);
  };

  const fetchUserInfo = async () => {
    try {
      const token = authState.accessToken;
      const response = await axios.get(
        `${apiUrl}/users/${authState.user?.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const userData = response.data;
      // Retrieve the file ID for the profile picture
      console.log("Testing", userData);

      let profilePictureUrl = await getProfilePictureUrl(userData);

      // Update authState with the fetched user data
      setAuthState((prevState) => ({
        ...prevState,
        user: {
          ...prevState.user,
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          mobile: userData.mobile,
          profilePicture: profilePictureUrl,
        },
      }));
    } catch (error) {
      console.error(
        t("Error fetching user info after switching organizations:"),
        error
      );
    }
  };

  const handleLogout = () => {
    setAuthState({
      isAuthenticated: false,
      accessToken: null,
      user: null,
      currentOrganisationId: null,
    });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("authState");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("hasShownPopup");

    closeProfile();
    navigate("/login");
  };

  return (
    <div className={styles.profileManagerOverlay} onClick={handleOverlayClick}>
      <div
        className={styles.profileManagerContainer}
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <div className={styles.preloaderContainer}>
            <img
              src={preloader}
              alt={t("Loading...")}
              className={styles.preloader}
            />
          </div>
        ) : (
          <>
            <div className={styles.profileManagerOverlayContainer}>
              <img
                src={companyBackdrop}
                alt={t("company backdrop")}
                className={styles.backdropPic}
              />
            </div>
            <div className={styles.profileHeader}>
              <div
                className={styles.profilePic}
                style={{
                  backgroundImage: `url(${
                    authState.user?.profilePicture || profilePicPlaceholder
                  })`,
                }}
              ></div>

              <h4>{t("Hello!")}</h4>
              <h2>{authState.user?.firstName || t("Guest")}</h2>
            </div>
            <div className={styles.profileOptions}>
              <div
                className={styles.profileOption}
                onClick={handleProfileSettingsClick}
              >
                <div className={styles.profileOptionOne}>
                  <img
                    src={profilesettingsicon}
                    alt={t("Profile settings")}
                    className={styles.profilesettingsIcon}
                  />
                  <span>{t("Profile Settings")}</span>
                  <img
                    src={profilesettingsforward}
                    alt={t("Profile settings arrow")}
                    className={styles.profilesettingsforward}
                  />
                </div>
              </div>
              {organisationList.length > 0 && (
                <div className={styles.profileOptionTwo}>
                  <img
                    src={selectedCompany?.logo || profilePicPlaceholder}
                    alt={t("Company logo")}
                    className={styles.currentcompanyLogo}
                  />
                  <span>
                    {selectedCompany?.name || t("Select Organisation")}
                  </span>
                  {organisationList.length > 1 && (
                    <span
                      className={styles.switchButton}
                      onClick={handleSwitchButtonClick}
                    >
                      {t("Switch")}
                    </span>
                  )}
                </div>
              )}
              {showCompanySelect && (
                <select
                  className={styles.companySelect}
                  onChange={handleCompanyChange}
                  value={selectedCompany?.id || ""}
                  onClick={(e) => e.stopPropagation()}
                >
                  {organisationList.map((org) => (
                    <option
                      key={org.organisation.id}
                      value={org.organisation.id}
                    >
                      {org.organisation.name}
                    </option>
                  ))}
                </select>
              )}
              <div className={styles.profileOption} onClick={handleLogout}>
                <div className={styles.profileOptionLogout}>
                  <img
                    src={logoutIcon}
                    alt={t("Logout")}
                    className={styles.logoutIcon}
                  />
                  <span>{t("Logout")}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileManager;
