// src/hooks/useWebsocketSubscription.js

import { useEffect } from 'react';

export const useWebsocketSubscription = (socket, callback) => {
  useEffect(() => {
    if (!socket) return;

    // Add event listener
    socket.addEventListener('message', callback);

    // Cleanup on unmount or when callback changes
    return () => {
      socket.removeEventListener('message', callback);
    };
  }, [socket, callback]);
};
