import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import styles from "./AllAssignedTasks.module.css"; // Create and define appropriate styles
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import MosaikWelcomeScreen from "../MosaikWelcomeScreen";
import welcomeBgimage from "../../assets/images/tasks-bg.jpg";
import welcomeIcon from "../../assets/images/tasks-iconinverted.png";
import TaskList from "./TaskList"; // Reusable TaskList component
import debounce from "lodash.debounce"; 
import styles from "./MosaikTasks.module.css";
const AllAssignedTasks = () => {
  const navigate = useNavigate();

  // State Management
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [assignedTasksWithDetails, setAssignedTasksWithDetails] = useState([]);
  const [filteredAssignedTasks, setFilteredAssignedTasks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(""); // No default date filter
  const [sortOption, setSortOption] = useState("Date");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 10; // Define how many tasks per page
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Retrieve token and user info from localStorage
  const token = localStorage.getItem("accessToken");
  const storedUser = JSON.parse(localStorage.getItem("userInfo"));
  const apiUrl = process.env.REACT_APP_API_URL;

  /**
   * Fetch "Assigned Tasks" from the API.
   */
  const fetchAssignedTasks = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const tasksResponse = await axios.get(`${apiUrl}/tasks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const allTasks = tasksResponse.data;

      console.log("Fetched All Tasks:", allTasks);

      // Filter tasks assigned by the current user but not to themselves
      const assignedFilteredTasks = allTasks.filter(
        (task) =>
          task.assigner &&
          task.assigner.id === storedUser.id &&
          (!task.assignee || task.assignee.id !== storedUser.id)
      );

      setAssignedTasks(assignedFilteredTasks);
      console.log("Assigned Tasks:", assignedFilteredTasks);
    } catch (error) {
      console.error("Error fetching assigned tasks:", error.message);
      setError("Failed to fetch assigned tasks. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Fetch assignee details for each task.
   */
  const fetchAssignees = async () => {
    try {
      const updatedAssignedTasks = await Promise.all(
        assignedTasks.map(async (task) => {
          if (task.assignee && task.assignee.id) {
            const userResponse = await axios.get(`${apiUrl}/users/${task.assignee.id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            return { ...task, assigneeDetails: userResponse.data };
          }
          return { ...task, assigneeDetails: null };
        })
      );
      setAssignedTasksWithDetails(updatedAssignedTasks);
      console.log("Assigned Tasks with Details:", updatedAssignedTasks);
    } catch (error) {
      console.error("Error fetching assignee details:", error);
      setError("Failed to fetch assignee details. Please try again later.");
    }
  };

  /**
   * Fetch tasks on component mount.
   */
  useEffect(() => {
    fetchAssignedTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch assignee details whenever assignedTasks change.
   */
  useEffect(() => {
    if (assignedTasks.length > 0) {
      fetchAssignees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedTasks]);

  /**
   * Debounced search to improve performance.
   */
  const debouncedSearch = debounce((value) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to first page on new search
  }, 300); // 300ms delay

  /**
   * Handle search input change event.
   * @param {object} event - The event object.
   */
  const handleSearchInputChange = (event) => {
    debouncedSearch(event.target.value);
  };

  /**
   * Filter and sort tasks based on selected date, sort option, and search term.
   */
  useEffect(() => {
    const filterAndSortTasks = () => {
      let filtered = assignedTasksWithDetails;

      // Apply Date Filter if selectedDate is set
      if (selectedDate) {
        filtered = filtered.filter(
          (task) => moment(task.createdAt).format("YYYY-MM-DD") === selectedDate
        );
      }

      // Apply Search Filter
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        filtered = filtered.filter((task) => {
          const taskNameMatch = task.name
            ? task.name.toLowerCase().includes(searchLower)
            : false;

          const assigneeName = task.assigneeDetails
            ? `${task.assigneeDetails.firstName} ${task.assigneeDetails.lastName}`.toLowerCase()
            : "";
          const assigneeNameMatch = assigneeName.includes(searchLower);

          return taskNameMatch || assigneeNameMatch;
        });
      }

      // Apply Sorting
      filtered = filtered.sort((a, b) => {
        const aName = a.name || "";
        const bName = b.name || "";
        const aPriority = a.priority || "";
        const bPriority = b.priority || "";
        const aCreatedAt = a.createdAt ? new Date(a.createdAt) : new Date(0);
        const bCreatedAt = b.createdAt ? new Date(b.createdAt) : new Date(0);

        switch (sortOption) {
          case "Alphabetical":
            return aName.localeCompare(bName);
          case "Priority":
            return aPriority.localeCompare(bPriority);
          case "Date":
          default:
            return aCreatedAt - bCreatedAt;
        }
      });

      setFilteredAssignedTasks(filtered);
      setCurrentPage(1); // Reset to first page on new filter/sort
      console.log("Filtered & Sorted Assigned Tasks:", filtered);
    };

    filterAndSortTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, sortOption, searchTerm, assignedTasksWithDetails]);

  /**
   * Calculate pagination details.
   */
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = filteredAssignedTasks.slice(indexOfFirstTask, indexOfLastTask);
  const totalPages = Math.ceil(filteredAssignedTasks.length / tasksPerPage);

  return (
    <div>
      <MosaikHeaderWithBack pageTitle="All Assigned Tasks" />

      <div className={styles.container}>
      <div className={styles.containerParent}>
            {/* Sort and Search Controls */}
        <div className={styles.sortAndFilterInTaskPage}>
      
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search by task name or assignee..."
            onChange={handleSearchInputChange}
            className={styles.searchInput}
          />
        </div>

        {/* Loading and Error Messages */}
        {isLoading && <p>Loading assigned tasks...</p>}
        {error && <div className={styles.errorMessage}>{error}</div>}

        {/* Task List with Pagination */}
        {!isLoading && !error && (
          <TaskList
            tasks={currentTasks}
            type="assigned"
            handleStatusToggle={null} // No status toggle for assigned tasks
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div></div>
      <MosaikFooter />
    </div>
  );
};

export default AllAssignedTasks;
