// EmployeesPage.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './EmployeesPage.module.css';
import { AuthContext } from '../../context/AuthContext'; // Import AuthContext
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { placeHolder } from '../../constants';
import { getProfilePictureUrl } from '../../utils/getProfileUrl';

const EmployeesPage = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext); // Access authState from AuthContext
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = authState.accessToken;
  const currentOrganisationId = authState.currentOrganisationId;

  const fetchTeamMembers = async () => {
    if (!currentOrganisationId || !token) {
      console.error(t('Missing organisation ID or access token.'));
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/users`, {
        params: {
          'where[userOrganisationRoles][some][organisation][id]': currentOrganisationId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const employeesData = await Promise.all(
        response.data.map(async (user) => {

          let profilePicture = await getProfilePictureUrl(user);
      
          if (!profilePicture) {
            profilePicture = '/assets/images/placeholder.webp';
          }
      
          return {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            role: user.role || t('Employee'),
            email: user.email,
            img: profilePicture, 
          };
        })
      );
      

      setEmployees(employeesData);
      setFilteredEmployees(employeesData);
    } catch (error) {
      console.error(t('Error fetching team members:'), error);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganisationId]);

  useEffect(() => {
    setFilteredEmployees(
      employees.filter((employee) =>
        employee.name
          ? employee.name.toLowerCase().includes(searchTerm.toLowerCase())
          : false
      )
    );
  }, [searchTerm, employees]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEmployeeClick = (employee) => {
    navigate(`/employee/${employee.id}`, { state: employee });
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Employees')} />
      <div className={styles.mosaikContainer}>
        <input
          type="text"
          className={styles.searchInput}
          placeholder={t('Search employees...')}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className={styles.employeeList}>
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map((employee) => (
              <div
                key={employee.id}
                className={styles.employeeItem}
                onClick={() => handleEmployeeClick(employee)}
              >
                <div className={styles.employeeInfo}>
                  <img
                    src={employee.img || placeHolder}
                    alt={employee.name}
                  />
                  <div>
                    <span>{employee.name}</span>
                    <span className={styles.employeeRole}>{employee.role}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>{t('No employees found.')}</p>
          )}
        </div>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default EmployeesPage;
