// AddEmployee.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './AddEmployee.module.css';
import { AuthContext } from '../../context/AuthContext'; // Import AuthContext
import { useTranslation } from 'react-i18next'; // Import useTranslation

const AddEmployee = () => {
  const { t } = useTranslation(); // Initialize the translation function
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState(''); // Added firstName state
  const [lastName, setLastName] = useState('');   // Added lastName state
  const [errorMessage, setErrorMessage] = useState('');
  const [subscriptionInfo, setSubscriptionInfo] = useState(null); // Subscription info state
  const [planInfo, setPlanInfo] = useState(null);                 // Plan info state
  const [remainingInvitations, setRemainingInvitations] = useState(null); // Remaining invitations
  const [expiryMessage, setExpiryMessage] = useState(''); // Plan expiry message
  const [sentInvitations, setSentInvitations] = useState([]); // Sent invitations

  const { authState } = useContext(AuthContext); // Access authState from AuthContext
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = authState.accessToken;
  const userId = authState.user?.id;
  const currentOrganisationId = authState.currentOrganisationId;

  // Popup State
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('success'); // 'success' or 'error'

  useEffect(() => {
    fetchSubscriptionInfo();
    fetchSentInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubscriptionInfo = async () => {
    try {
      if (!currentOrganisationId || !token) {
        console.error(t('Missing organisation ID or access token.'));
        return;
      }

      // Fetch subscriptions for the organisation
      const subscriptionResponse = await axios.get(
        `${apiUrl}/organisations/${currentOrganisationId}/subscriptions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (subscriptionResponse.data.length === 0) {
        console.error(t('No subscription found for this organisation.'));
        return;
      }

      const subscription = subscriptionResponse.data[0];
      setSubscriptionInfo(subscription);

      // Fetch plan info
      const planId = subscription.plan.id;
      const planResponse = await axios.get(`${apiUrl}/plans/${planId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPlanInfo(planResponse.data);

      // Calculate remaining invitations
      const maxUsers = planResponse.data.maxEmployees;
      const organisationResponse = await axios.get(`${apiUrl}/organisations/${currentOrganisationId}/userOrganisationRoles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const currentUsers = organisationResponse.data.length;
      const remaining = maxUsers - currentUsers;

      setRemainingInvitations(remaining);

      // Check plan expiry
      const planStartDate = new Date(subscription.startDate);
      const currentDate = new Date();
      const timeDiff = currentDate - planStartDate;
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      const planDurationDays = 30; // Assuming plan duration is 30 days
      const daysRemaining = planDurationDays - daysDiff;

      if (daysRemaining <= 3) {
        setExpiryMessage(t('Your membership is about to expire. Please renew.'));
      } else if (daysRemaining <= 7) {
        setExpiryMessage(t('Your membership will expire in {{days}} days. Please renew.', { days: daysRemaining }));
      }

    } catch (error) {
      console.error(t('Error fetching subscription or plan info:'), error);
    }
  };

  const fetchSentInvitations = async () => {
    try {
      if (!userId || !token || !currentOrganisationId) {
        console.error(t('Missing user ID, organisation ID, or access token.'));
        return;
      }

      const response = await axios.get(
        `${apiUrl}/users/${userId}/sentInvitations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Filter invitations by currentOrganisationId
      const invitations = response.data.filter(
        (invitation) => invitation.organisation.id === currentOrganisationId
      );
      setSentInvitations(invitations);
    } catch (error) {
      console.error(t('Error fetching sent invitations:'), error);
    }
  };

  const handleInvite = async () => {
    if (!email || !firstName || !lastName) {
      setErrorMessage(t('Please fill in all required fields.'));
      showTemporaryPopup(t('Please fill in all required fields.'), 'error');
      return;
    }

    if (remainingInvitations !== null && remainingInvitations <= 0) {
      setErrorMessage(t('You have reached the maximum number of users for your subscription plan.'));
      showTemporaryPopup(t('You have reached the maximum number of users for your subscription plan.'), 'error');
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/invitations/invite-employee`,
        { destination: email, firstName, lastName }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle the response based on its structure
      if (response.data === true ) {
        // Successful invitation
        showTemporaryPopup(t('Invitation sent successfully.'), 'success');
        // Clear the form
        setEmail('');
        setFirstName('');
        setLastName('');
        // Refresh the sent invitations list
        fetchSentInvitations();
      }
      else if (response.data.message === 'Invitation resent successfully'){
        // Invitation resent successfully
        showTemporaryPopup(response.data.message,'success');
           // Clear the form
           setEmail('');
           setFirstName('');
           setLastName('');
        // Refresh the sent invitations list
        fetchSentInvitations();
      }
      else if (response.data.message) {
        // Display the message from the response
        showTemporaryPopup(response.data.message, 'error');
      } else {
        // Fallback for unexpected responses
        showTemporaryPopup(t('Failed to send invitation. Please try again.'), 'error');
      }
    } catch (error) {
      console.error(t('Error sending invitation:'), error);
      const message = error.response?.data?.message || t('Failed to send invitation. Please try again.');
      setErrorMessage(message);
      showTemporaryPopup(message, 'error');
    }
  };

  const handleResendInvitation = async (firstName, lastName, email) => {
    try {
      const response = await axios.post(
        `${apiUrl}/invitations/invite-employee`,
        { destination: email, firstName, lastName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Handle the response based on its structure
      if (response.data === true ) {
        // Successful invitation
        showTemporaryPopup(t('Invitation sent successfully.'), 'success');
        // Clear the form
        setEmail('');
        setFirstName('');
        setLastName('');
        // Refresh the sent invitations list
        fetchSentInvitations();
      }
      else if (response.data.message === 'Invitation resent successfully'){
        // Invitation resent successfully
        showTemporaryPopup(response.data.message,'success');
           // Clear the form
           setEmail('');
           setFirstName('');
           setLastName('');
        // Refresh the sent invitations list
        fetchSentInvitations();
      }
      else if (response.data.message) {
        // Display the message from the response
        showTemporaryPopup(response.data.message, 'error');
      } else {
        // Fallback for unexpected responses
        showTemporaryPopup(t('Failed to send invitation. Please try again.'), 'error');
      }
    } catch (error) {
      console.error(t('Error sending invitation:'), error);
      const message = error.response?.data?.message || t('Failed to send invitation. Please try again.');
      showTemporaryPopup(message, 'error');
    }
  };
  

  const getInvitationStatus = (invitation) => {
    if (
      invitation.accepted &&
      invitation.accepted !== '1970-01-01T00:00:00.001Z'
    ) {
      return t('Accepted');
    } else {
      return t('Pending');
    }
  };

  // Function to show temporary popup
  const showTemporaryPopup = (message, type) => {
    setPopupMessage(message);
    setPopupType(type); // 'success' or 'error'
    setShowPopup(true);
    // Hide the popup after 3 seconds
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Add Employee')} />
      <div className={styles.addEmployee}>
        <h2>{t('Invite Employee')}</h2>

        {/* Remove the errorText paragraph and use popup instead */}
        {/* {errorMessage && <p className={styles.errorText}>{errorMessage}</p>} */}

        {expiryMessage && <p className={styles.expiryMessage}>{expiryMessage}</p>}

        {remainingInvitations !== null && (
          <p className={styles.subscriptionInfo}>
            {t('You can invite {{remaining}} more {{userPlural}} under your current plan.', {
              remaining: remainingInvitations,
              userPlural: remainingInvitations === 1 ? t('user') : t('users'),
            })}
          </p>
        )}

        <input
          type="text"
          placeholder={t('First name')}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <input
          type="text"
          placeholder={t('Last name')}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />

        <input
          type="email"
          placeholder={t('Employee Email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <button onClick={handleInvite}>{t('Invite New Employee to Mosaik')}</button>
        <div className={styles.sentInvitations}>
          <h2>{t('Sent Invitations')}</h2>
          {sentInvitations.length > 0 ? (
            <div className={styles.invitationList}>
              {sentInvitations.map((invitation) => (
                <div key={invitation.id} className={styles.invitationItem}>
                  <div className={styles.invitationInfo}>
                    <div className={styles.invitationName}>
                      {invitation.firstName} {invitation.lastName}
                    </div>
                    <div className={styles.invitationEmail}>
                      {invitation.email}
                    </div>
                    <div className={styles.invitationStatus}>
                      {getInvitationStatus(invitation)}
                    </div>
                  </div>
                  {(!invitation.accepted || invitation.accepted === '1970-01-01T00:00:00.001Z') && (
               <button
               className={styles.resendButton}
               onClick={() => handleResendInvitation(invitation.firstName, invitation.lastName, invitation.email)}
             >
               {t('Resend Invite')}
             </button>
             
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p>{t('No invitations sent yet.')}</p>
          )}
        </div>
      </div>
      <MosaikFooter />

      {/* Popup Message */}
      {showPopup && (
        <div className={`${styles.popup} ${popupType === 'success' ? styles.success : styles.error}`}>
          <p>{popupMessage}</p>
        </div>
      )}
    </div>
  );
};

export default AddEmployee;
