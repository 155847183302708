import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ReplyComponent.module.css';
import profilePicture from '../../assets/images/user-avatar.png';
import attachmentIcon from '../../assets/images/attachment-icon.png';

const AttachmentSection = ({ handleFileUpload, isUploading, attachedFileUrl }) => {
  const { t } = useTranslation();
  const [previewUrl, setPreviewUrl] = useState(profilePicture);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPreviewUrl(URL.createObjectURL(file)); // Set the preview URL
      handleFileUpload(file); // Call the handler function passed as a prop
    }
  };

  useEffect(() => {
    // Clean up the preview URL when the component unmounts or the preview changes
    return () => {
      if (previewUrl && previewUrl !== profilePicture) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <div className={styles.attachmentSection}>
      <div className={styles.attachmentBar}>
        <div className={styles.uploadAttachment}>
          <img
            loading="lazy"
            src={attachedFileUrl ? previewUrl : profilePicture}
            alt={t("Profile")}
            className={styles.profilePicture}
          />
          <div className={styles.attachmentText}>{t("Upload Attachments")}</div>
        </div>
        <div className={styles.attachmentControls}>
          <div className={styles.attachmentDivider} />
          <input
            type="file"
            id="file-upload"
            className={styles.uploadInput}
            onChange={handleFileChange}
            disabled={isUploading}
            style={{ display: 'none' }}
            accept="image/*,application/pdf"
          />
          <label htmlFor="file-upload">
            <img
              loading="lazy"
              src={attachmentIcon}
              alt={t("Attachment")}
              className={styles.attachmentIcon}
            />
          </label>
        </div>
      </div>
      <div className={styles.horizontalDivider} />
    </div>
  );
};

export default AttachmentSection;
