import {
  DragHandleMenuProps,
  useBlockNoteEditor,
  useComponentsContext,
} from "@blocknote/react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export function CustomRemoveBlockButton(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { authState } = useContext(AuthContext);
  const token = authState.accessToken;
  const editor = useBlockNoteEditor();
  const Components = useComponentsContext();
  async function handleDelete(props) {
    console.log("HandleDelete", props);

    // Assuming `props` is the block object
    const block = props.block;

    // Ensure block content exists
    if (block.content) {
      for (const item of block.content) {
        // Check if the item is of type 'page' and contains a link
        if (item.type === "page" && item.props && item.props.link) {
          const link = item.props.link;
          const pageId = link.split("/").pop(); // Extract the page ID from the link

          console.log("Page Type Found:", item.type, "ID from Link:", pageId);

          try {
            // First: Remove the page from the backend
            const response = await fetch(
              `${apiUrl}/libraryContents/${pageId}`,
              {
                method: "DELETE",
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            if (!response.ok) {
              throw new Error("Failed to remove the page from the backend");
            }

            console.log("Page removed from backend:", pageId);

            // Second: Remove the block from the frontend
            if (editor) {
              editor.removeBlocks([block.id]); // Use the block's id for removal
              console.log("Block removed from the frontend:", block.id);
            } else {
              console.error("Editor instance is undefined.");
            }
          } catch (error) {
            console.error("Error removing page/block:", error);
          }

          return; // Exit once the 'page' block is handled
        }
      }
    }

    // If no 'page' type found, remove the block directly
    if (editor) {
      editor.removeBlocks([block.id]);
      console.log("Block removed:", block.id);
    } else {
      console.error("Editor instance is undefined.");
    }
  }

  return (
    <Components.Generic.Menu.Item
      onClick={() => {
        handleDelete(props);
      }}
    >
      Delete
    </Components.Generic.Menu.Item>
  );
}
