// src/context/Auth.js

import { useContext } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { userAllowedRoutes } from "./userPermissions";

const Auth = () => {
  const { authState } = useContext(AuthContext);
  const location = useLocation();

  // Check if the user is authenticated
  if (!authState.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Determine if the user has restricted access based on roles
  const userRoles = authState.user?.roles || [];
  const isLimitedUser = userRoles.length === 0 || (userRoles.length === 1 && userRoles.includes("user"));
  
  console.log('User Roles:', userRoles);

  // Restrict access for limited users to specific routes
  if (isLimitedUser && !userAllowedRoutes.includes(location.pathname)) {
    return <Navigate to="/unauthorized" replace />;
  }

  // If the user has more access roles, allow full access
  return <Outlet />; // Render child routes
};

export default Auth;
