// SubscriptionDetails.js
import React, { useContext, useEffect, useState } from "react";
import styles from "./SubscriptionDetails.module.css"; // Assuming you have a CSS module for styling
import axios from "axios";
import { format } from "date-fns"; // For formatting dates
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import welcomeIcon from "../../assets/images/memberships-icon.png";
import welcomeBgimage from "../../assets/images/memberships-bg.png";
import HeaderWithoutRoles from "../HeaderWithoutRoles";
import MosaikWelcomeScreen from "../MosaikWelcomeScreen";
import MosaikFooter from "../MosaikFooter";
import { useTranslation } from "react-i18next"; // Import useTranslation

const SubscriptionDetails = () => {
  const { t } = useTranslation(); // Initialize t
  const [subscription, setSubscription] = useState(null);
  const [isNearEnd, setIsNearEnd] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { authState } = useContext(AuthContext); // Use authState from AuthContext

  useEffect(() => {
    // Fetch subscription details from the API
    const fetchSubscriptionDetails = async () => {
      try {
        const token = authState.accessToken;
        const userId = authState.user?.id;
        const response = await axios.get(
          `${apiUrl}/users/${userId}/subscriptionsDetails`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("RESPONSE", response.data);
        const subscriptionData = response.data[0]; // Assuming there is only one subscription
        setSubscription(subscriptionData);
        console.log("RESPONSE", response.data[0].endDate);
        // Check if the subscription is near its end
        if (subscriptionData.endDate) {
          const endDate = new Date(subscriptionData.endDate);
          const currentDate = new Date();
          const daysLeft = (endDate - currentDate) / (1000 * 60 * 60 * 24);
          if (daysLeft < 4) {
            setIsNearEnd(true);
          }
          // Simulate payment failure check
          // This can be updated to check actual payment status from an API
          if (daysLeft < 2) {
            setPaymentFailed(true);
          }
        }
        setLoading(false);
      } catch (error) {
        setError(t("Failed to fetch subscription details"));
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, []);

  if (loading) {
    return <div>{t("Loading subscription details...")}</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const { plan, startDate, endDate } = subscription || {};
  const { prices } = plan || {};
  const planPrice = prices?.[0]?.pricePerMonth || 0;
  const currencySymbol = prices?.[0]?.currencySymbol || "";

  return (
    <div className={styles.subscriptionContainer}>
      <HeaderWithoutRoles pageTitle={t("Subscription Details")} />
      <MosaikWelcomeScreen
        welcomeTitle={t("Membership")}
        welcomeDescription={t(
          "Membership offers employees the ability to get all the free and premium features of Mosaik services."
        )}
        welcomeIcon={welcomeIcon}
        welcomeBgimage={welcomeBgimage}
      />
      <div className={styles.subscriptionInfo}>
        <p>
          <strong>{t("Plan Name")}:</strong> {plan?.name || t("N/A")}
        </p>
        <p>
          <strong>{t("Start Date")}:</strong>{" "}
          {format(new Date(startDate), "yyyy-MM-dd")}
        </p>
        <p>
          <strong>{t("End Date")}:</strong>{" "}
          {endDate ? format(new Date(endDate), "yyyy-MM-dd") : t("N/A")}
        </p>
        <p>
          <strong>{t("Monthly Price")}:</strong> {currencySymbol}
          {planPrice}
        </p>
      </div>
      {isNearEnd && (
        <div className={styles.warningMessage}>
          <p>
            {t(
              "Your current subscription is near to end. We will process your payment of {{currency}}{{price}} automatically from your card in the next 2 days.",
              { currency: currencySymbol, price: planPrice }
            )}
          </p>
        </div>
      )}
      {paymentFailed && (
        <div className={styles.errorMessage}>
          <p>
            {t(
              "Your card could not process the payment, and some of your account's permissions have been restricted. Please update your payment details."
            )}
          </p>
        </div>
      )}
      <MosaikFooter />
    </div>
  );
};

export default SubscriptionDetails;
