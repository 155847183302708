import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import MessageCard from "./MessageCard";
import SupportMessage from "./SupportMessage";
import { useTranslation } from 'react-i18next';
import "react-quill/dist/quill.snow.css";
import styles from "./TicketDetail.module.css";
import ReplyComponent from "./ReplyComponent";
import userAvatar from "../../assets/images/user-avatar.png";
import supportAvatar from "../../assets/images/support-avatar.png";
import parse from "html-react-parser";
import FileDisplay from "./FileDisplay";

const apiUrl = process.env.REACT_APP_API_URL;

const TicketDetail = () => {
  const { t } = useTranslation();
  const { ticketId } = useParams();
  const [ticketDetails, setTicketDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchTicketDetails = async () => {
    if (!ticketId) {
      console.error(t('ticketId is undefined'));
      return;
    }

    try {
      const response = await axios.get(
        `${apiUrl}/supports/${ticketId}/detail`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setTicketDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error(t('Error fetching ticket details:'), error);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
  }, [ticketId, apiUrl]);

  const extractAttachmentInfo = (content) => {
    const fileLinkRegex = /<a[^>]*href=["']([^"']+)["'][^>]*>(.*?)<\/a>/;
    const match = fileLinkRegex.exec(content);

    const cleanedContent = match ? content.replace(fileLinkRegex, "") : content;

    if (match && match[2].includes(t('Download Attachment'))) {
      return { url: match[1], name: match[2], cleanedContent };
    }
    return { cleanedContent: content };
  };

  const filteredComments =
    ticketDetails?.comments?.filter((comment) => {
      const hasAnchorTag = /<a\b[^>]*>(.*?)<\/a>/i.test(comment.content);
      const attachmentInfo = extractAttachmentInfo(comment.content);
      return !hasAnchorTag || (hasAnchorTag && attachmentInfo.url);
    }) || [];

  const renderCommentContent = (comment) => {
    const attachmentInfo = extractAttachmentInfo(comment.content);
    const messageContent = parse(attachmentInfo.cleanedContent);

    const containsLinkToFile = /<a\b[^>]*href=["'][^"']+["'][^>]*>.*<\/a>/i.test(
      attachmentInfo.cleanedContent
    );

    return (
      <>
        {messageContent}
        {attachmentInfo.url && !containsLinkToFile && (
          <FileDisplay fileUrl={attachmentInfo.url} fileName={attachmentInfo.name} />
        )}
      </>
    );
  };

  if (loading) {
    return <p>{t('Loading ticket details...')}</p>;
  }

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Support')} />
      <div className={styles.ticketDetailContainer}>
        <div className={styles.ticketHeader}>
          <h4>{new Date(ticketDetails?.createdAt).toLocaleDateString()}</h4>
          <h2>{ticketDetails?.subject}</h2>
          <span className={styles.ticketId}>
            {t('Ticket ID')}: {ticketDetails?.ticketId}
          </span>
        </div>

        <div className={styles.issueFiles}>
          <h3>{t('Issue File')}</h3>
          {ticketDetails?.files?.length > 0 ? (
            <FileDisplay
              fileUrl={ticketDetails.files[0].file_url}
              fileName={ticketDetails.files[0].file_name}
            />
          ) : (
            <p>{t('No files attached to this issue.')}</p>
          )}
        </div>

        <div className={styles.pastMessages}>
          {filteredComments.map((comment, index) => {
            const isSupportComment =
              comment.author.includes("support") ||
              comment.author === "harald.roessler@dsncon.com";

            return (
              <div key={index} className={styles.comment}>
                {isSupportComment ? (
                  <SupportMessage
                    avatar={supportAvatar}
                    userName={comment.author}
                    userRole={t('Support Staff')}
                    date={new Date(comment.creation).toLocaleDateString()}
                    time={new Date(comment.creation).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    message={renderCommentContent(comment)}
                  />
                ) : (
                  <MessageCard
                    avatar={userAvatar}
                    user={{ name: comment.author, role: t('User') }}
                    date={new Date(comment.creation).toLocaleDateString()}
                    time={new Date(comment.creation).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    message={renderCommentContent(comment)}
                    hasAttachment={!!extractAttachmentInfo(comment.content).url}
                    attachmentUrl={extractAttachmentInfo(comment.content).url}
                    attachmentName={extractAttachmentInfo(comment.content).name}
                  />
                )}
              </div>
            );
          })}
        </div>

        {ticketDetails?.status !== t('Closed') && (
          <ReplyComponent
            ticketId={ticketId}
            onReplySubmit={fetchTicketDetails}
            onResolveTicket={fetchTicketDetails}
          />
        )}
      </div>
      <MosaikFooter />
    </div>
  );
};

export default TicketDetail;
