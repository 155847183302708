// List of placeholder images for attendies lists
const apiUrl = process.env.REACT_APP_API_URL;
export const placeholderImages = [
    'https://cdn.builder.io/api/v1/image/assets/TEMP/6bd723c9ae3f4af32f241a83c43d57ab2e3f7fbf1dba4d3211a8f8f388f0c71d?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&'
  ];
  export const searchIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/d44c7e2be5790a4066cd0b356e15400c60a9e1551342df55862833cf14d8bdcd?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&"
  export const selectedIcon ="https://cdn.builder.io/api/v1/image/assets/TEMP/2a428e1e895b6028615b70c2a12c5a410b1637254f0316f820b621f0089b491b?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&"


// repair send 
export const dummyImage = "https://cdn.builder.io/api/v1/image/assets/TEMP/6dceac7e5b0e7411b0ee1a66f81b87f3ecd2f163c3808ce99d63c9054d0a7b3c?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&" 


// repair send 
export const placeHolder ="https://placehold.co/150"

// repair and order 
export const defaultprofile = "https://cdn.builder.io/api/v1/image/assets/TEMP/4cd7e802fc2f27044bfb37e3ef8913cdc01d2840a1f09984ab1463a543f741b9?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&"
export const defaultLogo = "https://cdn.builder.io/api/v1/image/assets/TEMP/48f0c358842da85f9224e1c7e7db273edf8299d9df3f97cf1ca40976cf3c628e?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&";
export const lineIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/3ca802c45b2faf0ef7b2df625e560f77d5fd911dfd36725628a7998547c38e6c?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&";
export const tickIcon =  "https://cdn.builder.io/api/v1/image/assets/TEMP/5c564454e5ffd3c3b5d332a396048f79d0464581314b1ed439967786f509d251?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&";
// event agenda 
export const crossIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/1b95cc377a46f86f65333b692bea7f6d18f2734a83787949125b8e89d39c680c?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&";
export const plusIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/e12906601fd1b47b33a2fb99dc1b7dedb17adf63bb0094959ab492f314c7fd2e?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&";

// order detail 
export const calculatorIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/6213796696e322c8d4e513ebb0f4bdc2ea266ae92bc422415ffb7a5b9d944fe4?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&";
export const calculatorIcon2 = "https://cdn.builder.io/api/v1/image/assets/TEMP/baea0c23018d5f32a2ade2a9251944f3bddf68ea1d23a3fbb39e808682c0edea?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&";
export const emptyTickIcon =  "https://cdn.builder.io/api/v1/image/assets/TEMP/97f7c47539983571f07c61739d9fd7067b2ffa5f9df7f32cc6fdb9ce5687eb6c?apiKey=ee3f213fa3f44a16b8dba7dc5be4f861&";