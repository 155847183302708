import NotFoundImage from "../assets/images/page-404-Not-Found.png";
const NotFound = () => {
  return (
    <div>
      {/* <h1>404 - Page Not Found</h1> */}
      <img src={NotFoundImage} alt="404 Error" />
    </div>
  );
};
export default NotFound;
