import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import { useTranslation } from 'react-i18next';
import styles from './TicketNew.module.css';
import attachmentIcon1 from '../../assets/images/attachment-1.png';
import arrowDownIcon from '../../assets/images/downarrow.png';

const TicketNew = () => {
  const { t } = useTranslation();
  const [priority, setPriority] = useState(t('Very Urgent'));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]); // Holds raw file objects
  const [previewUrl, setPreviewUrl] = useState(null); // Holds URL for image preview
  const [uploadError, setUploadError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();

      if (fileExtension === 'webp') {
        setUploadError(t('Uploading .webp files is not allowed.'));
        return;
      }

      if (/\s/.test(fileName)) {
        setUploadError(t('File names with spaces are not allowed. Please rename the file.'));
        return;
      }

      setFiles([selectedFile]);
      setUploadError('');

      if (/\.(jpg|jpeg|png|gif)$/i.test(fileName)) {
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreviewUrl(objectUrl);
      } else {
        setPreviewUrl(null);
      }
    }
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePriorityChange = (priority) => {
    setPriority(priority);
    setIsDropdownOpen(false);
  };

  const handleSubmit = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const storedUser = localStorage.getItem('userInfo');
    const user = JSON.parse(storedUser);
    const userId = user.id;

    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('assignments', 'John Doe');
    formData.append('orderHint', '1');
    formData.append('percentComplete', '0');
    formData.append('title', title);
    formData.append('description', description);
    formData.append('resolved', 'false');
    formData.append('rating', '0');

    if (files.length > 0) {
      files.forEach((file) => {
        formData.append('file', file);
      });
    }

    try {
      const response = await axios.post(`${apiUrl}/supports/task`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      console.log(t('Ticket created:'), response.data);

      alert(t('Ticket created successfully!'));
      navigate('/mosaik-support');
    } catch (error) {
      console.error(t('Error creating ticket:'), error);
      setUploadError(t('Failed to create the ticket. Please try again.'));
    }
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Create Ticket')} />
      <div className={styles.ticketNewContainer}>
        <div className={styles.div}>
          <div className={styles.newTicket}>{t('New Ticket')}</div>
          <div className={styles.div2}>
            <div>{t('Title')}</div>
            <input 
              type="text" 
              className={styles.inputField} 
              value={title} 
              onChange={(e) => setTitle(e.target.value)} 
            />
          </div>
          <div className={styles.div4}>
            <div>{t('Description')}</div>
            <textarea 
              className={styles.textareaField} 
              value={description} 
              onChange={(e) => setDescription(e.target.value)} 
            ></textarea>
          </div>
          <div className={styles.div6}>
            <img 
              src={previewUrl || attachmentIcon1} 
              alt={t('Attachment Preview')} 
              className={styles.img} 
            />
            <input 
              type="file" 
              className={styles.uploadInput} 
              onChange={handleFileUpload} 
            />
          </div>
          {uploadError && <p className={styles.errorText}>{uploadError}</p>}

          <div className={styles.issuePriority}>{t('Issue Priority')}</div>
          <div className={styles.div13}>
            <div className={styles.div14} onClick={handleDropdownClick}>
              <div className={styles.veryUrgent}>{priority}</div>
              <img src={arrowDownIcon} alt={t('Arrow down')} className={styles.img4} />
            </div>
            {isDropdownOpen && (
              <div className={styles.dropdown}>
                <div className={styles.dropdownItem} onClick={() => handlePriorityChange(t('Low'))}>{t('Low')}</div>
                <div className={styles.dropdownItem} onClick={() => handlePriorityChange(t('Medium'))}>{t('Medium')}</div>
                <div className={styles.dropdownItem} onClick={() => handlePriorityChange(t('High'))}>{t('High')}</div>
                <div className={styles.dropdownItem} onClick={() => handlePriorityChange(t('Very Urgent'))}>{t('Very Urgent')}</div>
              </div>
            )}
          </div>
          <button className={styles.button} onClick={handleSubmit} disabled={uploadError}>
            {t('Submit a new Ticket')}
          </button>
        </div>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default TicketNew;
