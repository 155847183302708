// AddGroup.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './AddGroup.module.css';
import { AuthContext } from '../../context/AuthContext'; // Import AuthContext
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { getProfilePictureUrl } from '../../utils/getProfileUrl';

const AddGroup = () => {
  const { t } = useTranslation(); // Initialize the translation function
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [members, setMembers] = useState([]); // Array to store member details
  const [availableMembers, setAvailableMembers] = useState([]);
  const [sortOrder, setSortOrder] = useState('name');
  const [searchTerm, setSearchTerm] = useState('');
  const [groupImage, setGroupImage] = useState(null);
  const [groupImageFile, setGroupImageFile] = useState(null);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  const { authState } = useContext(AuthContext); // Access authState from AuthContext
  const token = authState.accessToken;
  const userId = authState.user?.id;
  const currentOrganisationId = authState.currentOrganisationId;

  // Fetch available members from the API
  useEffect(() => {
    const fetchUsers = async () => {
      if (!currentOrganisationId || !token) {
        console.error(t('Missing organisation ID or access token.'));
        return;
      }
      try {
        const response = await axios.get(`${apiUrl}/users`, {
          params: {
            'where[userOrganisationRoles][some][organisation][id]': currentOrganisationId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const usersWithProfilePictures = await Promise.all(
          response.data.map(async (user) => ({
            ...user,
            profilePictureUrl: await getProfilePictureUrl(user),
          }))
        );
        const filteredUsers = usersWithProfilePictures.filter(
          (user) => !members.some((member) => member.user[0].id === user.id) // Assuming member.user[0].id matches user.id
        );

        setAvailableMembers(filteredUsers);
      } catch (error) {
        console.error(t('Error fetching users:'), error);
      }
    };
    if (currentOrganisationId) {
      fetchUsers();
    }
  }, [apiUrl, currentOrganisationId, token, members]);

  const handleMemberSelect = (member) => {
    if (selectedMembers.includes(member.id)) {
      setSelectedMembers(selectedMembers.filter((id) => id !== member.id));
    } else {
      setSelectedMembers([...selectedMembers, member.id]);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setGroupImage(URL.createObjectURL(file));
      setGroupImageFile(file);
    }
  };

  // Step 1: Create the group
  const createGroup = async (groupImageId) => {
    if (!currentOrganisationId || !token || !userId) {
      console.error(t('Missing organisation ID, user ID, or access token.'));
      return null;
    }

    const groupData = {
      name: groupName,
      owner: { id: userId },
      organisation: { id: currentOrganisationId },
      groupType: ['team'], // Define group type
      //description: groupDescription,
      // Include groupImageId if available
      ...(groupImageId && { groupProfile: { id: groupImageId } }),
    };

    try {
      const response = await axios.post(`${apiUrl}/groups`, groupData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.id; // Return the created group's ID
    } catch (error) {
      console.error(t('Error creating group:'), error);
      alert(t('Failed to create group. Please try again.'));
      return null;
    }
  };

  const addMembersToGroup = async (groupId) => {
    if (!currentOrganisationId || !token) {
      console.error(t('Missing organisation ID or access token.'));
      return;
    }
    for (const userId of selectedMembers) {
      try {
        const memberData = {
          memberGroups: {
            connect: [{ id: groupId }],
          },
          organisation: { id: currentOrganisationId },
          parentGroup: { id: groupId },
          user: { connect: [{ id: userId }] },
        };

        await axios.post(`${apiUrl}/members`, memberData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error(t('Error adding member {{userId}}:', { userId }), error);
      }
    }
  };

  // Main function to handle group creation and member assignment
  const handleCreateGroup = async () => {
    try {
      let groupImageId = null;

      // Upload image if exists
      if (groupImageFile) {
        if (!token) {
          console.error(t('Missing access token.'));
          return;
        }
        const formData = new FormData();
        formData.append('file', groupImageFile);
        formData.append('documentTitle', groupName);
        formData.append('fileMimeType', groupImageFile.type);
        formData.append('fileType', groupImageFile.type.split('/')[1]);
        formData.append('mediaType', 'image');
        formData.append('moduleField', 'group');
        formData.append('bucket', 'mosaik-dev');

        const uploadResponse = await axios.post(`${apiUrl}/files/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        groupImageId = uploadResponse.data.id;
      }

      // Step 1: Create the group
      const groupId = await createGroup(groupImageId);

      // Step 2: Add members to the group
      if (groupId) {
        await addMembersToGroup(groupId);
        alert(t('Group created and members added successfully'));
        navigate('/mosaik-teams');
      }
    } catch (error) {
      console.error(t('Error creating group and adding members:'), error);
      alert(t('Failed to create group and add members. Please try again.'));
    }
  };

  // Sorting and filtering members
  const sortedMembers = [...availableMembers]
    .filter((member) =>
      member.firstName && member.firstName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortOrder) {
        case 'name':
          return a.firstName.localeCompare(b.firstName);
        case 'new-to-old':
          return new Date(b.createdAt) - new Date(a.createdAt);
        case 'old-to-new':
          return new Date(a.createdAt) - new Date(b.createdAt);
        default:
          return 0;
      }
    });

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('New Group')} />
      <div className={styles.addGroupContainer}>
        <div className={styles.groupImageUpload}>
          <label htmlFor="groupImage" className={styles.imageUploadLabel}>
            {groupImage ? (
              <img src={groupImage} alt={t('Group')} className={styles.groupImage} />
            ) : (
              <span className={styles.uploadPlaceholder}>{t('Upload Group Image')}</span>
            )}
          </label>
          <input
            type="file"
            id="groupImage"
            accept="image/*"
            onChange={handleImageUpload}
            className={styles.imageUploadInput}
          />
        </div>
        <input
          type="text"
          placeholder={t('Name of the Group...')}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className={styles.input}
        />
        <textarea
          placeholder={t('Description of the Group...')}
          value={groupDescription}
          onChange={(e) => setGroupDescription(e.target.value)}
          className={styles.textarea}
        />
        <div className={styles.addMembersSection}>
          <h4>{t('Add Members')}</h4>
          <div className={styles.controls}>
            <select
              className={styles.sortSelect}
              onChange={(e) => setSortOrder(e.target.value)}
              value={sortOrder}
            >
              <option value="name">{t('Sort by Name')}</option>
              <option value="new-to-old">{t('New to Old')}</option>
              <option value="old-to-new">{t('Old to New')}</option>
            </select>
            <input
              type="text"
              placeholder={t('Search members...')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
          </div>
          <div className={styles.memberList}>
            {sortedMembers.map((member) => (
              <div key={member.id} className={styles.memberItem}>
                <div className={styles.memberInfo}>
                  <img
                    src={member.profilePictureUrl}
                    alt={member.firstName}
                    className={styles.memberImage}
                  />
                  <div className={styles.memberDetails}>
                    <span className={styles.memberName}>
                      {member.firstName} {member.lastName}
                    </span>
                    <span className={styles.memberDepartment}>{member.email}</span>
                  </div>
                </div>
                <button
                  className={`${styles.selectButton} ${
                    selectedMembers.includes(member.id) ? styles.selected : ''
                  }`}
                  onClick={() => handleMemberSelect(member)}
                >
                  {selectedMembers.includes(member.id) ? '✔' : '+'}
                </button>
              </div>
            ))}
          </div>
        </div>
        <button className={styles.createGroupButton} onClick={handleCreateGroup}>
          {t('Create Group')}
        </button>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default AddGroup;
