// CountryCodeDropdown.js
import React, { useState, useEffect } from 'react';
import styles from './CountryCodeDropdown.module.css';

function CountryCodeDropdown({ selectedCode, setSelectedCode }) {
  const countryCodes = [
    { code: '+1', country: 'United States' },
    { code: '+44', country: 'United Kingdom' },
    { code: '+49', country: 'Germany' },
    { code: '+66', country: 'Thailand' },
    { code: '+92', country: 'Pakistan' },
    { code: '+63', country: 'Philippines' },
    // Add more country codes as needed
  ];

  const [inputValue, setInputValue] = useState(selectedCode || '');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setInputValue(selectedCode || '');
  }, [selectedCode]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setSelectedCode(value);
    setShowDropdown(true); // Always show the dropdown when input changes
  };

  const handleOptionClick = (code) => {
    setInputValue(code);
    setSelectedCode(code);
    setShowDropdown(false);
  };

  const filteredCodes = countryCodes.filter((item) =>
    item.code.includes(inputValue) || item.country.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleBlur = () => {
    // Delay hiding the dropdown to allow click event to register
    setTimeout(() => {
      setShowDropdown(false);
    }, 100);
  };

  return (
    <div className={styles.comboBox}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setShowDropdown(true)}
        onBlur={handleBlur}
        placeholder="Code"
        className={styles.input}
      />
      {showDropdown && (
        <div className={styles.dropdown}>
          {filteredCodes.map((item) => (
            <div
              key={item.code}
              className={styles.option}
              onMouseDown={() => handleOptionClick(item.code)}
            >
              {item.country} ({item.code})
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CountryCodeDropdown;
