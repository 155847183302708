// src/App.js

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'; // Import your global styles
import { AuthProvider } from './context/AuthContext';
import { WebSocketProvider } from './context/RocketchatContext';
import { AlertSettingsProvider } from './context/AlertSettingsContext';
import { NotificationProvider } from './context/NotificationContext';
import theme from './theme'; // Import your custom theme
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Importing all your components
import MosaikHome from './components/MosaikHome';
import PersonalInformation from './components/profile/PersonalInformation';
import ProfileSettings from './components/profile/ProfileSettings';
import MosaikCalendar from './components/calendar/MosaikCalendar';
import Memberships from './components/memberships/Memberships';
import Fobi from './components/fobi/Fobi';
import ViewEvent from './components/calendar/ViewEvent';
import MosaikTeams from './components/teams/MosaikTeams';
import EmployeesPage from './components/teams/EmployeesPage';
import GroupsPage from './components/teams/GroupsPage';
import AddGroup from './components/teams/AddGroup';
import MosaikChats from './components/chats/MosaikChats';
import ChatDetail from './components/chats/ChatDetail';
import GroupChatDetail from './components/chats/GroupChatDetail';
import ChatInformation from './components/chats/ChatInformation';
import GroupChatInformation from './components/chats/GroupChatInformation';
import ChatMedia from './components/chats/ChatMedia';
import GroupChatMedia from './components/chats/GroupChatMedia';
import GroupChatMembers from './components/chats/GroupChatMembers';
import NewChat from './components/chats/NewChat';
import NewGroup from './components/chats/NewGroup';
import MosaikAlerts from './components/alerts/MosaikAlerts';
import AlertsSettings from './components/alerts/AlertsSettings';
import Welcome from './components/Welcome';
import Login from './components/Login';
import Signup from './components/Signup';
import SignupOtpEmail from './components/SignupOtpEmail';
import SignupOtpMobile from './components/SignupOtpMobile';
import TermsConditions from './components/TermsConditions';
import DataProtection from './components/DataProtection';
import UsersPage from './components/UsersPage';
import HomePage from './components/HomePage';
import ForgotPassword from './components/ForgotPassword';
import OtpVerification from './components/OTPVerification';
import ResetPassword from './components/ResetPassword';
import PasswordChanged from './components/PasswordChanged';
import MosaikSupport from './components/support/MosaikSupport';
import MosaikLibrary from './components/library/MosaikLibrary';
import MosaikTasks from './components/tasks/MosaikTasks';
import TaskDetails from './components/tasks/TaskDetails';
import InstructionDetails from './components/tasks/InstructionDetails';
import AssignTask from './components/tasks/AssignTask';
import AssignedTaskDetail from './components/tasks/AssignedTaskDetail';
import EditTask from './components/tasks/EditTask';
import CreateResource from './components/library/CreateResource';
import CreateContent from './components/library/CreateContent';
import CategoryPage from './components/library/CategoryPage';
import CategoryList from './components/library/CategoryList';
import ManualPage from './components/library/ManualPage';
import TutorialPage from './components/library/TutorialPage';
import MosaikFeedback from './components/feedback/MosaikFeedback';
import TicketDetail from './components/support/TicketDetail';
import FeedbackDetail from './components/feedback/FeedbackDetail';
import OrderDetail from './components/orders/OrderDetail';
import CreateOrder from './components/orders/CreateOrder';
import OrderSent from './components/orders/OrderSent';
import RepairDetail from './components/repairs/RepairDetail';
import CreateRepair from './components/repairs/CreateRepair';
import RepairSent from './components/repairs/RepairSent';
import TicketNew from './components/support/TicketNew';
import FeedbackNew from './components/feedback/FeedbackNew';
import CreateEvent from './components/calendar/CreateEvent';
import SelectImage from './components/calendar/SelectImage';
import MosaikOrders from './components/orders/MosaikOrders';
import MosaikRepairs from './components/repairs/MosaikRepairs';

// New imports for the additional routes
import EmployeeDetail from './components/teams/EmployeeDetail';
import GroupDetail from './components/teams/GroupDetail';
import AddEmployee from './components/teams/AddEmployee';
import OtpVerificationForLogin from './components/loginOTP';
import MobileOtpVerification from './components/MobileOtpVerification';
import EditEvent from './components/calendar/EditEvent';
import NotFound from './components/NotFound';
import SubscriptionDetails from './components/memberships/SubscriptionDetails';
import OrganisationUpdate from './components/memberships/OrganisationPage';
import OrganisationPage from './components/memberships/OrganisationPage';
import InvitationsList from './components/InvitationsList';
import ChatDetails from './components/chats/ChatDetails';

// Import necessary components for language support
import Auth from "./context/Auth";
import AllAssignedTasks from "./components/tasks/AllAssignedTasks";
import AllMyTasks from "./components/tasks/AllMyTasks";
import CollaborativeEditor from "./components/Editor/TestEditor";
import TestEditor from "./components/Editor/TestEditor";
import ErrorBoundary from './components/ErrorBoundary';
import Unauthorized from './components/Unauthorized';

function App() {
  return (
    // Suspense is used to wait for the translations to load
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CssBaseline />
          <div>Loading...</div>
        </div>
      }
    >
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* Normalize styles across browsers */}
        <AuthProvider>
        <WebSocketProvider>
        <ErrorBoundary>
          <AlertSettingsProvider> 
            <NotificationProvider>
              <Router>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<Welcome />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/loginotp" element={<OtpVerificationForLogin />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/signupotpemail" element={<SignupOtpEmail />} />
                    <Route path="/signupotpmobile" element={<SignupOtpMobile />} />
                    <Route path="/terms-conditions" element={<TermsConditions />} />
                    <Route path="/data-protection" element={<DataProtection />} />

                    {/* Protected Routes */}
                    <Route
                      element={
                        <Auth>
                          {/* You can add additional layout components here if needed */}
                          <React.Fragment />
                        </Auth>
                      }
                    >
                      {/* Dashboard and Home */}
                      <Route path="/mosaik-home" element={<MosaikHome />} />
                      <Route path="/home" element={<HomePage />} />

                      {/* User Profile */}
                      <Route path="/personal-information" element={<PersonalInformation />} />
                      <Route path="/profile-settings" element={<ProfileSettings />} />

                      {/* Library */}
                      <Route path="/mosaik-library" element={<MosaikLibrary />} />
                      <Route path="/mosaik-library/create-resource" element={<CreateResource />} />
                      <Route path="/mosaik-library/add-page" element={<CreateContent />} />
                      <Route path="/mosaik-library/category/:id" element={<CategoryPage />} />
                      <Route
                        path="/mosaik-library/category/:categoryId/subcategory/:subCategoryName"
                        element={<CategoryList />}
                      />
                      <Route path="/mosaik-library/manual/:id" element={<ManualPage />} />
                      <Route path="/mosaik-library/tutorial/:id" element={<TutorialPage />} />

                      {/* Memberships */}
                      <Route path="/memberships" element={<Memberships />} />
                      <Route path="/memberships/SubscriptionDetails" element={<SubscriptionDetails />} />
                      <Route path="/memberships/OrganisationPage" element={<OrganisationPage />} />

                      {/* Teams */}
                      <Route path="/mosaik-teams" element={<MosaikTeams />} />
                      <Route path="/employees" element={<EmployeesPage />} />
                      <Route path="/groups" element={<GroupsPage />} />
                      <Route path="/add-group" element={<AddGroup />} />
                      <Route path="/employee/:id" element={<EmployeeDetail />} />
                      <Route path="/group/:id" element={<GroupDetail />} />
                      <Route path="/add-employee" element={<AddEmployee />} />

                      {/* Chats */}
                      <Route path="/mosaik-chats" element={<MosaikChats />} />
                      <Route path="/chat-detail/:id" element={<ChatDetail />} />
                      <Route path="/chat-details/:id" element={<ChatDetails />} />
                      <Route path="/groupchat-detail/:id" element={<GroupChatDetail />} />
                      <Route path="/chat-information/:id" element={<ChatInformation />} />
                      <Route path="/chat-information/:id/chat-media" element={<ChatMedia />} />
                      <Route path="/new-chat/" element={<NewChat />} />
                      <Route path="/new-group/" element={<NewGroup />} />
                      <Route path="/groupchat-information/:id" element={<GroupChatInformation />} />
                      <Route path="/groupchat-information/:id/groupchat-media" element={<GroupChatMedia />} />
                      <Route path="/groupchat-information/:id/groupchat-members" element={<GroupChatMembers />} />

                      {/* Alerts */}
                      <Route path="/mosaik-alerts" element={<MosaikAlerts />} />
                      <Route path="/mosaik-alerts/InvitationsList" element={<InvitationsList />} />
                      <Route path="/alerts-settings" element={<AlertsSettings />} />

                      {/* Tasks */}
                      <Route path="/mosaik-tasks" element={<MosaikTasks />} />
                      <Route path="/task-detail/:taskId" element={<TaskDetails />} />
                      <Route path="/my-tasks" element={<AllMyTasks />} />
                      <Route path="/assigned-tasks" element={<AllAssignedTasks />} />
                      <Route path="/instruction-detail/:instructionId" element={<InstructionDetails />} />
                      <Route path="/assign-task" element={<AssignTask />} />
                      <Route path="/assigned-task-detail/:id" element={<AssignedTaskDetail />} />
                      <Route path="/edit-task/:id" element={<EditTask />} />

                      {/* Support */}
                      <Route path="/mosaik-support" element={<MosaikSupport />} />
                      <Route path="/ticket-detail/:ticketId" element={<TicketDetail />} />
                      <Route path="/create-ticket" element={<TicketNew />} />

                      {/* Feedback */}
                      <Route path="/feedback-detail/:id" element={<FeedbackDetail />} />
                      <Route path="/create-feedback" element={<FeedbackNew />} />
                      <Route path="/mosaik-feedback" element={<MosaikFeedback />} />

                      {/* Calendar */}
                      <Route path="/mosaik-calendar" element={<MosaikCalendar />} />
                      <Route path="/mosaik-calendar/event/:id" element={<ViewEvent />} />
                      <Route path="/mosaik-calendar/create-event" element={<CreateEvent />} />
                      <Route path="/mosaik-calendar/edit-event/:eventId" element={<EditEvent />} />
                      <Route path="/mosaik-calendar/select-image" element={<SelectImage />} />

                      {/* Orders */}
                      <Route path="/mosaik-orders" element={<MosaikOrders />} />
                      <Route path="/order-detail/:id" element={<OrderDetail />} />
                      <Route path="/create-order" element={<CreateOrder />} />
                      <Route path="/order-sent" element={<OrderSent />} />

                      {/* Repairs */}
                      <Route path="/mosaik-repairs" element={<MosaikRepairs />} />
                      <Route path="/repair-detail/:id" element={<RepairDetail />} />
                      <Route path="/create-repair" element={<CreateRepair />} />
                      <Route path="/repair-sent" element={<RepairSent />} />

                      {/* Editors */}
                      <Route path="/editor" element={<CollaborativeEditor />} />
                      <Route path="/test-editor" element={<TestEditor />} />

                      {/* Unauthorized Access */}
                      <Route path="/unauthorized" element={<Unauthorized />} />
                    </Route>

                    {/* Authentication and Miscellaneous Routes */}
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/otp-verification" element={<OtpVerification />} />
                    <Route path="/mobile-otp-verification" element={<MobileOtpVerification />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/password-changed" element={<PasswordChanged />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Router>
            </NotificationProvider>
            </AlertSettingsProvider>
            </ErrorBoundary>
          </WebSocketProvider>
        </AuthProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
