import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import Tesseract from "tesseract.js";
import styles from "./Fobi.module.css";
import searchIcon from "../../assets/images/search-button.png";
import sortIcon from "../../assets/images/sort-icon.png";
import successImage from "../../assets/icons/check-icon.png";
import addIcon from "../../assets/images/plus-button.png";
import paymentImage from "../../assets/images/payment-image.png";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import jsPDF from "jspdf";
import logoImage from "../../assets/images/logo.png";
import { uploadFile } from "../../services/mosaikUploadService";
import { useLocation } from "react-router-dom";

const Fobi = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [courses, setCourses] = useState([
    { title: t("Physician"), category: t("Medical"), points: 10 },
    { title: t("Medical Directors"), category: t("Medical"), points: 5 },
    {
      title: t("Aesthetic Medicine Physician"),
      category: t("Medical"),
      points: 10,
    },
    { title: t("Dermatologic Surgeons"), category: t("Medical"), points: 10 },
    { title: t("Resident Physician"), category: t("Medical"), points: 10 },
    { title: t("Dermatology Residents"), category: t("Medical"), points: 10 },
    {
      title: t("Quality Management Program"),
      category: t("Program"),
      points: 10,
    },
  ]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [ocrResults, setOcrResults] = useState([]);
  const [showUploadScreen, setShowUploadScreen] = useState(false);
  const [showVerificationScreen, setShowVerificationScreen] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { authState } = useContext(AuthContext);
  const [points, setPoints] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const fetchFobiPoints = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/profiles/calculate-fobi-points`,
          {
            headers: {
              Authorization: `Bearer ${authState.accessToken}`,
            },
          }
        );
        const data = await response.data;
        console.log("DATA", data);
        // Assuming the API returns an object with a 'points' field
        setPoints(data.totalPoints || 0); // Set points from API response
      } catch (error) {
        console.error("Error fetching Fobi points:", error);
        // Handle error (optional, like setting points to 0 or showing a message)
      }
    };

    fetchFobiPoints();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const step = params.get("step");

    if (step === "upload") {
      setShowUploadScreen(true);
    }
  }, [location]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCourses = courses.filter((course) =>
    course.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handlePay = async () => {
    setShowPaymentModal(false);
    const userId = authState.user?.id;
    try {
      const customer = await axios.post(
        `${apiUrl}/subscriptions/createCustomer`,
        {
          userId: userId,
        },
        {
          headers: { Authorization: `Bearer ${authState.accessToken}` },
        }
      );
      const customerId = customer.data.id; // Get the Stripe customer ID
      console.log("CUSTOMER ID", customerId);
      const response = await axios.post(
        `${apiUrl}/subscriptions/create-checkout-session-fobi`,
        {
          customer: customerId,
        },
        {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        }
      );

      if (response && response.data.url) {
        window.location = response.data.url;
      } else {
        console.error("Error creating checkout session:");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const generateAndUploadPdf = async (data) => {
    const doc = new jsPDF();

    // Set up basic styles and colors
    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.setFontSize(18);
    doc.setTextColor("#ff7f2a"); // Orange color for course name

    const logo = new Image();
    logo.src = logoImage;
    doc.addImage(logo, "PNG", 10, 10, 30, 30); // Adjust position and size as needed
    // Logo (Mockup placeholder for the logo)
    doc.setFontSize(12);
    doc.text("mosaik", 10, 20); // Adjust logo position as needed

    // University and Date of Passing
    doc.setTextColor("#4A4A4A");
    doc.setFontSize(12);
    doc.text(`${data.university}`, 150, 20);
    doc.text(`Date of Passing: ${data.dateOfPassing}`, 150, 30);

    // Course Name
    doc.setFontSize(24);
    doc.setTextColor("#ff7f2a"); // Orange color
    doc.text(data.courseName, 10, 40);

    // Course Details Box
    doc.setFontSize(12);
    doc.setTextColor("#4A4A4A"); // Darker text color for content
    doc.setDrawColor(200); // Light border color
    doc.rect(10, 50, 180, 40); // Draw rectangle for Course Details

    doc.text("Course Details:", 15, 55);
    doc.setFontSize(10);
    doc.text(data.courseDetails, 15, 65, {
      maxWidth: 170,
      lineHeightFactor: 1.5,
    });

    // QR Code placeholder (You may use jsPDF's `addImage` if you have a QR code image)
    doc.setFontSize(12);
    doc.setTextColor("#4A4A4A");
    doc.text("Marks Obtained:", 150, 120);
    doc.setFontSize(24);
    doc.setTextColor("#2c5f2d"); // Dark green
    doc.text(`${data.marks}/500`, 150, 130);

    // Convert to Blob and upload
    const pdfBlob = doc.output("blob");

    // Create FormData and upload
    const formData = new FormData();
    formData.append("pdf", pdfBlob, "course_details.pdf");
    // Make an API call to upload the PDF
    try {
      const fileData = await uploadFile(pdfBlob, "profile", data.courseName);
      // Extract only the 'url' and 'fileName' properties from 'fileData'
      const filteredFileData = {
        fileUrl: fileData?.fileUrl,
        fileName: fileData?.fileName,
        id: fileData?.id,
      };
      console.log("FILE DATA", filteredFileData);
      alert("Record saved successfully!");
      handleVerificationContinue();
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleAddDocument = () => {
    if (!showPaymentModal) {
      setShowPaymentModal(true);
    }
  };
  const [selectedFields, setSelectedFields] = useState({
    university: "",
    dateOfPassing: "",
    courseName: "",
    marks: "",
    courseDetails: "",
  });
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setUploadedImages([...uploadedImages, ...imageUrls]);
  };

  const handleTakePicture = () => {
    // This function should handle taking pictures using the device camera
  };

  const handleContinueUpload = () => {
    // Process OCR on uploaded images
    Promise.all(
      uploadedImages.map((image) =>
        Tesseract.recognize(image, "eng", {
          logger: (m) => console.log(m),
        })
      )
    ).then((results) => {
      setOcrResults(results.map((result) => result.data.text));
      setShowUploadScreen(false);
      setShowVerificationScreen(true);
    });
  };

  const handleVerificationContinue = () => {
    setShowVerificationScreen(false);
    setShowSuccessScreen(true);
  };

  const renderUploadedImages = () => (
    <div className={styles.uploadedImagesContainer}>
      {uploadedImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`uploaded ${index}`}
          className={styles.uploadedImage}
        />
      ))}
    </div>
  );

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t("FOBI")} />
      <div className={styles.fobiContainer}>
        {showPaymentModal && (
          <div className={styles.paymentModal}>
            <div className={styles.modalContent}>
              <button
                className={styles.closeButton}
                onClick={() => setShowPaymentModal(false)}
              >
                &times;
              </button>
              <img
                src={paymentImage}
                alt="Payment"
                className={styles.paymentImage}
              />
              <h2>€ 1.99</h2>
              <p>{t("You have just to pay € 1.99")}</p>
              <p>
                {t(
                  "Hey! Just pay € 1.99 and unlock all the features with the brand new AI service of FOBI"
                )}
              </p>
              <button className={styles.payButton} onClick={handlePay}>
                {t("Pay")}
              </button>
            </div>
          </div>
        )}

        {showUploadScreen && (
          <div className={styles.uploadScreen}>
            <h2>{t("Upload your Documents")} 📄</h2>
            <p>
              {t(
                "Regulations require you to upload a national identity card and all other documents. Don't worry, your data will stay safe and private."
              )}
            </p>
            <input
              type="file"
              multiple
              onChange={handleFileUpload}
              className={styles.fileInput}
            />
            <button className={styles.cameraButton} onClick={handleTakePicture}>
              {t("Open Camera & Take Photo")}
            </button>
            {uploadedImages.length > 0 && renderUploadedImages()}
            <button
              className={styles.continueButton}
              onClick={handleContinueUpload}
            >
              {t("Continue")}
            </button>
          </div>
        )}
        {showVerificationScreen && (
          <div className={styles.verificationScreen}>
            <h2>{t("Data of Scanned Documents")}</h2>
            <p>
              {t(
                "You can check your documents. Our AI feature has read and checked the documents to field your data."
              )}
            </p>

            {/* Render fields with dropdowns and manual input options */}
            <div className={styles.fieldMapping}>
              {ocrResults.length > 0 && (
                <>
                  <div className={styles.field}>
                    <label>{t("Name of university")}</label>
                    <div className={styles.inputContainer}>
                      <select
                        className={styles.ocrDropdown}
                        value={selectedFields.university}
                        onChange={(e) =>
                          setSelectedFields({
                            ...selectedFields,
                            university: e.target.value,
                          })
                        }
                      >
                        <option value="">{t("Select data")}</option>
                        {ocrResults
                          .flatMap((result) => result.split("\n"))
                          .map((line, index) => (
                            <option key={index} value={line}>
                              {line}
                            </option>
                          ))}
                      </select>
                      <input
                        type="text"
                        className={styles.manualInput}
                        value={selectedFields.university}
                        placeholder={t("Enter manually")}
                        onChange={(e) =>
                          setSelectedFields({
                            ...selectedFields,
                            university: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={styles.field}>
                    <label>{t("Date of Passing")}</label>
                    <div className={styles.inputContainer}>
                      <select
                        className={styles.ocrDropdown}
                        value={selectedFields.dateOfPassing}
                        onChange={(e) =>
                          setSelectedFields({
                            ...selectedFields,
                            dateOfPassing: e.target.value,
                          })
                        }
                      >
                        <option value="">{t("Select data")}</option>
                        {ocrResults
                          .flatMap((result) => result.split("\n"))
                          .map((line, index) => (
                            <option key={index} value={line}>
                              {line}
                            </option>
                          ))}
                      </select>
                      <input
                        type="text"
                        className={styles.manualInput}
                        value={selectedFields.dateOfPassing}
                        placeholder={t("Enter manually")}
                        onChange={(e) =>
                          setSelectedFields({
                            ...selectedFields,
                            dateOfPassing: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={styles.field}>
                    <label>{t("Course Name")}</label>
                    <div className={styles.inputContainer}>
                      <select
                        className={styles.ocrDropdown}
                        value={selectedFields.courseName}
                        onChange={(e) =>
                          setSelectedFields({
                            ...selectedFields,
                            courseName: e.target.value,
                          })
                        }
                      >
                        <option value="">{t("Select data")}</option>
                        {ocrResults
                          .flatMap((result) => result.split("\n"))
                          .map((line, index) => (
                            <option key={index} value={line}>
                              {line}
                            </option>
                          ))}
                      </select>
                      <input
                        type="text"
                        className={styles.manualInput}
                        value={selectedFields.courseName}
                        placeholder={t("Enter manually")}
                        onChange={(e) =>
                          setSelectedFields({
                            ...selectedFields,
                            courseName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={styles.field}>
                    <label>{t("No of Marks obtained")}</label>
                    <div className={styles.inputContainer}>
                      <select
                        className={styles.ocrDropdown}
                        value={selectedFields.marks}
                        onChange={(e) =>
                          setSelectedFields({
                            ...selectedFields,
                            marks: e.target.value,
                          })
                        }
                      >
                        <option value="">{t("Select data")}</option>
                        {ocrResults
                          .flatMap((result) => result.split("\n"))
                          .map((line, index) => (
                            <option key={index} value={line}>
                              {line}
                            </option>
                          ))}
                      </select>
                      <input
                        type="text"
                        className={styles.manualInput}
                        value={selectedFields.marks}
                        placeholder={t("Enter manually")}
                        onChange={(e) =>
                          setSelectedFields({
                            ...selectedFields,
                            marks: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={styles.field}>
                    <label>{t("Course full detail")}</label>
                    <textarea
                      className={styles.ocrTextArea}
                      value={selectedFields.courseDetails}
                      onChange={(e) =>
                        setSelectedFields({
                          ...selectedFields,
                          courseDetails: e.target.value,
                        })
                      }
                      placeholder={t("Add text or select from OCR data")}
                    />
                  </div>
                </>
              )}
            </div>

            <button
              className={styles.continueButton}
              onClick={() => generateAndUploadPdf(selectedFields)}
            >
              {t("Continue")}
            </button>
          </div>
        )}

        {showSuccessScreen && (
          <div className={styles.successScreen}>
            <h2>{t("Documents Uploaded Successful")}</h2>
            <div className={styles.successIcon}>
              <img src={successImage} alt={t("Success Image")} />
            </div>
            <p className={styles.Orangey}>{t("You earned 10 points")}</p>
            <p>{t("Your documents are successfully uploaded and verified.")}</p>
            <button
              className={styles.saveButton}
              onClick={() => alert(t("Returning to dashboard..."))}
            >
              {t("Save")}
            </button>
          </div>
        )}

        {!showUploadScreen && !showVerificationScreen && !showSuccessScreen && (
          <>
            <div className={styles.scoreSection}>
              <div className={styles.scoreCircle}>
                <span className={styles.scorePoints}>{points}</span>
                <span className={styles.pointsLabel}>{t("Points")}</span>
              </div>
              <p className={styles.scoreText}>
                {t("Your Total Score is excellent")}
              </p>
              <p className={styles.checkDates}>
                {t("Last Check on")} 16 August 2024
                <br />
                <span className={styles.OrangeText}>
                  {t("Next Check on")} 16 August 2029
                </span>
              </p>
            </div>

            <div className={styles.overviewSection}>
              <div className={styles.overviewHeader}>
                <h3>{t("FOBI Overview")}</h3>
                <button
                  className={styles.addButton}
                  onClick={handleAddDocument}
                >
                  <img src={addIcon} alt={t("Add")} />
                </button>
              </div>

              <div className={styles.searchAndSort}>
                <div className={styles.searchField}>
                  <input
                    type="text"
                    placeholder={t("Search Courses...")}
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchInput}
                  />
                </div>
              </div>

              <div className={styles.coursesList}>
                {filteredCourses.map((course, index) => (
                  <div key={index} className={styles.courseItem}>
                    <div className={styles.courseDetails}>
                      <h4>{course.title}</h4>
                      <p>{course.category}</p>
                    </div>
                    <div className={styles.pointsBadge}>
                      {course.points < 10 ? `0${course.points}` : course.points}{" "}
                      {t("Points")}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <MosaikFooter />
    </div>
  );
};

export default Fobi;
