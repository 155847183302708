// GroupDetail.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './GroupDetail.module.css';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { getProfilePictureUrl } from '../../utils/getProfileUrl';

const Modal = ({ isOpen, onClose, onConfirm, title, children }) => {
  const { t } = useTranslation(); // Initialize translation function
  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h3>{t(title)}</h3>
        <div className={styles.modalBody}>{children}</div>
        <div className={styles.modalActions}>
          <button onClick={onClose} className={styles.modalButton}>
            {t('Cancel')}
          </button>
          <button onClick={onConfirm} className={styles.modalButton}>
            {t('Confirm')}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

const apiUrl = process.env.REACT_APP_API_URL;

const GroupDetail = () => {
  const { t } = useTranslation(); // Initialize translation function
  const { id } = useParams(); // Get the group ID from URL parameters
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const token = authState.accessToken;
  const userId = authState.user?.id;
  const currentOrganisationId = authState.currentOrganisationId;

  // Component state
  const [groupName, setGroupName] = useState('');
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = useState(false);
  const [isRemoveMemberModalOpen, setIsRemoveMemberModalOpen] = useState(false);
  const [isAssignRolesModalOpen, setIsAssignRolesModalOpen] = useState(false);
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [memberToRemoveId, setMemberToRemoveId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [groupOwnerId, setGroupOwnerId] = useState(null);

  // Fetch group details
  const fetchGroupDetails = async () => {
    if (!token || !id) {
      console.error(t('Missing access token or group ID.'));
      return;
    }
    try {
      const response = await axios.get(`${apiUrl}/groups/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const groupData = response.data;
      setGroupName(groupData.name); // Set group name
      setGroupOwnerId(groupData.owner?.id);
      setIsOwner(groupData.owner?.id === userId);
      fetchGroupMembers();
    } catch (error) {
      console.error(t('Error fetching group details:'), error);
    }
  };

  // Fetch group members
  const fetchGroupMembers = async () => {
    if (!token || !id) {
      console.error(t('Missing access token or group ID.'));
      return;
    }
    try {
      const response = await axios.get(`${apiUrl}/groups/${id}/members`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const membersData = response.data;

      const membersWithUserData = await Promise.all(
        membersData.map(async (member) => {
          try {
            const userResponse = await axios.get(`${apiUrl}/members/${member.id}/user`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const userData = userResponse.data;
            console.log('user',userData[0])
        // let profilePictureUrl = getProfilePictureUrl(userData)
            const profileImageUrl = await getProfilePictureUrl(userData[0]);
            return { ...member, user: userData[0], profileImageUrl };
          } catch (error) {
            console.error(t('Error fetching user data for member:'), error);
            return member; // Return member without user data if error occurs
          }
        })
      );

      setMembers(membersWithUserData);
    } catch (error) {
      console.error(t('Error fetching group members:'), error);
    }
  };

  // Fetch available users to add to the group
  useEffect(() => {
    const fetchUsers = async () => {
      if (!currentOrganisationId || !token) {
        console.error(t('Missing organisation ID or access token.'));
        return;
      }
      try {
        const response = await axios.get(`${apiUrl}/users`, {
          params: {
            'where[userOrganisationRoles][some][organisation][id]': currentOrganisationId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const filteredUsers = response.data.filter(
          (user) => !members.some((member) => member.user?.id === user.id)
        );
        setAvailableUsers(filteredUsers);
      } catch (error) {
        console.error(t('Error fetching users:'), error);
      }
    };
    fetchUsers();
  }, [members, currentOrganisationId, token]);

  // Fetch group details on component mount or when ID changes
  useEffect(() => {
    fetchGroupDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Add members to group
  const addMembersToGroup = async () => {
    if (!token || !id || !currentOrganisationId) {
      console.error(t('Missing access token, group ID, or organisation ID.'));
      return;
    }
    try {
      for (let userId of selectedUserIds) {
        const memberData = {
          memberGroups: {
            connect: [{ id: id }],
          },
          organisation: { id: currentOrganisationId },
          parentGroup: { id: id },
          user: { connect: [{ id: userId }] },
        };
        await axios.post(`${apiUrl}/members`, memberData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      alert(t('Members added successfully'));
      fetchGroupMembers();
      setIsAddMembersModalOpen(false);
      setSelectedUserIds([]);
    } catch (error) {
      console.error(t('Error adding members to group:'), error);
      alert(t('Failed to add members to the group. Please try again.'));
    }
  };

  // Open roles modal and fetch current roles for a member
  const handleRolesModalOpen = async (memberId) => {
    if (!token || !id) {
      console.error(t('Missing access token or group ID.'));
      return;
    }
    try {
      const response = await axios.get(`${apiUrl}/groups/${id}/permissionUserGroups`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const permissions = response.data.filter((pug) => pug.memberId === memberId);
      setUserRoles(permissions.map((pug) => pug.permission.name));
      setSelectedMemberId(memberId);
      setIsAssignRolesModalOpen(true);
    } catch (error) {
      console.error(t('Error fetching roles for member:'), error);
    }
  };

  // Update member roles
  const updateMemberRoles = async () => {
    if (!token || !id || !selectedMemberId) {
      console.error(t('Missing access token, group ID, or member ID.'));
      return;
    }
    try {
      await axios.patch(
        `${apiUrl}/groups/${id}/permissionUserGroups`,
        {
          memberId: selectedMemberId,
          roles: userRoles,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(t('Roles updated successfully'));
      setIsAssignRolesModalOpen(false);
    } catch (error) {
      console.error(t('Error updating roles:'), error);
    }
  };

  // Toggle user role
  const toggleUserRole = (role) => {
    if (userRoles.includes(role)) {
      setUserRoles(userRoles.filter((r) => r !== role));
    } else {
      setUserRoles([...userRoles, role]);
    }
  };

  // Remove member from group
  const removeMemberFromGroup = async (memberId) => {
    if (!token) {
      console.error(t('Missing access token.'));
      return;
    }
    try {
      await axios.delete(`${apiUrl}/members/${memberId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchGroupMembers();
      alert(t('Member removed successfully'));
    } catch (error) {
      console.error(t('Error removing member:'), error);
      alert(t('Failed to remove member. Please try again.'));
    }
  };

  // Confirm member removal
  const confirmRemoveMember = () => {
    removeMemberFromGroup(memberToRemoveId);
    setIsRemoveMemberModalOpen(false);
  };

  // Delete group
  const deleteGroup = async () => {
    if (!token || !id) {
      console.error(t('Missing access token or group ID.'));
      return;
    }
    try {
      const membersResponse = await axios.get(`${apiUrl}/groups/${id}/members`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const groupMembers = membersResponse.data;
      for (let member of groupMembers) {
        await axios.delete(`${apiUrl}/members/${member.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      await axios.delete(`${apiUrl}/groups/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(t('Group deleted successfully'));
      navigate('/mosaik-teams');
    } catch (error) {
      console.error(t('Error deleting group:'), error);
      alert(t('Failed to delete group. Please try again.'));
    }
  };

  // Fetch user profile image
  const getUserProfileImage = async (userId) => {
    if (!token || !userId) {
      console.error(t('Missing access token or user ID.'));
      return '/assets/images/placeholder.webp';
    }
    try {
      const response = await axios.get(`${apiUrl}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = response.data;
  
      // Check if the user has a profile photo
      if (!user) {
        return '/assets/images/placeholder.webp';
      } else {
        // Fetch the correct URL using getProfilePictureUrl if the file URL is present
        let url = await getProfilePictureUrl(user.profilePhotoFile);
        return url;
      }
    } catch (error) {
      console.error(t('Error fetching user profile image:'), error);
      return '/assets/images/placeholder.webp';
    }
  };
  

  // Filter members based on search term
  const filteredMembers = members.filter((member) =>
    (member.user?.firstName || '').toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Group Details')} />
      <div className={styles.groupDetail}>
        <h3>{groupName || t('Group')}</h3>
        {isOwner && (
          <>
            <p>{t('You are the owner of this group!')}</p>
          </>
        )}
        <div className={styles.sectionControls}>
          <select
            className={styles.sortSelect}
            onChange={(e) => setSortOrder(e.target.value)}
            value={sortOrder}
          >
            <option value="">{t('Sort by')}</option>
            <option value="name">{t('Name')}</option>
            <option value="date">{t('Date of Joining')}</option>
          </select>
          {isOwner && (
            <>
              <button className={styles.addButton} onClick={() => setIsAddMembersModalOpen(true)}>
                +
              </button>
            </>
          )}
        </div>
        <input
          type="text"
          className={styles.searchInput}
          placeholder={t('Search members...')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className={styles.memberList}>
          {filteredMembers.length > 0 ? (
            filteredMembers.map((member) => (
              <div key={member.id} className={styles.memberItem}>
                <div
                  className={styles.memberInfo}
                  onClick={() => navigate(`/employee/${member.user?.id}`)}
                >
                  <img
                    src={member.profileImageUrl || '/assets/images/placeholder.webp'}
                    alt={member.user?.firstName || t('User')}
                    className={styles.memberImage}
                  />
                  <div className={styles.memberBadger}>
                    <span className={styles.memberName}>
                      {member.user?.firstName
                        ? `${member.user?.firstName} ${member.user?.lastName || ''}`
                        : t('Unnamed Member')}
                    </span>
                    <span className={styles.memberDepartment}>
                      {Array.isArray(member.user?.roles) && member.user?.roles.length > 0
                        ? member.user?.roles.join(', ')
                        : t('No roles assigned')}
                    </span>
                  </div>
                </div>
                {isOwner && (
                  <>
                    <button
                      className={styles.removeButton}
                      onClick={() => {
                        setMemberToRemoveId(member.id);
                        setIsRemoveMemberModalOpen(true);
                      }}
                    >
                      {t('Remove')}
                    </button>
                    {/* Uncomment if you have roles functionality
                    <button
                      className={styles.rolesButton}
                      onClick={() => handleRolesModalOpen(member.id)}
                    >
                      {t('Roles')}
                    </button>
                    */}
                  </>
                )}
              </div>
            ))
          ) : (
            <p>{t('No members found.')}</p>
          )}
        </div>
      </div>
      {isOwner && (
        <div className={styles.deleteGroupContainer}>
          <button
            className={styles.deleteGroupButton}
            onClick={() => setIsDeleteGroupModalOpen(true)}
          >
            {t('Delete Group')}
          </button>
        </div>
      )}
      <MosaikFooter />

      {/* Add Members Modal */}
      <Modal
        isOpen={isAddMembersModalOpen}
        onClose={() => setIsAddMembersModalOpen(false)}
        onConfirm={addMembersToGroup}
        title={t('Add Members to Group')}
      >
        <div className={styles.userSelection}>
          {availableUsers.map((user) => (
            <div key={user.id} className={styles.userSelectItem}>
              <input
                type="checkbox"
                id={`user-${user.id}`}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedUserIds([...selectedUserIds, user.id]);
                  } else {
                    setSelectedUserIds(selectedUserIds.filter((id) => id !== user.id));
                  }
                }}
              />
              <label htmlFor={`user-${user.id}`} className={styles.userLabel}>
                <span>
                  {user.firstName} {user.lastName}
                </span>
              </label>
            </div>
          ))}
        </div>
      </Modal>

      {/* Remove Member Modal */}
      <Modal
        isOpen={isRemoveMemberModalOpen}
        onClose={() => setIsRemoveMemberModalOpen(false)}
        onConfirm={confirmRemoveMember}
        title={t('Confirm Removal')}
      >
        <p>{t('Are you sure you want to remove this member from the group?')}</p>
      </Modal>

      {/* Assign Roles Modal (Optional) */}
      <Modal
        isOpen={isAssignRolesModalOpen}
        onClose={() => setIsAssignRolesModalOpen(false)}
        onConfirm={updateMemberRoles}
        title={t('Assign Roles')}
      >
        <p>{t('Select roles for the member:')}</p>
        <div>
          <label>
            <input
              type="checkbox"
              checked={userRoles.includes('admin')}
              onChange={() => toggleUserRole('admin')}
            />
            {t('Admin')}
          </label>
          <label>
            <input
              type="checkbox"
              checked={userRoles.includes('member')}
              onChange={() => toggleUserRole('member')}
            />
            {t('Member')}
          </label>
          {/* Add more roles as needed */}
        </div>
      </Modal>

      {/* Delete Group Modal */}
      <Modal
        isOpen={isDeleteGroupModalOpen}
        onClose={() => setIsDeleteGroupModalOpen(false)}
        onConfirm={deleteGroup}
        title={t('Confirm Deletion')}
      >
        <p>{t('Are you sure you want to delete this group? This action cannot be undone.')}</p>
      </Modal>
    </div>
  );
};

export default GroupDetail;
