export const userAllowedRoutes = [
    "/home",
    "/mosaik-home",
    "/personal-information",
    "/memberships",
    "/profile-settings",
    "/mosaik-support",
    "/create-ticket",
    "/ticket-detail/:ticketId",
    "/mosaik-alerts/InvitationsList",
    "/mosaik-alerts",
    "/alerts-settings",
    "/memberships/OrganisationPage"
  ];
  
