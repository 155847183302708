import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './OtpVerification.module.css'; // Adjust import to match your file structure
import logo from '../assets/images/logo.png';
import backIcon from '../assets/icons/back-icon.png';
import axios from 'axios';

const OtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [error, setError] = useState('');

  const inputRefs = useRef([]);  // Initialize the useRef hook for managing input references

  // Redirect to the forgot password screen if the email is not present
  useEffect(() => {
    if (!location.state || !location.state.email) {
      navigate('/forgot-password'); // Redirect back to forgot password if email is missing
    }
  }, [location, navigate]);

 useEffect(() => {
    inputRefs.current[0].focus()
  }, []);
  const handleOtpChange = (element, index) => {
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.value && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    if (newOtp.every((digit) => digit !== '')) {
      handleVerifyOtp(newOtp.join(''));
    }
    setError('');
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  // Handle pasting the OTP
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').slice(0, 6); // Get only the first 6 digits
    const newOtp = [...otp];

    pasteData.split('').forEach((char, index) => {
      if (index < 6) {
        newOtp[index] = char;
        inputRefs.current[index].value = char; // Set value to each input field
      }
    });

    setOtp(newOtp);

    // Automatically verify when all digits are pasted
    if (pasteData.length === 6) {
      handleVerifyOtp(newOtp.join(''));
    }
  };

  // Function to request OTP for mobile number
  const handleRequestOtpForMobile = async (userInfo, token) => {
    const { mobile } = userInfo;

    if (!mobile) {
      console.error('Mobile number not found');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/request-otp`, 
        {
          purpose: 'reset',
          method: 'mobile',
          destination: mobile
        }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if (response.data && response.data.accessToken) {
        console.log('OTP sent successfully to mobile:', mobile);
        localStorage.setItem('accessToken', response.data.accessToken); // Store the new OTP token
        navigate('/mobile-otp-verification', { state: { mobile: response.data.mobile } });
      } else {
        console.error('Failed to send OTP to mobile');
      }
    } catch (error) {
      console.error('Error requesting OTP for mobile:', error?.response?.data || error.message);
    }
  };

  const handleVerifyOtp = async (otpCode) => {
    const token = localStorage.getItem('accessToken'); // Retrieve stored OTP token
  
    if (!token) {
      setError('Token not found. Please request OTP again.');
      return;
    }
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify-otp`,
        {
          purpose: 'reset',
          method: 'email',
          destination: location.state.email,
          otp: otpCode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.result === true) {
        const { userInfo, accessToken } = response.data;
        localStorage.setItem('accessToken', accessToken); // Store the reset token
        console.log('Email OTP verified, now requesting OTP for mobile...');
        await handleRequestOtpForMobile(userInfo, accessToken);
      } else {
        setError('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Failed to verify OTP. Please try again.');
    }
  };

  if (!location.state || !location.state.email) {
    return null; // Optionally, render nothing while redirecting
  }

  return (
    <main className={styles.signupContainer}>
      <img src={logo} alt="Mosaik Logo" className={styles.logo} />
      <section className={styles.signupCard}>
        <div className={styles.header}>
          <img src={backIcon} alt="Go back" className={styles.backIcon} onClick={() => navigate('/signup')} />
          <h1 className={styles.signupHeading}>Email Verification</h1>
        </div>
        <div className={styles.divider}></div>
        <p className={styles.instructionText}>
          We emailed you the six-digit OTP code to {location.state.email}
        </p>
        <div className={styles.otpInputContainer} onPaste={handlePaste}>
          {otp.map((data, index) => (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength="1"
              className={styles.otpInputBox}
              value={data}
              onChange={(e) => handleOtpChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => inputRefs.current[index] = el}
            />
          ))}
        </div>
        {error && <p className={styles.errorText}>{error}</p>}
        <button onClick={() => handleVerifyOtp(otp.join(''))} className={styles.verifyButton}>
          Verify
        </button>
      </section>
    </main>
  );
};

export default OtpVerification;
