import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './EmployeeDetail.module.css';
import { useTranslation } from 'react-i18next';
import image from '../../assets/images/noimage.png';
import { AuthContext } from '../../context/AuthContext'; // Ensure you have AuthContext

// Reusable Modal Component
const Modal = ({ isOpen, onClose, onConfirm, title, children }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h3>{t(title)}</h3>
        <div className={styles.modalBody}>{children}</div>
        <div className={styles.modalActions}>
          <button onClick={onClose} className={styles.modalButtonSecondary}>
            {t('Cancel')}
          </button>
          <button onClick={onConfirm} className={styles.modalButtonPrimary}>
            {t('Confirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

const EmployeeDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [employee, setEmployee] = useState(null);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const { authState } = useContext(AuthContext);
  const token = authState.accessToken;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [groups, setGroups] = useState([
    t('Medical Support Group'),
    t('Billing Department'),
    t('IT Support'),
  ]);
  const currentOrganisationId = authState.currentOrganisationId;
  // const groups = [
  //   t('Medical Support Group'),
  //   t('Billing Department'),
  //   t('IT Support'),
  // ];

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/profiles/${id}/details`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEmployee(response.data);
      } catch (error) {
        console.error('Failed to fetch employee details:', error);
        // Optionally, set employee to a default object or show an error message
      }
    };

    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/groups`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGroups(response.data); // Assuming response.data is the list of groups
      } catch (error) {
        console.error('Failed to fetch groups:', error);
      }
    };
    fetchGroups();
    fetchEmployeeDetails();
  }, [id, apiUrl, token]);

  const handleChatClick = () => {
    window.location.href = `/new-chat?userId=${id}`;
  };

  const handleAddToGroup = () => {
    setIsGroupModalOpen(true);
  };

  const handleConfirmAddToGroup = async () => {
    if (selectedGroup) {
      try {
        await addMembersToGroup(selectedGroup);
        alert(t('Employee added to the group successfully.'));
      } catch (error) {
        console.error(t('Failed to add employee to the group.'), error);
      }
      setIsGroupModalOpen(false);
    }
  };
  
  // Add Members to Group Function
  const addMembersToGroup = async (groupId) => {
    if (!currentOrganisationId || !token) {
      console.error(t('Missing organisation ID or access token.'));
      return;
    }
  
    const userId = employee.user.id;
        try {
      const memberData = {
        memberGroups: {
          connect: [{ id: groupId }],
        },
        organisation: { id: currentOrganisationId },
        parentGroup: { id: groupId },
        user: { connect: [{ id: userId }] },
      };
  
      await axios.post(`${apiUrl}/members`, memberData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(t('Error adding member {{userId}}:', { userId }), error);
    }
  };
  

  const handleRemoveEmployee = () => {
    setIsRemoveModalOpen(true);
  };

  const handleConfirmRemoveEmployee = () => {
    // TODO: Perform the removal of the employee, e.g., API call
    alert(t('Employee removed from Mosaik'));
    setIsRemoveModalOpen(false);
    navigate('/mosaik-teams');
  };

  if (!employee) {
    return <div className={styles.loading}>{t('Loading...')}</div>;
  }

  // Destructure optionalFiles if present
  const optionalFiles = employee.optionalFiles || {};

  return (
    <div className={styles.container}>
      <MosaikHeaderWithBack pageTitle={t('Employee')} />
      <div className={styles.employeeDetail}>
        {/* Employee Card */}
        <div className={styles.employeeCard}>
          <img
            src={employee.profilePhotoFile || image}
            alt={employee.firstName || t('No Name')}
            className={styles.employeeImage}
          />
          <div className={styles.employeeInfo}>
            <h2 className={styles.employeeName}>{employee.user.firstName || t('No Name')}</h2>
            <p className={styles.employeeRole}>{employee.role?.join(', ') || t('No Role')}</p>
            <p className={styles.employeeDepartment}>
              {employee.department || t('No Department')}
            </p>
            <div className={styles.actions}>
              <button onClick={handleChatClick} className={styles.chatButton}>
                💬 {t('Chat')}
              </button>
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className={styles.stats}>
          <div className={styles.statItem}>
            <span className={styles.statNumber}>
              {employee.statistics?.activeTasks ?? '0'}
            </span>
            <p className={styles.statLabel}>{t('Active Tasks')}</p>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statNumber}>
              {employee.statistics?.teamGroups ?? '0'}
            </span>
            <p className={styles.statLabel}>{t('Team Groups')}</p>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statNumber}>
              {employee.statistics?.completedTasks ?? '0'}
            </span>
            <p className={styles.statLabel}>{t('Completed Tasks')}</p>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statNumber}>
              {employee.statistics?.completionRate ?? '0%'}
            </span>
            <p className={styles.statLabel}>{t('Completion Rate')}</p>
          </div>
        </div>

        {/* Personal Information Section */}
        <div className={styles.infoSection}>
          <h3 className={styles.sectionTitle}>{t('Personal Information')}</h3>
          <div className={styles.infoGrid}>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Date of Birth')}</span>
              <span className={styles.infoValue}>
                {employee.dateOfBirth || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Address')}</span>
              <span className={styles.infoValue}>
                {employee.address || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Nationality')}</span>
              <span className={styles.infoValue}>
                {employee.nationality || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Religion')}</span>
              <span className={styles.infoValue}>
                {employee.religion || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Marital Status')}</span>
              <span className={styles.infoValue}>
                {employee.civilStatus || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Social Security Number')}</span>
              <span className={styles.infoValue}>
                {employee.socialSecurityNumber || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Health Insurance Number')}</span>
              <span className={styles.infoValue}>
                {employee.healthInsuranceNumber || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('LANR / LAZNR')}</span>
              <span className={styles.infoValue}>
                {employee.laNr_lazNr || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Upload Insurance Confirmation')}</span>
              {employee.insuranceConfirmation ? (
                <a
                  href={employee.insuranceConfirmation}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.documentLink}
                >
                  {t('View Document')}
                </a>
              ) : (
                <span className={styles.noDocument}>{t('No Document')}</span>
              )}
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Additional Health Insurance')}</span>
              <span className={styles.infoValue}>
                {employee.additionalHealthInsurance || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Tax ID')}</span>
              <span className={styles.infoValue}>
                {employee.taxId || t('Not Provided')}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Bank Account Details')}</span>
              <span className={styles.infoValue}>
                {employee.iban && employee.bic
                  ? `${employee.iban}, ${employee.bic}`
                  : t('Not Provided')}
              </span>
            </div>
          </div>
        </div>

        {/* Optional Information Section */}
        <div className={styles.infoSection}>
          <h3 className={styles.sectionTitle}>{t('Optional Information')}</h3>
          <div className={styles.infoGrid}>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Approval Certificate')}</span>
              {optionalFiles.approvalCertificate ? (
                <a
                  href={optionalFiles.approvalCertificate}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.documentLink}
                >
                  {t('View Document')}
                </a>
              ) : (
                <span className={styles.noDocument}>{t('No Document')}</span>
              )}
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Promotion Certificate')}</span>
              {optionalFiles.promotionCertificate ? (
                <a
                  href={optionalFiles.promotionCertificate}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.documentLink}
                >
                  {t('View Document')}
                </a>
              ) : (
                <span className={styles.noDocument}>{t('No Document')}</span>
              )}
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>
                {t('Last G42 – Medical Examination Result')}
              </span>
              {optionalFiles.g42MedicalExam ? (
                <a
                  href={optionalFiles.g42MedicalExam}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.documentLink}
                >
                  {t('View Document')}
                </a>
              ) : (
                <span className={styles.noDocument}>{t('No Document')}</span>
              )}
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('X-ray Certificate of Expertise')}</span>
              {optionalFiles.xrayCertificate ? (
                <a
                  href={optionalFiles.xrayCertificate}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.documentLink}
                >
                  {t('View Document')}
                </a>
              ) : (
                <span className={styles.noDocument}>{t('No Document')}</span>
              )}
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>{t('Vaccination Certificate')}</span>
              {optionalFiles.vaccinationCertificate ? (
                <a
                  href={optionalFiles.vaccinationCertificate}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.documentLink}
                >
                  {t('View Document')}
                </a>
              ) : (
                <span className={styles.noDocument}>{t('No Document')}</span>
              )}
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>
                {t('Document for Capital-forming Benefits')}
              </span>
              {optionalFiles.capitalBenefitsDocument ? (
                <a
                  href={optionalFiles.capitalBenefitsDocument}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.documentLink}
                >
                  {t('View Document')}
                </a>
              ) : (
                <span className={styles.noDocument}>{t('No Document')}</span>
              )}
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>
                {t('Proof of Additional Qualifications')}
              </span>
              {optionalFiles.additionalQualificationsProof ? (
                <a
                  href={optionalFiles.additionalQualificationsProof}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.documentLink}
                >
                  {t('View Document')}
                </a>
              ) : (
                <span className={styles.noDocument}>{t('No Document')}</span>
              )}
            </div>
          </div>
        </div>

        {/* Groups Section */}
        <div className={styles.groups}>
          {employee.groups && employee.groups.length > 0 ? (
            employee.groups.map((group, index) => (
              <div className={styles.groupCard} key={index}>
                <img
                  src="/assets/images/group.png"
                  alt={t('Group')}
                  className={styles.groupImage}
                />
                <div className={styles.groupInfo}>
                  <p className={styles.groupName}>{group.name}</p>
                  <span className={styles.groupTime}>{group.lastActive}</span>
                </div>
              </div>
            ))
          ) : (
            <p>{t('No Groups Assigned')}</p>
          )}
        </div>

        {/* Action Buttons */}
        <div className={styles.actionButtons}>
          <button className={styles.addButton} onClick={handleAddToGroup}>
            + {t('Add to Group')}
          </button>
          <button className={styles.removeButton} onClick={handleRemoveEmployee}>
            {t('Remove Employee from Mosaik')}
          </button>
        </div>
      </div>
      <MosaikFooter />

      {/* Add to Group Modal */}
      <Modal
        isOpen={isGroupModalOpen}
        onClose={() => setIsGroupModalOpen(false)}
        onConfirm={handleConfirmAddToGroup}
        title={t('Select Group')}
      >
        <select
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          className={styles.groupSelect}
        >
          <option value="">{t('Select a group')}</option>
          {groups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </select>
      </Modal>

      {/* Remove Employee Confirmation Modal */}
      <Modal
        isOpen={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
        onConfirm={handleConfirmRemoveEmployee}
        title={t('Confirm Removal')}
      >
        <p>{t('Are you sure you want to remove this employee from Mosaik?')}</p>
      </Modal>
    </div>
  );
};

export default EmployeeDetail;
