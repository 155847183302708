import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './RepairSent.module.css';
import checkmarkIcon from '../../assets/icons/check-icon.png'; // Assuming you have a checkmark icon
import moment from 'moment'; // Import moment for robust date parsing
import { dummyImage } from '../../constants';

const RepairSent = () => {
  const { t } = useTranslation(); // Destructure t for translations
  const navigate = useNavigate();
  const location = useLocation();
  const { repairIds } = location.state || {}; // Retrieve repairIds (array) from the navigation state
  const [repairDetails, setRepairDetails] = useState([]); // Store array of repair details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  // Retrieve the token from localStorage
  const token = localStorage.getItem('accessToken');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchRepairDetails = async () => {
      try {
        if (!repairIds || !Array.isArray(repairIds) || repairIds.length === 0) {
          throw new Error(t('No Repair IDs provided.'));
        }

        const requests = repairIds.map(id =>
          axios.get(`${apiUrl}/repairs/detail/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`, // Add token to Authorization header
            },
          })
        );

        const responses = await Promise.all(requests);
        const details = responses.map(response => response.data);
        setRepairDetails(details);
      } catch (err) {
        setError(t('Failed to fetch repair details. Please try again later.'));
      } finally {
        setLoading(false); // Set loading to false after the call
      }
    };

    if (repairIds && token) {
      fetchRepairDetails(); // Call the function to fetch data if repairIds and token exist
    } else {
      setError(t('Repair IDs or authentication token is missing.'));
      setLoading(false);
    }
  }, [repairIds, token, apiUrl]);

  const handleViewRepair = (repairId) => {
    navigate(`/repair-detail/${repairId}`); // Navigate to the repair detail page dynamically
  };

  const formatDate = (dateString) => {
    // Use moment.js to parse and format the date properly
    return moment(dateString).isValid() ? moment(dateString).format('MMMM Do, YYYY') : t('Invalid Date');
  };

  if (error) {
    return (
      <div className={styles.container}>
        <MosaikHeaderWithBack pageTitle={t('Repair Sent')} />
        <div className={styles.error}>
          <p>{error}</p>
        </div>
        <MosaikFooter />
      </div>
    ); // Display error message if there's an error
  }

  if (loading) {
    return (
      <div className={styles.container}>
        <MosaikHeaderWithBack pageTitle={t('Repair Sent')} />
        <div className={styles.loading}>
          <p>{t('Loading...')}</p>
        </div>
        <MosaikFooter />
      </div>
    ); // Loading state while the data is being fetched
  }

  return (
    <div className={styles.container}>
      <MosaikHeaderWithBack pageTitle={t('Repair Sent')} />
      <div className={styles.confirmation}>
        <img src={checkmarkIcon} alt={t('Success')} className={styles.checkmark} />
        <h1>{t('Repair Sent')}</h1>
        <p>{t('Your Repair request(s) have been successfully registered. Please find the repair details below.')}</p>
        {repairDetails.map(repair => (
          <div key={repair.id} className={styles.repairSummary}>
            {/* Product Image */}
            <img 
              src={
                repair.files && repair.files.length > 0 
                  ? `https://${repair.files[0].fileUrl}` 
                  : dummyImage
              } 
              alt={t('Product')} 
              className={styles.productImage} 
            />
            
            {/* Repair Details */}
            <div className={styles.repairDetails}>
              <h4>{t('Item Name')}: {repair.itemName || t('N/A')}</h4>
              <div className={styles.repairMeta}>
                <button className={styles.viewButton} onClick={() => handleViewRepair(repair.id)}>{t('View')}</button>
                <span className={styles.repairDate}>{formatDate(repair.createdAt)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <MosaikFooter />
    </div>
  );
};

export default RepairSent;
