import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
// import styles from "./AllMyTasks.module.css"; // Create and define appropriate styles
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import MosaikWelcomeScreen from "../MosaikWelcomeScreen";
import welcomeBgimage from "../../assets/images/tasks-bg.jpg";
import welcomeIcon from "../../assets/images/tasks-iconinverted.png";
import TaskList from "./TaskList"; // Reusable TaskList component
import { AiOutlinePlus } from "react-icons/ai";
import styles from "./MosaikTasks.module.css";

const AllMyTasks = () => {
  const navigate = useNavigate();

  // State Management
  const [myTasks, setMyTasks] = useState([]);
  const [myTasksWithDetails, setMyTasksWithDetails] = useState([]);
  const [filteredMyTasks, setFilteredMyTasks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(""); // No default date filter
  const [sortOption, setSortOption] = useState("Date");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 10; // Define how many tasks per page
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Retrieve token and user info from localStorage
  const token = localStorage.getItem("accessToken");
  const storedUser = JSON.parse(localStorage.getItem("userInfo"));
  const apiUrl = process.env.REACT_APP_API_URL;

  /**
   * Fetch "My Tasks" from the API.
   */
  const fetchMyTasks = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const tasksResponse = await axios.get(`${apiUrl}/tasks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const allTasks = tasksResponse.data;

      console.log("Fetched All Tasks:", allTasks);

      // Filter tasks assigned to the current user
      const myFilteredTasks = allTasks.filter(
        (task) => task.assignee && task.assignee.id === storedUser.id
      );

      setMyTasks(myFilteredTasks);
      console.log("My Tasks:", myFilteredTasks);
    } catch (error) {
      console.error("Error fetching my tasks:", error.message);
      setError("Failed to fetch your tasks. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Fetch assigner details for each task.
   */
  const fetchAssigners = async () => {
    try {
      const updatedMyTasks = await Promise.all(
        myTasks.map(async (task) => {
          if (task.assigner && task.assigner.id) {
            const userResponse = await axios.get(`${apiUrl}/users/${task.assigner.id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            return { ...task, assignerDetails: userResponse.data };
          }
          return { ...task, assignerDetails: null };
        })
      );
      setMyTasksWithDetails(updatedMyTasks);
      console.log("My Tasks with Details:", updatedMyTasks);
    } catch (error) {
      console.error("Error fetching assigner details:", error);
      setError("Failed to fetch assigner details. Please try again later.");
    }
  };

  /**
   * Fetch tasks on component mount.
   */
  useEffect(() => {
    fetchMyTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch assigner details whenever myTasks change.
   */
  useEffect(() => {
    if (myTasks.length > 0) {
      fetchAssigners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTasks]);

  /**
   * Debounced search to improve performance.
   */
  const debouncedSearch = debounce((value) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to first page on new search
  }, 300); // 300ms delay

  /**
   * Handle search input change event.
   * @param {object} event - The event object.
   */
  const handleSearchInputChange = (event) => {
    debouncedSearch(event.target.value);
  };

  /**
   * Filter and sort tasks based on selected date, sort option, and search term.
   */
  useEffect(() => {
    const filterAndSortTasks = () => {
      let filtered = myTasksWithDetails;

      // Apply Date Filter if selectedDate is set
      if (selectedDate) {
        filtered = filtered.filter(
          (task) => moment(task.createdAt).format("YYYY-MM-DD") === selectedDate
        );
      }

      // Apply Search Filter
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        filtered = filtered.filter((task) => {
          const taskNameMatch = task.name
            ? task.name.toLowerCase().includes(searchLower)
            : false;

          const assignerName = task.assignerDetails
            ? `${task.assignerDetails.firstName} ${task.assignerDetails.lastName}`.toLowerCase()
            : "";
          const assignerNameMatch = assignerName.includes(searchLower);

          return taskNameMatch || assignerNameMatch;
        });
      }

      // Apply Sorting
      filtered = filtered.sort((a, b) => {
        const aName = a.name || "";
        const bName = b.name || "";
        const aPriority = a.priority || "";
        const bPriority = b.priority || "";
        const aCreatedAt = a.createdAt ? new Date(a.createdAt) : new Date(0);
        const bCreatedAt = b.createdAt ? new Date(b.createdAt) : new Date(0);

        switch (sortOption) {
          case "Alphabetical":
            return aName.localeCompare(bName);
          case "Priority":
            return aPriority.localeCompare(bPriority);
          case "Date":
          default:
            return aCreatedAt - bCreatedAt;
        }
      });

      setFilteredMyTasks(filtered);
      setCurrentPage(1); // Reset to first page on new filter/sort
      console.log("Filtered & Sorted My Tasks:", filtered);
    };

    filterAndSortTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, sortOption, searchTerm, myTasksWithDetails]);

  /**
   * Calculate pagination details.
   */
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = filteredMyTasks.slice(indexOfFirstTask, indexOfLastTask);
  const totalPages = Math.ceil(filteredMyTasks.length / tasksPerPage);

  return (
    <div>
      <MosaikHeaderWithBack pageTitle="All My Tasks" />
      {/* <MosaikWelcomeScreen
        welcomeTitle="All My Tasks"
        welcomeDescription="Here you can view all tasks assigned to you."
        welcomeIcon={welcomeIcon}
        welcomeBgimage={welcomeBgimage}
      /> */}
      <div className={styles.allTaskcontainer}>

  
        <div className={styles.sortAndFilterInTaskPage}>
      
          <input
            type="text"
            placeholder="Search by task name or assigner..."
            onChange={handleSearchInputChange}
            className={styles.searchInput}
          />
           <button
            onClick={() => navigate("/assign-task")}
            className={styles.iconButton}
          >
            <AiOutlinePlus />
          </button>
        </div>

        {/* Loading and Error Messages */}
        {isLoading && <p>Loading your tasks...</p>}
        {error && <div className={styles.errorMessage}>{error}</div>}

        {/* Task List with Pagination */}
        {!isLoading && !error && (
          <TaskList
            tasks={currentTasks}
            type="my"
            // handleStatusToggle={handleStatusToggle}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
      <MosaikFooter />
    </div>
  );
};

export default AllMyTasks;
