// src/context/NotificationContext.js

import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import alertSound from '../assets/sounds/alert.wav';
import axios from 'axios';
import { useAlertSettings } from './AlertSettingsContext';
import profilePicPlaceholder from '../assets/images/placeholder.webp';
import useNotificationPermission from '../hooks/useNotificationPermission';
import { showNotification } from '../utils/notificationUtils';
import { getProfilePictureUrl } from '../utils/getProfileUrl';
import { v4 as uuidv4 } from 'uuid'; // Ensure uuid is installed

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  console.log('NotificationProvider is rendering'); // Debugging statement

  // Access soundEnabled from AlertSettingsContext with error handling
  let soundEnabled = false;
  try {
    const alertSettings = useAlertSettings();
    soundEnabled = alertSettings.soundEnabled;
  } catch (error) {
    console.error('Error accessing AlertSettingsContext:', error);
    // Optionally, set a default value or handle the error as needed
  }

  const [alerts, setAlerts] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('accessToken');

  // Fetch logged-in user's info from localStorage
  const storedUserInfo = localStorage.getItem('userInfo');
  const userInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;

  // Cache to store assigner information to avoid redundant requests
  const assignerCache = useRef({}); // Use useRef for persistent cache across renders

  // Ref to track if it's the initial load
  const isInitialLoad = useRef(true);

  // Refs to store interval and timeout IDs
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);

  // Request notification permission when the component mounts
  useNotificationPermission();

  // Function to play alert sound
  const playAlertSound = useCallback(() => {
    if (!soundEnabled) return; // Do not play sound if disabled
    const audio = new Audio(alertSound);
    audio.play().catch((error) => console.error('Error playing sound:', error));
  }, [soundEnabled]);

  // Function to add an alert with grouping logic
  const addAlert = useCallback(
    (newAlert) => {
      console.log('addAlert called with:', newAlert); // Debugging statement
      setAlerts((prevAlerts) => {
        const timeWindow = 5 * 60 * 1000; // 5 minutes
        const currentTime = Date.now();

        // Check if an alert with the same link exists within the time window
        const existingAlertIndex = prevAlerts.findIndex(
          (alert) =>
            alert.link === newAlert.link && currentTime - alert.timestamp <= timeWindow
        );

        if (existingAlertIndex !== -1) {
          // Update existing alert by incrementing count
          const updatedAlerts = [...prevAlerts];
          const existingAlert = updatedAlerts[existingAlertIndex];
          existingAlert.count += 1;
          existingAlert.action = `You have ${existingAlert.count} new messages in ${existingAlert.chatName}.`;
          existingAlert.time = new Date().toLocaleTimeString(); // Update time to latest message
          existingAlert.timestamp = currentTime; // Update timestamp for grouping

          // Trigger browser notification and sound if it's a chat alert
          if (newAlert.type === 'chat') {
            showNotification(existingAlert.user, {
              body: existingAlert.action,
              icon: existingAlert.image,
              data: { link: existingAlert.link },
            });

            if (soundEnabled) {
              playAlertSound();
            }
          }

          return updatedAlerts;
        }

        // If no existing alert, create a new one
        const newAlertWithDefaults = {
          id: uuidv4(), // Unique identifier
          ...newAlert,
          count: 1, // Initialize count
          time: new Date().toLocaleTimeString(),
          timestamp: currentTime, // Store timestamp for grouping
        };

        // Trigger browser notification and sound if it's a chat alert
        if (newAlert.type === 'chat') {
          showNotification(newAlert.user, {
            body: newAlert.action,
            icon: newAlert.image,
            data: { link: newAlert.link },
          });

          if (soundEnabled) {
            playAlertSound();
          }
        }

        return [...prevAlerts, newAlertWithDefaults];
      });
    },
    [playAlertSound, soundEnabled]
  );

  // Function to remove an alert
  const removeAlert = useCallback((id) => {
    console.log('removeAlert called with id:', id); // Debugging statement
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  }, []);

  // Function to clear all alerts
  const clearAlerts = useCallback(() => {
    console.log('clearAlerts called'); // Debugging statement
    setAlerts([]);
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/notifications?where[userId]=${userInfo?.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const notificationsData = response.data;

        // Fetch assigner details for each notification
        const alertsWithAssignerInfo = await Promise.all(
          notificationsData.map(async (notification) => {
            const notificationData = notification.notification || {};

            // Fetch assigner info
            let assignerFirstName = 'Someone';
            let assignerLastName = '';
            let assignerProfilePhoto = profilePicPlaceholder;

            if (notificationData.assignerId) {
              if (assignerCache.current[notificationData.assignerId]) {
                // Use cached assigner info
                const assigner = assignerCache.current[notificationData.assignerId];
                assignerFirstName = assigner.firstName;
                assignerLastName = assigner.lastName;
                assignerProfilePhoto = assigner.profilePhoto;
              } else {
                try {
                  const assignerResponse = await axios.get(
                    `${apiUrl}/users/${notificationData.assignerId}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  const assigner = assignerResponse.data;
                  assignerFirstName = assigner.firstName || 'Someone';
                  assignerLastName = assigner.lastName || '';
                  assignerProfilePhoto = await getProfilePictureUrl(assigner);
                  // Cache the assigner info
                  assignerCache.current[notificationData.assignerId] = {
                    firstName: assignerFirstName,
                    lastName: assignerLastName,
                    profilePhoto: assignerProfilePhoto,
                  };
                } catch (error) {
                  console.error('Error fetching assigner info:', error);
                }
              }
            } else {
              console.warn('Assigner ID is missing in notification data');
            }

            // Construct the action message based on notification type
            const assignerName = `${assignerFirstName} ${assignerLastName}`.trim();
            let actionMessage = '';
            if (notificationData.type === 'task_assignment') {
              actionMessage =
                notificationData.message || `${assignerName} assigned you a task.`;
            } else {
              actionMessage = notificationData.message || 'You have a new notification.';
            }

            return {
              id: notification.id,
              user: assignerName,
              action: actionMessage,
              time: new Date(notification.createdAt).toLocaleString(),
              image: assignerProfilePhoto,
              link: `/task-detail/${notificationData.taskId}`,
              type: 'task', // Specify the type of alert
            };
          })
        );

        if (isMounted) {
          if (isInitialLoad.current) {
            // Initial load; set alerts without triggering notifications
            alertsWithAssignerInfo.forEach((alert) => {
              addAlert(alert); // Use addAlert to ensure grouping
            });
            isInitialLoad.current = false;
          } else {
            // Check for new notifications
            const newAlerts = alertsWithAssignerInfo.filter(
              (newAlert) => !alerts.some((alert) => alert.id === newAlert.id)
            );

            if (newAlerts.length > 0) {
              // New notifications received
              newAlerts.forEach((newAlert) => {
                // Display browser notification
                showNotification(newAlert.user, {
                  body: newAlert.action,
                  icon: newAlert.image,
                  data: { link: newAlert.link },
                });

                // Dispatch grouped alert
                addAlert(newAlert);
              });

              if (soundEnabled) {
                playAlertSound();
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    const delay = 15000; // 15 seconds in milliseconds

    if (userInfo && !intervalRef.current && !timeoutRef.current) {
      // Delay the initial call by 15 seconds
      timeoutRef.current = setTimeout(() => {
        fetchNotifications();

        // After the initial delay, set up the interval to fetch every 15 seconds
        intervalRef.current = setInterval(fetchNotifications, delay);
        timeoutRef.current = null;
      }, delay); // 15-second delay
    }

    return () => {
      isMounted = false;
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [apiUrl, token, userInfo, soundEnabled, addAlert, alerts]); // Added 'alerts' to dependencies

  return (
    <NotificationContext.Provider value={{ alerts, addAlert, removeAlert, clearAlerts }}>
      {children}
    </NotificationContext.Provider>
  );
};
