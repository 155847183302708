// src/components/tasks/AssignTask.js

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './AssignTask.module.css';
import { useTranslation } from 'react-i18next';
import profilePicPlaceholder from '../../assets/images/placeholder.webp';
import spinnerGif from '../../assets/images/spinner.gif';
import attachmentIcon1 from '../../assets/images/attachment-1.png';
import { AiOutlinePlus } from 'react-icons/ai';
import { uploadFile } from '../../services/mosaikUploadService';
import { getProfilePictureUrl } from '../../utils/getProfileUrl';

const AssignTask = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [selectedMember, setSelectedMember] = useState(null);
  const [newTask, setNewTask] = useState({
    name: '',
    description: '',
    category: '',
    checklist: [],
  });
  const [taskFrequency, setTaskFrequency] = useState('none');
  const [taskDate, setTaskDate] = useState('');
  const [repeatDays, setRepeatDays] = useState([]);
  const [priority, setPriority] = useState('medium');
  const [isTemplate, setIsTemplate] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);
  const [enrichedTeamMembers, setEnrichedTeamMembers] = useState([]);
  const [taskCategories, setTaskCategories] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('accessToken');

  const userCache = useRef({});
  const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(true);

  // State variables for modal and file upload
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  // Fetch the current logged-in user's info from localStorage
  const user = useMemo(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      return JSON.parse(storedUserInfo);
    } else {
      console.error('User info not found in localStorage');
      return null;
    }
  }, []);

  // Fetch team members and task categories
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        setIsLoadingTeamMembers(true);
        const organisationId =
          user?.organisationRoles?.find((role) => role.isLoggedIn)?.organisationId;
        if (!organisationId) {
          throw new Error('Organisation ID is missing');
        }
        const response = await axios.get(
          `${apiUrl}/users?where[userOrganisationRoles][some][organisation][id]=${organisationId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeamMembers(response.data);
      } catch (error) {
        console.error('Error fetching team members:', error);
      } finally {
        setIsLoadingTeamMembers(false);
      }
    };

    const fetchTaskCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/taskCategories`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTaskCategories(response.data);
      } catch (error) {
        console.error('Error fetching task categories:', error);
      }
    };

    if (user) {
      fetchTeamMembers();
      fetchTaskCategories();
    }
  }, [apiUrl, token, user]);

  // Memoize fetchUserDetails to prevent unnecessary re-fetching
  const fetchUserDetails = useCallback(
    async (userId) => {
      if (userCache.current[userId]) {
        return userCache.current[userId];
      }
      try {
        const userResponse = await axios.get(`${apiUrl}/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const userData = userResponse.data;

        // const profilePictureUrl = userData.profilePhotoFile?.fileUrl
        //   ? userData.profilePhotoFile.fileUrl.startsWith('https')
        //     ? userData.profilePhotoFile.fileUrl
        //     : `https://${userData.profilePhotoFile.fileUrl}`
        //   : profilePicPlaceholder;
        let profilePictureUrl =await getProfilePictureUrl(userData)

        const enrichedUser = { ...userData, profilePicture: profilePictureUrl };
        userCache.current[userId] = enrichedUser;

        return enrichedUser;
      } catch (error) {
        console.error('Error fetching user details:', error.message);
        return { firstName: 'Unknown', lastName: '', profilePicture: profilePicPlaceholder };
      }
    },
    [apiUrl, token]
  );

  // Enrich team members with profile pictures
  useEffect(() => {
    const fetchEnrichedTeamMembers = async () => {
      try {
        const enriched = await Promise.all(
          teamMembers.map(async (member) => {
            if (member.id) {
              const userDetails = await fetchUserDetails(member.id);
              return { ...member, profilePicture: userDetails.profilePicture };
            }
            return { ...member, profilePicture: profilePicPlaceholder };
          })
        );
        setEnrichedTeamMembers(enriched);
      } catch (error) {
        console.error('Error enriching team members:', error.message);
      }
    };

    if (teamMembers.length > 0) {
      fetchEnrichedTeamMembers();
    }
  }, [teamMembers, fetchUserDetails]);

  // Handle Member Selection
  const handleMemberSelect = (id) => {
    setSelectedMember(id);
  };

  // Navigate to Next Step
  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  // Navigate to Previous Step
  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  // Handle Input Changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask((prevTask) => ({ ...prevTask, [name]: value }));
  };

  // Add Checklist Item
  const handleAddChecklist = () => {
    setNewTask((prevTask) => ({ ...prevTask, checklist: [...prevTask.checklist, ''] }));
  };

  // Handle Checklist Item Change
  const handleChecklistChange = (index, value) => {
    setNewTask((prevTask) => {
      const updatedChecklist = [...prevTask.checklist];
      updatedChecklist[index] = value;
      return { ...prevTask, checklist: updatedChecklist };
    });
  };

  // Toggle Template Saving
  const handleSaveTemplate = () => {
    setIsTemplate((prev) => !prev);
  };

  // Handle Task Frequency Change
  const handleFrequencyChange = (e) => {
    setTaskFrequency(e.target.value);
  };

  // Handle Task Date Change
  const handleDateChange = (e) => {
    setTaskDate(e.target.value);
  };

  // Toggle Repeat Days
  const handleRepeatToggle = (day) => {
    setRepeatDays((prevDays) =>
      prevDays.includes(day) ? prevDays.filter((d) => d !== day) : [...prevDays, day]
    );
  };

  // Handle Priority Change
  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
  };

  // Handle File Upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);

      // Check if the file is an image
      if (file.type.startsWith('image/')) {
        const objectUrl = URL.createObjectURL(file);
        setPreviewUrl(objectUrl);
      } else {
        // If not an image, set previewUrl to null or a default attachment icon
        setPreviewUrl(null);
      }
    }
  };

  // Clean up object URL to prevent memory leaks
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  // Handle Assign Task with File Upload
  const handleAssignTask = async () => {
    try {

      if (!selectedMember) {
        throw new Error('No team member selected');
      }

      let fileId = null;

      // Upload the file if one is selected
      if (selectedFile) {
        setIsUploadingFile(true);
        const uploadResponse = await uploadFile(selectedFile, "feedback", selectedFile.name);
        if (uploadResponse && uploadResponse.id) {
          fileId = uploadResponse.id; // Extract only the file ID
        } else {
          throw new Error('File upload failed. Please try again.');
        }
        setIsUploadingFile(false);
      }
      const getCurrentDate = () => {
        const date = new Date();
        return date.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
      };
      // Create task payload
      const taskPayload = {
        name: newTask.name || '',
        description: newTask.description || '',
        priority: priority.toLowerCase() || 'normal',
        recurrence: taskFrequency,
        endDate: taskDate || getCurrentDate(),
        assignee: { id: selectedMember },
        checklistTemplate:
          newTask.checklist.length > 0
            ? {
                items: newTask.checklist.map((item) => ({
                  itemName: item || 'Unnamed Item',
                  checked: true,
                })),
              }
            : undefined,
        ...(newTask.category ? { taskCategory: { id: newTask.category } } : {}),
        recurrenceCustomDays: repeatDays.length > 0 ? repeatDays : undefined,
        location: 'Test Location',
        isTemplate: isTemplate,
        ...(fileId && { taskAttachmentFiles: { connect: [{ id: fileId }] } }),
      };

      const taskResponse = await axios.post(`${apiUrl}/tasks/create`, taskPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const assignedTask = taskResponse.data;

      // Create notification payload with custom message
      const notificationPayload = {
        notification: {
          type: 'task_assignment',
          taskId: assignedTask.id,
          assignerId: user.id, // Include the assigner's ID
          message: `assigned you a task.`,
        },
        userId: selectedMember, // The ID of the user receiving the notification
        organisationId:
          assignedTask.organisation?.id ||
          user.organisationRoles?.find((role) => role.isLoggedIn)?.organisationId,
      };

      await axios.post(`${apiUrl}/notifications`, notificationPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert('Task assigned and notification sent successfully!');
      navigate('/mosaik-tasks');
    } catch (error) {
      console.error('Error assigning task or sending notification:', error.message || error);
      alert(error.message || 'Failed to assign task or send notification. Please try again.');
      setIsUploadingFile(false);
    }
  };

  // Functions for handling the Add Category modal
  const openAddCategoryModal = () => {
    setShowAddCategoryModal(true);
  };

  const closeAddCategoryModal = () => {
    setShowAddCategoryModal(false);
    setNewCategoryName('');
  };

  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) {
      alert('Category name cannot be empty.');
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/taskCategories`,
        { name: newCategoryName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Assuming the API returns the newly created category
      setTaskCategories((prevCategories) => [...prevCategories, response.data]);

      // Optionally, set the new category as selected
      setNewTask((prevTask) => ({ ...prevTask, category: response.data.id }));

      // Close the modal and reset the input
      closeAddCategoryModal();
    } catch (error) {
      console.error('Error adding new category:', error);
      alert('Failed to add new category. Please try again.');
    }
  };

  // Filter team members based on the search term
  const filteredTeamMembers = enrichedTeamMembers.filter(
    (member) =>
      (member.firstName && member.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (member.lastName && member.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
  );


  return (
    <div className={styles.container}>
      <MosaikHeaderWithBack pageTitle={`Step ${step}/3`} />
      <div className={styles.AssignTaskMaster}>
        {step === 1 && (
          <div>
            <h2>Assign Task</h2>
            <input
              type="text"
              placeholder="Name of the team member or ID..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchBar}
            />
            <div className={styles.teamList}>
              {isLoadingTeamMembers ? (
                <div className={styles.loadingSpinner}>
                  <img src={spinnerGif} alt="Loading..." width="90px" />
                </div>
              ) : filteredTeamMembers.length > 0 ? (
                filteredTeamMembers.map((member) => (
                  <div
                    key={member.id}
                    className={`${styles.teamMember} ${
                      selectedMember === member.id ? styles.selected : ''
                    }`}
                    onClick={() => handleMemberSelect(member.id)}
                  >
                    <img
                      src={member.profilePicture || profilePicPlaceholder}
                      alt={`${member.firstName} ${member.lastName}`}
                      className={styles.memberImage}
                    />
                    <div className={styles.memberDetails}>
                      <h4>
                        {member.firstName} {member.lastName}
                      </h4>
                      <p>{member.email}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No team members found for the search criteria.</p>
              )}
            </div>
            <button
              className={styles.assignButton}
              onClick={handleNext}
              disabled={!selectedMember}
            >
              Assign Task
            </button>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2>Task Details</h2>
            <button className={styles.backButton} onClick={handlePrevious}>
              Back
            </button>
            <div className={styles.createTaskSection}>
              {/* Task Name Input */}
              <input
                type="text"
                name="name"
                required={true}
                value={newTask.name}
                onChange={handleInputChange}
                placeholder="Name of the Task"
                className={styles.inputField}
              />

              {/* Task Description Textarea */}
              <textarea
                name="description"
                value={newTask.description}
                onChange={handleInputChange}
                placeholder="Task's Description"
                className={styles.textAreaField}
              ></textarea>

              {/* Task Category Section */}
              <div className={styles.categorySection}>
                <h4>Task's Category</h4>
        
                <AiOutlinePlus className={styles.iconButton} onClick={openAddCategoryModal} />
              </div>
              {taskCategories.map((category) => (
                  <button
                    key={category.id}
                    className={`${styles.taskButton} ${
                      newTask.category === category.id ? styles.selectedCategory : ''
                    }`}
                    onClick={() => setNewTask({ ...newTask, category: category.id })}
                  >
                    {category.name}
                  </button>
                ))}
              {/* Save as Template Button */}
              <button className={styles.saveTemplateButton} onClick={handleSaveTemplate}>
                Save as Template
              </button>

              {/* Checklist Section */}
              <div className={styles.checklistSection}>
                <h4>Task's Checklists</h4>
                {newTask.checklist.map((item, index) => (
                  <input
                    key={index}
                    type="text"
                    value={item}
                    onChange={(e) => handleChecklistChange(index, e.target.value)}
                    placeholder="Add a checklist item"
                    className={styles.inputField}
                  />
                ))}
                <button onClick={handleAddChecklist} className={styles.addChecklistButton}>
                  + Add a new checklist
                </button>
              </div>

              {/* Updated File Upload Section */}
              <div className={styles.fileUploadSection}>
                <h4>Attach a File (Optional)</h4>
                
                {/* Image Preview or Attachment Icon */}
                <div className={styles.div6}>
                  <img 
                    src={previewUrl || attachmentIcon1} 
                    alt={t('Attachment Preview')} 
                    className={styles.img} 
                  />
                  <input 
                    type="file" 
                    className={styles.uploadInput} 
                    onChange={handleFileUpload} 
                  />
                </div>

                {/* Display Selected File Name if Not an Image */}
                {selectedFile && !previewUrl && (
                  <p className={styles.selectedFileName}>Selected File: {selectedFile.name}</p>
                )}
              </div>
            </div>

            <button
              className={styles.assignButton}
              onClick={handleNext}
              disabled={isUploadingFile}
            >
              {isUploadingFile ? 'Uploading...' : 'Assign Task'}
            </button>
          </div>
        )}

        {step === 3 && (
          <div>
            <h2>Task Routine</h2>
            <button className={styles.backButton} onClick={handlePrevious}>
              Back
            </button>
            <div className={styles.frequencySection}>
              <label>Task Frequency</label>
              <select
                value={taskFrequency}
                onChange={handleFrequencyChange}
                className={styles.inputField}
              >
                <option value="none">One-time task</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="yearly">Yearly</option>
                <option value="custom">Custom</option>
              </select>
            </div>

            <div className={styles.dateSection}>
              <label>Task's Date</label>
              <input
                type="date"
                value={taskDate}
                onChange={handleDateChange}
                className={styles.inputField}
              />
            </div>
            {taskFrequency === 'weekly' && (
              <div className={styles.repeatSection}>
                <label>Repeat</label>
                <div className={styles.repeatDays}>
                  {['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].map((day, index) => (
                    <button
                      key={index}
                      className={`${styles.repeatDayButton} ${
                        repeatDays.includes(day) ? styles.activeDay : ''
                      }`}
                      onClick={() => handleRepeatToggle(day)}
                    >
                      {day}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className={styles.prioritySection}>
              <label>Priority</label>
              <select
                value={priority}
                onChange={handlePriorityChange}
                className={styles.inputField}
              >
                <option value="low">Low</option>
                <option value="medium">Normal</option>
                <option value="urgent">Urgent</option>
              </select>
            </div>

            <button className={styles.assignButton} onClick={handleAssignTask}>
              Assign Task
            </button>
          </div>
        )}
      </div>

      {/* Modal for adding a new category */}
      {showAddCategoryModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <h3>Add New Category</h3>
            <input
              type="text"
              placeholder="Category Name"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              className={styles.modalInput}
            />
            <div className={styles.modalButtons}>
              <button onClick={handleAddCategory} className={styles.saveButton}>
                Save
              </button>
              <button onClick={closeAddCategoryModal} className={styles.cancelButton}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <MosaikFooter />
    </div>
  );
};

export default AssignTask;
