// src/components/chats/ChatItem.js

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Badge,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  PushPin as PushPinIcon,
  PushPinOutlined as PushPinOutlinedIcon,
  VolumeOff as VolumeOffIcon,
  VolumeUp as VolumeUpIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import profilePlaceholder from '../../assets/images/placeholder.webp';
import { format } from 'date-fns';

/**
 * ChatItem Component
 *
 * Props:
 * - id: Unique identifier for the chat
 * - name: Display name of the chat (user's full name or group name)
 * - avatar: URL of the chat avatar
 * - lastMessage: The content of the last message in the chat
 * - lastMessageDate: ISO string of the last message's timestamp
 * - unreadCount: Number of unread messages
 * - isPinned: Boolean indicating if the chat is pinned
 * - isMuted: Boolean indicating if the chat is muted
 * - type: Type of the chat ('group' or 'direct')
 * - onMute: Function to toggle mute
 * - onPin: Function to toggle pin
 * - onDelete: Function to delete the chat
 * - onClick: Function to navigate to the chat details
 */
function ChatItem({
  id,
  name,
  avatar,
  lastMessage,
  lastMessageDate,
  unreadCount,
  isPinned,
  isMuted,
  type,
  onMute,
  onPin,
  onDelete,
  onClick,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Mobile if screen width <= 'sm' breakpoint (600px)
  const [showActions, setShowActions] = useState(false); // For mobile long press
  const [isHovered, setIsHovered] = useState(false); // For desktop hover
  const touchTimeout = useRef(null);
  const containerRef = useRef(null);

  // Utility function to truncate text to a specified length
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.slice(0, maxLength - 3)}...` : text;
  };

  // Truncated name with a maximum of 13 characters
  const displayName = truncateText(name, 13);

  // Format the timestamp to display date (e.g., Sep 15, 2023) and time (e.g., 3:45 PM)
  const formattedDate = lastMessageDate
    ? format(new Date(lastMessageDate), 'MMM d, yyyy')
    : '';
  const formattedTime = lastMessageDate
    ? format(new Date(lastMessageDate), 'h:mm a')
    : '';

  // Handle touch start for long press (mobile)
  const handleTouchStart = () => {
    if (isMobile) {
      touchTimeout.current = setTimeout(() => {
        setShowActions(true);
      }, 500); // 500ms for long press
    }
  };

  // Handle touch end to clear long press timeout
  const handleTouchEnd = () => {
    if (isMobile) {
      if (touchTimeout.current) {
        clearTimeout(touchTimeout.current);
      }
    }
  };

  // Close action buttons when clicking outside (for mobile)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowActions(false);
      }
    };

    if (isMobile && showActions) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    }

    return () => {
      if (isMobile && showActions) {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
      }
    };
  }, [isMobile, showActions]);

  // Tooltip title: Show full name if truncated
  const tooltipTitle = name.length > 30 ? name : '';

  return (
    <ListItem
      button
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      ref={containerRef}
      sx={{
        bgcolor: type === 'group' ? 'rgba(9, 79, 84, 0.1)' : 'inherit', // Light green background for group chats
        '&:hover': {
          bgcolor: type === 'group'
            ? 'rgba(76, 175, 80, 0.15)'
            : 'rgba(0, 0, 0, 0.04)', // Slightly darker on hover
        },
        borderRadius: '4px', // Rounded border radius
        boxShadow: '0px 1px 3px rgba(0,0,0,0.1)', // Subtle shadow
        padding: '8px 16px',
        marginBottom: '10px', // Spacing between chat items
        transition: 'background-color 0.3s, box-shadow 0.3s',
        position: 'relative',
        userSelect: 'none', // Prevent text selection on long press
      }}
    >
      {/* Avatar Section */}
      <ListItemAvatar>
        <Avatar
          src={avatar || profilePlaceholder}
          alt={name}
          sx={{ width: 56, height: 56, mr: 2 }}
        />
      </ListItemAvatar>

      {/* Chat Details Section */}
      <ListItemText
        primary={
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {/* Chat Name and Pin Icon */}
            <Box display="flex" alignItems="center">
              <Tooltip title={tooltipTitle} arrow>
                <Typography
                  variant="subtitle1"
                  component="span"
                  sx={{ fontWeight: 'bold', mr: 1 }} // 10px spacing between avatar and name
                >
                  {displayName}
                </Typography>
              </Tooltip>
              {isPinned && (
                <PushPinIcon
                  fontSize="small"
                  color="action"
                  titleAccess={t('Pinned')}
                />
              )}
            </Box>
            {/* Last Message Time */}
            {formattedTime && (
              <Typography variant="caption" color="textSecondary">
                {formattedTime}
              </Typography>
            )}
          </Box>
        }
        secondary={
          <Box display="flex" flexDirection="column">
            {/* Last Message Content */}
            <Typography
              variant="body2"
              color="text.secondary"
              noWrap
              sx={{ maxWidth: '70%' }}
            >
              {lastMessage ? lastMessage : t('No messages yet.')}
            </Typography>
            {/* Last Message Date (Only for Group Chats) */}
            {type === 'group' && formattedDate && (
              <Typography variant="caption" color="textSecondary">
                {formattedDate}
              </Typography>
            )}
          </Box>
        }
      />

      {/* Unread Messages Badge */}
      {unreadCount > 0 && (
        <Badge
          badgeContent={unreadCount}
          color="error"
          sx={{ position: 'absolute', right: 5, top: 5 }}
        />
      )}

      {/* Action Buttons */}
      {(isHovered && !isMobile) || showActions ? (
        <Box
          position={isMobile ? 'absolute' : 'absolute'}
          right={isMobile ? (showActions ? '16px' : '-100px') : '16px'}
          top="50%"
          transform={isMobile ? 'translateY(-50%)' : 'translateY(-50%)'}
          display="flex"
          gap={1}
          sx={{
            transition: 'right 0.3s ease-in-out',
            // For mobile, slide in from the right
            ...(isMobile && {
              right: showActions ? '16px' : '-100px',
            }),
          }}
        >
          {/* Pin/Unpin Button */}
          <IconButton
            edge="end"
            aria-label={isPinned ? t('Unpin') : t('Pin')}
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering onClick for the ListItem
              onPin(id);
              if (isMobile) setShowActions(false); // Hide actions after click on mobile
            }}
            size="small"
          >
            {isPinned ? (
              <PushPinIcon color="primary" fontSize="small" />
            ) : (
              <PushPinOutlinedIcon fontSize="small" />
            )}
          </IconButton>

          {/* Mute/Unmute Button */}
          <IconButton
            edge="end"
            aria-label={isMuted ? t('Unmute') : t('Mute')}
            onClick={(e) => {
              e.stopPropagation();
              onMute(id);
              if (isMobile) setShowActions(false); // Hide actions after click on mobile
            }}
            size="small"
          >
            {isMuted ? (
              <VolumeOffIcon color="secondary" fontSize="small" />
            ) : (
              <VolumeUpIcon fontSize="small" />
            )}
          </IconButton>

          {/* Delete Button */}
          <IconButton
            edge="end"
            aria-label={t('Delete')}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(id);
              if (isMobile) setShowActions(false); // Hide actions after click on mobile
            }}
            size="small"
          >
            <DeleteIcon color="error" fontSize="small" />
          </IconButton>
        </Box>
      ) : null}
    </ListItem>
  );
}

export default ChatItem;
