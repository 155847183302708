// src/components/alerts/AlertsSettings.js
import React, { useState } from 'react';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import { useAlertSettings } from '../../context/AlertSettingsContext'; // Use the alert settings context
import styles from './AlertsSettings.module.css';
import { useTranslation } from 'react-i18next';

const AlertsSettings = () => {
  const { t } = useTranslation();
  const { soundEnabled, toggleSound } = useAlertSettings(); // Access sound settings from context
  const [quietDays, setQuietDays] = useState([]); // State for selected quiet days

  const handleDayToggle = (day) => {
    setQuietDays(prevDays =>
      prevDays.includes(day) ? prevDays.filter(d => d !== day) : [...prevDays, day]
    );
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Alerts Settings')} />
      <div className={styles.settingsContainer}>
        {/* Allow Push Notifications Section */}
        <section className={styles.settingsSection}>
          <h4>{t('Allow Push Notifications')}</h4>
          <div className={styles.toggleRow}>
            <label>{t('All')}</label>
            <input type="checkbox" defaultChecked />
          </div>
          {[
            t('Chats'),
            t('Boards'),
            t('Library'),
            t('Instructions'),
            t('Orders'),
            t('Feedback'),
            t('Tasks'),
            t('Calendar'),
          ].map((item, index) => (
            <div className={styles.toggleRow} key={index}>
              <label>{item}</label>
              <input type="checkbox" defaultChecked />
            </div>
          ))}
        </section>

        {/* Sound Settings Section */}
        <section className={styles.settingsSection}>
          <h4>{t('Notification Sound')}</h4>
          <div className={styles.toggleRow}>
            <label>{t('Enable Notification Sound')}</label>
            <input type="checkbox" checked={soundEnabled} onChange={toggleSound} />
          </div>
        </section>

        {/* Block Push Notifications Section */}
        <section className={styles.settingsSection}>
          <h4>{t('Block Push Notifications')}</h4>
          <div className={styles.toggleRow}>
            <label>{t('All')}</label>
            <input type="checkbox" />
          </div>
          <div className={styles.toggleRow}>
            <label>{t('During Quiet Hours')}</label>
            <select className={styles.selectInput}>
              <option value="weekdays">{t('Weekdays')}</option>
              <option value="weekends">{t('Weekends')}</option>
            </select>
          </div>
          <div className={styles.toggleRow}>
            <label>{t('Certain Hours')}</label>
            <input type="time" className={styles.timeInput} defaultValue="09:00" />
            <input type="time" className={styles.timeInput} defaultValue="10:00" />
          </div>
          <div className={styles.toggleRow}>
            <label>{t('During Quiet Days')}</label>
            <div className={styles.daysToggle}>
              {[
                { label: t('S'), value: 'S' },
                { label: t('M'), value: 'M' },
                { label: t('T'), value: 'T' },
                { label: t('W'), value: 'W' },
                { label: t('T'), value: 'T' },
                { label: t('F'), value: 'F' },
                { label: t('S'), value: 'S' },
              ].map((day, index) => (
                <button
                  key={index}
                  className={`${styles.dayButton} ${quietDays.includes(day.value) ? styles.active : ''}`}
                  onClick={() => handleDayToggle(day.value)}
                >
                  {day.label}
                </button>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AlertsSettings;
