// src/components/chats/ChatDetail.js

import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Box,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemIcon,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  MoreVert as MoreVertIcon,
  Send as SendIcon,
  AttachFile as AttachFileIcon,
  Mic as MicIcon,
  Image as ImageIcon,
  CameraAlt as CameraAltIcon,
  Description as DescriptionIcon,
  Contacts as ContactsIcon,
  Event as EventIcon,
  Poll as PollIcon,
  ShoppingCart as ShoppingCartIcon,
  Build as BuildIcon,
  Assignment as AssignmentIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Reply as ReplyIcon,
  Close as CloseIcon,
  PersonAdd as PersonAddIcon, // For Invite Members
} from '@mui/icons-material';
import { TransitionGroup } from 'react-transition-group';
import { WebSocketContext } from '../../context/RocketchatContext';
import { AuthContext } from '../../context/AuthContext';
import { useFetchChatInfo } from '../../hooks/useFetchChatInfo';
import { useFetchUsers } from '../../hooks/useFetchUsers';
import { useInfiniteScroll } from '@reactuses/core';
import styles from './ChatDetail.module.css';
import profilePlaceholder from '../../assets/images/placeholder.webp';

// Transition for Dialogs
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChatDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams(); // 'id' is the room ID for direct chat
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const chatContainerRef = useRef(null);

  // Attachment and Header Menus
  const [anchorElAttachment, setAnchorElAttachment] = useState(null);
  const [anchorElHeader, setAnchorElHeader] = useState(null);

  // Recording State
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);

  // Modals State
  const [showContactModal, setShowContactModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [showPollModal, setShowPollModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showRepairModal, setShowRepairModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  // Poll State
  const [pollQuestion, setPollQuestion] = useState('');
  const [pollOptions, setPollOptions] = useState(['']);
  const [polls, setPolls] = useState([]);

  // Invite Members State
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Snackbar State
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Replying to message
  const [replyingTo, setReplyingTo] = useState(null);

  const { getSocket, isSocketOpenAndReady, subscribeToRooms, sendMessage, loadHistory, deleteMessage } = useContext(WebSocketContext);
  const { authState } = useContext(AuthContext);
  const currentUserEmail = authState.user.email;

  const { chatRoomInfo } = useFetchChatInfo(id);
  const { summarizedUsers } = useFetchUsers(); // Ensure this hook returns an array of user objects with 'name' and 'email'

  // Direct chat specifics
  const otherUser = chatRoomInfo?.chatPartner;
  const otherUserName = otherUser?.fullName || 'Unknown';
  const otherUserAvatar = otherUser?.avatar ? `https://${otherUser.avatar}` : null;

  const [lastReceivedDate, setLastReceivedDate] = useState(null);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // Callback for WebSocket messages
  const handleChatCallback = useCallback((event) => {
    if (event.data) {
      const message = JSON.parse(event.data);

      // Handle new messages
      if (message.msg === 'changed' && message.collection === 'stream-room-messages' && message.fields?.eventName === id) {
        const messageData = message.fields.args[0];
        const recipient = messageData.u.name === currentUserEmail ? 'sent' : 'received';

        // Handle reply if present
        const replyTo = messageData.attachments && messageData.attachments[0]?.message_link;
        const replyText = messageData.attachments && messageData.attachments[0]?.text;

        setMessages(prevMessages => [
          ...prevMessages,
          {
            id: messageData._id,
            text: messageData.msg,
            time: new Date(messageData.ts.$date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            timestamp: messageData.ts.$date,
            type: recipient,
            pending: false,
            replyTo,
            replyText,
          }
        ]);
      }

      // Handle loading history
      if (message.msg === 'result') {
        if (message.id.includes(`sendMessage_${id}`)) {
          chatContainerRef.current.scrollTo({ top: chatContainerRef.current.scrollHeight });
          return;
        }

        if (message.id.includes(`loadHistory_${id}`)) {
          if (message.result?.messages?.length === 0) {
            setHasMoreMessages(false);
            return;
          }

          const chatItems = message.result?.messages?.map(msg => {
            const recipient = msg.u.name === currentUserEmail ? 'sent' : 'received';
            return {
              id: msg._id,
              text: msg.msg,
              time: new Date(msg.ts.$date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
              timestamp: msg.ts.$date,
              type: recipient,
              pending: false,
              replyTo: msg.attachments && msg.attachments[0]?.message_link,
              replyText: msg.attachments && msg.attachments[0]?.text,
            };
          });

          setLastReceivedDate(new Date().valueOf());

          if (message.id.includes('_initial')) {
            setMessages(chatItems?.reverse() || []);
            setInitialLoadComplete(true);
            // Scroll to bottom after initial load
            setTimeout(() => {
              chatContainerRef.current?.scrollTo({
                top: chatContainerRef.current.scrollHeight,
              });
            }, 100);
          } else {
            const oldHeight = chatContainerRef.current?.scrollHeight || 0;
            const oldScroll = chatContainerRef.current?.scrollTop || 0;
            setMessages(prevMessages => [...(chatItems?.reverse() || []), ...prevMessages]);

            if (chatContainerRef.current) {
              const newHeight = chatContainerRef.current.scrollHeight;
              chatContainerRef.current.scrollTop = oldScroll + (newHeight - oldHeight);
            }
            setIsLoadingMore(false);
          }
        }
      }
    }
  }, [id, currentUserEmail]);

  // Infinite Scroll
  const canLoadMore = !isLoadingMore && initialLoadComplete && hasMoreMessages;

  const loadMoreMessages = useCallback(() => {
    if (canLoadMore) {
      setIsLoadingMore(true);
      loadHistory(id, messages[0]?.timestamp, 10, lastReceivedDate);
    }
  }, [id, messages, lastReceivedDate, canLoadMore, loadHistory]);

  useInfiniteScroll(chatContainerRef, loadMoreMessages, {
    direction: 'top',
    distance: 400,
  });

  useEffect(() => {
    if (initialLoadComplete) {
      chatContainerRef.current?.scrollTo({
        top: chatContainerRef.current.scrollHeight,
      });
    }
  }, [initialLoadComplete]);

  useEffect(() => {
    subscribeToRooms([id]);
  }, [id, subscribeToRooms]);

  useEffect(() => {
    if (isSocketOpenAndReady) {
      setMessages([]);
      loadHistory(id, null, 20, lastReceivedDate);
    }
  }, [isSocketOpenAndReady, id, loadHistory, lastReceivedDate]);

  useEffect(() => {
    const socket = getSocket();
    if (socket) {
      socket.addEventListener('message', handleChatCallback);
      return () => {
        socket.removeEventListener('message', handleChatCallback);
      };
    }
  }, [getSocket, handleChatCallback]);

  // Handle Send Message
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      sendMessage(newMessage.trim(), id);
      setNewMessage('');
      setReplyingTo(null);
    }
  };

  // Handle Voice Message
  const handleVoiceMessage = () => {
    if (!isRecording) {
      // Start recording
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const recorder = new MediaRecorder(stream);
          recorder.ondataavailable = event => setAudioChunks(prev => [...prev, event.data]);
          recorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
            const audioUrl = URL.createObjectURL(audioBlob);
            const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            // Save audio message
            const audioMessage = {
              id: Date.now(),
              sender: t('You'),
              audioUrl,
              time: currentTime,
              type: 'sent',
              isAudio: true,
              pending: false,
            };
            setMessages(prevMessages => [...prevMessages, audioMessage]);
            localStorage.setItem(`audio_${audioMessage.id}`, audioUrl); // Optional: Persist audio
            setAudioChunks([]);
            setSnackbar({ open: true, message: t('Voice message sent'), severity: 'success' });
          };
          recorder.start();
          setMediaRecorder(recorder);
          setIsRecording(true);
        })
        .catch(error => {
          console.error(t('Error accessing microphone:'), error);
          setSnackbar({ open: true, message: t('Error accessing microphone'), severity: 'error' });
        });
    } else {
      // Stop recording
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  // Handle Attachments
  const handleImageSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const imageMessage = {
          id: Date.now(),
          sender: t('You'),
          imageUrl: reader.result,
          time: currentTime,
          type: 'sent',
          isImage: true,
          pending: false,
        };
        setMessages(prevMessages => [...prevMessages, imageMessage]);
        setSnackbar({ open: true, message: t('Image sent'), severity: 'success' });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDocumentSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const fileUrl = URL.createObjectURL(file);
      const documentMessage = {
        id: Date.now(),
        sender: t('You'),
        documentUrl: fileUrl,
        documentName: file.name,
        time: currentTime,
        type: 'sent',
        isDocument: true,
        pending: false,
      };
      setMessages(prevMessages => [...prevMessages, documentMessage]);
      setSnackbar({ open: true, message: t('Document sent'), severity: 'success' });
    }
  };

  const handleCameraClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const videoTracks = stream.getVideoTracks();
      if (videoTracks.length > 0) {
        // Implement photo capture logic here
        // For simplicity, we'll just alert and stop the camera
        alert(t('Camera access granted!'));
        videoTracks.forEach(track => track.stop());
      }
    } catch (error) {
      console.error(t('Error accessing camera:'), error);
      setSnackbar({ open: true, message: t('Error accessing camera'), severity: 'error' });
    }
  };

  // Handle Sharing Items
  const handleShareItem = (type, item, link) => {
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const sharedMessage = {
      id: Date.now(),
      sender: t('You'),
      sharedItem: item,
      shareType: type,
      link,
      time: currentTime,
      type: 'sent',
      isShared: true,
      pending: false,
    };
    setMessages(prevMessages => [...prevMessages, sharedMessage]);
    setSnackbar({ open: true, message: `${t(type)} ${t('shared')}`, severity: 'success' });
    // Close all modals
    setShowContactModal(false);
    setShowEventModal(false);
    setShowOrderModal(false);
    setShowRepairModal(false);
    setShowTaskModal(false);
  };

  // Handle Poll Creation
  const handleCreatePoll = () => {
    if (!pollQuestion.trim() || pollOptions.filter(opt => opt.trim()).length < 2) {
      setSnackbar({ open: true, message: t('Please enter a question and at least two options.'), severity: 'warning' });
      return;
    }
    const poll = {
      id: Date.now(),
      question: pollQuestion,
      options: pollOptions.map(option => ({ text: option, votes: 0 })),
      voted: false,
    };
    const pollMessage = {
      id: poll.id,
      sender: t('You'),
      poll,
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      type: 'sent',
      isPoll: true,
      pending: false,
    };
    setPolls(prevPolls => [...prevPolls, poll]);
    setMessages(prevMessages => [...prevMessages, pollMessage]);
    // Reset Poll Modal
    setShowPollModal(false);
    setPollQuestion('');
    setPollOptions(['', '']);
    setSnackbar({ open: true, message: t('Poll created'), severity: 'success' });
  };

  // Handle Poll Voting
  const handlePollVote = (pollId, optionIndex) => {
    setMessages(prevMessages =>
      prevMessages.map(msg => {
        if (msg.isPoll && msg.poll.id === pollId && !msg.poll.voted) {
          msg.poll.options[optionIndex].votes += 1;
          msg.poll.voted = true;
        }
        return msg;
      })
    );
    setSnackbar({ open: true, message: t('Your vote has been recorded'), severity: 'success' });
  };

  // Handle Invite Members
  const handleInviteMembers = () => {
    setShowInviteModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleUserSelect = (userEmail) => {
    if (!selectedUsers.includes(userEmail)) {
      setSelectedUsers(prev => [...prev, userEmail]);
    } else {
      setSelectedUsers(prev => prev.filter(email => email !== userEmail));
    }
  };

  const filteredUsers = useCallback(() => {
    return summarizedUsers.filter(user =>
      (user.name || '').toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, summarizedUsers]);

  // Handle Header Menu
  const handleMenuOpenHeader = (event) => {
    setAnchorElHeader(event.currentTarget);
  };

  const handleMenuCloseHeader = () => {
    setAnchorElHeader(null);
  };

  const navigateToChatInformation = () => {
    navigate(`/chat-information/${id}`);
    handleMenuCloseHeader();
  };

  // Implement handleMuteChat and handleDeleteChat
  const handleMuteChat = () => {
    // Implement mute functionality here
    // For now, we'll show a snackbar
    setSnackbar({ open: true, message: t('Chat muted'), severity: 'info' });
    handleMenuCloseHeader();
  };

  const handleDeleteChat = () => {
    // Implement delete functionality here
    // For now, we'll show a snackbar and navigate back
    setSnackbar({ open: true, message: t('Chat deleted'), severity: 'error' });
    handleMenuCloseHeader();
    navigate(-1); // Navigate back after deletion
  };

  // Snackbar Handler
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Handle Invite Members Submission
  const handleInviteSubmit = () => {
    if (selectedUsers.length === 0) {
      setSnackbar({ open: true, message: t('Please select at least one member to invite.'), severity: 'warning' });
      return;
    }
    // Implement invitation logic here, e.g., send invitations via WebSocket or API
    setSnackbar({ open: true, message: t('Members invited successfully'), severity: 'success' });
    setShowInviteModal(false);
    setSelectedUsers([]);
  };

  // Handle Reply Message
  const handleReplyMessage = (message) => {
    setReplyingTo(message);
  };

  // Handle Delete Message
  const handleDeleteMessage = (messageId) => {
    deleteMessage(messageId);

    // Optimistically remove the message from the state
    setMessages((prevMessages = []) => prevMessages.filter((msg) => msg.id !== messageId));
    setSnackbar({ open: true, message: t('Message deleted'), severity: 'info' });
  };

  // Handle Menu Open Attachment
  const handleMenuOpenAttachment = (event) => {
    setAnchorElAttachment(event.currentTarget);
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      {/* AppBar/Header */}
      <AppBar position="static" color="primary">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Avatar src={otherUserAvatar || undefined} alt={otherUserName} sx={{ marginRight: 2 }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {otherUserName}
          </Typography>
          <IconButton color="inherit" onClick={handleMenuOpenHeader}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElHeader}
            open={Boolean(anchorElHeader)}
            onClose={handleMenuCloseHeader}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={navigateToChatInformation}>
              {t('Chat Details')}
            </MenuItem>
            <MenuItem onClick={handleInviteMembers}>
              <ListItemIcon>
                <PersonAddIcon fontSize="small" />
              </ListItemIcon>
              {t('Invite Members')}
            </MenuItem>
            <MenuItem onClick={handleMuteChat}>
              {t('Mute Chat')}
            </MenuItem>
            <MenuItem onClick={handleDeleteChat}>
              {t('Delete Chat')}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Replying to message */}
      {replyingTo && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 1,
            backgroundColor: '#f0f0f0',
            borderRadius: 2,
            mb: 1,
          }}
        >
          <Typography variant="body2" sx={{ flexGrow: 1 }}>
            {t('Replying to')}: {replyingTo.text}
          </Typography>
          <IconButton size="small" onClick={() => setReplyingTo(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}

      {/* Chat Messages */}
      <Box
        ref={chatContainerRef}
        flexGrow={1}
        overflow="auto"
        p={2}
        sx={{
          backgroundColor: '#f5f5f5',
          transition: 'background-color 0.3s',
        }}
      >
        {isLoadingMore && (
          <Box display="flex" justifyContent="center" mb={2}>
            <CircularProgress />
          </Box>
        )}
        <TransitionGroup>
          {messages.map(message => (
            <Box
              key={message.id}
              display="flex"
              justifyContent={message.type === 'sent' ? 'flex-end' : 'flex-start'}
              mb={2}
            >
              {message.type !== 'sent' && (
                <Avatar src={otherUserAvatar || undefined} alt={otherUserName} sx={{ marginRight: 1 }} />
              )}
              <Box
                sx={{
                  maxWidth: '70%',
                  backgroundColor: message.type === 'sent' ? 'primary.main' : 'grey.300',
                  color: message.type === 'sent' ? 'common.white' : 'text.primary',
                  borderRadius: 2,
                  p: 1.5,
                  position: 'relative',
                }}
              >
                {/* Display reply info if available */}
                {message.replyText && (
                  <Box
                    sx={{
                      backgroundColor: message.type === 'sent' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
                      borderRadius: 1,
                      p: 1,
                      mb: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                      {t('Replying to')}: {message.replyText}
                    </Typography>
                  </Box>
                )}

                {message.isAudio ? (
                  <audio controls src={message.audioUrl} style={{ width: '100%' }} />
                ) : message.isImage ? (
                  <img src={message.imageUrl} alt={t('Sent Image')} style={{ maxWidth: '100%', borderRadius: 8 }} />
                ) : message.isDocument ? (
                  <Button
                    href={message.documentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                    color="secondary"
                    startIcon={<DescriptionIcon />}
                    fullWidth
                    sx={{ justifyContent: 'flex-start' }}
                  >
                    {message.documentName}
                  </Button>
                ) : message.isShared ? (
                  <Button
                    href={message.link}
                    variant="outlined"
                    color="secondary"
                    startIcon={
                      message.shareType === 'Contact' ? <ContactsIcon /> :
                      message.shareType === 'Event' ? <EventIcon /> :
                      message.shareType === 'Order' ? <ShoppingCartIcon /> :
                      message.shareType === 'Repair' ? <BuildIcon /> :
                      message.shareType === 'Task' ? <AssignmentIcon /> :
                      <DescriptionIcon />
                    }
                    fullWidth
                    sx={{ justifyContent: 'flex-start' }}
                  >
                    {t('Shared')} {t(message.shareType)}: {message.sharedItem}
                  </Button>
                ) : message.isPoll ? (
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      {message.poll.question}
                    </Typography>
                    {message.poll.options.map((option, index) => (
                      <Button
                        key={index}
                        variant="contained"
                        color="secondary"
                        onClick={() => handlePollVote(message.poll.id, index)}
                        disabled={message.poll.voted}
                        fullWidth
                        sx={{ mb: 1, textTransform: 'none' }}
                      >
                        {option.text} - {option.votes} {t('votes')}
                      </Button>
                    ))}
                  </Box>
                ) : (
                  <>
                    <Typography variant="body1">{message.text}</Typography>
                    <Typography variant="caption" sx={{ position: 'relative', bottom: 0, left: 0 }}>
                      {message.time}
                    </Typography>
                  </>
                )}

                {/* Pending Indicator */}
                {message.pending && (
                  <CircularProgress size={12} sx={{ position: 'absolute', bottom: 4, right: 4 }} />
                )}

                {/* Message Actions */}
                <Box
                  className="message-actions"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    visibility: 'hidden',
                  }}
                >
                  {/* Delete Button */}
                  {message.type === 'sent' && (
                    <IconButton size="small" onClick={() => handleDeleteMessage(message.id)}>
                      <DeleteIcon
                        fontSize="small"
                        sx={{ color: message.type === 'sent' ? '#fff' : '#000' }}
                      />
                    </IconButton>
                  )}

                  {/* Reply Button */}
                  <IconButton size="small" onClick={() => handleReplyMessage(message)}>
                    <ReplyIcon fontSize="small" sx={{ color: message.type === 'sent' ? '#fff' : '#000' }} />
                  </IconButton>
                </Box>
              </Box>
              {message.type === 'sent' && (
                <Avatar src={otherUserAvatar || undefined} alt={otherUserName} sx={{ marginLeft: 1 }} />
              )}
            </Box>
          ))}
        </TransitionGroup>
      </Box>

      {/* Chat Input */}
      <Box
        display="flex"
        alignItems="center"
        p={1}
        sx={{ borderTop: '1px solid #ddd', backgroundColor: '#fff' }}
      >
        <TextField
          variant="outlined"
          placeholder={t('Type a message...')}
          fullWidth
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={handleMenuOpenAttachment} color="primary">
                  <AttachFileIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={isRecording ? t('Stop Recording') : t('Record')}>
                  <IconButton onClick={handleVoiceMessage} color={isRecording ? 'secondary' : 'primary'}>
                    <MicIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{ mr: 1 }}
        />
        <IconButton color="primary" onClick={handleSendMessage}>
          <SendIcon />
        </IconButton>

        {/* Attachment Menu */}
        <Menu
          anchorEl={anchorElAttachment}
          open={Boolean(anchorElAttachment)}
          onClose={() => setAnchorElAttachment(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => { document.getElementById('fileInput').click(); setAnchorElAttachment(null); }}>
            <ImageIcon sx={{ mr: 1 }} />
            {t('Images')}
          </MenuItem>
          <MenuItem onClick={() => { handleCameraClick(); setAnchorElAttachment(null); }}>
            <CameraAltIcon sx={{ mr: 1 }} />
            {t('Camera')}
          </MenuItem>
          <MenuItem onClick={() => { document.getElementById('documentInput').click(); setAnchorElAttachment(null); }}>
            <DescriptionIcon sx={{ mr: 1 }} />
            {t('Document')}
          </MenuItem>
          <MenuItem onClick={() => { setShowContactModal(true); setAnchorElAttachment(null); }}>
            <ContactsIcon sx={{ mr: 1 }} />
            {t('Contact')}
          </MenuItem>
          <MenuItem onClick={() => { setShowEventModal(true); setAnchorElAttachment(null); }}>
            <EventIcon sx={{ mr: 1 }} />
            {t('Event')}
          </MenuItem>
          <MenuItem onClick={() => { setShowPollModal(true); setAnchorElAttachment(null); }}>
            <PollIcon sx={{ mr: 1 }} />
            {t('Poll')}
          </MenuItem>
          <MenuItem onClick={() => { setShowOrderModal(true); setAnchorElAttachment(null); }}>
            <ShoppingCartIcon sx={{ mr: 1 }} />
            {t('Order')}
          </MenuItem>
          <MenuItem onClick={() => { setShowRepairModal(true); setAnchorElAttachment(null); }}>
            <BuildIcon sx={{ mr: 1 }} />
            {t('Repair')}
          </MenuItem>
          <MenuItem onClick={() => { setShowTaskModal(true); setAnchorElAttachment(null); }}>
            <AssignmentIcon sx={{ mr: 1 }} />
            {t('Task')}
          </MenuItem>
          {/* Hidden File Inputs */}
          <input
            type="file"
            accept="image/*"
            id="fileInput"
            style={{ display: 'none' }}
            onChange={handleImageSelection}
          />
          <input
            type="file"
            accept=".xlsx, .csv, .zip, .rar, .doc, .docx, .pdf, .psd, .ai"
            id="documentInput"
            style={{ display: 'none' }}
            onChange={handleDocumentSelection}
          />
        </Menu>
      </Box>

      {/* Invite Members Modal */}
      <Dialog open={showInviteModal} onClose={() => setShowInviteModal(false)} TransitionComponent={Transition} fullWidth maxWidth="sm">
        <DialogTitle>{t('Invite Members')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('Search Users')}
            type="text"
            fullWidth
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ mb: 2 }}
          />
          <List>
            {filteredUsers().map((user, index) => (
              <ListItem key={index} button onClick={() => handleUserSelect(user.email)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedUsers.includes(user.email)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={user.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInviteModal(false)} color="secondary">
            {t('Cancel')}
          </Button>
          <Button
            onClick={handleInviteSubmit}
            color="primary"
            variant="contained"
            disabled={selectedUsers.length === 0}
          >
            {t('Invite')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Shared Item Modals */}
      {/* Contact Modal */}
      <Dialog open={showContactModal} onClose={() => setShowContactModal(false)} TransitionComponent={Transition}>
        <DialogTitle>{t('Select Contact')}</DialogTitle>
        <DialogContent>
          {['Jane Smith', 'John Doe'].map((contact, index) => (
            <Button
              key={index}
              fullWidth
              startIcon={<ContactsIcon />}
              onClick={() => handleShareItem('Contact', contact, '#contact-link')}
              sx={{ mb: 1, textTransform: 'none' }}
            >
              {contact}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowContactModal(false)} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Event Modal */}
      <Dialog open={showEventModal} onClose={() => setShowEventModal(false)} TransitionComponent={Transition}>
        <DialogTitle>{t('Select Event')}</DialogTitle>
        <DialogContent>
          {['Meeting at 10 AM', 'Lunch with Team'].map((event, index) => (
            <Button
              key={index}
              fullWidth
              startIcon={<EventIcon />}
              onClick={() => handleShareItem('Event', event, '#event-link')}
              sx={{ mb: 1, textTransform: 'none' }}
            >
              {event}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEventModal(false)} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Order Modal */}
      <Dialog open={showOrderModal} onClose={() => setShowOrderModal(false)} TransitionComponent={Transition}>
        <DialogTitle>{t('Select Order')}</DialogTitle>
        <DialogContent>
          {['Order #12345', 'Order #67890'].map((order, index) => (
            <Button
              key={index}
              fullWidth
              startIcon={<ShoppingCartIcon />}
              onClick={() => handleShareItem('Order', order, '#order-link')}
              sx={{ mb: 1, textTransform: 'none' }}
            >
              {order}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowOrderModal(false)} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Repair Modal */}
      <Dialog open={showRepairModal} onClose={() => setShowRepairModal(false)} TransitionComponent={Transition}>
        <DialogTitle>{t('Select Repair')}</DialogTitle>
        <DialogContent>
          {['Repair Ticket #54321', 'Repair Ticket #98765'].map((repair, index) => (
            <Button
              key={index}
              fullWidth
              startIcon={<BuildIcon />}
              onClick={() => handleShareItem('Repair', repair, '#repair-link')}
              sx={{ mb: 1, textTransform: 'none' }}
            >
              {repair}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRepairModal(false)} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Task Modal */}
      <Dialog open={showTaskModal} onClose={() => setShowTaskModal(false)} TransitionComponent={Transition}>
        <DialogTitle>{t('Select Task')}</DialogTitle>
        <DialogContent>
          {['Task #1: Review', 'Task #2: Approve'].map((task, index) => (
            <Button
              key={index}
              fullWidth
              startIcon={<AssignmentIcon />}
              onClick={() => handleShareItem('Task', task, '#task-link')}
              sx={{ mb: 1, textTransform: 'none' }}
            >
              {task}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTaskModal(false)} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Poll Modal */}
      <Dialog open={showPollModal} onClose={() => setShowPollModal(false)} TransitionComponent={Transition} fullWidth maxWidth="sm">
        <DialogTitle>{t('Create Poll')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('Poll Question')}
            type="text"
            fullWidth
            variant="outlined"
            value={pollQuestion}
            onChange={(e) => setPollQuestion(e.target.value)}
            sx={{ mb: 2 }}
          />
          {pollOptions.map((option, index) => (
            <TextField
              key={index}
              margin="dense"
              label={`${t('Option')} ${index + 1}`}
              type="text"
              fullWidth
              variant="outlined"
              value={option}
              onChange={(e) => {
                const newOptions = [...pollOptions];
                newOptions[index] = e.target.value;
                setPollOptions(newOptions);
              }}
              sx={{ mb: 1 }}
            />
          ))}
          <Button onClick={() => setPollOptions([...pollOptions, ''])} startIcon={<AddIcon />} sx={{ mb: 2 }}>
            {t('Add Option')}
          </Button>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleCreatePoll} variant="contained" color="primary" sx={{ mr: 1 }}>
              {t('Create Poll')}
            </Button>
            <Button onClick={() => setShowPollModal(false)} variant="outlined" color="secondary">
              {t('Cancel')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Box>
  );
};

export default ChatDetail;