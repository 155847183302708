import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Add i18n hook
import styles from './CreateServiceProvider.module.css'; 

const CreateServiceProvider = ({ onSuccess }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(null);

    if (!name.trim()) {
      setSubmitError(t('Name is required.'));
      return;
    }

    const payload = { name: name.trim() };

    try {
      setIsSubmitting(true);
      const token = localStorage.getItem('accessToken');
      await axios.post(`${apiUrl}/serviceProviders`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      onSuccess();
    } catch (error) {
      console.error(t('Error creating service provider:'), error);
      setSubmitError(t('Failed to create service provider. Please try again.'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.orderForm}>
        <h1 className={styles.title}>{t('Create New Service Provider')}</h1>
        <form onSubmit={handleSubmit} className={styles.form}>
          <label className={styles.label}>
            {t("Name")}:
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className={styles.input}
              placeholder={t('Enter service provider name')}
            />
          </label>

          {submitError && <p className={styles.error}>{submitError}</p>}

          <div className={styles.buttons}>
            <button
              type="submit"
              disabled={isSubmitting}
              className={styles.submitButton}
            >
              {isSubmitting ? t('Submitting...') : t('Create')}
            </button>
            <button
              type="button"
              onClick={onSuccess}
              disabled={isSubmitting}
              className={styles.cancelButton}
            >
              {t('Cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateServiceProvider;
