// src/utils/extractText.js

/**
 * Extracts text from the first heading or paragraph block, truncated to 25 characters.
 * @param {Array} blocks - Array of block objects from the Editor.
 * @returns {string} - Extracted text, truncated to 25 characters, or an empty string if not found.
 */
export const extractTextFromBlocks = (blocks) => {
    for (let block of blocks) {
        if (block.type.toLowerCase() === 'heading' && block.content) {
            // Concatenate all text nodes within the heading
            const text = block.content.map(textNode => textNode.text).join(' ');
            return text.length > 30 ? text.substring(0, 30) : text;
        } else if (block.type.toLowerCase() === 'paragraph' && block.content) {
            // Concatenate all text nodes within the paragraph
            const text = block.content.map(textNode => textNode.text).join(' ');
            return text.length > 30 ? text.substring(0, 30) : text;
        }
    }
    return '';
};
