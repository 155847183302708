const apiUrl = process.env.REACT_APP_API_URL;

// Upload file function (Zain: modified with delete file function)
const uploadFile = async (file, moduleField, documentTitle) => {
    const token = localStorage.getItem('accessToken');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('moduleField', moduleField); // Pass the module name like 'chat', 'order', etc.
    formData.append('documentTitle', documentTitle); // Title of the file/image
    
    // Determine the MIME type and media type dynamically
    const fileMimeType = file.type;
    let mediaType;

    // Check if the file is an image or document based on MIME type
    if (fileMimeType.startsWith('image')) {
      mediaType = 'image';
    } else if (fileMimeType === 'application/pdf' || fileMimeType.includes('word') || fileMimeType.includes('spreadsheet')) {
      mediaType = 'document';
    } else {
      mediaType = 'other';
    }

    // Append MIME type and media type dynamically
    formData.append('fileMimeType', fileMimeType);
    formData.append('mediaType', mediaType);

    try {
      const response = await fetch(`${apiUrl}/files/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the Authorization header
        },
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }

      const data = await response.json();

      const fileType = file.type.split('/')[1]; // Get the file extension from the MIME type
      const fileName = file.name; // Use the actual file name from the upload
      const fileUrl = data.fileUrl.replace(/\/[^/]+$/, `/${fileType}`);

      const payload = {
        ...data,
        fileType: fileType,   // Ensure correct file type (e.g., 'jpeg', 'png', 'pdf')
        fileName: fileName,   // Ensure correct file name
        fileUrl: fileUrl,     // Ensure correct file URL
      };

      console.log('File uploaded successfully:', payload);

      return payload; // Return the payload with file metadata
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
};

// Delete file function
const deleteFile = async (fileId) => {
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch(`${apiUrl}/files/${fileId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the Authorization header
        },
      });

      if (!response.ok) {
        throw new Error('File delete failed');
      }

      console.log(`File with ID ${fileId} deleted successfully`);
      return true;
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
};

// Function to retrieve file URL by ID (unchanged)

const getFileUrl = async (fileId) => {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    console.error('Authentication token not found. Please log in.');
    return null;
  }

  try {
    const response = await fetch(`${apiUrl}/files/${fileId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.fileUrl;
    } else {
      console.error('Error retrieving file URL:', response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error fetching file:', error);
    return null;
  }
};

// Function to render uploaded files in the UI (unchanged)
const renderFile = (fileUrl, fileType) => {
  if (fileType.startsWith('image')) {
    return `<img src="https://${fileUrl}" alt="Uploaded Image" />`;
  } else if (fileType === 'pdf') {
    return `<iframe src="${fileUrl}" width="100%" height="500px"></iframe>`;
  } else {
    return `<a href="${fileUrl}" target="_blank">Download File</a>`;
  }
};

export { uploadFile, deleteFile, getFileUrl, renderFile };
