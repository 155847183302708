import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next'; // Import useTranslation
import AttachmentSection from "./AttachmentSection";
import FeedbackSection from "./FeedbackSection";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill CSS
import styles from "./ReplyComponent.module.css";
import { uploadFile } from "../../services/mosaikUploadService"; // Import the centralized uploadFile

const ReplyComponent = ({ feedbackId, onReplySuccess, feedbackStatus }) => {
  const { t } = useTranslation(); // Initialize t
  const [replyMessage, setReplyMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [attachedFileId, setAttachedFileId] = useState(null); // State for file ID
  const [attachedFileUrl, setAttachedFileUrl] = useState(null); // State for file URL
  const [uploadError, setUploadError] = useState(""); // State for handling file upload errors
  const [isUploading, setIsUploading] = useState(false); // State for upload progress

  const handleReplyChange = (content) => {
    setReplyMessage(content);
  };

  // File upload handler using centralized uploadFile function
  const handleFileUpload = async (file) => {
    try {
      setIsUploading(true); // Start upload
      const uploadResponse = await uploadFile(file, "feedback", file.name); // Use centralized uploadFile
      if (uploadResponse && uploadResponse.id) {
        setAttachedFileId(uploadResponse.id); // Store the file ID
        setAttachedFileUrl(`https://${uploadResponse.fileUrl}`); // Store the file URL for display
        setUploadError(""); // Clear any previous errors
        console.log(t("File uploaded successfully:"), uploadResponse);
      } else {
        throw new Error(t("Invalid upload response structure."));
      }
    } catch (error) {
      console.error(t("Error uploading file:"), error);
      setUploadError(t("Error uploading file. Please try again."));
    } finally {
      setIsUploading(false); // End upload
    }
  };

  // Reply submission handler
  const handleSubmitReply = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const storedUser = localStorage.getItem("userInfo");
    const user = JSON.parse(storedUser);
    const userId = user.id;
    const replyType = user.roles.includes('admin') || user.roles.includes('feedbackManager') 
    ? 'feedback_staff' 
    : 'employee';
    const replyData = {
      feedback: {
        id: feedbackId,
      },
      text: replyMessage,
      typeField: replyType, // Set type to employee for the reply
      user: {
        id: userId,
      },
      // Attach the uploaded file if present
      ...(attachedFileId && { file: { id: attachedFileId } }),
    };

    try {
      await axios.post(`${apiUrl}/feedbacks/${feedbackId}/reply`, replyData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setReplyMessage(""); // Clear the input field after successful reply
      setAttachedFileId(null); // Clear the attached file ID
      setAttachedFileUrl(null); // Clear the attached file URL
      onReplySuccess(); // Reload the feedback details to show the reply
    } catch (error) {
      console.error(t("Error submitting reply:"), error);
      setErrorMessage(t("Failed to submit the reply. Please try again."));
    }
  };

  return (
    <div className={styles.container}>
      {feedbackStatus && feedbackStatus !== "resolved" && (
        <>
          <h1 className={styles.replyHeader}>{t("Reply to Feedback")}:</h1>

          {/* Quill Text Editor for Reply */}
          <div className={styles.replyBox}>
            <ReactQuill
              value={replyMessage}
              onChange={handleReplyChange}
              className={styles.replyInput}
              placeholder={t("Type your message here...")}
            />
          </div>

          {/* Error Messages */}
          {errorMessage && <p className={styles.errorText}>{errorMessage}</p>}
          {uploadError && <p className={styles.errorText}>{uploadError}</p>}

          {/* Attachment Section */}
          <AttachmentSection
            handleFileUpload={handleFileUpload}
            isUploading={isUploading}
            attachedFileUrl={attachedFileUrl} // Pass the uploaded file URL for display
          />

          {/* Submit Button */}
          <button
            className={styles.submitButton}
            onClick={handleSubmitReply}
            disabled={isUploading}
          >
            {isUploading ? t("Uploading...") : t("Submit Reply")}
          </button>
        </>
      )}
      <FeedbackSection
        feedbackId={feedbackId}
        feedbackStatus={feedbackStatus}
        onReplySuccess={onReplySuccess}
      />
    </div>
  );
};

export default ReplyComponent;
