// src/hooks/useFetchUsers.js

import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { getProfilePictureUrl } from '../utils/getProfileUrl';

const apiUrl = process.env.REACT_APP_API_URL;

export const useFetchUsers = () => {
  const currentUser = localStorage.getItem('userInfo');
  // TODO: Fix this: sometimes the currentUser is not set yet
  const currentUserId = currentUser ? JSON.parse(currentUser)?.id : null;
  
  const [users, setUsers] = useState([]);
  const [summarizedUsers, setSummarizedUsers] = useState([]);
  const [error, setError] = useState(null);

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${apiUrl}/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        setError(error);
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Process users to include profile pictures and full names
  useEffect(() => {
    const processUsers = async () => {
      try {
        const processedUsers = await Promise.all(
          users.map(async (user) => {
            const profilePicture = await getProfilePictureUrl(user);
            return {
              id: user.id,
              name: `${user.firstName} ${user.lastName}`.trim(),
              department: user?.department ?? '',
              avatar: profilePicture || '/assets/images/placeholder.webp',
              email: user.email,
            };
          })
        );
        setSummarizedUsers(processedUsers);
      } catch (error) {
        console.error('Error processing users:', error);
      }
    };

    if (users.length > 0) {
      processUsers();
    }
  }, [users]);

  // Exclude the current user from the users list
  const summarizedUsersWithoutCurrentUser = useMemo(() => {
    return summarizedUsers.filter(user => user.id !== currentUserId);
  }, [summarizedUsers, currentUserId]);

  return { users, summarizedUsers, summarizedUsersWithoutCurrentUser, error };
};
