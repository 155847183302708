import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import MosaikFooter from "../MosaikFooter";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import styles from "./AssignedTaskDetail.module.css";
import userImage from "../../assets/images/assigned-by1.png";
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_URL;

const AssignedTaskDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState(null);
  const token = localStorage.getItem("accessToken"); // Get the access token from local storage

  useEffect(() => {
    // Function to fetch task details from the API
    const fetchTaskDetails = async () => {
      try {
        // Make API call to fetch task details
        const response = await axios.get(`${apiUrl}/tasks/detail/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        });
        console.log("Task Details:", response.data); // Console log the response data
        setTask(response.data); // Set the task state with the API response
      } catch (error) {
        console.error("Error fetching task details:", error);
      }
    };

    fetchTaskDetails(); // Call the fetch function on component mount
  }, [id, token]);

  if (!task) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Task Details')} />
      <div className={styles.container}>
        <div className={styles.taskHeader}>
          <div className={styles.taskInfo}>
            <span className={`${styles.priorityTag} ${styles[task.priority.toLowerCase()]}`}>
              {t(task.priority)}
            </span>
            <span className={styles.categoryTag}>
              {task.taskCategory?.id || t('No Category')}
            </span>
          </div>
          <h2>{task.name}</h2>
          <div className={styles.taskMeta}>
            <p>
              <strong>{t('Location:')}</strong> {task.location}
            </p>
          </div>
          <div className={styles.taskDate}>
          <div className={styles.assignedInfo}>
            <img
              src={userImage}
              alt="Assigned By"
              className={styles.userImage}
            />
            <div>
              <p>
                <strong>{t('Assigned by:')}</strong>
              </p>
              <h4>{task.assigner?.firstName}</h4>
            </div>
            </div>
            <div className={styles.dateContainer}>
              <img
                src={require('../../assets/images/calendar-vector.png')}
                alt={t('Calendar')}
                className={styles.calendarVector}
              />
              <p className={styles.time}>{new Date(task.endDate).toLocaleTimeString()}</p>
              <p className={styles.date}>{new Date(task.endDate).toLocaleDateString()}</p>
            </div>
          </div>
        </div>

        <div className={styles.taskDetails}>
          <h3>{t('Task Details:')}</h3>
          <p>{task.description}</p>
        </div>

        {/* Task Occurrences Section */}
        {task.taskOccurrences.map((occurrence, index) => (
          <div key={occurrence.id} className={styles.occurrenceSection}>
            <h3>{t('Occurrence {{index}}', { index: index + 1 })}</h3>
            <p>
              <strong>{t('Status:')}</strong> {t(occurrence.status)}
            </p>

            {/* Checklist for the occurrence */}
            <div className={styles.checkLists}>
              <h4>{t('Check Lists:')}</h4>
              {occurrence.checklist.map((item, i) => (
                <div key={i} className={styles.checklistItem}>
                  <span
                    className={
                      item.checked === 'true' ? styles.checkedItem : styles.uncheckedItem
                    }
                  >
                    {item.itemName} -{' '}
                    {item.checked === 'true' ? t('Completed') : t('Pending')}
                  </span>
                </div>
              ))}
            </div>

            {/* Incomplete Reason if any */}
            {occurrence.status === "Pending" && occurrence.incompleteReason && (
              <div className={styles.incompleteSection}>
                <h4>{t('Incomplete Reason')}</h4>
                <p>{occurrence.incompleteReason}</p>
              </div>
            )}
          </div>
        ))}

        <button
          className={styles.editTaskButton}
          onClick={() => navigate(`/edit-task/${task.id}`)}
        >
          {t('Edit Task')}
        </button>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default AssignedTaskDetail;
