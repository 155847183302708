// src/components/chats/ChatDetails.js

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  List,
  Paper,
  Tooltip,
  Button,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import {
  Send as SendIcon,
  MoreVert as MoreVertIcon,
  InsertEmoticon as InsertEmoticonIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Reply as ReplyIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { WebSocketContext } from '../../context/RocketchatContext';
import { AuthContext } from '../../context/AuthContext';
import { useFetchChatInfo } from '../../hooks/useFetchChatInfo';
import { useFetchUsers } from '../../hooks/useFetchUsers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Picker from '@emoji-mart/react'; // Ensure correct import based on version
import data from '@emoji-mart/data';
import DateSeparator from './DateSeparator'; // Import DateSeparator component
import Attachments from './attachments'; // Import Attachments component
import { uploadFile } from '../../services/mosaikUploadService'; // Import uploadFile

// Define a placeholder avatar URL
const placeholderAvatar = '/path/to/placeholder/avatar.png'; // Replace with actual path

// Helper function to get emoji from shortcode
const getEmoji = (shortcode) => {
  const emojiMapping = {
    ':tada:': '🎉',
    ':heart:': '❤️',
    ':thumbsup:': '👍',
    ':thumbsdown:': '👎',
    ':joy:': '😂',
    ':cry:': '😢',
    ':open_mouth:': '😮',
    // Add more mappings as needed
  };
  return emojiMapping[shortcode] || shortcode;
};

// Subcomponent to display individual messages
const MessageItem = ({
  message,
  isOwnMessage,
  isGroupChat,
  onReact,
  onReply,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(message.msg);
  const openMenu = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setIsEditing(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    onDelete(message.id);
    handleMenuClose();
  };

  const handleSaveEdit = () => {
    onEdit(message.id, editedText);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditedText(message.msg);
    setIsEditing(false);
  };

  return (
    <Box
      display="flex"
      justifyContent={isOwnMessage ? 'flex-end' : 'flex-start'}
      mb={2}
    >
      {!isOwnMessage && (
        <Avatar
          src={message.sender.avatar || placeholderAvatar}
          alt={message.sender.name}
        />
      )}
      <Box
        ml={isOwnMessage ? 0 : 1}
        mr={isOwnMessage ? 1 : 0}
        maxWidth="60%"
        position="relative"
      >
        <Paper
          elevation={1}
          sx={{
            padding: 1,
            backgroundColor: isOwnMessage ? '#DCF8C6' : '#F1F0F0',
            borderRadius: 2,
          }}
        >
          {/* Display Sender's Name and Email in Group Chats */}
          {isGroupChat && !isOwnMessage && (
            <Box>
              <Typography variant="subtitle2">{message.sender.name}</Typography>
              {message.sender.email && (
                <Typography variant="caption" color="textSecondary">
                  {message.sender.email}
                </Typography>
              )}
            </Box>
          )}

          {/* Reply Information */}
          {message.reply && (
            <Box
              mb={1}
              p={1}
              bgcolor="#e0e0e0"
              borderRadius={1}
              sx={{ cursor: 'pointer' }}
              onClick={() => onReply(message.reply)}
            >
              <Typography variant="caption" color="textSecondary">
                {t('Replying to')}: {message.reply.sender.name}
              </Typography>
              <Typography variant="body2" noWrap>
                {message.reply.msg}
              </Typography>
            </Box>
          )}

          {/* Message Content or Editing */}
          {isEditing ? (
            <TextField
              fullWidth
              multiline
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              variant="outlined"
              size="small"
            />
          ) : (
            <Typography variant="body1">{message.msg}</Typography>
          )}

          {/* Attachments (Images, Files, etc.) */}
          {message.attachments && message.attachments.length > 0 && (
            <Box mt={1}>
              {message.attachments.map((attachment, index) => (
                <Box key={index} mb={1}>
                  <Typography variant="body2" color="textSecondary">
                    {attachment.title}
                  </Typography>
                  {attachment.image_url || attachment.file_url ? (
                    attachment.image_url ? (
                      <img
                        src={attachment.image_url}
                        alt={attachment.title}
                        style={{ maxWidth: '100%', borderRadius: 8 }}
                      />
                    ) : (
                      <a href={attachment.file_url} target="_blank" rel="noopener noreferrer">
                        {t('Download File')}
                      </a>
                    )
                  ) : null}
                </Box>
              ))}
            </Box>
          )}

          {/* Message Metadata and Actions */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            {/* Timestamp */}
            <Typography variant="caption" color="textSecondary">
              {moment(message.ts).format('LT')}
              {message.editedAt && ' (edited)'}
            </Typography>

            {/* Reactions and Actions */}
            <Box display="flex" alignItems="center">
              {/* Display Reactions */}
              {message.reactions &&
                Object.entries(message.reactions).map(([emoji, users]) => (
                  <Tooltip
                    key={emoji}
                    title={`${users.length} ${t('reactions')}`}
                  >
                    <Typography variant="caption" mr={1}>
                      {getEmoji(emoji)} {users.length}
                    </Typography>
                  </Tooltip>
                ))}

              {/* React Button */}
              <IconButton
                size="small"
                onClick={(e) => onReact(e, message.id)}
                aria-label={t('React to message')}
              >
                <InsertEmoticonIcon fontSize="small" />
              </IconButton>

              {/* Reply Button */}
              {!isOwnMessage && (
                <IconButton size="small" onClick={() => onReply(message)}>
                  <ReplyIcon fontSize="small" />
                </IconButton>
              )}

              {/* Edit/Delete Menu for Own Messages */}
              {isOwnMessage && (
                <>
                  <IconButton key="more-vert" size="small" onClick={handleMenuOpen}>
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                  <Menu
                    key="menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleEdit}>
                      <EditIcon fontSize="small" sx={{ mr: 1 }} />
                      {t('Edit')}
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                      <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                      {t('Delete')}
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          </Box>
        </Paper>

        {/* Editing Controls */}
        {isEditing && (
          <Box display="flex" justifyContent="flex-end" mt={0.5}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleSaveEdit}
              sx={{ mr: 1 }}
            >
              {t('Save')}
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={handleCancelEdit}
            >
              {t('Cancel')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

// Subcomponent to display the reply preview
const ReplyPreview = ({ reply, onCancel }) => {
  const { t } = useTranslation();
  if (!reply) return null;

  return (
    <Box
      display="flex"
      alignItems="center"
      bgcolor="#f0f0f0"
      p={1}
      borderRadius={1}
      mb={1}
    >
      <Typography variant="body2" noWrap>
        {t('Replying to')}: {reply.sender.name}: {reply.msg}
      </Typography>
      <IconButton size="small" onClick={onCancel} sx={{ ml: 'auto' }}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

function ChatDetails() {
  const [loadCooldownRef, setLoadCooldownRef] = useState(false);
  const { id: roomId } = useParams();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const {
    summarizedUsersWithoutCurrentUser,
    users,
    error: usersError,
  } = useFetchUsers();
  const currentUserId = authState.user?.id;
  const currentUsername =
    authState.user?.username || authState.user?.fullName || 'You';
  const {
    sendMessage,
    loadHistory,
    eventEmitter,
    setReaction,
    deleteMessage,
    updateMessage,
    getRoomById,
    sendTypingNotification,
    getSingleMessage, // Ensure this method exists in RocketChatContext
  } = useContext(WebSocketContext);
  const { t } = useTranslation();

  const {
    chatInfo,
    groupInfo,
    directChatInfo,
    loading: chatLoading,
    error: chatError,
  } = useFetchChatInfo(roomId);

  const isGroupChat = groupInfo !== null;
  const isDirectChat = directChatInfo !== null;

  const [room, setRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [loading, setLoading] = useState(true);
  const [replyTo, setReplyTo] = useState(null);
  const [anchorElReact, setAnchorElReact] = useState(null);
  const [currentReactMessageId, setCurrentReactMessageId] = useState(null);
  const [availableReactions] = useState([
    '👍',
    '❤️',
    '😂',
    '🎉',
    '😮',
    '😢',
    '👎',
  ]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [typingUsers, setTypingUsers] = useState([]);

  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [oldestMessageTimestamp, setOldestMessageTimestamp] = useState(null);

  // State and handlers for header menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Menu action handlers
  const handleLeaveGroup = () => {
    // Implement leave group functionality
    handleMenuClose();
    setSnackbar({
      open: true,
      message: t('Left the group successfully.'),
      severity: 'success',
    });
  };

  const handleInviteMembers = () => {
    // Implement invite members functionality
    handleMenuClose();
    setSnackbar({
      open: true,
      message: t('Invite members functionality is not implemented yet.'),
      severity: 'info',
    });
  };

  const handleGroupDetails = () => {
    // Implement group details navigation
    handleMenuClose();
    navigate(`/group-details/${roomId}`);
  };

  const handleDeleteConversation = () => {
    // Implement delete conversation functionality
    handleMenuClose();
    setSnackbar({
      open: true,
      message: t('Conversation deleted successfully.'),
      severity: 'success',
    });
  };

  const handleChatDetails = () => {
    // Implement chat details navigation
    handleMenuClose();
    navigate(`/chat-details/${roomId}`);
  };

  const handleMuteConversation = () => {
    // Implement mute conversation functionality
    handleMenuClose();
    setSnackbar({
      open: true,
      message: t('Conversation muted successfully.'),
      severity: 'success',
    });
  };

  // Helper function to get username by ID
  const getUsernameById = (id) => {
    const user = summarizedUsersWithoutCurrentUser.find((user) => user.id === id);
    return user ? user.name : id; // Fallback to ID if name not found
  };

  // Helper function to get avatar URL by user ID
  const getAvatarById = (id) => {
    const user = summarizedUsersWithoutCurrentUser.find((user) => user.id === id);
    if (user && user.avatar) {
      if (typeof user.avatar === 'string') {
        return user.avatar;
      } else if (typeof user.avatar === 'object' && user.avatar.url) {
        return user.avatar.url;
      }
    }
    return placeholderAvatar; // Fallback to placeholder
  };

  // Debounced function to send typing notifications
  const debouncedSendTyping = useCallback(
    _.debounce((isTyping) => {
      sendTypingNotification(roomId, currentUserId, isTyping);
    }, 500),
    [sendTypingNotification, roomId, currentUserId]
  );

  // Emoji Picker State
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  // Fetch room details and initial messages
  useEffect(() => {
    const fetchWithRetry = async (fetchFunction, retries = 5, delay = 1000) => {
      try {
        return await fetchFunction();
      } catch (error) {
        if (retries === 0) throw error;
        await new Promise((res) => setTimeout(res, delay));
        return fetchWithRetry(fetchFunction, retries - 1, delay * 2);
      }
    };

    const fetchRoomAndMessages = async () => {
      try {
        const roomDetails = isGroupChat ? groupInfo : chatInfo;
        setRoom(roomDetails);

        // Load initial 50 messages with retry
        const initialMessagesResponse = await fetchWithRetry(() =>
          loadHistory(roomId, null, 50, null)
        );

        if (
          initialMessagesResponse &&
          initialMessagesResponse.messages &&
          initialMessagesResponse.messages.length > 0
        ) {
          const sortedMessages = initialMessagesResponse.messages
            .sort((a, b) => new Date(a.ts.$date) - new Date(b.ts.$date))
            .map((msg) => ({
              id: msg._id,
              msg: msg.msg,
              ts: msg.ts.$date,
              u: {
                id: msg.u._id,
                username: msg.u.username,
                avatar: getAvatarById(msg.u._id), // Ensure string URL
                name: msg.u.name || getUsernameById(msg.u.username),
                email: msg.u.email || '', // Include email
              },
              reactions: msg.reactions,
              attachments: msg.attachments,
              editedAt: msg._updatedAt?.$date || null,
              tmid: msg.tmid || null, // Thread Message ID for replies
              reply: msg.reply || null, // Include existing reply data if any
            }));
          setMessages(sortedMessages);
          setOldestMessageTimestamp(sortedMessages[0]?.ts || null); // Set to oldest message timestamp
        } else {
          console.warn(
            'Initial messages response is missing "messages" array or empty:',
            initialMessagesResponse
          );
        }

        setLoading(false);
        scrollToBottom();
      } catch (error) {
        console.error('Error fetching room or messages:', error);
        setSnackbar({
          open: true,
          message: t('Failed to load chat.'),
          severity: 'error',
        });
        setLoading(false);
      }
    };

    if (!chatLoading && !chatError) {
      fetchRoomAndMessages();
    }
  }, [
    roomId,
    loadHistory,
    isGroupChat,
    groupInfo,
    chatInfo,
    chatLoading,
    chatError,
    t,
    isDirectChat,
    directChatInfo,
    currentUserId,
    getUsernameById,
    getAvatarById,
  ]);

  // Fetch original messages for replies
  useEffect(() => {
    const fetchReplies = async () => {
      const messagesWithReplies = messages.filter((msg) => msg.tmid && !msg.reply);
      const updatedMessages = [...messages];

      for (const msg of messagesWithReplies) {
        try {
          const originalMessage = await getSingleMessage(msg.tmid);
          if (originalMessage) {
            const replyData = {
              id: originalMessage._id,
              msg: originalMessage.msg,
              sender: {
                name: originalMessage.u?.name || 'Unknown',
                email: originalMessage.u?.email || '',
                avatar: getAvatarById(originalMessage.u?._id) || placeholderAvatar,
              },
            };
            // Find the index of the message to update
            const index = updatedMessages.findIndex((m) => m.id === msg.id);
            if (index !== -1) {
              updatedMessages[index].reply = replyData;
            }
          }
        } catch (error) {
          console.error(`Error fetching original message for reply ID ${msg.tmid}:`, error);
        }
      }

      setMessages(updatedMessages);
    };

    if (messages.length > 0) {
      fetchReplies();
    }
  }, [messages, getSingleMessage, getAvatarById]);

  // Listen for new messages from WebSocket
  useEffect(() => {
    const handleNewMessage = async (newMessage) => {
      if (newMessage.rid !== roomId) return;

      const isOwnMessage = newMessage.u.username === currentUserId;

      if (isOwnMessage) {
        // Find the temporary message
        const tempMessageIndex = messages.findIndex(
          (msg) =>
            msg.pending &&
            msg.msg === newMessage.msg &&
            Math.abs(new Date(msg.ts) - new Date(newMessage.ts.$date)) < 1000 // Allowing 1-second difference
        );

        if (tempMessageIndex !== -1) {
          // Use existing reply data from temp message
          const tempMessage = messages[tempMessageIndex];
          const replyData = tempMessage.reply;

          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[tempMessageIndex] = {
              id: newMessage._id,
              msg: newMessage.msg,
              ts: newMessage.ts.$date,
              u: {
                id: newMessage.u._id,
                username: newMessage.u.username,
                avatar: getAvatarById(newMessage.u._id), // Ensure string URL
                name: newMessage.u.name || getUsernameById(newMessage.u.username),
                email: newMessage.u.email || '', // Include email
              },
              reactions: newMessage.reactions,
              attachments: newMessage.attachments,
              editedAt: newMessage._updatedAt?.$date || null,
              tmid: newMessage.tmid || null,
              reply: replyData, // Use existing reply data
            };
            return updatedMessages;
          });
          return;
        }
      }

      // For messages from other users or if no matching temp message, append normally
      const replyData = newMessage.tmid
        ? await fetchReplyData(newMessage.tmid)
        : null;

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: newMessage._id,
          msg: newMessage.msg,
          ts: newMessage.ts.$date,
          u: {
            id: newMessage.u._id,
            username: newMessage.u.username,
            avatar: getAvatarById(newMessage.u._id), // Ensure string URL
            name: newMessage.u.name || getUsernameById(newMessage.u.username),
            email: newMessage.u.email || '', // Include email
          },
          reactions: newMessage.reactions,
          attachments: newMessage.attachments,
          editedAt: newMessage._updatedAt?.$date || null,
          tmid: newMessage.tmid || null,
          reply: replyData, // Include reply data if any
        },
      ]);
      scrollToBottom();
    };

    const fetchReplyData = async (replyId) => {
      try {
        const originalMessage = await getSingleMessage(replyId);
        if (originalMessage) {
          return {
            id: originalMessage._id,
            msg: originalMessage.msg,
            sender: {
              name: originalMessage.u?.name || 'Unknown',
              email: originalMessage.u?.email || '',
              avatar: getAvatarById(originalMessage.u?._id) || placeholderAvatar,
            },
          };
        }
      } catch (error) {
        console.error(`Error fetching original message for reply ID ${replyId}:`, error);
      }
      return null;
    };

    // Subscribe to 'newMessage' events
    eventEmitter.on('newMessage', handleNewMessage);

    // Cleanup on unmount
    return () => {
      eventEmitter.off('newMessage', handleNewMessage);
    };
  }, [roomId, eventEmitter, messages, currentUserId, getUsernameById, getAvatarById, getSingleMessage, t]);

  // Listen for typing events from other users
  useEffect(() => {
    const handleTyping = (typingData) => {
      if (typingData.rid !== roomId) return;

      const { username, flag } = typingData;

      setTypingUsers((prevTypingUsers) => {
        if (flag) {
          // User started typing
          if (!prevTypingUsers.includes(username)) {
            return [...prevTypingUsers, username];
          }
        } else {
          // User stopped typing
          return prevTypingUsers.filter((user) => user !== username);
        }
        return prevTypingUsers;
      });
    };

    // Listen for 'typing' events
    eventEmitter.on('typing', handleTyping);

    return () => {
      eventEmitter.off('typing', handleTyping);
    };
  }, [roomId, eventEmitter]);

  // Handle input change and send typing notifications
  const handleInputChange = (e) => {
    setMessageText(e.target.value);
    if (e.target.value) {
      debouncedSendTyping(true);
    } else {
      debouncedSendTyping(false);
    }
  };

  // Load Cooldown Ref
  const loadCooldownRefCurrent = useRef(false);

  // Handle scroll to load more messages
  const handleScroll = useCallback(
    _.debounce(async () => {
      if (
        !chatContainerRef.current ||
        isLoadingMore ||
        !hasMoreMessages ||
        loadCooldownRefCurrent.current
      ) {
        return;
      }

      // Check if user has scrolled near the top (e.g., within 150px)
      if (chatContainerRef.current.scrollTop < 150) {
        loadCooldownRefCurrent.current = true;
        setIsLoadingMore(true);

        // Capture the current scroll height
        const previousScrollHeight = chatContainerRef.current.scrollHeight;

        try {
          const response = await loadHistory(roomId, oldestMessageTimestamp, 50, oldestMessageTimestamp);
          if (response && response.messages && response.messages.length > 0) {
            const sortedMessages = response.messages
              .sort((a, b) => new Date(a.ts.$date) - new Date(b.ts.$date))
              .map((msg) => ({
                id: msg._id,
                msg: msg.msg,
                ts: msg.ts.$date,
                u: {
                  id: msg.u._id,
                  username: msg.u.username,
                  avatar: getAvatarById(msg.u._id), // Ensure string URL
                  name: msg.u.name || getUsernameById(msg.u.username),
                  email: msg.u.email || '', // Include email
                },
                reactions: msg.reactions,
                attachments: msg.attachments,
                editedAt: msg._updatedAt?.$date || null,
                tmid: msg.tmid || null, // Thread Message ID for replies
                reply: msg.reply || null, // Include existing reply data if any
              }));
            setMessages((prevMessages) => [...sortedMessages, ...prevMessages]);
            setOldestMessageTimestamp(sortedMessages[0]?.ts || null);

            if (response.messages.length < 50) {
              setHasMoreMessages(false);
            }

            // After new messages are prepended, adjust the scroll position
            setTimeout(() => {
              const newScrollHeight = chatContainerRef.current.scrollHeight;
              chatContainerRef.current.scrollTop = newScrollHeight - previousScrollHeight;
            }, 100);
          } else {
            setHasMoreMessages(false);
          }
        } catch (error) {
          console.error('Error loading more messages:', error);
          setSnackbar({
            open: true,
            message: t('Failed to load more messages.'),
            severity: 'error',
          });

          // Handle rate limiting
          if (error.error === 'too-many-requests') {
            // Extract the wait time from the reason using regex
            const match = error.reason.match(/wait (\d+) seconds/i);
            const waitTime = match ? parseInt(match[1], 10) : 10; // Default to 10 seconds if not found

            setSnackbar({
              open: true,
              message: t('Too many requests. Please wait {waitTime} seconds before trying again.', { waitTime }),
              severity: 'warning',
            });

            // Set the cooldown based on server response
            setTimeout(() => {
              loadCooldownRefCurrent.current = false;
            }, waitTime * 1000); // Convert to milliseconds
          } else {
            // For other errors, set a default cooldown
            setTimeout(() => {
              loadCooldownRefCurrent.current = false;
            }, 7000); // 7 seconds
          }
        } finally {
          setIsLoadingMore(false);
        }
      }
    }, 2000), // Increased debounce to 2000ms to reduce request frequency
    [
      chatContainerRef,
      isLoadingMore,
      hasMoreMessages,
      loadHistory,
      roomId,
      oldestMessageTimestamp,
      t,
      getUsernameById,
      getAvatarById,
    ]
  );

  // Attach scroll event listener
  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  // Function to scroll to bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Handle sending a new message with Optimistic UI
  const handleSendMessage = async () => {
    if (!messageText.trim()) return;

    const tempId = `temp-${Date.now()}`; // Unique temporary ID

    // Create a temporary message object
    const tempMessage = {
      id: tempId,
      msg: messageText.trim(),
      ts: new Date().toISOString(),
      u: {
        id: currentUserId,
        username: currentUserId,
        avatar: getAvatarById(currentUserId), // Ensure string URL
        name: currentUsername,
        email: authState.user.email || '', // Include email
      },
      reactions: {},
      attachments: [],
      editedAt: null,
      pending: true, // Flag to identify temporary messages
      reply: replyTo
        ? {
            id: replyTo.id,
            msg: replyTo.msg,
            sender: {
              name: replyTo.sender.name,
              email: replyTo.sender.email,
              avatar: replyTo.sender.avatar,
            },
          }
        : null,
    };

    // Add the temporary message to the state
    setMessages((prevMessages) => [...prevMessages, tempMessage]);
    setMessageText('');
    setReplyTo(null);
    scrollToBottom();

    try {
      await sendMessage(
        messageText.trim(),
        roomId,
        replyTo?.id || null,
        replyTo?.msg || null
      );
      // No need to update the message here as WebSocket will handle it
    } catch (error) {
      console.error('Error sending message:', error);
      setSnackbar({
        open: true,
        message: t('Failed to send message.'),
        severity: 'error',
      });
      // Optionally, remove the temporary message or mark it as failed
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== tempId)
      );
    }
  };

  // Handle pressing Enter to send message
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Handle reacting to a message
  const handleReact = (event, messageId) => {
    setAnchorElReact(event.currentTarget);
    setCurrentReactMessageId(messageId);
  };

  const handleCloseReactMenu = () => {
    setAnchorElReact(null);
    setCurrentReactMessageId(null);
  };

  const handleAddReaction = async (emoji) => {
    if (!currentReactMessageId) return;

    // Map Unicode emoji to Rocket.Chat shortcodes if necessary
    const emojiToShortcode = {
      '👍': ':thumbsup:',
      '❤️': ':heart:',
      '😂': ':joy:',
      '🎉': ':tada:',
      '😮': ':open_mouth:',
      '😢': ':cry:',
      '👎': ':thumbsdown:',
    };

    const shortcode = emojiToShortcode[emoji] || emoji; // Fallback to emoji if no mapping

    try {
      await setReaction(currentReactMessageId, shortcode, false);
      setSnackbar({
        open: true,
        message: t('Reaction added.'),
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding reaction:', error);
      setSnackbar({
        open: true,
        message: t('Failed to add reaction.'),
        severity: 'error',
      });
    } finally {
      handleCloseReactMenu();
    }
  };

  // Handle replying to a message
  const handleReply = (message) => {
    setReplyTo(message);
  };

  // Handle canceling reply
  const handleCancelReply = () => {
    setReplyTo(null);
  };

  // Handle deleting a message
  const handleDelete = async (messageId) => {
    try {
      await deleteMessage(messageId);
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== messageId)
      );
      setSnackbar({
        open: true,
        message: t('Message deleted.'),
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting message:', error);
      setSnackbar({
        open: true,
        message: t('Failed to delete message.'),
        severity: 'error',
      });
    }
  };

  // Handle editing a message
  const handleEdit = async (messageId, newText) => {
    try {
      await updateMessage(roomId, messageId, newText);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId
            ? { ...msg, msg: newText, editedAt: new Date().toISOString() }
            : msg
        )
      );
      setSnackbar({
        open: true,
        message: t('Message edited.'),
        severity: 'success',
      });
    } catch (error) {
      console.error('Error editing message:', error);
      setSnackbar({
        open: true,
        message: t('Failed to edit message.'),
        severity: 'error',
      });
    }
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Auto-scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Determine chat type
  const otherUserId = isDirectChat
    ? directChatInfo.participants.find((username) => username !== currentUserId)
    : null;

  const otherUserName = otherUserId ? getUsernameById(otherUserId) : '';

  // Early return for loading and error states
  if (chatLoading || !room) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (chatError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{chatError}</Typography>
      </Box>
    );
  }

  // Helper function to group messages with date separators
  const renderMessagesWithDateSeparators = () => {
    const messagesWithSeparators = [];
    let lastMessageDate = null;

    messages.forEach((message, index) => {
      const messageDate = moment(message.ts).startOf('day');

      if (!lastMessageDate || !messageDate.isSame(lastMessageDate)) {
        messagesWithSeparators.push(
          <DateSeparator key={`separator-${message.id}`} date={message.ts} />
        );
        lastMessageDate = messageDate;
      }

      messagesWithSeparators.push(
        <MessageItem
          key={message.id}
          message={{
            ...message,
            sender: {
              name: message.u?.name || 'Unknown',
              email: message.u?.email || '',
              avatar: message.u?.avatar || placeholderAvatar,
            },
          }}
          isOwnMessage={message.u?.username === currentUserId}
          isGroupChat={isGroupChat}
          onReact={handleReact}
          onReply={handleReply}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      );
    });

    return messagesWithSeparators;
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        p={2}
        bgcolor="#f5f5f5"
        borderBottom="1px solid #ddd"
      >
        {/* Back Arrow */}
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>

        {/* Chat Info */}
        <Avatar
          src={room.avatar || placeholderAvatar}
          alt={isGroupChat ? room.fname || room.name : otherUserName}
        />
        <Box ml={2} flexGrow={1}>
          <Typography variant="h6">
            {isGroupChat ? (room.fname || room.name) : otherUserName}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {isGroupChat ? t('Group Chat') : t('Direct Message')}
          </Typography>
        </Box>

        {/* 3-Dot Menu */}
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {isGroupChat
            ? [
                <MenuItem key="leave-group" onClick={handleLeaveGroup}>
                  {t('Leave Group')}
                </MenuItem>,
                <MenuItem key="invite-members" onClick={handleInviteMembers}>
                  {t('Invite Members')}
                </MenuItem>,
                <MenuItem key="group-details" onClick={handleGroupDetails}>
                  {t('Group Details')}
                </MenuItem>,
              ]
            : [
                <MenuItem key="delete-conversation" onClick={handleDeleteConversation}>
                  {t('Delete Conversation')}
                </MenuItem>,
                <MenuItem key="chat-details" onClick={handleChatDetails}>
                  {t('Chat Details')}
                </MenuItem>,
                <MenuItem key="mute-conversation" onClick={handleMuteConversation}>
                  {t('Mute Conversation')}
                </MenuItem>,
              ]}
        </Menu>
      </Box>

      {/* Typing Indicators */}
      {typingUsers.length > 0 && (
        <Box
          display="flex"
          alignItems="center"
          p={1}
          bgcolor="#f0f0f0"
          borderRadius={1}
          mb={1}
        >
          <Typography variant="body2" color="textSecondary">
            {typingUsers.join(', ')} {t('is typing...')}
          </Typography>
        </Box>
      )}

      {/* Messages List */}
      <Box
        flexGrow={1}
        p={2}
        overflow="auto"
        bgcolor="#e5ddd5"
        ref={chatContainerRef}
        position="relative"
      >
        <List>
          {isLoadingMore && (
            <Box display="flex" justifyContent="center" mb={2}>
              <CircularProgress size={20} />
            </Box>
          )}
          {renderMessagesWithDateSeparators()}
          <div ref={messagesEndRef} />
        </List>

        {/* Emoji Picker */}
        {showEmojiPicker && (
          <Box position="absolute" bottom="100px" left="20px"> {/* Adjusted position */}
            <Picker
              data={data}
              onEmojiSelect={(emoji) => {
                setMessageText((prev) => prev + emoji.native);
                setShowEmojiPicker(false);
              }}
              theme="light" // or "dark"
            />
          </Box>
        )}
      </Box>

      {/* Reply Preview */}
      {replyTo && (
        <ReplyPreview reply={replyTo} onCancel={handleCancelReply} />
      )}

      {/* Message Input */}
      <Box
        display="flex"
        alignItems="center"
        p={2}
        bgcolor="#f5f5f5"
        borderTop="1px solid #ddd"
        position="relative"
      >
        {/* Attachments Component */}
        <Attachments roomId={roomId} />

        {/* Existing TextField for message input */}
        <TextField
          fullWidth
          multiline
          maxRows={4}
          variant="outlined"
          placeholder={t('Type a message')}
          value={messageText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {/* Emoji Picker Button */}
                <IconButton onClick={toggleEmojiPicker} aria-label={t('Select Emoji')}>
                  <InsertEmoticonIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* Send Button */}
        <IconButton color="primary" onClick={handleSendMessage} aria-label={t('Send Message')}>
          <SendIcon />
        </IconButton>
      </Box>

      {/* Reaction Menu */}
      <Menu
        anchorEl={anchorElReact}
        open={Boolean(anchorElReact)}
        onClose={handleCloseReactMenu}
      >
        {availableReactions.map((emoji) => (
          <MenuItem
            key={emoji}
            onClick={() => handleAddReaction(emoji)}
            style={{ fontSize: '1.5rem' }}
          >
            {emoji}
          </MenuItem>
        ))}
      </Menu>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ChatDetails;
