// src/components/chats/DateSeparator.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

const DateSeparator = ({ date }) => {
  let displayDate;

  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const messageDate = moment(date).startOf('day');

  if (messageDate.isSame(today)) {
    displayDate = 'Today';
  } else if (messageDate.isSame(yesterday)) {
    displayDate = 'Yesterday';
  } else {
    displayDate = moment(date).format('Do MMM YYYY');
  }

  return (
    <Box display="flex" justifyContent="center" my={2}>
      <Typography variant="caption" color="textSecondary">
        {displayDate}
      </Typography>
    </Box>
  );
};

export default DateSeparator;
