import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ReplyComponent.module.css';
import profilePicture from '../../assets/images/user-avatar.png';
import attachmentIcon from '../../assets/images/attachment-icon.png';

const AttachmentSection = ({ onFileSelect, selectedFile }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [preview, setPreview] = useState(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onFileSelect(file);

      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setPreview(null);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  return (
    <div className={styles.attachmentSection}>
      <div className={styles.attachmentBar}>
        <div className={styles.uploadAttachment}>
          <img
            loading="lazy"
            src={preview || profilePicture}
            alt={t('Profile')}
            className={styles.profilePicture}
          />
          <label className={styles.attachmentText}>
            {t('Upload Attachments')}
            <input
              type="file"
              accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleFileChange}
              ref={fileInputRef}
              className={styles.fileInput}
            />
          </label>
        </div>
        <div className={styles.attachmentControls}>
          <div className={styles.attachmentDivider} />
          <img
            loading="lazy"
            src={attachmentIcon}
            alt={t('Attachment')}
            className={styles.attachmentIcon}
            onClick={handleIconClick}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      {selectedFile && (
        <div className={styles.selectedFile}>
          <span>{selectedFile.name}</span>
        </div>
      )}
      <div className={styles.horizontalDivider} />
    </div>
  );
};

export default AttachmentSection;
