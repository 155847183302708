// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from "react";

// Create the AuthContext
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  // Initialize authState from localStorage
  const storedAuthState = localStorage.getItem("authState");
  const initialAuthState = storedAuthState
    ? JSON.parse(storedAuthState)
    : {
        isAuthenticated: false,
        accessToken: null,
        rocketchatToken: null,
        user: null,
        currentOrganisationId: null,
      };

  const [authState, setAuthState] = useState(initialAuthState);
  const [loading, setLoading] = useState(true);

  // Function to handle login
  const login = (token, rocketchatToken, userData, currentOrganisationId) => {
    if (!token) {
      // If userData is undefined, clear localStorage
      console.error(
        "User data is undefined, clearing localStorage and logging out."
      );

      // Clear auth-related localStorage data
      localStorage.clear();

      // Optionally, you could trigger a logout as well
      setAuthState({
        isAuthenticated: false,
        accessToken: null,
        rocketchatToken: null,
        user: null,
        currentOrganisationId: null,
      });

      return; // Exit the function, and prevent further execution
    }

    const newAuthState = {
      isAuthenticated: true,
      accessToken: token,
      rocketchatToken,
      user: userData,
      currentOrganisationId: currentOrganisationId,
    };

    setAuthState(newAuthState);

    // Save authState in localStorage
    localStorage.setItem("authState", JSON.stringify(newAuthState));
    localStorage.setItem("accessToken", token);
    localStorage.setItem("rocketchatToken", rocketchatToken);
  };

  // Function to handle logout
  const logout = () => {
    const newAuthState = {
      isAuthenticated: false,
      accessToken: null,
      rocketchatToken: null,
      user: null,
      currentOrganisationId: null,
    };

    setAuthState(newAuthState);

    // Remove authState from localStorage
    localStorage.removeItem("authState");
    localStorage.removeItem("rocketchatToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("hasShownPopup");
    localStorage.clear();
  };

  // Function to update the access token
  const updateAccessToken = (newToken) => {
    const updatedAuthState = {
      ...authState,
      accessToken: newToken,
    };
    setAuthState(updatedAuthState);
    localStorage.setItem("authState", JSON.stringify(updatedAuthState));
    localStorage.setItem("accessToken", newToken);
  };

  // Sync authState to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("authState", JSON.stringify(authState));
    localStorage.setItem("accessToken", authState.accessToken || "");
    localStorage.setItem("rocketchatToken", authState.rocketchatToken || "");
  }, [authState]);

  // Check if authState exists in localStorage on app load
  useEffect(() => {
    const storedAuthState = localStorage.getItem("authState");
    if (storedAuthState) {
      const parsedAuthState = JSON.parse(storedAuthState);
      setAuthState(parsedAuthState);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a spinner or any loading indicator
  }

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        login,
        logout,
        updateAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
