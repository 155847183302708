// src/components/MosaikFooter.js

import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './MosaikFooter.module.css';

import homeIcon from '../assets/images/home-icon.png';
import homeIconInverted from '../assets/images/home-iconinverted.png';
import calendarIcon from '../assets/images/calendar-icon.png';
import calendarIconInverted from '../assets/images/calendar-iconinverted.png';
import teamsIcon from '../assets/images/teams-icon.png';
import teamsIconInverted from '../assets/images/teams-iconinverted.png';
import chatsIcon from '../assets/images/chats-icon.png';
import chatsIconInverted from '../assets/images/chats-iconinverted.png';
import alertsIcon from '../assets/images/alerts-icon.png';
import alertsIconInverted from '../assets/images/alerts-iconinverted.png';
import elipse from '../assets/icons/elipse.svg';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../context/NotificationContext'; // Import NotificationContext

const MosaikFooter = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('/mosaik-home');
  const { alerts } = useContext(NotificationContext); // Use NotificationContext

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  const getIcon = (tab, regularIcon, invertedIcon) => {
    return activeTab === tab ? (
      <img src={invertedIcon} alt="" className={styles.invertedIcon} />
    ) : (
      <img src={regularIcon} alt="" />
    );
  };

  const footerItems = [
    { path: '/mosaik-home', icon: homeIcon, iconInverted: homeIconInverted, label: t('Home') },
    { path: '/mosaik-calendar', icon: calendarIcon, iconInverted: calendarIconInverted, label: t('Calendar') },
    { path: '/mosaik-teams', icon: teamsIcon, iconInverted: teamsIconInverted, label: t('Teams') },
    { path: '/mosaik-chats', icon: chatsIcon, iconInverted: chatsIconInverted, label: t('Chats') },
    { path: '/mosaik-alerts', icon: alertsIcon, iconInverted: alertsIconInverted, label: t('Alerts') },
  ];

  return (
    <footer className={styles.footerContainer}>
      {footerItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.path}
          className={styles.footerItem}
          isActive={() => activeTab === item.path}
        >
          {getIcon(item.path, item.icon, item.iconInverted)}
          <span>{item.label}</span>
          {item.path === '/mosaik-alerts' && alerts && alerts.length > 0 && (
            <div className={styles.alertCounter}>
              {alerts.length}
            </div>
          )}
          {activeTab === item.path && (
            <div className={styles.activeIndicator}>
              <img src={elipse} alt={t('Active Indicator')} />
            </div>
          )}
        </NavLink>
      ))}
    </footer>
  );
};

export default MosaikFooter;
