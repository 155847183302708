import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const API_URL = `${apiUrl}/`;

class AuthService {
  async login(emailOrMobile, password) {
    return axios
      .post(API_URL + "userlogin", {
        emailOrMobile,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("rocketchatToken", response.data.authToken);
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("hasShownPopup");
  }

  async register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  // Updated method to fetch users
  async getUsers() {
    try {
      // Log in as admin to get the JWT token
      const loginResponse = await axios.post(API_URL + "login", {
        username: "admin",
        password: "admin",
      });

      const token = loginResponse.data.accessToken;

      // Use the JWT token to fetch users
      const response = await axios.get(API_URL + "users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
}

export default new AuthService();
