// src/components/Page.js
import React, { useState, useContext } from "react";
import { createReactInlineContentSpec } from "@blocknote/react";
import { AuthContext } from "../../context/AuthContext"; // Import AuthContext
import { EditorContext } from "../../context/EditorContext"; // Import EditorContext

// Define the PageComponent to manage state and context
import { useEffect, useRef } from "react";

const PageComponent = ({ title, link, blockId }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null); // Create a ref for the menu element

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handlePermissions = () => {
    const pageId = link.split("/").pop(); // Extract the page ID from the link
    console.log("Open permissions modal for", pageId);
  };

  const { authState } = useContext(AuthContext); // Access authState from AuthContext
  const editor = useContext(EditorContext); // Access editor from EditorContext
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = authState.accessToken;

  const handleRemove = async () => {
    const pageId = link.split("/").pop(); // Extract the page ID from the link

    console.log("Attempting to remove block with ID:", blockId);

    if (editor) {
      try {
        editor.removeBlocks([blockId]);
        console.log("Block removed from the frontend:", blockId);
      } catch (err) {
        console.error("Error removing block from frontend:", err);
      }
    } else {
      console.error("Editor instance is undefined.");
    }

    try {
      const response = await fetch(`${apiUrl}/libraryContents/${pageId}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Failed to remove the page from the backend");
      }

      console.log("Page removed from backend:", pageId);
    } catch (error) {
      console.error("Error removing page from backend:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the menu if the user clicks outside of it
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div style={{ display: "inline-block", position: "relative" }}>
      📁
      <a
        href={link}
        rel="noopener noreferrer"
        style={{
          backgroundColor: "#f5f5f5",
          color: "#333333",
          padding: "5px",
          borderRadius: "4px",
          textDecoration: "none",
        }}
      >
        {title}
      </a>
      <span
        onClick={toggleMenu}
        style={{ cursor: "pointer", marginLeft: "8px" }}
      >
        &#x22EE;
      </span>
      {isMenuOpen && (
        <div
          ref={menuRef} // Attach the ref to the menu element
          style={{
            position: "absolute",
            top: "100%",
            right: "0",
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "4px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
            zIndex: 10,
          }}
        >
          <button
            style={{ padding: "8px", width: "100%", textAlign: "left" }}
            onClick={handlePermissions}
          >
            Permissions
          </button>
          {/* <button
            style={{ padding: "8px", width: "100%", textAlign: "left" }}
            onClick={handleRemove}
          >
            Remove
          </button> */}
        </div>
      )}
    </div>
  );
};

// The Page inline content spec.
export const Page = createReactInlineContentSpec(
  {
    type: "page",
    propSchema: {
      title: {
        default: "Untitled Page",
      },
      link: {
        default: "", // Default link value
      },
    },
    content: "none", // No additional content inside the page block itself
  },
  {
    render: (props) => {
      // Debugging: Log props to understand the structure
      console.log("Page inline content props:", props);

      // Attempt to retrieve blockId from props
      const blockId = props.inlineContent?.blockId || props.blockId;

      return (
        <PageComponent
          title={props.inlineContent.props.title}
          link={props.inlineContent.props.link}
          blockId={blockId} // Pass the block ID for deletion
        />
      );
    },
  }
);
