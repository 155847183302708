// src/context/AlertSettingsContext.js

import React, { createContext, useState, useContext } from 'react';

// Create the context
const AlertSettingsContext = createContext();

// Create the provider component
export const AlertSettingsProvider = ({ children }) => {
  // State to manage whether sound is enabled
  const [soundEnabled, setSoundEnabled] = useState(true); // Default to true

  // Function to toggle sound setting
  const toggleSound = () => {
    setSoundEnabled((prev) => !prev);
  };

  return (
    <AlertSettingsContext.Provider value={{ soundEnabled, toggleSound }}>
      {children}
    </AlertSettingsContext.Provider>
  );
};

// Custom hook to use the AlertSettingsContext
export const useAlertSettings = () => {
  const context = useContext(AlertSettingsContext);
  if (!context) {
    throw new Error('useAlertSettings must be used within an AlertSettingsProvider');
  }
  return context;
};
