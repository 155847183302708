import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import styles from "./RepairDetail.module.css";

const RepairAdminActions = ({ status, onChangeStatus }) => {
  const { t } = useTranslation(); // Destructure t from useTranslation hook

  const renderActions = () => {
    switch (status?.toLowerCase()) {
      case "pending":
        return (
          <button
            className={styles.changeStatusButton}
            onClick={() => onChangeStatus("approved")}
          >
            {t('Place Repair')}
          </button>
        );
      case "approved":
        return (
          <button
            className={styles.changeStatusButton}
            onClick={() => onChangeStatus("accepted")}
          >
            {t('Mark as Repaired')}
          </button>
        );
      case "accepted":
        return (
          <button
            className={styles.changeStatusButton}
            onClick={() => onChangeStatus("completed")}
          >
            {t('Mark as Completed')}
          </button>
        );
      default:
        return null;
    }
  };

  return <div className={styles.adminActionsContainer}>{renderActions()}</div>;
};

RepairAdminActions.propTypes = {
  status: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
};

export default RepairAdminActions;
