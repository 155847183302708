import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import PropTypes from 'prop-types'; // Import PropTypes
import styles from './GroupChatMembers.module.css';
import backArrowIcon from '../../assets/icons/arrow-back.png';
import submenuIcon from '../../assets/images/menu-icon.png'; // Replace with actual icon path
import userAvatarPlaceholder from '../../assets/images/placeholder.webp'; // Placeholder image for user avatars
import { useFetchGroupInfo } from '../../hooks/useFetchGroupInfo'; 


const GroupChatMembers = () => { // Accept members prop
  const { t } = useTranslation(); // Initialize t
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState(''); // State for search input
  const [showMenu, setShowMenu] = useState(null); // State for showing submenu
  const [showInviteModal, setShowInviteModal] = useState(false); // State for invite members modal
  const [searchTermTwo, setsearchTermTwo] = useState(''); // Search term for invite modal
  const [selectedUsers, setSelectedUsers] = useState([]);
  const submenuRef = useRef(null);

  const { roomInfo, error } = useFetchGroupInfo(id);
  const members = roomInfo?.members || [];

  // Filtered and sorted members based on the search term
  const filteredMembers = members.filter((member) =>
    member.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredUsers = members.filter(user => 
    user.fullName.toLowerCase().includes(searchTermTwo.toLowerCase())
  );

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term state
  };

  const handleSearchChangeTwo = (e) => {
    setsearchTermTwo(e.target.value); // Update invite modal search term state
  };

  const toggleMenu = (id) => {
    setShowMenu(showMenu === id ? null : id); // Toggle submenu visibility
  };

  const handleMenuAction = (action, member) => {
    alert(`${t(action)} ${t('on')} ${member.fullName}`); // Handle menu actions
    setShowMenu(null); // Close submenu after action
  };

  const handleClickOutside = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      setShowMenu(null); // Close submenu if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isAdmin = true; // Assume the current user is an admin for demonstration purposes

  // Separate members by role
  const owners = filteredMembers.filter((member) => member.role === 'admin');
  const moderators = filteredMembers.filter((member) => member.role === 'moderator');
  const generalMembers = filteredMembers.filter((member) => member.role === 'user');

  const handleUserSelect = (user) => {
    if (!selectedUsers.includes(user)) {
      setSelectedUsers([...selectedUsers, user]);
    } else {
      setSelectedUsers(selectedUsers.filter(u => u !== user));
    }
  };

  const handleUserSelectTwo = (user) => {
    if (!selectedUsers.includes(user)) {
      setSelectedUsers([...selectedUsers, user]);
    } else {
      setSelectedUsers(selectedUsers.filter(u => u !== user));
    }
  };

  return (
    <div className={styles.chatMembersPage}>
      <header className={styles.headerContainer}>
        <div className={styles.headerLeft} onClick={handleGoBack}>
          <img src={backArrowIcon} alt={t("Back")} className={styles.backArrow} />
        </div>
        <div className={styles.headerTitle}>
          <h2>{t('Group Members')}</h2>
        </div>
      </header>

      <div className={styles.searchBarContainer}>
        <input
          type="text"
          placeholder={t("Search a member...")}
          value={searchTerm}
          onChange={handleSearchChange}
          className={styles.searchBar}
        />
      </div>

      <div className={styles.membersSection}>
        {/* Group Owners */}
        <h3 className={styles.sectionTitle}>{t('Group Owners')}</h3>
        {owners.map((member) => (
          <div key={member.id} className={styles.memberItem}>
            <img src={member.avatar || userAvatarPlaceholder} alt={member.fullName} className={styles.avatar} />
            <div className={styles.memberInfo}>
              <span>{member.fullName}</span>
              <span className={styles.department}>{member.department}</span>
            </div>
            <button className={styles.submenuButton} onClick={() => toggleMenu(member.id)}>
              <img src={submenuIcon} alt={t("Menu")} />
            </button>
            {showMenu === member.id && (
              <div className={styles.submenu} ref={submenuRef}>
                <button onClick={() => handleMenuAction('Send Direct Message', member)}>
                  {t('Send Direct Message')}
                </button>
                {isAdmin && (
                  <>
                    <button onClick={() => handleMenuAction('Remove member', member)}>
                      {t('Remove member')}
                    </button>
                    <button onClick={() => handleMenuAction('Remove from Admin', member)}>
                      {t('Remove from Admin')}
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        ))}

        {/* Group Moderators */}
        <h3 className={styles.sectionTitle}>{t('Group Moderators')}</h3>
        {moderators.map((member) => (
          <div key={member.id} className={styles.memberItem}>
            <img src={member.avatar || userAvatarPlaceholder} alt={member.fullName} className={styles.avatar} />
            <div className={styles.memberInfo}>
              <span>{member.fullName}</span>
              <span className={styles.department}>{member.department}</span>
            </div>
            <button className={styles.submenuButton} onClick={() => toggleMenu(member.id)}>
              <img src={submenuIcon} alt={t("Menu")} />
            </button>
            {showMenu === member.id && (
              <div className={styles.submenu} ref={submenuRef}>
                <button onClick={() => handleMenuAction('Send Direct Message', member)}>
                  {t('Send Direct Message')}
                </button>
                {isAdmin && (
                  <>
                    <button onClick={() => handleMenuAction('Remove member', member)}>
                      {t('Remove member')}
                    </button>
                    <button onClick={() => handleMenuAction('Remove as a Moderator', member)}>
                      {t('Remove as a Moderator')}
                    </button>
                    <button onClick={() => handleMenuAction('Assign Adminship', member)}>
                      {t('Assign Adminship')}
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        ))}

        {/* Other Members */}
        <h3 className={styles.sectionTitle}>{t('Other Members')}</h3>
        {members.map((member) => (
          <div key={member.id} className={styles.memberItem}>
            <img src={member.avatar || userAvatarPlaceholder} alt={member.fullName} className={styles.avatar} />
            <div className={styles.memberInfo}>
              <span>{member.fullName}</span>
              <span className={styles.department}>{member.department}</span>
            </div>
            <button className={styles.submenuButton} onClick={() => toggleMenu(member.id)}>
              <img src={submenuIcon} alt={t("Menu")} />
            </button>
            {showMenu === member.id && (
              <div className={styles.submenu} ref={submenuRef}>
                <button onClick={() => handleMenuAction('Send Direct Message', member)}>
                  {t('Send Direct Message')}
                </button>
                {isAdmin && (
                  <>
                    <button onClick={() => handleMenuAction('Remove member', member)}>
                      {t('Remove member')}
                    </button>
                    <button onClick={() => handleMenuAction('Assign Moderator Rights', member)}>
                      {t('Assign Moderator Rights')}
                    </button>
                    <button onClick={() => handleMenuAction('Assign Adminship', member)}>
                      {t('Assign Adminship')}
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Invite Members Button */}
      <div className={styles.membersFooter}>
        <button className={styles.inviteButton} onClick={() => setShowInviteModal(true)}>
          {t('Invite Members')}
        </button>
      </div>

      {/* Invite Members Modal */}
      {showInviteModal && (
        <div className={styles.modal}>
          <div className={styles.mosaikModal}>
            <h3>{t('Invite Members')}</h3>
            <input
              type="text"
              placeholder={t("Search users...")}
              value={searchTermTwo}
              onChange={handleSearchChangeTwo}
              className={styles.searchInput}
            />
            <ul className={styles.userList}>
              {filteredUsers.map((user, index) => (
                <li
                  key={index}
                  className={`${styles.userItem} ${searchTermTwo && user.toLowerCase().includes(searchTermTwo.toLowerCase()) ? styles.highlight : ''}`}
                  onClick={() => handleUserSelectTwo(user)}
                >
                  {user} {selectedUsers.includes(user) && '✓'}
                </li>
              ))}
            </ul>
            <button className={styles.closeButton} onClick={() => setShowInviteModal(false)}>
              {t('Invite members')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupChatMembers;
