// src/components/MosaikRepairs/MosaikRepairs.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import MosaikWelcomeScreen from "../MosaikWelcomeScreen";
import welcomeIcon from "../../assets/images/repair-iconinverted.png";
import welcomeBgimage from "../../assets/images/repairs-bg.jpg";
import searchIcon from "../../assets/images/search-button.png";
import addIcon from "../../assets/images/plus-button.png";
import calendarIcon from "../../assets/images/calendar-iconinverted.png";
import styles from "./MosaikRepairs.module.css";
import sampleOne from "../../assets/images/repair-1.jpg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaSortAmountDownAlt } from "react-icons/fa";
import { AiOutlineAppstore } from "react-icons/ai";
import useRepairAdminMode from "../../hooks/useRepairAdminMode";
import CreateServiceProvider from "./CreateServiceProvider";
import RepairOne from "../../assets/images/r1.png";
import RepairTwo from "../../assets/images/r2.png";
import RepairThree from "../../assets/images/r3.png";
import RepairFour from "../../assets/images/r4.png";
import { getRepairImageUrl } from "../../utils/getFileUrl";

const MosaikRepairs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("Pending");
  const [repairs, setRepairs] = useState([]);
  const [filteredRepairs, setFilteredRepairs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddServiceProviderOpen, setIsAddServiceProviderOpen] = useState(false);
  const { repairAdminMode } = useRepairAdminMode();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchRepairs = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error(t("No token found"));
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/repairs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Use Promise.all to fetch all image URLs concurrently
        const mappedRepairs = await Promise.all(
          response.data.map(async (repair) => ({
            id: repair.id,
            name: repair.itemName || "N/A",
            category: repair.location || "N/A",
            priority: repair.priority || "N/A",
            refNumber: repair.repairRefNo || "N/A",
            date: formatDate(repair.createdAt),
            status: repair.status
              ? repair.status.charAt(0).toUpperCase() + repair.status.slice(1)
              : t("Pending"),
            image: await getRepairImageUrl(repair),
          }))
        );

        setRepairs(mappedRepairs);
      } catch (err) {
        console.error(t("Error fetching repairs:"), err);
        setError(t("Failed to fetch repairs. Please try again later."));
      } finally {
        setLoading(false);
      }
    };

    fetchRepairs();
  }, [apiUrl]);

  useEffect(() => {
    const filtered = repairs.filter(
      (repair) =>
        repair.status.toLowerCase() === statusFilter.toLowerCase() &&
        repair.refNumber.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRepairs(filtered);
  }, [searchTerm, repairs, statusFilter]);

  const openRepairDetail = (repairId) => {
    navigate(`/repair-detail/${repairId}`);
  };

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const goToCreateNewRepair = () => {
    navigate("/create-repair");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilter = (status) => {
    setStatusFilter(status);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? t("Invalid Date") : date.toLocaleDateString();
  };

  const calculateProgress = () => {
    const totalRepairs = repairs.length;
    const completedRepairs = repairs?.filter(
      (repair) => repair?.status?.toLowerCase() === "completed"
    ).length;
    const pendingRepairs = repairs?.filter(
      (repair) => repair?.status?.toLowerCase() === "pending"
    ).length;
    const failedRepairs = repairs?.filter(
      (repair) => repair?.status?.toLowerCase() === "failed"
    ).length;

    const completionRate = totalRepairs
      ? (completedRepairs / totalRepairs) * 100
      : 0;

    return {
      completed: completionRate,
      pending: totalRepairs ? (pendingRepairs / totalRepairs) * 100 : 0,
      failed: totalRepairs ? (failedRepairs / totalRepairs) * 100 : 0,
      activeRepairs: pendingRepairs,
      repairRequests: totalRepairs,
      totalRepairs: totalRepairs,
      completionRate: completionRate.toFixed(2),
    };
  };

  if (loading) {
    return (
      <div>
        <MosaikHeaderWithBack pageTitle={t("Repairs")} />
        <div className={styles.loading}>
          <p>{t("Loading repairs...")}</p>
        </div>
        <MosaikFooter />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <MosaikHeaderWithBack pageTitle={t("Repairs")} />
        <div className={styles.error}>
          <p>{error}</p>
        </div>
        <MosaikFooter />
      </div>
    );
  }

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t("Repairs")} />

      {repairAdminMode ? (
        <div className={styles.overviewSection}>
          <h3>{t("Overview")}</h3>
          <div className={styles.circularProgress}>
            <CircularProgressbar
              value={calculateProgress().completed}
              text={`${Math.round(calculateProgress().completed)}%`}
              styles={buildStyles({
                textColor: "#094F54",
                pathColor: "#094F54",
                trailColor: "#f3752c",
              })}
            />
          </div>
          <div className={styles.progressLegend}>
            <div>
              <span style={{ color: "#f3752c" }}>●</span> {t("Pending Repairs")}
            </div>
            <div>
              <span style={{ color: "#094F54" }}>●</span> {t("Completed Repairs")}
            </div>
            <div>
              <span style={{ color: "#C7D7D7" }}>●</span> {t("Failed")}
            </div>
          </div>
          <div className={styles.statsCards}>
            <div className={styles.statCard}>
              <img src={RepairOne} alt={t("repair one")} />
              <h4>{calculateProgress().activeRepairs}</h4>
              <p>{t("Active Repairs")}</p>
            </div>
            <div className={styles.statCard}>
              <img src={RepairTwo} alt={t("repair two")} />
              <h4>{calculateProgress().repairRequests}</h4>
              <p>{t("Repair Requests")}</p>
            </div>
            <div className={styles.statCard}>
              <img src={RepairThree} alt={t("repair three")} />
              <h4>{calculateProgress().totalRepairs}</h4>
              <p>{t("Total Repairs")}</p>
            </div>
            <div className={styles.statCard}>
              <img src={RepairFour} alt={t("repair four")} />
              <h4>{calculateProgress().completionRate}%</h4>
              <p>{t("Completion Rate")}</p>
            </div>
          </div>
        </div>
      ) : (
        <MosaikWelcomeScreen
          welcomeTitle={t("Repairs")}
          welcomeDescription={t(
            "Repairs enable users to generate repair requests directly from the Mosaik app instantly. You can also review old requests here."
          )}
          welcomeIcon={welcomeIcon}
          welcomeBgimage={welcomeBgimage}
        />
      )}

      <div className={`${styles.ticketSection}`}>
        {repairAdminMode && (
          <button
            onClick={() => setIsAddServiceProviderOpen(true)}
            className={styles.serviceProviderButton}
          >
            + {t("Add Service Provider")}
          </button>
        )}
        <div className={styles.ticketSectionHeader}>
          <h3>
            {repairAdminMode ? t("All Repair Requests") : t("My Repairs")}
          </h3>
          <div className={styles.ticketSearchAndAdd}>
            <button onClick={toggleSearchBar}>
              <img src={searchIcon} alt={t("Search")} />
            </button>
            <button onClick={goToCreateNewRepair}>
              <img src={addIcon} alt={t("Add")} />
            </button>
          </div>
        </div>

        <div className={styles.statusButtons}>
          <button
            className={statusFilter === "Pending" ? styles.active : ""}
            onClick={() => handleStatusFilter("Pending")}
          >
            {t("Pending")}
          </button>
          <button
            className={statusFilter === "Completed" ? styles.active : ""}
            onClick={() => handleStatusFilter("Completed")}
          >
            {t("Completed")}
          </button>
          <button
            className={statusFilter === "Failed" ? styles.active : ""}
            onClick={() => handleStatusFilter("Failed")}
          >
            {t("Failed")}
          </button>
          <button
            className={statusFilter === "Cancelled" ? styles.active : ""}
            onClick={() => handleStatusFilter("Cancelled")}
          >
            {t("Cancelled")}
          </button>
        </div>

        <div className={styles.sortAndFilter}>
          <button className={styles.sortButton}>
            <FaSortAmountDownAlt /> {t("Sort by latest")}
          </button>
          <button className={styles.gridButton}>
            <AiOutlineAppstore />
          </button>
        </div>

        {showSearchBar && (
          <div className={styles.searchField}>
            <input
              type="text"
              placeholder={t("Search by Product REF #")}
              value={searchTerm}
              onChange={handleSearch}
              className={styles.searchInput}
            />
          </div>
        )}

        {filteredRepairs.length > 0 ? (
          filteredRepairs.map((repair) => (
            <div
              key={repair.id}
              className={styles.ticketItem}
              onClick={() => openRepairDetail(repair.id)}
            >
              <img
                src={repair.image}
                alt={repair.name}
                className={styles.repairImage}
              />
              <div className={styles.ticketDetails}>
                <h4>{repair.name}</h4>
                <p className={styles.category}>{repair.category}</p>

                {repair.priority && (
                  <span
                    className={`${styles.priorityTag} ${
                      styles[repair.priority.toLowerCase()]
                    }`}
                  >
                    {t(repair.priority)}
                  </span>
                )}
              </div>
              <div className={styles.ticketButton}>
                <div className={styles.ticketDate}>
                  <img
                    src={calendarIcon}
                    alt={t("Date")}
                    className={styles.calendarIcon}
                  />
                  <span>{repair.date}</span>
                </div>
                <button className={styles.viewTicketButton}>{t("View")}</button>
              </div>
            </div>
          ))
        ) : (
          <p>{t("No repairs found matching your criteria.")}</p>
        )}
      </div>

      <div className={styles.submitNewTicketButton}>
        <button onClick={goToCreateNewRepair}>
          <span>+</span> {t("Submit a new")}
        </button>
      </div>

      <MosaikFooter />

      {isAddServiceProviderOpen && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <button
              className={styles.popupCloseButton}
              onClick={() => setIsAddServiceProviderOpen(false)}
            >
              &times;
            </button>
            <CreateServiceProvider
              onSuccess={() => setIsAddServiceProviderOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MosaikRepairs;
