// GroupsPage.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './GroupsPage.module.css';
import { AuthContext } from '../../context/AuthContext'; // Import AuthContext
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { placeHolder } from '../../constants';
import { getFileUrl } from '../../services/mosaikUploadService';

const GroupsPage = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [groupSearchTerm, setGroupSearchTerm] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);
  const { authState } = useContext(AuthContext); // Access authState from AuthContext
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = authState.accessToken;
  const userId = authState.user?.id;
  const currentOrganisationId = authState.currentOrganisationId;

  useEffect(() => {
    const fetchGroups = async () => {
      if (!currentOrganisationId || !token) {
        console.error(t('Missing organisation ID or access token.'));
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/groups`, {
          params: {
            'where[organisation][id]': currentOrganisationId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const groupsData = await Promise.all(
          response.data.map(async (group) => {
            const groupDetails = await axios.get(`${apiUrl}/groups/${group.id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            const groupData = groupDetails.data;
            const isOwner = groupData.owner.id === userId;
            const isMember = groupData.members.some((member) => member.id === userId);
            let groupImageUrl = placeHolder;
            console.log('group images',groupImageUrl,groupData)
            if (groupData.groupProfile && groupData.groupProfile.id) {
              try {
                groupImageUrl = await getFileUrl(groupData.groupProfile.id) || placeHolder;
                console.log('group images',groupImageUrl)
              } catch (imageError) {
                console.error(`Error fetching image for group ${group.id}:`, imageError);
                // Optionally, handle the error or set a different default image
              }
            }
            if (isOwner || isMember) {
              return {
                id: groupData.id,
                name: groupData.name,
                role: isOwner ? t('Owner') : t('Member'),
                img: groupImageUrl,
              };
            }

            return null;
          })
        );

        const validGroups = groupsData.filter((group) => group !== null);
        setGroups(validGroups);
        setFilteredGroups(validGroups);
      } catch (error) {
        console.error(t('Error fetching groups:'), error);
      }
    };

    fetchGroups();
  }, [apiUrl, token, userId, currentOrganisationId]);

  useEffect(() => {
    if (groupSearchTerm) {
      const filtered = groups.filter((group) =>
        group.name.toLowerCase().includes(groupSearchTerm.toLowerCase())
      );
      setFilteredGroups(filtered);
    } else {
      setFilteredGroups(groups);
    }
  }, [groupSearchTerm, groups]);

  const handleGroupClick = (group) => {
    navigate(`/group/${group.id}`, { state: group });
  };

  const handleAddGroup = () => {
    navigate('/add-group'); // Route for adding new groups
  };

  const handleSearchChange = (event) => {
    setGroupSearchTerm(event.target.value);
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Groups')} />
      <div className={styles.mosaikContainer}>
        <section className={styles.groupsSection}>
          <div className={styles.sectionHeader}>
            <h2>{t('Groups')}</h2>
            <div className={styles.sectionControls}>
              <button className={styles.addButton} onClick={handleAddGroup}>
                +
              </button>
            </div>
          </div>
          <input
            type="text"
            className={styles.searchInput}
            placeholder={t('Search groups...')}
            value={groupSearchTerm}
            onChange={handleSearchChange}
          />
          <div className={styles.groupList}>
            {filteredGroups.length > 0 ? (
              filteredGroups.map((group) => (
                <div
                  key={group.id}
                  className={styles.groupItem}
                  onClick={() => handleGroupClick(group)}
                >
                  <div className={styles.groupInfo}>
                  <img
                        src={group.img || placeHolder}
                        alt={group.name}
                        className={styles.groupImage}
                      />
                    <span>{group.name}</span>
                    <span className={styles.groupRole}>{group.role}</span>
                  </div>
                </div>
              ))
            ) : (
              <p>{t('No groups found.')}</p>
            )}
          </div>
        </section>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default GroupsPage;
