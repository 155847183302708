import { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const useFetchGroupInfo = (roomId) => {
  const [roomInfo, setRoomInfo] = useState(null);
  const members = roomInfo?.members;
  const name = roomInfo?.room.customFields?.name;
  const description = roomInfo?.room.customFields?.description;
  const avatar = roomInfo?.room.customFields?.avatar;
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRoomInfo = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${apiUrl}/chats/room/${roomId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRoomInfo(response.data);
      } catch (error) {
        setError(error);
        console.error('Error fetching room info:', error);
      }
    };

    fetchRoomInfo();
  }, [roomId]);

  return {
    roomInfo: {
      members,
      name,
      description,
      avatar,
    },
    error,
  }
};