// src/components/Editor.js

import { AiFillBulb, AiTwotoneBook } from "react-icons/ai"; 
import { FaWandSparkles, FaYoutube } from "react-icons/fa6";
import React, { useState, useMemo, useRef, useEffect, useContext } from "react";
import {
  BlockNoteSchema,
  defaultBlockSpecs,
  defaultInlineContentSpecs,
  insertOrUpdateBlock,
  defaultStyleSpecs,
} from "@blocknote/core";
import {
  useCreateBlockNote,
  SuggestionMenuController,
  DragHandleButton,
  getDefaultReactSlashMenuItems,
  SideMenu,
  SideMenuController,
} from "@blocknote/react";
import {
  BlockColorsItem,
  DragHandleMenu,
  RemoveBlockItem,
} from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css"; // Mantine styles
import "./styles.css"; // Custom styles for your app
import { Mention, fetchUser } from "./Mentions"; // Import Mentions
import { uploadFile } from "./UploadFile"; // Import file upload handler
import { Page } from "./Page"; // Import the Page inline content
import { v4 as uuidv4 } from "uuid"; // Import uuid
import { AuthContext } from "../../context/AuthContext"; // Import AuthContext
import { EditorContext } from "../../context/EditorContext"; // Import EditorContext
import { useParams } from "react-router-dom";
import { CustomRemoveBlockButton } from "./RemoveButton";
import { handleAiPrompt, handleAiSubmit } from "./AiComponent";
import { Youtube } from "./Youtube";
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import axios from 'axios'; // Import Axios
import { marked } from "marked"; // Import marked for Markdown to HTML conversion
import DOMPurify from "dompurify"; // Import DOMPurify for sanitization

// Function to create a new page on the backend
const createNewPage = async (name, token, parentId) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const newPageData = {
    content: {},
    name: name,
    parentArticle: {
      id: parentId,
    },
  };

  try {
    const response = await fetch(`${apiUrl}/libraryContents`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newPageData),
    });

    if (!response.ok) {
      throw new Error("Failed to create new page");
    }

    const data = await response.json();
    return data; // Return the created page data, including its ID
  } catch (error) {
    console.error("Error creating page:", error);
  }
};

// Function to filter suggestion items based on query
const filterSuggestionItems = (items, query) => {
  return items.filter((item) =>
    item.title.toLowerCase().includes(query.toLowerCase())
  );
};

export default function LiveEditor({blocks, setBlocks}) {
  const { id } = useParams();
  const documentName = id;
  const ydocRef = useRef(new Y.Doc({ guid: documentName }));
  const providerRef = useRef(null);
  const editorViewRef = useRef(null);
  const [userName, setUserName] = useState(null);
  const [aiPromptVisible, setAiPromptVisible] = useState(false); // To control input visibility
  const [aiPromptInput, setAiPromptInput] = useState('');
  const [loading, setLoading] = useState(false); 
  const { authState } = useContext(AuthContext);
  const token = authState.accessToken;
  const randomUserName = `User_${Math.floor(Math.random() * 10000)}`;
  const [cursorPositions, setCursorPositions] = useState({});
  const [markdown, setMarkdown] = useState(""); // Markdown representation
  const [html, setHtml] = useState(""); // Rendered HTML
  const [rendering, setRendering] = useState(false); // To handle rendering state
  const [renderError, setRenderError] = useState(null); // To handle rendering errors



  // Initialize WebSocket Provider for Collaboration
  useEffect(() => {
    const initializeProvider = async () => {
      const authToken = localStorage.getItem('accessToken');
      if (!authToken) {
        console.error('Authentication token is required to connect.');
        return;
      }

      const room = documentName;
      const provider = new WebsocketProvider(
        `${process.env.REACT_APP_WEBSOCKET_URL}/${room}`,
        documentName,
        ydocRef.current,
        { params: { yauth: authToken } }
      );
      providerRef.current = provider;

      provider.awareness.setLocalStateField('user', {
        name: randomUserName,
      });

      provider.awareness.on('change', () => {
        const states = Array.from(provider.awareness.getStates().values());
        const updatedUsers = {};
        states.forEach(state => {
          if (state.user) {
            updatedUsers[state.user.name] = state.user;
          }
        });
        // Optionally, update cursor positions or user list here
      });
    };

    initializeProvider();

    return () => {
      if (providerRef.current) providerRef.current.destroy();
      if (ydocRef.current) ydocRef.current.destroy();
    };
  }, [documentName, randomUserName]);

  // Function to trigger page creation and navigate to it
  const handleCreateNewPage = async (editor) => {
    const pageName = prompt("Enter a name for the new page", "Untitled Page");
    if (!pageName) return;
    const newPage = await createNewPage(
      pageName,
      token,
      id
    );

    if (newPage && newPage.id) {
      editor.insertInlineContent([
        {
          type: "page",
          props: {
            blockId: newPage.id,
            title: pageName,
            link: `/mosaik-library/manual/${newPage.id}`,
          },
        },
        " ",
      ]);
    }
  };

  // Function to generate mention menu items
  const getMentionMenuItems = (editor) => {
    if (!userName) return [];
    const users = userName;
    return users?.map((user) => ({
      title: user.firstName,
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: "mention",
            props: {
              user: user.firstName,
            },
          },
          " ", // Add a space after the mention
        ]);
      },
    }));
  };

  // Define the schema
  const schema = useMemo(() => {
    return BlockNoteSchema.create({
      blockContentSpecs: {
        ...defaultBlockSpecs,
      },
      inlineContentSpecs: {
        ...defaultInlineContentSpecs,
        mention: Mention,
        page: Page, 
      },
      styleSpecs: {
        ...defaultStyleSpecs,
      },
      blockSpecs: {
        ...defaultBlockSpecs,
        page: Page,
        youtube: Youtube,
      },
    });
  }, []);

  const insertPage = (editor) => ({
    title: "Page",
    onItemClick: () => {handleCreateNewPage(editor,{type: "page",})},
    group: "Folder",
    icon: <AiTwotoneBook />,
  });

  const insertAi = (editor) => ({
    title: "Write",
    // onItemClick: () => {handleAiPrompt(editor,{type: "ai",})},
    onItemClick: () => {setAiPromptVisible(true)},
    group: "✨Mosaik Ai",
    icon: <FaWandSparkles
      style={{
        color: '#1a73e8', // Set the primary color for the AI icon
        cursor: 'pointer',  // Add a pointer cursor to make it feel clickable
      }}
    />,
  });

  const insertYoutube = (editor) => ({
    title: "Youtube",
    onItemClick: (event) => {
      insertOrUpdateBlock(editor, { type: "youtube" });
    },
    group: "media",
    icon: <FaYoutube />,
  });
  
  const handleAiSubmitClick = async () => {
    setLoading(true); // Show loader
    await handleAiPrompt(editor, aiPromptInput);
    setLoading(false); // Hide loader after submission
    setAiPromptVisible(false); // Hide the input after submitting
    setAiPromptInput(''); // Clear the input field
  };

  // Fetch users for mentions
  useEffect(() => {
    const fetchAndSetUser = async () => {
      const usersData = await fetchUser();
      const users = usersData?.map((user) => ({
        firstName: user.firstName,
        id: user.id,
      }));
      setUserName(users);
    };
    fetchAndSetUser();
  }, []);

  // Initialize the editor instance once blocks are fetched
  const editor = useCreateBlockNote({
    schema,
    initialContent:
    blocks.length > 0
    ? blocks
    : [
        {
          type: "paragraph",
          content: [""],
        },
      ],
    uploadFile,
    collaboration: {
      provider: providerRef.current,
      fragment: ydocRef.current.getXmlFragment('document-store'),
      user: {
        name: randomUserName,
      },
    },
  });

  // Update cursor positions based on the current selection
  useEffect(() => {
    const updateCursorPosition = () => {
      const position = editor.getTextCursorPosition();
      if (position) {
        setCursorPositions(prev => ({
          ...prev,
          [randomUserName]: position,
        }));
      }
    };

    const intervalId = setInterval(updateCursorPosition, 500); // Check cursor position every 500ms

    return () => clearInterval(intervalId);
  }, [editor, randomUserName]);

  // Function to handle conversion from Blocks to Markdown and then to HTML
  const handleConversion = async () => {
    try {
      // Convert Blocks to Markdown (Lossy)
      const md = await editor.blocksToMarkdownLossy(editor.document);
      setMarkdown(md);

      // Convert Markdown to HTML
      const rawHtml = marked(md);

      // Sanitize the HTML
      const sanitizedHtml = DOMPurify.sanitize(rawHtml);
      setHtml(sanitizedHtml);
    } catch (error) {
      console.error("Error during conversion:", error);
      // Optionally, set an error state here to inform the user
    }
  };

  // Effect to perform conversion on editor changes
  useEffect(() => {
    if (blocks) { // Ensure blocks are fetched before conversion
      handleConversion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocks, editor]);

  // Effect to sync editor changes to blocks state
  // useEffect(() => {
  //   const handleEditorChange = async () => {
  //     const updatedBlocks = editor.document;
  //     setBlocks(updatedBlocks);
  //     await handleConversion();
  //   };

  //   editor.on('change', handleEditorChange);

  //   return () => {
  //     editor.off('change', handleEditorChange);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [editor]);

  return (
    <EditorContext.Provider value={editor}>
      {/* Provide the editor instance via context */}
      <div className="wrapper">
        <div className="blocknote-container">
          {/* AI Prompt Popup */}
          {aiPromptVisible && (
            <div className="ai-popup-backdrop">
              <div className="ai-popup-container">
                <input
                  className="ai-input"
                  type="text"
                  placeholder="How to tie a tie"
                  value={aiPromptInput}
                  onChange={(e) => setAiPromptInput(e.target.value)} // Set the input value
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAiSubmitClick(); // Auto press Ask button on Enter key press
                    }
                  }}
                />
                <button
                  className={`ai-submit-button ${loading ? 'loading' : ''}`}
                  onClick={handleAiSubmitClick}
                  disabled={loading}
                >
                  {loading ? <div className="loader"></div> : 'Ask'}
                </button>
              </div>
            </div>
          )}

          {/* Rendered Output Section */}
          {/* <div className="rendered-output">
            <h2>Rendered HTML:</h2>
            {renderError && <p className="error">{renderError}</p>}
            {html ? (
              <div
                className="rendered-html"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            ) : (
              <p>Loading rendered content...</p>
            )}
          </div> */}

          {/* BlockNote Editor */}
          <BlockNoteView
            editor={editor}
            onChange={() => { /* The change is handled by event listeners */ }}
            ref={editorViewRef}
            data-theming-css-variables-demo
            sideMenu={false}
            slashMenu={false}
          >
            {/* Suggestion menu for mentions */}
            <SuggestionMenuController
              triggerCharacter={"@"}
              getItems={async (query) =>
                filterSuggestionItems(getMentionMenuItems(editor), query)
              }
            />
            {/* Suggestion menu for creating a new page */}
            <SuggestionMenuController
              triggerCharacter={"/"}
              getItems={async (query) =>
                filterSuggestionItems(
                  [
                    ...getDefaultReactSlashMenuItems(editor),
                    insertPage(editor),
                    insertYoutube(editor),
                    insertAi(editor)
                  ],
                  query
                )
              }
            />
            {/* Block Menu Component Section */}
            <SideMenuController
              sideMenu={(props) => (
                <SideMenu
                  {...props}
                  dragHandleMenu={(props) => (
                    <DragHandleMenu {...props}>
                      {/* <RemoveBlockItem {...props}>Delete</RemoveBlockItem> */}
                      <CustomRemoveBlockButton {...props}>
                        Delete
                      </CustomRemoveBlockButton>
                      <BlockColorsItem {...props}>Colors</BlockColorsItem>
                    </DragHandleMenu>
                  )}
                />
              )}
            />
          </BlockNoteView>
        </div>
      </div>
    </EditorContext.Provider>
  );
}
