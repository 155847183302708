import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './CreateEvent.module.css';
import calendarIcon from '../../assets/images/calendar-iconinverted.png';
import clockIcon from '../../assets/images/clock.png';

const EventDateTime = ({ onDateTimeChange, dateTimeData }) => {
  const { t } = useTranslation(); // Initialize t
  // Initialize state based on `dateTimeData` prop
  const [selectedDate, setSelectedDate] = useState(dateTimeData?.startDate || moment().format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState(dateTimeData?.startTime || moment().format('HH:mm'));
  const [endDate, setEndDate] = useState(dateTimeData?.endDate || moment().format('YYYY-MM-DD'));
  const [endTime, setEndTime] = useState(dateTimeData?.endTime || moment().add(1, 'hours').format('HH:mm'));
  const [repeatOption, setRepeatOption] = useState(dateTimeData?.repeatOption || 'none');
  const [selectedDays, setSelectedDays] = useState(dateTimeData?.selectedDays || []);

  const days = [
    { id: 'SU', label: t('S') },
    { id: 'MO', label: t('M') },
    { id: 'TU', label: t('T') },
    { id: 'WE', label: t('W') },
    { id: 'TH', label: t('TH') },
    { id: 'FR', label: t('F') },
    { id: 'SA', label: t('SA') }
  ];

  // Update the internal state when `dateTimeData` changes to support editing
  useEffect(() => {
    if (dateTimeData) {
      setSelectedDate(dateTimeData.startDate || moment().format('YYYY-MM-DD'));
      setStartTime(dateTimeData.startTime || moment().format('HH:mm'));
      setEndDate(dateTimeData.endDate || moment().format('YYYY-MM-DD'));
      setEndTime(dateTimeData.endTime || moment().add(1, 'hours').format('HH:mm'));
      setRepeatOption(dateTimeData.repeatOption || 'none');
      setSelectedDays(dateTimeData.selectedDays || []);
    }
  }, [dateTimeData]);

  const handleDateChange = (type, e) => {
    const value = e.target.value;
    if (type === 'startDate') {
      setSelectedDate(value);
    } else {
      setEndDate(value);
    }
    // Send string data to parent
    onDateTimeChange({ startDate: type === 'startDate' ? value : selectedDate, startTime, endDate: type === 'endDate' ? value : endDate, endTime, repeatOption, selectedDays });
  };

  const handleTimeChange = (type, e) => {
    const value = e.target.value;
    if (type === 'startTime') {
      setStartTime(value);
    } else {
      setEndTime(value);
    }
    onDateTimeChange({ startDate: selectedDate, startTime: type === 'startTime' ? value : startTime, endDate, endTime: type === 'endTime' ? value : endTime, repeatOption, selectedDays });
  };

  const handleRepeatOptionChange = (e) => {
    const option = e.target.value;
    setRepeatOption(option);
    if (option !== 'custom') {
      setSelectedDays([]);
    }
    onDateTimeChange({ startDate: selectedDate, startTime, endDate, endTime, repeatOption: option, selectedDays });
  };

  const toggleDaySelection = (dayId) => {
    const updatedDays = selectedDays.includes(dayId)
      ? selectedDays.filter((d) => d !== dayId)
      : [...selectedDays, dayId];
    setSelectedDays(updatedDays);
    onDateTimeChange({ startDate: selectedDate, startTime, endDate, endTime, repeatOption, selectedDays: updatedDays });
  };

  return (
    <section className={styles.eventDateTime}>
      <h2 className={styles.sectionTitle}>{t("Event's Date & Time")}</h2>
      
      <div className={styles.dateTimeContainer}>
        <div className={styles.dateTimeBlock}>
          <label htmlFor="startDate" className={styles.formLabel}>{t('Start Date')}</label>
          <div className={styles.inputWrapper}>
            <img src={calendarIcon} alt={t('Calendar')} className={styles.inputIcon} />
            <input
              id="startDate"
              type="date"
              value={selectedDate}
              onChange={(e) => handleDateChange('startDate', e)}
              className={styles.dateTimeField}
            />
          </div>
        </div>

        <div className={styles.dateTimeBlock}>
          <label htmlFor="startTime" className={styles.formLabel}>{t('Start Time')}</label>
          <div className={styles.inputWrapper}>
            <img src={clockIcon} alt={t('Clock')} className={styles.inputIcon} />
            <input
              id="startTime"
              type="time"
              value={startTime}
              onChange={(e) => handleTimeChange('startTime', e)}
              className={styles.dateTimeField}
            />
          </div>
        </div>

        <div className={styles.dateTimeBlock}>
          <label htmlFor="endDate" className={styles.formLabel}>{t('End Date')}</label>
          <div className={styles.inputWrapper}>
            <img src={calendarIcon} alt={t('Calendar')} className={styles.inputIcon} />
            <input
              id="endDate"
              type="date"
              value={endDate}
              onChange={(e) => handleDateChange('endDate', e)}
              className={styles.dateTimeField}
            />
          </div>
        </div>

        <div className={styles.dateTimeBlock}>
          <label htmlFor="endTime" className={styles.formLabel}>{t('End Time')}</label>
          <div className={styles.inputWrapper}>
            <img src={clockIcon} alt={t('Clock')} className={styles.inputIcon} />
            <input
              id="endTime"
              type="time"
              value={endTime}
              onChange={(e) => handleTimeChange('endTime', e)}
              className={styles.dateTimeField}
            />
          </div>
        </div>
      </div>

      <div className={styles.repeatOption}>
        <span>{t('Repeat')}</span>
        <select
          value={repeatOption}
          onChange={handleRepeatOptionChange}
          className={styles.customRepeat}
        >
          <option value="none">{t('None')}</option>
          <option value="weekday">{t('Weekday')}</option>
          <option value="daily">{t('Daily')}</option>
          <option value="weekly">{t('Weekly')}</option>
          <option value="monthly">{t('Monthly')}</option>
          <option value="quarterly">{t('Quarterly')}</option>
          <option value="yearly">{t('Yearly')}</option>
          <option value="custom">{t('Custom Days')}</option>
        </select>
      </div>
      
      {repeatOption === 'custom' && (
        <div className={styles.daysSelection}>
          {days.map((day) => (
            <button
              key={day.id}
              className={`${styles.dayButton} ${selectedDays.includes(day.id) ? styles.selectedDay : ''}`}
              onClick={() => toggleDaySelection(day.id)}
            >
              {day.label}
            </button>
          ))}
        </div>
      )}
    </section>
  );
};

export default EventDateTime;
