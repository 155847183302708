import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HomePage.module.css';

// Importing images locally
import logo from '../assets/images/logo.png';
import divider from '../assets/images/divider.png';

import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function HomePage() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]); // State to hold the list of users
  const { authState, logout } = useContext(AuthContext); // Use authState and logout from AuthContext
  const navigate = useNavigate(); // Initialize navigate

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = authState.accessToken;
  const user = authState.user;

  const handleSignOut = () => {
    // Use the logout function from AuthContext
    logout();
    navigate('/login');
  };

  // Fetch users when component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (Array.isArray(response.data)) {
          setUsers(response.data);
        } else {
          setUsers([]);
        }
      } catch (error) {
        console.error(t('Error fetching users:'), error);
        setUsers([]);
      }
    };

    if (token) {
      fetchUsers();
    } else {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [token, apiUrl, navigate, t]);

  let userRoles;
  if (Array.isArray(user.roles)) {
    userRoles = user.roles.join(', ');
  } else if (typeof user.roles === 'string') {
    userRoles = user.roles;
  } else if (typeof user.roles === 'object') {
    userRoles = JSON.stringify(user.roles);
  } else {
    userRoles = t('user');
  }

  return (
    <main className={styles.loginContainer}>
      <img src={logo} alt={t('Mosaik Logo')} className={styles.logo} />
      <section className={styles.loginCard}>
        <h1 className={styles.loginHeading}>{t('Home')}</h1>
        <img src={divider} alt="" className={styles.divider} />
        <p className={styles.loginSubtext}>{t('You can now start using Mosaik!')}</p>
        <div className={styles.homeContainer}>
          <h1>{t('Welcome, {{username}}', { username: user.username || user.email || user.mobile })}</h1>
          <p>{t('You are logged in to the Mosaik app')}</p>
          <p>{t('Your ID is: {{id}}', { id: user.id })}</p>
          <p>{t('Your Role is: {{role}}', { role: userRoles })}</p>
          <p>{t('Your Token is:')}</p>
          <p>{token}</p>
        </div>

        <div className={styles.usersTableContainer}>
          <h2>{t('Registered Users')}</h2>
          <table className={styles.usersTable}>
            <thead>
              <tr>
                <th>{t('First Name')}</th>
                <th>{t('Last Name')}</th>
                <th>{t('Email')}</th>
              </tr>
            </thead>
            <tbody>
              {users.map((userItem, index) => (
                <tr key={index}>
                  <td>{userItem.firstName}</td>
                  <td>{userItem.lastName}</td>
                  <td>{userItem.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <p className={styles.signupPrompt}>
          <button onClick={handleSignOut} className={styles.signupLink}>
            {t('Sign out')}
          </button>
        </p>
      </section>
    </main>
  );
}

export default HomePage;
