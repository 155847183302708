import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SupportMessage.module.css';

const SupportMessage = ({ avatar, userName, userRole, date, time, message }) => {
  const { t } = useTranslation();

  return (
    <article className={styles.container}>
      <div className={styles.messageWrapper}>
        <div className={styles.messageContent}>
          <header className={styles.messageHeader}>
            <img loading="lazy" src={avatar} alt={`${userName} ${t('avatar')}`} className={styles.avatar} />
            <div className={styles.divider} />
            <div className={styles.userInfo}>
              <h2 className={styles.userName}>{userName}</h2>
              <p className={styles.userRole}>{userRole}</p>
            </div>
          </header>
          <p className={styles.messageBody}>{message}</p>
          <div className={styles.messageDate}>
            <time className={styles.date}>{date}</time>
            <time className={styles.time}>{time}</time>
          </div>
        </div>
      </div>
    </article>
  );
};

export default SupportMessage;
