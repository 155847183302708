// src/hooks/useNotificationPermission.js

import { useEffect } from 'react';

const useNotificationPermission = () => {
  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission === 'default') {
        Notification.requestPermission();
      }
    }
  }, []);
};

export default useNotificationPermission;
