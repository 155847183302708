// src/components/MosaikTeams.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import MosaikWelcomeScreen from '../MosaikWelcomeScreen';
import welcomeIcon from '../../assets/images/teams-icon.png';
import welcomeBgimage from '../../assets/images/teams-bg.jpg';
import styles from './MosaikTeams.module.css';
import { FaCommentDots, FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';
import { placeHolder } from '../../constants';
import { useTranslation } from 'react-i18next';
import { getProfilePictureUrl } from '../../utils/getProfileUrl';
import { getFileUrl, deleteFile } from '../../services/mosaikUploadService'; // Added deleteFile if needed

const MosaikTeams = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  // State variables
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [employeeSearchTerm, setEmployeeSearchTerm] = useState('');
  const [groupSearchTerm, setGroupSearchTerm] = useState('');
  const [loadingEmployees, setLoadingEmployees] = useState(true);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [errorEmployees, setErrorEmployees] = useState(null);
  const [errorGroups, setErrorGroups] = useState(null);

  // Context
  const { authState } = useContext(AuthContext);
  const token = authState?.accessToken;
  const userId = authState?.user?.id;
  const currentOrganisationId = authState?.currentOrganisationId;

  // Fetch Employees
  useEffect(() => {
    const fetchEmployees = async () => {
      if (!token) {
        console.error(t('No authentication token found.'));
        setErrorEmployees(t('Authentication token is missing.'));
        setLoadingEmployees(false);
        return;
      }

      try {
        setLoadingEmployees(true);
        // Fetch employees in the current organization
        const response = await axios.get(`${apiUrl}/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(t('Fetched Employees:'), response.data);

        const employeesData = await Promise.all(
          response.data.map(async (user) => {
            try {
              // Fetch the profile picture URL using getProfilePictureUrl function
              const profilePicture = await getProfilePictureUrl(user) || placeHolder;

              return {
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                role: user.role || t('Employee'),
                email: user.email,
                img: profilePicture,
                muted: false,
              };
            } catch (profileError) {
              console.error(`Error fetching profile picture for user ${user.id}:`, profileError);
              return {
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                role: user.role || t('Employee'),
                email: user.email,
                img: placeHolder,
                muted: false,
              };
            }
          })
        );

        setEmployees(employeesData);
        setFilteredEmployees(employeesData);
      } catch (error) {
        console.error(t('Error fetching team members:'), error);
        setErrorEmployees(t('Failed to fetch employees. Please try again later.'));
      } finally {
        setLoadingEmployees(false);
      }
    };

    fetchEmployees();
  }, [apiUrl, token, currentOrganisationId, t]);

  // Fetch Groups
  useEffect(() => {
    const fetchGroups = async () => {
      if (!token) {
        console.error(t('No authentication token found.'));
        setErrorGroups(t('Authentication token is missing.'));
        setLoadingGroups(false);
        return;
      }

      try {
        setLoadingGroups(true);
        // Fetch groups in the current organization
        const response = await axios.get(`${apiUrl}/groups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const groupsData = await Promise.all(
          response.data.map(async (group) => {
            try {
              // Fetch detailed group information if not included in the initial response
              // If the initial /groups endpoint provides all necessary data, skip this step
              const groupDetails = await axios.get(`${apiUrl}/groups/${group.id}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });

              const groupData = groupDetails.data;

              // Determine if the current user is the owner or a member of the group
              const isOwner = groupData.owner.id === userId;
              const isMember = groupData.members.some((member) => member.id === userId);

              // Fetch the group profile image URL
              let groupImageUrl = placeHolder;
              console.log('group images',groupImageUrl,groupData)
              if (groupData.groupProfile && groupData.groupProfile.id) {
                try {
                  groupImageUrl = await getFileUrl(groupData.groupProfile.id) || placeHolder;
                  console.log('group images',groupImageUrl)
                } catch (imageError) {
                  console.error(`Error fetching image for group ${group.id}:`, imageError);
                  // Optionally, handle the error or set a different default image
                }
              }

              // Only include groups where the user is an owner or a member
              if (isOwner || isMember) {
                return {
                  id: groupData.id,
                  name: groupData.name,
                  role: isOwner ? t('Owner') : t('Member'),
                  img: groupImageUrl,
                };
              }

              // Exclude groups where the user is neither an owner nor a member
              return null;
            } catch (groupDetailsError) {
              console.error(`Error fetching details for group ${group.id}:`, groupDetailsError);
              return null; // Exclude this group from the final list
            }
          })
        );

        // Filter out any null entries resulting from errors or non-membership
        const validGroups = groupsData.filter((group) => group !== null);
        setGroups(validGroups);
        setFilteredGroups(validGroups);
      } catch (error) {
        console.error(t('Error fetching groups:'), error);
        setErrorGroups(t('Failed to fetch groups. Please try again later.'));
      } finally {
        setLoadingGroups(false);
      }
    };

    fetchGroups();
  }, [apiUrl, token, userId, currentOrganisationId, t]);

  // Employee Search Filtering
  useEffect(() => {
    if (employeeSearchTerm) {
      const filtered = employees.filter((employee) =>
        employee.name.toLowerCase().includes(employeeSearchTerm.toLowerCase())
      );
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees(employees);
    }
  }, [employeeSearchTerm, employees]);

  // Group Search Filtering
  useEffect(() => {
    if (groupSearchTerm) {
      const filtered = groups.filter((group) =>
        group.name.toLowerCase().includes(groupSearchTerm.toLowerCase())
      );
      setFilteredGroups(filtered);
    } else {
      setFilteredGroups(groups);
    }
  }, [groupSearchTerm, groups]);

  // Handlers
  const handleEmployeeSearchChange = (e) => {
    setEmployeeSearchTerm(e.target.value);
  };

  const handleGroupSearchChange = (e) => {
    setGroupSearchTerm(e.target.value);
  };

  const handleEmployeeClick = (employee) => {
    navigate(`/employee/${employee.id}`, { state: employee });
  };

  const handleGroupClick = (group) => {
    navigate(`/group/${group.id}`, { state: group });
  };

  const handleMessageClick = (e, userId) => {
    e.stopPropagation();
    navigate(`/new-chat?userId=${userId}`); // Use navigate instead of window.location.href for SPA navigation
  };

  const handleMuteClick = (e, employee) => {
    e.stopPropagation();
    setEmployees((prevEmployees) =>
      prevEmployees.map((emp) =>
        emp.id === employee.id ? { ...emp, muted: !emp.muted } : emp
      )
    );
  };

  const handleAddEmployee = () => {
    navigate('/add-employee');
  };

  const handleAddGroup = () => {
    navigate('/add-group');
  };

  const handleViewMoreEmployees = () => {
    navigate('/employees');
  };

  const handleViewAllGroups = () => {
    navigate('/groups');
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Teams')} />

      {/* Uncomment if you want to show the welcome screen */}
      {/* 
      <MosaikWelcomeScreen
        welcomeTitle={t('Teams')}
        welcomeDescription={t(
          'Teams offers managers to manage their teams and employees, invite new employees to Mosaik, and allows employees to create and manage groups within the organisation.'
        )}
        welcomeIcon={welcomeIcon}
        welcomeBgimage={welcomeBgimage}
      /> 
      */}

      <div className={styles.mosaikContainer}>
        {/* Employees Section */}
        <section className={styles.employeesSection}>
          <div className={styles.sectionHeader}>
            <h2>{t('Employees')}</h2>
            <div className={styles.sectionControls}>
              <button className={styles.addButton} onClick={handleAddEmployee}>
                +
              </button>
            </div>
          </div>
          <input
            type="text"
            className={styles.searchInput}
            placeholder={t('Search employees...')}
            value={employeeSearchTerm}
            onChange={handleEmployeeSearchChange}
          />
          {loadingEmployees ? (
            <p>{t('Loading employees...')}</p>
          ) : errorEmployees ? (
            <p className={styles.error}>{errorEmployees}</p>
          ) : (
            <div className={styles.employeeList}>
              {filteredEmployees.length > 0 ? (
                filteredEmployees.map((employee) => (
                  <div
                    key={employee.id}
                    className={styles.employeeItem}
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    <div className={styles.employeeInfo}>
                      <img
                        src={employee.img || placeHolder}
                        alt={employee.name}
                        className={styles.employeeImage}
                      />
                      <div>
                        <span className={styles.employeeName}>{employee.name}</span>
                        <span className={styles.employeeRole}>{employee.role}</span>
                      </div>
                    </div>
                    <div className={styles.employeeActions}>
                      <button
                        className={styles.actionButton}
                        onClick={(e) => handleMessageClick(e, employee.id)}
                        aria-label={t('Send Message')}
                      >
                        <FaCommentDots />
                      </button>
                      {/* Uncomment if mute functionality is needed */}
                      {/* 
                      <button
                        className={styles.actionButton}
                        onClick={(e) => handleMuteClick(e, employee)}
                        aria-label={employee.muted ? t('Unmute') : t('Mute')}
                      >
                        {employee.muted ? <FaMicrophoneSlash /> : <FaMicrophone />}
                      </button> 
                      */}
                    </div>
                  </div>
                ))
              ) : (
                <p>{t('No employees found.')}</p>
              )}
              <button
                className={styles.viewMoreButton}
                onClick={handleViewMoreEmployees}
              >
                {t('View More Employees')}
              </button>
            </div>
          )}
        </section>

        {/* Groups Section */}
        <section className={styles.groupsSection}>
          <div className={styles.sectionHeader}>
            <h2>{t('Groups')}</h2>
            <div className={styles.sectionControls}>
              <button className={styles.addButton} onClick={handleAddGroup}>
                +
              </button>
            </div>
          </div>
          <input
            type="text"
            className={styles.searchInput}
            placeholder={t('Search groups...')}
            value={groupSearchTerm}
            onChange={handleGroupSearchChange}
          />
          {loadingGroups ? (
            <p>{t('Loading groups...')}</p>
          ) : errorGroups ? (
            <p className={styles.error}>{errorGroups}</p>
          ) : (
            <div className={styles.groupList}>
              {filteredGroups.length > 0 ? (
                filteredGroups.map((group) => (
                  <div
                    key={group.id}
                    className={styles.groupItem}
                    onClick={() => handleGroupClick(group)}
                  >
                    <div className={styles.groupInfo}>
                      <img
                        src={group.img || placeHolder}
                        alt={group.name}
                        className={styles.groupImage}
                      />
                      <div>
                        <span className={styles.groupName}>{group.name}</span>
                        <span className={styles.groupRole}>{group.role}</span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>{t('No groups found.')}</p>
              )}
              <button
                className={styles.viewMoreButton}
                onClick={handleViewAllGroups}
              >
                {t('View all Groups')}
              </button>
            </div>
          )}
        </section>
      </div>

      <MosaikFooter />
    </div>
  );
};

export default MosaikTeams;
