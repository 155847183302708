// src/components/FeedbackDetail/FeedbackDetail.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import MessageCard from './MessageCard';
import SupportMessage from "./SupportMessage";
import ReplyComponent from './ReplyComponent';
import styles from './FeedbackDetail.module.css';
import supportAvatar from '../../assets/images/support-avatar.png';
import userAvatar from '../../assets/images/user-avatar.png';
import placeholderImage from '../../assets/images/placeholder.webp'; // Ensure you have this
import spinner from '../../assets/images/spinner.gif'; // Import spinner
import { getFeedbackImageUrls, getFeedbackReplyImageUrl } from "../../utils/getFileUrl"; // Import the new functions
import ImageWithFallback from '../ImageWithFallback/ImageWithFallback';


const FeedbackDetail = () => {
  const { t } = useTranslation(); // Initialize t
  const { id } = useParams(); // Get feedback ID from URL
  const [feedbackDetails, setFeedbackDetails] = useState(null);
  const [feedbackRepliesWithImages, setFeedbackRepliesWithImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  // Fetch feedback details (including replies) from a single API call
  useEffect(() => {
    const fetchFeedbackDetails = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const feedbackResponse = await axios.get(`${apiUrl}/feedbacks/detail/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        const fetchedFeedbackDetails = feedbackResponse.data;

        // Fetch the image URLs for the main feedback
        const feedbackImageUrls = await getFeedbackImageUrls(fetchedFeedbackDetails);

        // Fetch the image URLs for each feedback reply
        const feedbackReplies = fetchedFeedbackDetails.feedbackReplies || [];
        const feedbackRepliesWithImagesPromises = feedbackReplies.map(async (reply) => {
          const imageUrl = await getFeedbackReplyImageUrl(reply);
          return { ...reply, imageUrl };
        });

        const feedbackRepliesWithImages = await Promise.all(feedbackRepliesWithImagesPromises);

        // Enhance the feedbackDetails with imageUrls
        const enhancedFeedbackDetails = {
          ...fetchedFeedbackDetails,
          imageUrls: feedbackImageUrls,
        };

        setFeedbackDetails(enhancedFeedbackDetails);
        setFeedbackRepliesWithImages(feedbackRepliesWithImages);
      } catch (error) {
        console.error(t('Error fetching feedback details:'), error);
        navigate('/mosaik-feedback'); // Redirect on failure
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeedbackDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Reload feedback details upon successful reply
  const handleReplySuccess = () => {
    // Refetch feedback details to update replies
    setIsLoading(true);
    const fetchFeedbackDetails = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const feedbackResponse = await axios.get(`${apiUrl}/feedbacks/detail/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        const fetchedFeedbackDetails = feedbackResponse.data;

        // Fetch the image URLs for the main feedback
        const feedbackImageUrls = await getFeedbackImageUrls(fetchedFeedbackDetails);

        // Fetch the image URLs for each feedback reply
        const feedbackReplies = fetchedFeedbackDetails.feedbackReplies || [];
        const feedbackRepliesWithImagesPromises = feedbackReplies.map(async (reply) => {
          const imageUrl = await getFeedbackReplyImageUrl(reply);
          return { ...reply, imageUrl };
        });

        const feedbackRepliesWithImages = await Promise.all(feedbackRepliesWithImagesPromises);

        // Enhance the feedbackDetails with imageUrls
        const enhancedFeedbackDetails = {
          ...fetchedFeedbackDetails,
          imageUrls: feedbackImageUrls,
        };

        setFeedbackDetails(enhancedFeedbackDetails);
        setFeedbackRepliesWithImages(feedbackRepliesWithImages);
      } catch (error) {
        console.error(t('Error fetching feedback details:'), error);
        navigate('/mosaik-feedback'); // Redirect on failure
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeedbackDetails();
  };

  // Handle feedback deletion
  const handleDeleteFeedback = async () => {
    const confirmed = window.confirm(t('Are you sure you want to delete this feedback?'));
    if (!confirmed) return;

    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      await axios.delete(`${apiUrl}/feedbacks/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      navigate('/mosaik-feedback'); // Redirect after deletion
    } catch (error) {
      console.error(t('Error deleting feedback:'), error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Format the date
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString(); // Format the time
  };

  if (isLoading) {
    return (
      <main className={styles.container}>
        <MosaikHeaderWithBack pageTitle={t("Feedback")} />
        <div className={styles.loader}>
          <img src={spinner} alt={t("Loading...")} className={styles.spinner} />
        </div>
        <MosaikFooter />
      </main>
    );
  }

  if (!feedbackDetails) {
    return (
      <main className={styles.container}>
        <MosaikHeaderWithBack pageTitle={t("Feedback")} />
        <div className={styles.error}>
          {t("Failed to load feedback details. Please try again later.")}
        </div>
        <MosaikFooter />
      </main>
    );
  }

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t("Feedback")} />
      <div className={styles.ticketDetailContainer}>
        <div className={styles.ticketHeader}>
          <h4>{formatDate(feedbackDetails.createdAt)}</h4>
          <h2>{feedbackDetails.title}</h2>
          <p>{feedbackDetails.description}</p>
          {/* Display all feedback images if they exist */}
          {feedbackDetails.imageUrls && feedbackDetails.imageUrls.length > 0 && (
            <div className={styles.feedbackImagesContainer}>
              {feedbackDetails.imageUrls.map((url, index) => (
                <ImageWithFallback
                  key={index}
                  src={url}
                  alt={`${feedbackDetails.title || t("Feedback")} Image ${index + 1}`}
                  className={styles.feedbackImage}
                />
              ))}
            </div>
          )}
          <span className={styles.ticketId}>{t('Ticket ID')}: {feedbackDetails.id}</span>
          <button className={styles.deleteButton} onClick={handleDeleteFeedback}>
            {t('Delete Feedback')}
          </button>
        </div>
        {/* Past Messages */}
        <div className={styles.pastMessages}>
          {feedbackRepliesWithImages.map(reply => (
            reply.typeField === 'feedback_staff' ? (
              <SupportMessage
                key={reply.id}
                avatar={supportAvatar}
                userName={reply.authorName || t('Support Staff')}
                userRole={t('Support Staff')}
                date={formatDate(reply.updatedAt)}
                time={formatTime(reply.updatedAt)}
                message={reply.text}
                file={reply.imageUrl} // Pass the fetched imageUrl
              />
            ) : (
              <MessageCard
                key={reply.id}
                user={{ name: `${reply.user.firstName} ${reply.user.lastName}` }}
                date={formatDate(reply.updatedAt)}
                time={formatTime(reply.updatedAt)}
                message={reply.text}
                file={reply.imageUrl} // Pass the fetched imageUrl
              />
            )
          ))}
        </div>
        {/* Reply Component */}
        <ReplyComponent
          feedbackId={id}
          onReplySuccess={handleReplySuccess}
          feedbackStatus={feedbackDetails.status}
        />
      </div>
      <MosaikFooter />
    </div>
  );
};

export default FeedbackDetail;
