import profilePicPlaceholder from "../assets/images/noimage.png"
import { getFileUrl } from "../services/mosaikUploadService";


export async function getProfilePictureUrl(userData) {
  const fileId = userData.profilePhotoFile?.id;
  
  if (fileId) {
    const fetchedUrl = await getFileUrl(fileId);
    return fetchedUrl || profilePicPlaceholder;
  }

  return profilePicPlaceholder;
}
