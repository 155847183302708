// MosaikHeader.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styles from './MosaikHeader.module.css';
import profilePicPlaceholder from '../assets/images/placeholder.webp';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProfileManager from './ProfileManager';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { getFileUrl } from '../services/mosaikUploadService';
import { getProfilePictureUrl } from '../utils/getProfileUrl';

const MosaikHeader = ({ showProfileProgress, profileCompletion, onProfileClick }) => {
  const { t } = useTranslation();
  const [isProfileManagerVisible, setIsProfileManagerVisible] = useState(false);
  const [organisationLogo, setOrganisationLogo] = useState(null); // New state for organization logo
  const { authState, setAuthState } = useContext(AuthContext);
  const token = authState.accessToken;
  const loggedUserId = authState.user?.id;

  // Fetch user and organization details from the API
  useEffect(() => {
    if (loggedUserId && token) {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${loggedUserId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const userData = response.data;

          // Determine the profile picture URL
          // const profilePictureUrl = userData.profilePhotoFile?.fileUrl
          //   ? userData.profilePhotoFile.fileUrl.startsWith('https')
          //     ? userData.profilePhotoFile.fileUrl
          //     : `https://${userData.profilePhotoFile.fileUrl}`
          //   : profilePicPlaceholder;

          let profilePictureUrl = await getProfilePictureUrl(userData)

          // Update authState with the fetched user data
          setAuthState((prevState) => ({
            ...prevState,
            user: {
              ...prevState.user,
              id: userData.id,
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              mobile: userData.mobile,
              profilePicture: profilePictureUrl,
              status: userData.status, // Add user status
            },
          }));
        } catch (error) {
          console.error(t('Error fetching user details:'), error);
          if (error.response && error.response.status === 401) {
            // Handle unauthorized error
          }
        }
      };

      const fetchOrganisationLogo = async () => {
        if (authState.currentOrganisationId) {
          try {
            // const response = await axios.get(
            //   `${process.env.REACT_APP_API_URL}/organisations/${authState.currentOrganisationId}`,
            //   {
            //     headers: { Authorization: `Bearer ${token}` },
            //   }
            // );
            // const orgLogo = await getFileUrl(response.data?.files?.[0]?.id) || profilePicPlaceholder;

            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/organisations/${authState.currentOrganisationId}/files`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            const profileFiles = response.data.filter(
              (file) => file.moduleField === "profile"
            );
            let orgLogo = null;

            if (profileFiles.length > 0) {
              // Sort by createdAt descending and pick the latest file
              profileFiles.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              );
              const latestProfileFile = profileFiles[0];
              orgLogo = await getFileUrl(latestProfileFile.id); // Fetch URL using getFileUrl
              console.log( orgLogo); // Debug log
            } else {
              console.log(`No profile files found for org`);
            }

            
            // const orgLogo = response.data?.files?.[0]?.fileUrl
            //   ? `https://${response.data.files[0].fileUrl}`
            //   : profilePicPlaceholder;
            setOrganisationLogo(orgLogo); // Set organization logo
          } catch (error) {
            console.error(t("Error fetching organization logo:"), error);
          }
        }
      };

      fetchUserDetails();
      fetchOrganisationLogo();
    }
  }, [loggedUserId, token, setAuthState]);

  const toggleProfileManager = () => {
    setIsProfileManagerVisible(!isProfileManagerVisible);
  };

  return (
    <>
      <header className={styles.headerContainer}>
        <div className={styles.headerLeft}>
          <h2>{t('Home')}</h2>
        </div>
        {showProfileProgress && (
          <div
            className={styles.profileCompletion}
            onClick={onProfileClick}
          >
            <CircularProgressbar
              value={profileCompletion}
              text={`${profileCompletion}%`}
              styles={buildStyles({
                pathColor: "#094F54",
                textColor: "#094F54",
                trailColor: "#F3752C",
              })}
            />
          </div>
        )}
        <div className={styles.headerRight} onClick={toggleProfileManager}>
          <div
            className={styles.profilePic}
            style={{
              backgroundImage: `url(${authState.user?.profilePicture || profilePicPlaceholder})`,
            }}
          >
            {authState.user?.status === 'online' && (
              <span className={styles.onlineIndicator}></span>
            )}
          </div>
          <span>{authState.user?.firstName || t('Guest')}</span>
        </div>
        <div
          className={styles.mosaikSelectedOrg} // New div for selected organization logo
          style={{
            backgroundImage: `url(${organisationLogo || profilePicPlaceholder})`,
          }}
        ></div>
      </header>
      {isProfileManagerVisible && (
        <div className={styles.profileManagerOverlay} onClick={toggleProfileManager}>
          <ProfileManager closeProfile={toggleProfileManager} />
        </div>
      )}
    </>
  );
};

export default MosaikHeader;
