import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import styles from './CreateEvent.module.css';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import { useNavigate, useParams } from 'react-router-dom';
import CreateEvent from './CreateEvent'; // Use the combined CreateEvent component

const apiUrl = process.env.REACT_APP_API_URL;

const EditEvent = () => {
  const { t } = useTranslation(); // Initialize t
  const { eventId } = useParams(); // Get event ID from URL params
  const navigate = useNavigate();
  
  // States to hold event data
  const [initialData, setInitialData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch the existing event data on load
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${apiUrl}/events/${eventId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const event = response.data;

        // Map and structure event data to match CreateEvent's props
        setInitialData({
          title: event.title,
          description: event.description,
          displayPictureId: event.eventsDisplayPicture?.id || '',
          attendees: event.invitees.attendees.map((attendee) => attendee.id),
          agenda: event.agenda?.topics || [],
          notes: event.notes || '',
          startDate: moment(event.startDate).format('YYYY-MM-DD'),
          startTime: moment(event.startTime).format('HH:mm'),
          endDate: moment(event.endDate).format('YYYY-MM-DD'),
          endTime: moment(event.endTime).format('HH:mm'),
          repeatOption: event.recurrence,
          selectedDays: event.recurrenceCustomDays || [],
        });
        setLoading(false);
      } catch (error) {
        console.error(t('Error fetching event data:'), error);
        setLoading(false);
      }
    };

    fetchEventData();
  }, [eventId]);

  // Handle form submission for updating event
  const handleUpdateEvent = async (updatedEventData) => {
    try {
      const token = localStorage.getItem('accessToken');

      // Make a PATCH request to update the event
      const response = await axios.patch(`${apiUrl}/events/${eventId}`, updatedEventData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error(t('Failed to update event'));
      }

      console.log(t('Event updated:'), response.data);
      // Navigate to the calendar page or event details page
      navigate('/mosaik-calendar');
    } catch (error) {
      console.error(t('Error updating event:'), error);
    }
  };

  if (loading) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <main className={styles.editEvent}>
      <MosaikHeaderWithBack pageTitle={t('Edit Event')} />
      <div className={styles.EditEventContainer}>
        {/* Pass initial data and update handler to CreateEvent */}
        <CreateEvent
          initialData={initialData}
          isEdit={true}
          onSubmit={handleUpdateEvent} // Handle form submission for updating event
        />
      </div>
      <MosaikFooter />
    </main>
  );
};

export default EditEvent;
