const apiUrl = process.env.REACT_APP_API_URL;

  export const uploadFile = async (file) => {
    const token = localStorage.getItem('accessToken');
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('moduleField', 'library');
    formData.append('documentTitle', file.type+file.title);
    formData.append('fileMimeType', file.type);
  
    // Determine the media type dynamically based on file MIME type
    let mediaType;
    if (file.type.startsWith('image')) {
      mediaType = 'image';
    } else if (file.type.startsWith('video')) {
      mediaType = 'video';
    } else if (file.type.startsWith('audio')) {
      mediaType = 'audio';
    } else if (file.type === 'application/pdf') {
      mediaType = 'pdf';
    } else {
      mediaType = 'document';
    }
    formData.append('mediaType', mediaType);
  
    try {
      const response = await fetch(`${apiUrl}/files/upload/libraryContent`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) throw new Error('File upload failed');
  
      const data = await response.json();
      return data.fileUrl;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };
  
  