import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './SelectImage.module.css';

// Import images
import image1 from '../../assets/images/event-image.jpg';
import image2 from '../../assets/images/event-image.jpg';
import image3 from '../../assets/images/event-image.jpg';
import image4 from '../../assets/images/event-image.jpg';
import image5 from '../../assets/images/event-image.jpg';
import image6 from '../../assets/images/event-image.png';

const SelectImage = () => {
  const { t } = useTranslation(); // Initialize t
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState('');

  const predefinedImages = [
    { src: image1, alt: 'Dietry.jpg' },
    { src: image2, alt: '4434.jpeg' },
    { src: image3, alt: 'Dietry.jpg' },
    { src: image4, alt: '5554.jpeg' },
    { src: image5, alt: 'report.PDF' },
    { src: image6, alt: 'attendance.docx' },
  ];

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveImage = () => {
    navigate('/mosaik-calendar/create-event', { state: { selectedImage } });
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t("Event's Image")} />
      <div className={styles.selectImageContainer}>
        <h2>{t('Select Group Image')}</h2>
        <div className={styles.imageGrid}>
          {predefinedImages.map((image, index) => (
            <div
              key={index}
              className={`${styles.imageItem} ${
                selectedImage === image.src ? styles.selected : ''
              }`}
              onClick={() => handleImageSelect(image.src)}
            >
              <img src={image.src} alt={image.alt} className={styles.imageThumbnail} />
              <p>{image.alt}</p>
            </div>
          ))}
        </div>

        <div className={styles.uploadSection}>
          <h3>{t('Upload from device')}</h3>
          <div className={styles.uploadContainer}>
            {selectedImage && <img src={selectedImage} alt={t('Selected')} className={styles.uploadedImage} />}
            <input type="file" accept="image/*" onChange={handleUpload} />
          </div>
        </div>

        <button className={styles.saveButton} onClick={handleSaveImage}>
          {t('Save Group Image')}
        </button>
        <MosaikFooter />
      </div>
    </div>
  );
};

export default SelectImage;
