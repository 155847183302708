// src/components/chats/NewChat.js

import React, { useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Avatar,
  InputAdornment,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { useFetchUsers } from '../../hooks/useFetchUsers';
import { WebSocketContext } from '../../context/RocketchatContext.js';
import { useWebsocketSubscription } from '../../hooks/useWebsocketSubscription';
import { AuthContext } from '../../context/AuthContext';
import MosaikFooter from "../MosaikFooter";

function NewChat() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' | 'error' | 'warning' | 'info'
  const navigate = useNavigate();

  const { summarizedUsersWithoutCurrentUser, error } = useFetchUsers();

  const { createNewChat, getSocket } = useContext(WebSocketContext);
  const { authState } = useContext(AuthContext);
  const token = authState.accessToken;

  // WebSocket message handler
  const handleWebSocketMessage = useCallback((event) => {
    if (event.data) {
      try {
        const message = JSON.parse(event.data);

        // Check if the message is a response to createDirectMessage
        if (
          message.msg === 'result' &&
          message.result?.rid &&
          message.id.includes('createDirectMessage')
        ) {
          navigate(`/chat-details/${message.result.rid}`);
          setSnackbarMessage(t('Chat created successfully!'));
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
          console.log('Chat Created:', message.result.rid);
        }
      } catch (err) {
        console.error('Error parsing WebSocket message:', err);
      }
    }
  }, [navigate, t]);

  // Subscribe to WebSocket messages
  useWebsocketSubscription(getSocket(), handleWebSocketMessage);

  // Handle user selection
  const handleUserSelect = (userId) => {
    setSelectedUserId((prevSelected) => (prevSelected === userId ? null : userId));
  };

  // Handle creating a new chat
  const handleCreateChat = () => {
    if (!selectedUserId) {
      setSnackbarMessage(t('Please select a user to chat with.'));
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return;
    }

    // Find the selected user object
    const selectedUser = summarizedUsersWithoutCurrentUser.find(user => user.id === selectedUserId);
console.log(selectedUser);
    if (!selectedUser) {
      setSnackbarMessage(t('Selected user not found.'));
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    if (!selectedUser.id) {
      setSnackbarMessage(t('Selected user does not have a id.'));
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    // Initiate the creation of a new private chat
    createNewChat(selectedUser.id);
  };

  // Handle closing the snackbar
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Filter users based on the search term
  const filteredUsers = summarizedUsersWithoutCurrentUser.filter(user =>
    (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (user.department && user.department.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
    >
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        sx={{ borderBottom: '1px solid #ddd' }}
      >
        <IconButton onClick={() => navigate(-1)} aria-label={t("Back")}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="div">
          {t('New Chat')}
        </Typography>
        <IconButton aria-label={t("Menu")}>
          <MoreVertIcon />
        </IconButton>
      </Box>

      {/* Main Content */}
      <Box
        flexGrow={1}
        p={2}
        display="flex"
        flexDirection="column"
      >
        <Typography variant="h5" gutterBottom>
          {t('Start a New Chat')}
        </Typography>
        <TextField
          variant="outlined"
          placeholder={t("Search users...")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
        />
        <List>
          {filteredUsers.map((user) => (
            <ListItem key={user.id} disablePadding>
              <ListItemButton
                onClick={() => handleUserSelect(user.id)}
                selected={selectedUserId === user.id}
              >
                <Avatar src={user.avatar || undefined} alt={user.name} sx={{ mr: 2 }}>
                  {user.name ? user.name.charAt(0).toUpperCase() : '?'}
                </Avatar>
                <ListItemText
                  primary={user.name || t('Unknown')}
                  secondary={user.department ? `(${user.department})` : null}
                />
                {selectedUserId === user.id && (
                  <Tooltip title={t("Selected")}>
                    <Typography variant="body2" color="primary">
                      ✓
                    </Typography>
                  </Tooltip>
                )}
              </ListItemButton>
            </ListItem>
          ))}
          {filteredUsers.length === 0 && (
            <Box display="flex" justifyContent="center" mt={4}>
              <Typography variant="body1">
                {t('No users found.')}
              </Typography>
            </Box>
          )}
        </List>

        {/* Create Chat Button */}
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateChat}
            disabled={!selectedUserId}
          >
            {t('Start Chat')}
          </Button>
        </Box>
      </Box>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Footer */}
      <MosaikFooter />
    </Box>
  );
}

export default NewChat;
