import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './ViewEvent.module.css';

const ViewEvent = () => {
  const { t } = useTranslation(); // Initialize t
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState(null); // State to store event details
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch event details from the API
    const fetchEventDetails = async () => {
      const accessToken = localStorage.getItem('accessToken'); // Get token from localStorage

      try {
        const response = await fetch(`${apiUrl}/events/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();

        // Map API data to the structure expected by the component
        const mappedDetails = {
          id: data.id,
          title: data.title || t('No Title'),
          category: data.eventType || t('No Category'),
          date: moment(data.startDate).format('Do MMM'),
          year: moment(data.startDate).format('YYYY'),
          startTime: moment(data.startTime).format('hh:mm A'),
          endTime: moment(data.endTime).format('hh:mm A'),
          host: data.organiser?.id || t('Unknown Host'), // Assuming the organiser's name or ID is available
          attendees: data.invitees?.attendees.map(attendee => ({
            name: attendee.id, // Change this based on the correct attendee name field
            department: t('Unknown Department'), // Adjust this based on available department info
            profileImage: require('../../assets/images/attendees.png'), // Use a default image or fetch actual
          })) || [],
          agenda: data.agenda?.topics.map((topic) => {
            // Use regex to extract the title and time from "Title (Start - End)"
            const match = topic.match(/(.+)\s\((\d{2}:\d{2})\s-\s(\d{2}:\d{2})\)/);
            if (match) {
              return {
                time: `${match[2]} - ${match[3]}`, // Extracted start and end time
                title: match[1].trim(), // Extracted title
              };
            }
            // Fallback in case regex doesn't match expected format
            return { title: topic, time: t('TBD') };
          }) || [],
          hostNotes: data.notes || t('No additional notes.'),
        };

        setEventDetails(mappedDetails);
      } catch (error) {
        console.error(t('Error fetching event details:'), error);
      }
    };

    fetchEventDetails();
  }, [id, apiUrl, t]);

  // Show loading state if eventDetails is not yet fetched
  if (!eventDetails) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Event Details')} />
      <div className={styles.eventDetailsContainer}>
        <div className={styles.titleSection}>
          <div className={styles.lefter}>
            <h1>{eventDetails.title}</h1>
            <h4><span>{t('Events: ')} </span>{eventDetails.category}</h4>
          </div>
          <div className={styles.righter}>
            <img src={require('../../assets/images/bell-icon.png')} alt={t('bell-icon')} className={styles.bellIcon} />
          </div>
        </div>

        <div className={styles.eventBasics}>
          <div className={styles.eventPager}>
            <img src={require('../../assets/images/calendar-vector.png')} alt={t('calendar-vector')} className={styles.calendarVector} />
            <h4>{eventDetails.startTime} - {eventDetails.endTime}</h4>
            <h5>{eventDetails.date}</h5>
            <h6>{eventDetails.year}</h6>
          </div>
          <div>
            <img src={require('../../assets/images/event-image.jpg')} alt={t('event')} className={styles.eventmainImage} />    
          </div>
        </div>

        <div className={styles.eventHost}>
          <div>
            <img src={require('../../assets/images/profile-pic.png')} alt={t('profile picture')} className={styles.ProfilePictureCalendar} />
          </div>
          <div>
            <p>{t('Hosted by:')}</p>
            <h4>{eventDetails.host}</h4>
          </div>
        </div>

        <div className={styles.attendees}>
          <div className={styles.attendeesList}>
            <img src={require('../../assets/images/attendees.png')} alt={t('attendees')} />
            <h2>{t('Attendees:')}</h2>
          </div>
          <ul className={styles.EventAttendees}>
            {eventDetails.attendees.map((attendee, index) => (
              <li key={index} className={styles.attendeeItem}>
                <img src={attendee.profileImage} alt={attendee.name} className={styles.attendeeImage} />
                <div className={styles.attendeeInfo}>
                  <strong>{attendee.name}</strong> <br />
                  <small>{attendee.department}</small>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.agenda}>
          <div className={styles.attendeesList}>
            <img src={require('../../assets/images/agenda-icon.png')} alt={t('agenda-icon')} />
            <h2>{t('Agenda')}</h2>
          </div>
          <ul className={styles.agendapoints}>
            {eventDetails.agenda.map((item, index) => (
              <li key={index} className={styles.agendaItem}>
                <span className={styles.agendaText}>{item.title}</span>

                <span className={styles.agendaTime}>{item.time}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.hostNotes}>
          <div className={styles.attendeesList}>
            <img src={require('../../assets/images/hostnotes-icon.png')} alt={t('host notes')} />
            <h2>{t('Host Notes:')}</h2>
          </div>
          <p>{eventDetails.hostNotes}</p>
        </div>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default ViewEvent;
