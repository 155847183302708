// src/polyfills.js

// Polyfill for process
window.process = {
    env: {
      NODE_ENV: 'development', // or 'production' depending on your environment
    },
  };
  
  // Polyfill for Buffer
  window.Buffer = require('buffer/').Buffer;