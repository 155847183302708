import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ForgotPassword.module.css';
import logo from '../assets/images/logo.png';
import emailIcon from '../assets/icons/email-icon.png';
import backIcon from '../assets/icons/back-icon.png';
import axios from 'axios';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!email) {
      setError('Email is required');
      return;
    }
  
    try {
      // Send OTP request to the backend
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/request-otp`, {
        purpose: 'reset',  // Specify purpose as 'reset' for password reset
        method: 'email',
        destination: email,
      });
  
      // If the OTP is sent successfully, move to OTP verification page
      if (response.data && response.data.accessToken) {
        localStorage.setItem('accessToken', response.data.accessToken); // Save OTP token
        navigate('/otp-verification', { state: { email } }); // Pass email to OTP page
      } else {
        setError('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error?.response?.data || error.message);
      setError('Failed to process request. Please try again.');
    }
  };
  

  return (
    <main className={styles.forgotPasswordContainer}>
      <img src={logo} alt="Mosaik Logo" className={styles.logo} />
      <section className={styles.forgotPasswordCard}>
        <div className={styles.header}>
          <img src={backIcon} alt="Go back" className={styles.backIcon} onClick={() => navigate('/login')} />
          <h1 className={styles.heading}>Forgot Password</h1>
        </div>
        <div className={styles.divider}></div>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <p>Enter your email and we’ll send you an OTP to reset your password</p>
          <div className={styles.inputField}>
            <img src={emailIcon} alt="Email icon" className={styles.inputIcon} />
            <input
              type="text"
              placeholder="Email"
              className={styles.inputText}
              value={email}
              onChange={handleInputChange}
            />
          </div>
          {error && <p className={styles.errorText}>{error}</p>}
          <button type="submit" className={styles.submitButton}>
            Next
          </button>
        </form>
        <p className={styles.backToLogin}>
          <a onClick={() => navigate('/login')}>Back to Login</a>
        </p>
      </section>
    </main>
  );
};

export default ForgotPassword;
