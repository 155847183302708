import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import for translation
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './OrderSent.module.css';
import checkmarkIcon from '../../assets/icons/check-icon.png';
import moment from 'moment';

const OrderSent = () => {
  const { t } = useTranslation(); // Use translation hook
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = location.state || {}; // Retrieve orderId from the navigation state

  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('accessToken');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        if (!orderId) {
          throw new Error(t('Order ID is missing.'));
        }

        const orderResponse = await axios.get(`${apiUrl}/orders/detail/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to Authorization header
          },
        });

        const order = orderResponse.data;
        setOrderDetails(order);
      } catch (err) {
        console.error(t('Error fetching order details:'), err);
        setError(t('Failed to fetch order details. Please try again later.'));
      } finally {
        setLoading(false);
      }
    };

    if (orderId && token) {
      fetchOrderDetails();
    } else {
      console.error(t('Order ID or token is missing'));
    }
  }, [orderId, token, apiUrl]);

  const handleViewOrder = () => {
    navigate(`/order-detail/${orderId}`);
  };

  const formatDate = (dateString) => {
    return moment(dateString).isValid() ? moment(dateString).format('MMMM Do, YYYY') : t('Invalid Date');
  };

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  if (loading) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <div className={styles.container}>
      <MosaikHeaderWithBack pageTitle={t("Order Sent")} />
      <div className={styles.confirmation}>
        <img src={checkmarkIcon} alt={t("Success")} className={styles.checkmark} />
        <h1>{t("Order Sent")}</h1>
        <p>{t("Your Order request has been successfully registered by the PO Manager. Please find the order details below.")}</p>
        <div className={styles.orderSummary}>
          <div className={styles.orderDetails}>
            <h4>{t("Ref No")}: {orderDetails?.productRefNo || t('N/A')}</h4>
            <h4>{t("Product Name")}: {orderDetails?.product?.name || t('N/A')}</h4>
            <h4>{t("Priority")}: {orderDetails?.priority || t('N/A')}</h4>
            <h4>{t("Status")}: {orderDetails?.status || t('N/A')}</h4>
            <h4>{t("Supplier")}: {orderDetails?.supplier?.name || t('N/A')}</h4>
            <div className={styles.orderMeta}>
              <button className={styles.viewButton} onClick={handleViewOrder}>{t("View Order")}</button>
              <span className={styles.orderDate}>{t("Created On")}: {formatDate(orderDetails?.createdAt)}</span>
            </div>
          </div>
        </div>
      </div>
      <MosaikFooter />
    </div>
  );
};

export default OrderSent;
