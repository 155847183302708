// ManualPage.js
import React, { useEffect, useState, useCallback } from "react";
import styles from "./ManualPage.module.css";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import MosaikHeaderManual from "../MosaikHeaderManual";
import MosaikFooter from "../MosaikFooter";
import Editor from "../Editor/Editor";
import { extractTextFromBlocks } from "../../utils/extractText";
import { useTranslation } from 'react-i18next';
import { getFileUrl } from "../../services/mosaikUploadService";
import { getLibraryContentImageUrl } from "../../utils/getFileUrl";
import LiveEditor from "../Editor/LiveEditor";

const SAVE_INTERVAL = process.env.REACT_APP_SAVE_INTERVAL;

const ManualPage = () => {
  const { t } = useTranslation();
  const DEFAULT_NAME = t("Untitled Article");

  const { id } = useParams();
  const navigate = useNavigate();
  const [manualResponse, setManualResponse] = useState(null);
  const [blocks, setBlocks] = useState([]);
  const [documentName, setDocumentName] = useState(DEFAULT_NAME);
  const [isEdited, setIsEdited] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [manualImageId, setManualImageId] = useState(null); // State for the uploaded manual image
  const [manualImageUrl, setManualImageUrl] = useState(null); // State for the uploaded manual image

  const apiUrl = process.env.REACT_APP_API_URL;
  console.log('manualImageId', manualImageUrl);

  // Fetch the manual data when the component mounts
  useEffect(() => {
    const fetchManualData = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token || !id) return;

      try {
        const response = await axios.get(`${apiUrl}/libraryContents/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const { content, name, file } = response.data;

        console.log("Fetched title from backend:", name); // Debugging log

        setManualResponse(response.data);
        setBlocks(content || []); // Set blocks content
        setDocumentName(name || DEFAULT_NAME); // Fallback to default name if undefined
        console.log('setManualImageUrl', file);
        if (file?.id) {
          const imageUrl = await getLibraryContentImageUrl(response.data);
          setManualImageUrl(imageUrl);
        }
      } catch (error) {
        console.error(t("Error fetching manual data:"), error);
      }
    };

    fetchManualData();
  }, [id, apiUrl, DEFAULT_NAME]);

  // Mark the document as edited whenever blocks or document name change
  useEffect(() => {
    setIsEdited(true);
  }, [blocks, documentName]);

  const handleSave = useCallback(async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error(t("Authentication token not found. Please log in."));
      return;
    }

    let resourceTitle = documentName?.trim();
    if (
      (!resourceTitle ||
        resourceTitle.toLowerCase() === DEFAULT_NAME.toLowerCase()) &&
      blocks.length > 0
    ) {
      resourceTitle = extractTextFromBlocks(blocks);
    }
    resourceTitle = resourceTitle || DEFAULT_NAME; // Ensure title is set

    // Base payload
    const payload = {
      // content: blocks,
      name: resourceTitle,
    };

    // Conditionally add manualImageId to the payload
    if (manualImageId) {
      payload.file = { id: manualImageId };
    }

    console.log(
      "Saving title and file to backend:",
      resourceTitle,
      manualImageId
    );

    try {
      const response = await axios.patch(
        `${apiUrl}/libraryContents/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { name, file } = response.data;
        console.log("Title saved successfully:", name);

        // Update document name and manual image URL if present
        setDocumentName(name);
        console.log('setManualImageUrl', file);
        if (file?.fileUrl) {
          const imageUrl = await getLibraryContentImageUrl(response?.data);
          setManualImageUrl(imageUrl);
        }
        setIsEdited(false);
      } else {
        console.error(
          t("Error updating resource:"),
          response.status,
          response.data
        );
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          t("Error saving content:"),
          error.response.status,
          error.response.data
        );
      } else if (error.request) {
        // Request made but no response
        console.error(t("No response received:"), error.request);
      } else {
        // Error in setting up the request
        console.error(t("Error setting up request:"), error.message);
      }
    }
  }, [blocks, documentName, id, apiUrl, manualImageId, DEFAULT_NAME, t]);

  // Auto-save the document at regular intervals
  useEffect(() => {
    const autoSaveTimeout = setTimeout(() => {
      handleSave();
    }, SAVE_INTERVAL);

    return () => clearTimeout(autoSaveTimeout);
  }, [blocks, documentName, handleSave]);

  // Save the document when pressing Ctrl + S
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault();
        handleSave();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [blocks, documentName, handleSave]);

  // Handle deleting the document
  const handleDelete = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error(t("Authentication token not found. Please log in."));
      return;
    }

    try {
      const response = await axios.delete(`${apiUrl}/libraryContents/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log(t("Resource successfully deleted!"));
        navigate(`/mosaik-library`);
      } else {
        console.error(t("Error deleting resource:"), response.data);
      }
    } catch (error) {
      console.error(t("Error deleting content:"), error);
    } finally {
      setShowDeletePopup(false); // Close the popup
    }
  };

  // Handle saving the document name from the header
  const handleTitleSave = async (newTitle) => {
    setDocumentName(newTitle);

    // Save the new title to the backend
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error(t("Authentication token not found. Please log in."));
      return;
    }

    const payload = {
      name: newTitle,
      // content: blocks, // Keep the current blocks when updating the name
    };

    console.log("Saving new title:", newTitle); // Debugging log

    try {
      const response = await axios.patch(
        `${apiUrl}/libraryContents/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log(t("Document title updated successfully!"));
        setDocumentName(response.data.name); // Set the title correctly here after save
        setIsEdited(false);
      } else {
        console.error(t("Error updating document title:"), response.data);
      }
    } catch (error) {
      console.error(t("Error saving document title:"), error);
    }
  };

  const handleFileSave = async (FileId) => {
    setManualImageId(FileId);
    console.log("Saving document:", FileId);
    // Save the new file ID to the backend
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error(t("Authentication token not found. Please log in."));
      return;
    }

    const payload = {
      file: { id: FileId },
    };

    console.log("Saving new manualImageId:", FileId); // Debugging log

    try {
      const response = await axios.patch(
        `${apiUrl}/libraryContents/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log(t("manualImageId updated successfully!"));
        if (response?.data?.file?.id) {
          const imageUrl = await getLibraryContentImageUrl(response?.data);
          // const fetchedUrl = await getFileUrl(response?.data?.file?.id)
          setManualImageUrl(imageUrl);
        }
      } else {
        console.error(t("Error updating file:"), response.data);
      }
    } catch (error) {
      console.error(t("Error saving file:"), error);
    }
  };

  return (
    <>
      {/* Editable header using MosaikHeaderManual */}
      <MosaikHeaderManual
        initialPageTitle={documentName + (isEdited ? " *" : "")}
        onSaveTitle={handleTitleSave} // Update the document title when saved
        manualImageUrl={manualImageUrl}
        setManualImageId={setManualImageId}
        onFileUpload={handleFileSave}
        resourceId={id} // Pass the current manual's ID
      />
      <div className={styles.ManualDetailPage}>
        {/* Render the editor if manual data has been loaded */}
        {manualResponse?.content && (
          <LiveEditor blocks={blocks} setBlocks={setBlocks} />
        )}
      </div>
      <MosaikFooter />

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popup}>
            <h2>{t("Are you sure you want to delete this page?")}</h2>
            <div className={styles.popupButtons}>
              <button onClick={handleDelete} className={styles.confirmButton}>
                {t("Yes")}
              </button>
              <button
                onClick={() => setShowDeletePopup(false)}
                className={styles.cancelButton}
              >
                {t("Cancel")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManualPage;
