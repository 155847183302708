import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ErrorBoundary from './components/ErrorBoundary';
import reportWebVitals from "./reportWebVitals";
import ReactModal from "react-modal";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactModal.setAppElement("#root"); // Set the app element to your root ID
root.render(
  <React.StrictMode>
     <ErrorBoundary>
    <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
