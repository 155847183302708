// src/utils/notificationUtils.js

export const showNotification = (title, options) => {
  if (Notification.permission === 'granted') {
    const notification = new Notification(title, options);
    notification.onclick = (event) => {
      event.preventDefault();
      if (options.data && options.data.link) {
        window.focus();
        window.location.href = options.data.link;
      }
    };
  }
};
