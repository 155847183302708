import { IoMdArrowForward } from "react-icons/io"; 
import { IoIosArrowRoundForward } from "react-icons/io"; 
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import logo from '../assets/images/logo.png';
import backIcon from '../assets/icons/back-icon.png';
import profileIcon from '../assets/icons/profile-icon.png';
import cameraIcon from '../assets/icons/profile-camera.png';
import emailIcon from '../assets/icons/email-icon.png';
import phoneIcon from '../assets/icons/phone-icon.png';
import lockIcon from '../assets/icons/lock-icon.png';
import visibilityIcon from '../assets/images/visibility-icon.png';
import visibilityOffIcon from '../assets/images/visibility-off-icon.png';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css'; // Import the styles
import styles from './SignupPage.module.css';

const SignupPage = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const { login, authState, logout } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [requestData, setRequestData] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [imageUrl, setImageUrl] = useState(profileIcon);

  // Initialize agreementsAccepted with saved values or defaults
  const [agreementsAccepted, setAgreementsAccepted] = useState(() => {
    const savedAgreements = localStorage.getItem('agreementsAccepted');
    if (savedAgreements) {
      try {
        const parsedAgreements = JSON.parse(savedAgreements);
        if (Array.isArray(parsedAgreements) && parsedAgreements.length > 0) {
          return parsedAgreements;
        }
      } catch (error) {
        // If parsing fails, we'll initialize with defaults
      }
    }
    return [
      { type: 'DPA', accepted: false, acceptedDate: null },
      { type: 'TC', accepted: false, acceptedDate: null },
    ];
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchAgreements = async () => {
      try {
        const response = await axios.get(`${apiUrl}/agreements/latest`);
        const agreements = response.data;

        const updatedAgreements = agreementsAccepted.map(agreement => {
          const latestVersion = agreements.find(item => item.agreementType === agreement.type)?.agreementVersion;

          return {
            ...agreement,
            version: latestVersion || '1', // default to 1 if version not found
          };
        });

        setAgreementsAccepted(updatedAgreements);
        localStorage.setItem('agreementsAccepted', JSON.stringify(updatedAgreements));
      } catch (error) {
        console.error('Failed to fetch agreements:', error);
      }
    };

    fetchAgreements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const verifiedEmail = localStorage.getItem('verifiedEmail');
    const verifiedPhone = localStorage.getItem('verifiedPhone');
    setEmail(verifiedEmail || '');
    setPhone(localStorage.getItem('phone') || '');
    setIsEmailVerified(!!verifiedEmail);
    setIsPhoneVerified(!!verifiedPhone);

    // Retrieve saved form data from localStorage
    setFirstName(localStorage.getItem('firstName') || '');
    setLastName(localStorage.getItem('lastName') || '');
    setPassword(localStorage.getItem('password') || '');
    setConfirmPassword(localStorage.getItem('confirmPassword') || '');
  }, []);

  useEffect(() => {
    // Persist agreementsAccepted state in localStorage
    localStorage.setItem('agreementsAccepted', JSON.stringify(agreementsAccepted));
  }, [agreementsAccepted]);

  useEffect(() => {
    // Check if both DPA and TC are accepted, along with verified email and phone
    const dpaAccepted = agreementsAccepted.find(agreement => agreement.type === 'DPA')?.accepted;
    const tcAccepted = agreementsAccepted.find(agreement => agreement.type === 'TC')?.accepted;

    if (isEmailVerified && isPhoneVerified && dpaAccepted && tcAccepted) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
  }, [isEmailVerified, isPhoneVerified, agreementsAccepted]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailVerified(false); // Reset email verification when user edits email
    setEmailError(''); // Clear error message
  };

  const handleEmailVerify = () => {
    if (!email) {
      setEmailError('Email is required');
      return;
    }

    // Save current form data to localStorage
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    localStorage.setItem('password', password);
    localStorage.setItem('confirmPassword', confirmPassword);
    localStorage.setItem('agreementsAccepted', JSON.stringify(agreementsAccepted));

    checkUniqueEmail(email);
  };

  const handlePhoneVerify = () => {
    if (!phone) {
      setPhoneError('Phone number is required');
      return;
    }

    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    localStorage.setItem('password', password);
    localStorage.setItem('confirmPassword', confirmPassword);
    localStorage.setItem('agreementsAccepted', JSON.stringify(agreementsAccepted));
    localStorage.setItem('phone', phone);

    checkUniquePhone(phone);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const checkUniqueEmail = async (email) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/check-uniqueness`, {
        type: 'email',
        value: email,
      });
      if (response.data === true) {
        await requestEmailOTP(email);
      } else {
        setEmailError('Email is already in use');
      }
    } catch (error) {
      setEmailError('Failed to check email uniqueness. Please try again.');
    }
  };

  const checkUniquePhone = async (phone) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/check-uniqueness`, {
        type: 'mobile',
        value: phone,
      });
      if (response.data === true) {
        await requestPhoneOTP(phone);
      } else {
        setPhoneError('Phone number is already in use');
      }
    } catch (error) {
      setPhoneError('Failed to check phone uniqueness. Please try again.');
    }
  };

  const requestEmailOTP = async (email) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

      const response = await axios.post(
        `${apiUrl}/auth/request-otp`,
        {
          purpose: 'signup',
          method: 'email',
          destination: email,
        },
        { headers }
      );

      if (response.data && response.data.accessToken) {
        localStorage.setItem('accessToken', response.data.accessToken);
        navigate('/signupotpemail', { state: { email } });
      } else {
        throw new Error('Token not received');
      }
    } catch (error) {
      console.error('Failed to request OTP:', error);
      setEmailError('Failed to send OTP to email. Please try again.');
    }
  };

  const requestPhoneOTP = async (phone) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

      const response = await axios.post(
        `${apiUrl}/auth/request-otp`,
        {
          purpose: 'signup',
          method: 'mobile',
          destination: phone,
        },
        { headers }
      );

      if (response.data && response.data.accessToken) {
        localStorage.setItem('accessToken', response.data.accessToken);
        navigate('/signupotpmobile', { state: { phone } });
      } else {
        throw new Error('Token not received');
      }
    } catch (error) {
      setPhoneError('Failed to send OTP to phone. Please try again.');
    }
  };

  const validatePassword = (password) => {
    const passwordCriteria = /^(?=.*[0-9])(?=.*[!@#$%^&*()_\-+={}[\]:;"'<>,.?/~`])[a-zA-Z0-9!@#$%^&*()_\-+={}[\]:;"'<>,.?/~`]{8,}$/;
    return passwordCriteria.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isEmailVerified || !isPhoneVerified) {
      setGeneralError('Please verify your email and phone number before signing up.');
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 8 characters long, contain a number and a special character');
      return;
    }

    try {
      const requestBody = {
        firstName,
        lastName,
        email,
        mobile: phone, // phone already includes country code
        password,
        contactMethodsVerified: {
          email: true,
          mobile: true,
        },
        agreementsAccepted: agreementsAccepted.filter(agreement => agreement.accepted),
      };

      const response = await axios.post(`${apiUrl}/auth/usersignup`, requestBody, {
        headers: {
          Authorization: `Bearer ${authState.accessToken}`,
        },
      });

      if (response.status === 201) {
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('password');
        localStorage.removeItem('confirmPassword');
        localStorage.removeItem('agreementsAccepted');
        localStorage.removeItem('verifiedEmail');
        localStorage.removeItem('verifiedPhone');
        localStorage.removeItem('phone');

        login(response.data.accessToken, response.data.authToken, { firstName, lastName, email });
        alert('User created successfully');
        navigate('/mosaik-home');
      } else {
        alert('Failed to create user');
      }
    } catch (error) {
      setErrorData(error.response?.data || error.message);
      alert('Failed to create user');
    }
  };

  const handleCheckboxChange = (agreementType, isChecked) => {
    const updatedAgreements = agreementsAccepted.map((agreement) =>
      agreement.type === agreementType
        ? { ...agreement, accepted: isChecked, acceptedDate: isChecked ? new Date().toISOString() : null }
        : agreement
    );

    setAgreementsAccepted(updatedAgreements);
    localStorage.setItem('agreementsAccepted', JSON.stringify(updatedAgreements));

    const dpaAccepted = updatedAgreements.find(agreement => agreement.type === 'DPA')?.accepted;
    const tcAccepted = updatedAgreements.find(agreement => agreement.type === 'TC')?.accepted;
    setIsSaveEnabled(dpaAccepted && tcAccepted && isEmailVerified && isPhoneVerified);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  if (authState.isAuthenticated && authState.user) {
    const user = authState.user;

    return (
      <main className={styles.signupContainer}>
        <img src={logo} alt="Mosaik Logo" className={styles.logo} />
        <section className={styles.signupCard}>
          <h1 className={styles.loginHeading}>
            Hello {user.username || user.email || user.mobile},
          </h1>

          <p className={styles.loginSubtext}>You are already signed in!</p>
          <button onClick={handleLogout} className={styles.logoutButton}>
            Logout
          </button>
          <p className={styles.signupPrompt}>
            <Link to="/mosaik-home" className={styles.signupLink}>
              Go back to homepage
            </Link>
          </p>
        </section>
      </main>
    );
  }

  return (
    <main className={styles.signupContainer}>
      <img src={logo} alt="Mosaik Logo" className={styles.logo} />
      <section className={styles.signupCard}>
        <div className={styles.header}>
          <img src={backIcon} alt="Go back" className={styles.backIcon} onClick={() => navigate('/login')} />
          <h1 className={styles.signupHeading}>Sign Up</h1>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.profilePictureContainer} onClick={() => fileInputRef.current.click()}>
          <img src={imageUrl} alt="Profile" className={styles.profileIcon} />
          <img src={cameraIcon} alt="Change profile" className={styles.cameraIcon} />
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleFileChange}
            className={styles.fileInput}
          />
        </div>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <div className={styles.inputWrapper}>
            <div className={styles.inputField}>
              <img src={profileIcon} alt="First name" className={styles.inputIcon} />
              <input
                type="text"
                placeholder="First name *"
                className={styles.inputText}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
               {/* <span className={styles.requiredAsterisk}>*</span> */}
            </div>
            <div className={styles.inputField}>
              <img src={profileIcon} alt="Last name" className={styles.inputIcon} />
              <input
                type="text"
                placeholder="Last name *"
                className={styles.inputText}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
               {/* <span className={styles.requiredAsterisk}>*</span> */}

            </div>
            <div className={styles.inputFieldWithButton}>
              <div className={styles.inputField}>
                <img src={emailIcon} alt="Email icon" className={styles.inputIcon} />
                <input
                  type="email"
                  placeholder="Email *"
                  className={styles.inputText}
                  value={email}
                  onChange={handleEmailChange}
                  required
                />


              </div>
              <button type="button" onClick={handleEmailVerify} className={styles.verifyButton}>
                {isEmailVerified ? 'Reverify' : 'Verify'}
                <IoMdArrowForward className={styles.forwardIcon}/>
          {/* <img src={backIcon} alt="Go back" className={styles.forwardIcon} onClick={() => navigate('/login')} /> */}

              </button>
              {/* <span className={styles.requiredAsterisk}>*</span> */}

            </div>
            {emailError && <p className={styles.errorText}>{emailError}</p>}
            <div className={styles.inputFieldWithButton}>

              <PhoneInput
                value={phone}
                onChange={(value, country) => {
                  setPhone(value);
                  setPhoneError(''); // Clear error message
                }}
                className={styles.internationalPhoneInput}
                required
              />
              <button type="button" onClick={handlePhoneVerify} className={styles.verifyButton}>
                {isPhoneVerified ? 'Reverify' : 'Verify'}
                <IoMdArrowForward className={styles.forwardIcon}/>
          {/* <img src={backIcon} alt="Go back" className={styles.forwardIcon} onClick={() => navigate('/login')} /> */}
              </button>
              {/* <span className={styles.requiredAsterisk}>*</span> */}
            </div>
            {phoneError && <p className={styles.errorText}>{phoneError}</p>}
            {generalError && <p className={styles.errorText}>{generalError}</p>}
            {passwordError && <p className={styles.errorText}>{passwordError}</p>}
            <div className={styles.inputField}>
              <img src={lockIcon} alt="Password icon" className={styles.inputIcon} />
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password *"
                className={styles.inputText}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (!validatePassword(e.target.value)) {
                    setPasswordError('Password must be at least 8 characters long, contain a number and a special character');
                  } else {
                    setPasswordError('');
                  }
                }}
                required
              />
              <img
                src={showPassword ? visibilityOffIcon : visibilityIcon}
                alt="Toggle visibility"
                className={styles.visibilityIcon}
                onClick={() => setShowPassword(!showPassword)}
              />
               {/* <span className={styles.requiredAsterisk}>*</span> */}

            </div>
            <div className={styles.inputField}>
              <img src={lockIcon} alt="Confirm Password icon" className={styles.inputIcon} />
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password *"
                className={styles.inputText}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (password && e.target.value !== password) {
                    setPasswordError('Passwords do not match');
                  } else {
                    setPasswordError('');
                  }
                }}
                required
              />
              <img
                src={showConfirmPassword ? visibilityOffIcon : visibilityIcon}
                alt="Toggle visibility"
                className={styles.visibilityIcon}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </div>
            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                id="dataProtection"
                className={styles.checkbox}
                checked={agreementsAccepted.find(agreement => agreement.type === 'DPA')?.accepted || false}
                onChange={(e) => handleCheckboxChange('DPA', e.target.checked)}
              />
              <label htmlFor="dataProtection" className={styles.checkboxLabel}>
                Accept <Link to="/data-protection" state={{ profileImage: imageUrl }}>Data Protection Agreement</Link>
              </label>
              
            </div>
            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                id="termsConditions"
                className={styles.checkbox}
                checked={agreementsAccepted.find(agreement => agreement.type === 'TC')?.accepted || false}
                onChange={(e) => handleCheckboxChange('TC', e.target.checked)}
              />
              <label htmlFor="termsConditions" className={styles.checkboxLabel}>
                Accept <Link to="/terms-conditions" state={{ profileImage: imageUrl }}>Terms & Conditions</Link>
              </label>
            </div>
          </div>
          <button type="submit" className={styles.saveButton} style={{ opacity: isSaveEnabled ? 1 : 0.5 }} disabled={!isSaveEnabled}>
            Signup
          </button>
        </form>

       
      </section>
    </main>
  );
};

export default SignupPage;
