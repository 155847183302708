// src/components/chats/MosaikChats.js

import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  List,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Typography,
  Snackbar,
  Alert,
  CircularProgress, // Import CircularProgress for preloader
} from '@mui/material';
import {
  Search as SearchIcon,
  PersonAdd as PersonAddIcon,
  Group as GroupIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import ChatItem from './ChatItem';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import { AuthContext } from '../../context/AuthContext';
import { WebSocketContext } from '../../context/RocketchatContext';
import { NotificationContext } from '../../context/NotificationContext';
import spinnerGif from '../../assets/images/spinner.gif';
import plusButtonImage from '../../assets/images/plus-button.png';
import profilePicPlaceholder from '../../assets/images/placeholder.webp';
import { sortChats } from '../../utils/ChatSorterUtility';
import { useFetchUsers } from '../../hooks/useFetchUsers';

const apiUrl = process.env.REACT_APP_API_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MosaikChats() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [chatData, setChatData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetchedChats, setHasFetchedChats] = useState(false); // New state
  const [filter, setFilter] = useState('all');
  const [showCategorySearchBar, setShowCategorySearchBar] = useState(false);
  const [categorySearchTerm, setCategorySearchTerm] = useState('');
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [anchorElDropdown, setAnchorElDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);

  // States for Delete Confirmation Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);

  // Snackbar state and handlers
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const { authState } = useContext(AuthContext);
  const userId = authState.user?.id;
  const accessToken = authState.accessToken;

  const {
    subscribeToRooms,
    muteChat,
    pinChat,
    deleteChat,
    getSubscriptions,
    getRooms,
  } = useContext(WebSocketContext);

  const { addAlert } = useContext(NotificationContext);

  // Use the useFetchUsers hook
  const { summarizedUsers, error: userError } = useFetchUsers();

  // Ref to store previous chat data for comparison
  const previousChatDataRef = useRef([]);

  // Toggle the dropdown menu
  const toggleDropdownMenu = useCallback((event) => {
    setAnchorElDropdown(event.currentTarget);
    setShowDropdownMenu((prev) => !prev);
  }, []);

  // Close the dropdown menu
  const handleCloseDropdownMenu = useCallback(() => {
    setAnchorElDropdown(null);
    setShowDropdownMenu(false);
  }, []);

  // Handle muting/unmuting a chat
  const handleMute = useCallback(
    async (id) => {
      const chat = chatData.find((chat) => chat.id === id);
      if (!chat) return;

      const isCurrentlyMuted = chat.isMuted;

      try {
        await muteChat(id, !isCurrentlyMuted);
        setChatData((prevChatData) => {
          const updatedChatData = prevChatData.map((chat) =>
            chat.id === id ? { ...chat, isMuted: !chat.isMuted } : chat
          );
          const sortedChatData = sortChats(updatedChatData);
          return sortedChatData;
        });
        setSnackbarMessage(
          t(`Chat ${!isCurrentlyMuted ? 'muted' : 'unmuted'} successfully.`)
        );
        setSnackbarSeverity('success');
      } catch (error) {
        setSnackbarMessage(
          t(`Failed to ${!isCurrentlyMuted ? 'mute' : 'unmute'} chat.`)
        );
        setSnackbarSeverity('error');
      } finally {
        setOpenSnackbar(true);
      }
    },
    [chatData, muteChat, sortChats, t]
  );

  // Handle pinning/unpinning a chat
  const handlePin = useCallback(
    async (id) => {
      const chat = chatData.find((chat) => chat.id === id);
      if (!chat) return;

      const isCurrentlyPinned = chat.isPinned;

      try {
        await pinChat(id, !isCurrentlyPinned);
        setChatData((prevChatData) => {
          const updatedChatData = prevChatData.map((chat) =>
            chat.id === id ? { ...chat, isPinned: !chat.isPinned } : chat
          );
          const sortedChatData = sortChats(updatedChatData);
          return sortedChatData;
        });
        setSnackbarMessage(
          t(`Chat ${!isCurrentlyPinned ? 'pinned' : 'unpinned'} successfully.`)
        );
        setSnackbarSeverity('success');
      } catch (error) {
        setSnackbarMessage(
          t(`Failed to ${!isCurrentlyPinned ? 'pin' : 'unpin'} chat.`)
        );
        setSnackbarSeverity('error');
      } finally {
        setOpenSnackbar(true);
      }
    },
    [chatData, pinChat, sortChats, t]
  );

  // Handle delete icon click - opens confirmation dialog
  const handleDeleteClick = useCallback((id) => {
    setChatToDelete(id);
    setOpenDeleteDialog(true);
  }, []);

  // Confirm deletion of chat
  const handleDeleteConfirm = useCallback(async () => {
    if (!chatToDelete) return;

    const chat = chatData.find((chat) => chat.id === chatToDelete);
    if (!chat) return;

    try {
      await deleteChat(chatToDelete);
      setChatData((prevChatData) =>
        prevChatData.filter((chat) => chat.id !== chatToDelete)
      );
      setSnackbarMessage(t('Chat deleted successfully.'));
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage(t('Failed to delete chat.'));
      setSnackbarSeverity('error');
    } finally {
      setOpenDeleteDialog(false);
      setChatToDelete(null);
      setOpenSnackbar(true);
    }
  }, [chatToDelete, chatData, deleteChat, t]);

  // Cancel deletion
  const handleDeleteCancel = useCallback(() => {
    setOpenDeleteDialog(false);
    setChatToDelete(null);
  }, []);

  // Fetch chats from WebSocket method call
  const fetchChats = useCallback(
    async () => {
      if (!authState.accessToken || summarizedUsers.length === 0) return;

      try {
        // Fetch rooms using WebSocket method call
        const date = 0; // Use 0 to get all rooms
        const roomsResponse = await getRooms(date);
        const rooms = roomsResponse.update || [];

        if (!rooms || !Array.isArray(rooms)) {
          throw new Error('Invalid rooms data');
        }

        // Fetch subscriptions
        const subscriptions = await getSubscriptions();
        const subscriptionsMap = {};
        subscriptions.forEach((sub) => {
          subscriptionsMap[sub.rid] = sub;
        });

        // Map room IDs to their names (for direct messages)
        const dmRoomNames = {};
        const userIdsToFetch = [];

        // Collect user IDs to fetch for direct messages
        rooms.forEach((room) => {
          if (room.t === 'd') {
            const participantIds = room.usernames || []; // Assuming 'usernames' contains user IDs
            const otherUserId = participantIds.find((id) => id !== userId);
            if (otherUserId) {
              userIdsToFetch.push(otherUserId);
              dmRoomNames[room._id] = otherUserId;
            } else if (room.usernames.length > 1) {
              dmRoomNames[room._id] = room.usernames[1];
            } else {
              dmRoomNames[room._id] = 'Unknown User';
            }
          }
        });

        // Create a map for quick user lookup using summarizedUsers
        const userDetailsMap = {};
        summarizedUsers.forEach((user) => {
          userDetailsMap[user.id] = {
            name: user.name,
            avatar: user.avatar,
          };
        });

        const updatedChats = rooms.map((room) => {
          const sub = subscriptionsMap[room._id];
          const chatTimestamp = new Date(
            room._updatedAt.$date || room._updatedAt
          ).toISOString();

          let chatName = room.name || room.fname || 'Unnamed Chat';
          let avatar = profilePicPlaceholder;

          if (room.t === 'd') {
            // For direct messages, use the other user's display name
            const otherUserId = dmRoomNames[room._id];
            const otherUser = userDetailsMap[otherUserId];
            if (otherUser) {
              chatName = otherUser.name || otherUser.email || 'Unknown User';
              avatar = otherUser.avatar || profilePicPlaceholder;
            } else {
              chatName = room.usernames[1];
            }
          }

          return {
            id: room._id,
            name: chatName,
            avatar: avatar,
            type: room.t === 'p' ? 'group' : 'direct',
            isPinned: sub?.f || false,
            isMuted: sub ? sub.desktopNotifications === 'nothing' : false,
            unreadCount: sub?.unread || 0,
            timestamp: chatTimestamp,
            lastMessage: room.lastMessage ? room.lastMessage.msg : '',
            lastMessageDate: room.lastMessage
              ? new Date(room.lastMessage.ts.$date).toISOString()
              : null, // Add lastMessageDate
          };
        });

        // Sort chats using the utility function
        const sortedChats = sortChats(updatedChats);

        // *** Begin: Detect New Messages and Send Alerts ***
        sortedChats.forEach((newChat) => {
          const prevChat = previousChatDataRef.current.find(
            (chat) => chat.id === newChat.id
          );
          if (!prevChat) return; // New chat, skip alerting

          // Check if unreadCount has increased, indicating new messages
          if (newChat.unreadCount > (prevChat.unreadCount || 0)) {
            addAlert({
              user: 'Chats',
              action: `sent you a new message in ${newChat.name}.`, // Updated to include chat name
              link: `/chat-details/${newChat.id}`, // Updated to link to specific chat
              chatName: newChat.name, // Updated to include specific chat name
              type: 'chat',
            });
          }
        });
        // *** End: Detect New Messages and Send Alerts ***

        // Update previous chat data reference
        previousChatDataRef.current = sortedChats;

        setChatData(sortedChats);
        setHasFetchedChats(true); // Set to true after fetching
        subscribeToRooms(sortedChats.map((chat) => chat.id));
      } catch (error) {
        console.error('Failed to fetch chats:', error);
        setSnackbarMessage(t('Failed to load chats.'));
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    },
    [
      authState.accessToken,
      getRooms,
      getSubscriptions,
      userId,
      summarizedUsers,
      subscribeToRooms,
      addAlert,
      t,
      sortChats,
    ]
  );

  // Polling mechanism to fetch chats every 20 seconds
  useEffect(() => {
    let pollingInterval;
    const startPolling = () => {
      fetchChats(); // Initial fetch
      pollingInterval = setInterval(() => {
        fetchChats();
      }, 20000); // Poll every 20 seconds
    };

    if (authState.accessToken && summarizedUsers.length > 0) {
      setIsLoading(true);
      startPolling();
      setIsLoading(false);
    }

    return () => {
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [authState.accessToken, summarizedUsers.length, fetchChats]);

  // Filter and search chats
  const filteredChats = useMemo(() => {
    return chatData
      .filter((chat) => {
        if (filter === 'all') return true;
        if (filter === 'unread') return chat.unreadCount > 0;
        if (filter === 'groups') return chat.type === 'group';
        if (filter === 'muted') return chat.isMuted;
        return true;
      })
      .filter((chat) =>
        chat.name.toLowerCase().includes(categorySearchTerm.toLowerCase())
      );
  }, [chatData, filter, categorySearchTerm]);

  // Close the dropdown menu if clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        handleCloseDropdownMenu();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, handleCloseDropdownMenu]);

  // Function to handle navigation based on chat type
  const handleChatClick = useCallback(
    (chat) => {
      navigate(`/chat-details/${chat.id}`);
    },
    [navigate]
  );

  // Handle opening the Invite Members dialog
  const handleOpenInviteDialog = useCallback(() => {
    setOpenInviteDialog(true);
  }, []);

  // Handle closing the Invite Members dialog
  const handleCloseInviteDialog = useCallback(() => {
    setOpenInviteDialog(false);
  }, []);

  // Handle Snackbar close
  const handleSnackbarClose = useCallback(() => {
    setOpenSnackbar(false);
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      {/* Header */}
      <MosaikHeaderWithBack pageTitle={t('Chats')} />

      {/* Filter and Actions */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{
          borderBottom: '1px solid #ddd',
          mt: '85px',
        }}
      >
        {/* Filter Buttons */}
        <Box display="flex" gap={1}>
          <Button
            variant={filter === 'all' ? 'contained' : 'outlined'}
            onClick={() => setFilter('all')}
          >
            {t('All')}
          </Button>
          <Button
            style={{ position: 'relative', padding: '6px 12px' }} // Adjusted padding for better appearance
            variant={filter === 'unread' ? 'contained' : 'outlined'}
            onClick={() => setFilter('unread')}
          >
            {t('Unread')}
            {chatData.reduce(
              (acc, chat) => acc + (chat.unreadCount || 0),
              0
            ) > 0 && (
              <Badge
                badgeContent={chatData.reduce(
                  (acc, chat) => acc + (chat.unreadCount || 0),
                  0
                )}
                color="error"
                sx={{
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  transform: 'translate(50%, -50%)',
                }}
              />
            )}
          </Button>
          <Button
            variant={filter === 'groups' ? 'contained' : 'outlined'}
            onClick={() => setFilter('groups')}
            startIcon={<GroupIcon />}
          >
            {t('Groups')}
          </Button>
          <Button
            variant={filter === 'muted' ? 'contained' : 'outlined'}
            onClick={() => setFilter('muted')}
          >
            {t('Muted')}
          </Button>
        </Box>

        {/* Search and Menu Buttons */}
        <Box display="flex" gap={1}>
          {/* Removed the standalone Search IconButton */}
          <IconButton
            onClick={toggleDropdownMenu}
            aria-label={t('Menu')}
            ref={dropdownRef}
          >
            <img
              src={plusButtonImage}
              alt={t('Menu')}
              style={{ width: '35px', height: '35px' }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorElDropdown}
            open={showDropdownMenu}
            onClose={handleCloseDropdownMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {/* New Search MenuItem */}
            <MenuItem
              onClick={() => {
                setShowCategorySearchBar((prev) => !prev); // Toggle search bar visibility
                handleCloseDropdownMenu(); // Close the dropdown menu
              }}
            >
              <SearchIcon fontSize="small" sx={{ mr: 1 }} />
              {t('Search')}
            </MenuItem>

            <MenuItem
              onClick={() => {
                navigate('/new-chat');
                handleCloseDropdownMenu();
              }}
            >
              <PersonAddIcon fontSize="small" sx={{ mr: 1 }} />
              {t('New Chat')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/new-group');
                handleCloseDropdownMenu();
              }}
            >
              <GroupIcon fontSize="small" sx={{ mr: 1 }} />
              {t('New Group')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/settings');
                handleCloseDropdownMenu();
              }}
            >
              <SettingsIcon fontSize="small" sx={{ mr: 1 }} />
              {t('Settings')}
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Category Search Bar */}
      {showCategorySearchBar && (
        <Box p={2} sx={{ borderBottom: '1px solid #ddd' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t('Search chats...')}
            value={categorySearchTerm}
            onChange={(e) => setCategorySearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      {/* Chat List */}
      <Box flexGrow={1} overflow="auto" p={2} mb={10}>
        {isLoading || !hasFetchedChats ? (
          // Show preloader while loading
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              {t('Loading Chats')}
            </Typography>
          </Box>
        ) : filteredChats.length > 0 ? (
          <List>
            {filteredChats.map((chat) => (
              <ChatItem
                key={chat.id}
                id={chat.id}
                name={chat.name}
                avatar={chat.avatar || profilePicPlaceholder}
                lastMessage={chat.lastMessage}
                lastMessageDate={chat.lastMessageDate}
                unreadCount={chat.unreadCount}
                isPinned={chat.isPinned}
                isMuted={chat.isMuted}
                type={chat.type}
                onMute={handleMute}
                onPin={handlePin}
                onDelete={handleDeleteClick}
                onClick={() => handleChatClick(chat)}
              />
            ))}
          </List>
        ) : (
          // Show "No chats found" only after fetching
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Typography variant="body1">{t('No chats found.')}</Typography>
          </Box>
        )}
      </Box>

      {/* Invite Members Dialog */}
      <Dialog
        open={openInviteDialog}
        onClose={handleCloseInviteDialog}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t('Invite Members')}</DialogTitle>
        <DialogContent>
          {/* Implement your invite members form or functionality here */}
          <Typography variant="body1">
            {t('Invite functionality goes here.')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInviteDialog} color="secondary">
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              /* Handle invite action */
              handleCloseInviteDialog();
            }}
            color="primary"
            variant="contained"
          >
            {t('Invite')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="delete-confirmation-dialog-title"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          {t('Delete Chat')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirmation-dialog-description">
            {t(
              'Are you sure you want to delete this chat? This action cannot be undone.'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="secondary">
            {t('Cancel')}
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Footer */}
      <MosaikFooter />
    </Box>
  );
}

export default MosaikChats;
