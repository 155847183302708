import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TutorialPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  // Fetch tutorial data by ID if needed. For now, we'll use dummy data.
  const tutorialData = {
    title: t('Tutorial {{id}}', { id }),
    content: t('Content of tutorial {{id}}.', { id }),
  };

  return (
    <div>
      <h1>{tutorialData.title}</h1>
      <p>{tutorialData.content}</p>
    </div>
  );
};

export default TutorialPage;
