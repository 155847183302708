// src/components/RepairDetail/RepairDetail.js

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import MosaikHeaderWithBack from "../MosaikHeaderWithBack";
import MosaikFooter from "../MosaikFooter";
import styles from "./RepairDetail.module.css";
import pendingIcon from "../../assets/images/pending-icon.png"; // Existing icon
import RepairAdminActions from "./RepairAdminActions";
import useRepairAdminMode from "../../hooks/useRepairAdminMode";
import {
  calculatorIcon,
  defaultLogo,
  defaultprofile,
  dummyImage,
  emptyTickIcon,
  lineIcon,
  tickIcon,
} from "../../constants";
import { getProfilePictureUrl } from "../../utils/getProfileUrl";
import { getRepairImageUrl } from "../../utils/getFileUrl";


// TimelineItem Component
const TimelineItem = ({
  date,
  icon,
  title,
  description,
  approved,
  onStatusChange,
  isAdmin,
}) => (
  <div
    className={`${styles.timelineItem} ${!approved ? styles.unApproved : ""}`}
  >
    <div className={styles.timelineHeader}>
      <div className={styles.timelineDate}>{date}</div>
      <div className={styles.timelineIcon}>
        <img src={icon} alt={`${title} icon`} className={styles.iconImage} />
        <img
          src={lineIcon}
          alt="Timeline line"
          className={styles.timelineLine}
        />
      </div>
      <div className={styles.timelineDetails}>
        <div className={styles.timelineTitle}>
          {title === "Accepted" ? "Repaired" : title}
        </div>
        <div className={styles.timelineDescription}>{description}</div>
      </div>
      {/* {isAdmin && (
        <button onClick={onStatusChange} className={styles.changeStatusButton}>
          Change
        </button>
      )} */}
    </div>
  </div>
);

// RepairDetail Component
const RepairDetail = () => {
  const { t } = useTranslation(); // Destructure t for translations
  const { id } = useParams(); // Retrieve repair ID from URL params
  const navigate = useNavigate();

  const [repairDetails, setRepairDetails] = useState(null); // Store repair details
  const [timelineItems, setTimelineItems] = useState([
    {
      title: "Pending",
      description: "Your repair is pending for approval.",
      approved: false,
      date: "",
      icon: pendingIcon,
    },
    {
      title: "Approved",
      description: "Your repair has been approved.",
      approved: false,
      date: "",
      icon: pendingIcon, // Updated icon
    },
    {
      title: "Accepted",
      description: "Your repair request has been accepted.",
      approved: false,
      date: "",
      icon: pendingIcon, // Updated icon
    },
    {
      title: "Completed",
      description: "Your repair has been completed.",
      approved: false,
      date: "",
      icon: pendingIcon, // Updated icon
    },
  ]);
  const [showSupplierList, setShowSupplierList] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState(defaultprofile);
  const [repairImageUrl, setRepairImageUrl] = useState(dummyImage); // New state for repair image
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false); // State for cancel modal
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  const {
    repairAdminMode,
    loading: adminLoading,
    error: adminError,
  } = useRepairAdminMode();
  const apiUrl = process.env.REACT_APP_API_URL;

  // **New States for Service Providers**
  const [serviceProviders, setServiceProviders] = useState([]); // Store service providers
  const [loadingServiceProviders, setLoadingServiceProviders] = useState(true); // Loading state for service providers
  const [errorServiceProviders, setErrorServiceProviders] = useState(null); // Error state for service providers

  useEffect(() => {
    const fetchRepairDetails = async () => {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        console.error(t("No token found"));
        navigate("/login"); // Redirect to login if the token is missing
        return;
      }

      if (id) {
        try {
          const response = await axios.get(`${apiUrl}/repairs/detail/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setRepairDetails(response.data);
          const eventHistory = response.data.statusEventHistory || [];

          // Fetch repair image URL using getRepairImageUrl
          const fetchedRepairImageUrl = await getRepairImageUrl(response.data);
          setRepairImageUrl(fetchedRepairImageUrl);

          const updatedTimelineItems = timelineItems.map((item) => {
            const matchingEvent = eventHistory?.find(
              (event) =>
                event?.status?.toLowerCase() === item?.title?.toLowerCase()
            );

            let icon = pendingIcon; // Default to pending icon

            if (matchingEvent) {
              // Current status with date uses the main custom icon
              icon = tickIcon;
            }

            return {
              ...item,
              date: matchingEvent
                ? new Date(matchingEvent.eventDateTime).toLocaleString()
                : "",
              approved: !!matchingEvent, // Approved if the status has been completed
              icon,
            };
          });

          setTimelineItems(updatedTimelineItems);
          let profilePicUrl = await getProfilePictureUrl(response.data.user);
          setProfilePicUrl(profilePicUrl);
        } catch (error) {
          console.error(t("Error fetching repair details"), error);
          setError(t("Failed to fetch repair details. Please try again later."));
        } finally {
          setLoading(false); // Set loading to false after the call
        }
      } else {
        console.error(t("Repair ID is missing"));
        setError(t("Repair ID is missing."));
        setLoading(false);
      }
    };

    fetchRepairDetails();
  }, [id, apiUrl]);

  // **New useEffect to Fetch Service Providers**
  useEffect(() => {
    const fetchServiceProviders = async () => {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        console.error(t("No token found"));
        setErrorServiceProviders(t("No access token found."));
        setLoadingServiceProviders(false);
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/serviceProviders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setServiceProviders(response.data); // Assuming API returns an array of service providers
      } catch (error) {
        console.error(t("Error fetching service providers"), error);
        setErrorServiceProviders(t("Failed to fetch service providers."));
      } finally {
        setLoadingServiceProviders(false);
      }
    };

    fetchServiceProviders();
  }, [apiUrl]);

  // Handler to select a supplier (admin only)
  const handleSelectSupplier = async (supplier) => {
    // Set the selected supplier and hide the supplier list modal
    setSelectedSupplier(supplier);
    setShowSupplierList(false);

    // Retrieve the access token from localStorage
    const token = localStorage.getItem("accessToken");

    if (!token) {
      console.error(t("No token found"));
      navigate("/login"); // Redirect to login if the token is missing
      return;
    }

    try {
      // Define the payload with the selected supplier's ID
      const payload = {
        serviceProvider: {
          id: supplier.id,
        },
      };

      // Send PATCH request to update the repair's service provider
      const response = await axios.patch(
        `${apiUrl}/repairs/${id}`, // Endpoint with the current repair ID
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the authorization header
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );

      console.log(t(`Service provider updated to ${supplier.name}:`), response.data);

      // Update the repairDetails state with the updated serviceProvider from the response
      setRepairDetails((prevDetails) => ({
        ...prevDetails,
        serviceProvider: response.data.serviceProvider,
      }));
    } catch (error) {
      console.error(t("Error updating service provider"), error);
      setError(t("Failed to update service provider. Please try again later."));
    }
  };

  // Format date utility
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? t("Invalid Date") : date.toLocaleString();
  };

  // Handlers for Cancel Repair functionality
  const handleCancelRepairClick = () => {
    setShowCancelModal(true);
  };

  const handleConfirmCancelRepair = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error(t("No token found"));
        navigate("/login"); // Redirect to login if token is missing
        return;
      }

      // Send PATCH request to update the status to 'cancelled'
      const payload = { status: "cancelled" };
      const response = await axios.patch(
        `${apiUrl}/repairs/${id}/status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(t("Repair cancelled:"), response.data);

      // Update the repairDetails state with the new status and add to statusEventHistory
      setRepairDetails((prevDetails) => ({
        ...prevDetails,
        status: "cancelled",
        statusEventHistory: [
          ...(prevDetails.statusEventHistory || []),
          {
            status: "cancelled",
            eventDateTime: new Date().toISOString(),
          },
        ],
      }));

      // Optionally, navigate to another page or show a success message
      navigate("/mosaik-repairs"); // Redirect to repairs list after cancellation
    } catch (error) {
      console.error(t("Error cancelling repair"), error);
      // Optionally, display an error message to the user
      setError(t("Failed to cancel repair. Please try again later."));
    } finally {
      setShowCancelModal(false);
    }
  };

  const handleCloseModal = () => {
    setShowCancelModal(false);
  };

  // Handler to change repair status (admin only)
  const handleChangeStatus = async (newStatus) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error(t("No token found"));
        navigate("/login"); // Redirect to login if token is missing
        return;
      }

      // Send PATCH request to update the status
      const payload = { status: newStatus };
      const response = await axios.patch(
        `${apiUrl}/repairs/${id}/status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(t(`Repair status changed to ${newStatus}:`), response.data);

      // Update the repairDetails state with the new status
      const updatedRepairDetails = {
        ...repairDetails,
        status: newStatus,
        statusEventHistory: [
          ...repairDetails.statusEventHistory,
          {
            status: newStatus,
            eventDateTime: new Date().toISOString(),
          },
        ],
      };

      setRepairDetails(updatedRepairDetails);

      // Update timelineItems using the helper function
      const updatedTimelineItems = timelineItems.map((item) => {
        const matchingEvent = updatedRepairDetails.statusEventHistory?.find(
          (event) =>
            event?.status?.toLowerCase() === item?.title?.toLowerCase()
        );

        let icon = pendingIcon; // Default to pending icon

        if (matchingEvent) {
          // Current status with date uses the main custom icon
          icon = tickIcon;
        }

        return {
          ...item,
          date: matchingEvent
            ? new Date(matchingEvent.eventDateTime).toLocaleString()
            : "",
          approved: !!matchingEvent, // Approved if the status has been completed
          icon,
        };
      });

      setTimelineItems(updatedTimelineItems);
    } catch (error) {
      console.error(t(`Error changing repair status to ${newStatus}:`), error);
      // Optionally, display an error message to the user
      setError(
        t(`Failed to change status to ${newStatus}. Please try again.`)
      );
    }
  };

  // **Conditional Rendering for Loading and Errors**
  if (loading || adminLoading || loadingServiceProviders) {
    return (
      <main className={styles.container}>
        <MosaikHeaderWithBack pageTitle={t("Repair Detail")} />
        <div className={styles.loader}>{t("Loading...")}</div>
        <MosaikFooter />
      </main>
    );
  }

  if (error || adminError || errorServiceProviders) {
    return (
      <main className={styles.container}>
        <MosaikHeaderWithBack pageTitle={t("Repair Detail")} />
        <div className={styles.error}>
          <p>{error || adminError || errorServiceProviders}</p>
        </div>
        <MosaikFooter />
      </main>
    );
  }

  return (
    <main className={styles.container}>
      <MosaikHeaderWithBack pageTitle={t("Repair Detail")} />
      <div className={styles.RepairHeader}>
        <h1 className={styles.referenceNumber}>
          {t("Ref")} {repairDetails.repairRefNo || t("N/A")}
        </h1>
        <p className={styles.category}>{repairDetails.itemName || t("N/A")}</p>
      </div>
      <div className={styles.RepairDetail}>
        {/* Product Image */}
        {!repairAdminMode && (
          <img
            src={repairImageUrl} // Use the fetched repair image URL
            alt={t("Product")}
            className={styles.productImage}
          />
        )}

        {/* Product Card */}
        <section className={styles.productCard}>
          <div className={styles.productInfo}>
            <img
              src={repairImageUrl} // Use the fetched repair image URL
              alt={repairDetails.itemName || t("Unknown Product")}
              className={styles.productThumbnail}
            />
            <div className={styles.divider} />
            <div className={styles.productDetails}>
              <h2 className={styles.productName}>
                {repairDetails.itemName || t("Unknown Product")}
              </h2>
              <p className={styles.productCategory}>
                {repairDetails.location || t("N/A")}
              </p>
            </div>
            <div className={styles.deliveryDate}>
              <img
                src={calculatorIcon}
                alt="Calendar"
                className={styles.calendarIcon}
              />
              <p>{formatDate(repairDetails.createdAt)}</p>
            </div>
          </div>
        </section>

        {/* **Updated Supplier Details Section** */}
        {(repairDetails?.serviceProvider || repairAdminMode) && (
          <section>
            <h2 className={styles.sectionTitle}>{t("Supplier Details")}</h2>
            <div className={styles.supplierCard}>
              <div className={styles.supplierInfo}>
                <div className={styles.supplierDetails}>
                  <img
                    src={
                      repairDetails.serviceProvider &&
                      repairDetails.serviceProvider.imageFile
                        ? `https://${repairDetails.serviceProvider.imageFile.fileUrl}`
                        : defaultLogo
                    }
                    alt={
                      repairDetails.serviceProvider?.name || t("Supplier Logo")
                    }
                    className={styles.supplierLogo}
                  />
                  <div className={styles.divider} />
                  <div>
                    {selectedSupplier ? (
                      <>
                        <h3 className={styles.supplierName}>
                          {selectedSupplier.name}
                        </h3>
                        <p className={styles.supplierLocation}>
                          {selectedSupplier.location || "Berlin"}
                        </p>
                      </>
                    ) : (
                      <>
                        <h3 className={styles.supplierName}>
                          {repairDetails.serviceProvider?.name || t("N/A")}
                        </h3>
                        <p className={styles.supplierLocation}>Berlin</p>
                      </>
                    )}
                  </div>
                  {repairAdminMode ? (
                    <>
                      <button
                        onClick={() => setShowSupplierList(true)}
                        className={styles.emailButton}
                      >
                        {t("Select Supplier")}
                      </button>
                    </>
                  ) : (
                    <button className={styles.emailButton}>{t("Email")}</button>
                  )}
                </div>
                {showSupplierList && (
                  <div className={styles.supplierList}>
                    <input
                      type="text"
                      placeholder={t("Search Supplier...")}
                      className={styles.supplierSearch}
                    />
                    <ul>
                      {serviceProviders.map((supplier) => (
                        <li
                          key={supplier.id}
                          onClick={() => handleSelectSupplier(supplier)}
                        >
                          {supplier.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
        {/* Repair Details Header */}
        <section className={styles.repairDetailsHeader}>
          <h2 className={styles.sectionTitle}>{t("Repair Details")}</h2>
          <div className={styles.priorityLabel}>
            <span>{t("Priority")}:</span>
            <span className={styles.priorityHigh}>
              {repairDetails.priority || t("N/A")}
            </span>
          </div>
        </section>

        {/* Repair Description */}
        <div className={styles.repairDescription}>
          <img
            src={emptyTickIcon}
            alt="Repair Icon"
            className={styles.repairIcon}
          />
          <p>
            {repairDetails?.description || t("No description provided.")}
          </p>
        </div>

        {/* User Info Section */}
        <section className={styles.repairCard}>
          <div className={styles.repairInfo}>
            <div className={styles.userInfo}>
              <img
                src={profilePicUrl}
                alt={t("User Avatar")}
                className={styles.userAvatar}
              />
              <div className={styles.userDetails}>
                <p className={styles.userLabel}>{t("Repaired by")}</p>
                <p className={styles.userName}>
                  {repairDetails?.user?.firstName || t("N/A")}
                  {/* {repairDetails.user?.lastName || 'N/A'} */}
                </p>
              </div>
            </div>
            <div className={styles.repairDate}>
              <img
                src={calculatorIcon}
                alt="Calendar"
                className={styles.calendarIconSmall}
              />
              <p className={styles.dateText}>
                {new Date(repairDetails.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>
        </section>

        {/* Repair Status Timeline or Cancelled Message */}
        {repairDetails?.status?.toLowerCase() === "cancelled" ? (
          <div className={styles.cancelledMessage}>
            <h3>{t("Your repair has been cancelled.")}</h3>
          </div>
        ) : (
          <div className={styles.repairTimeline}>
            <div className={styles.timelineContent}>
              {timelineItems.map((item, index) => (
                <TimelineItem
                  key={index}
                  date={item.date}
                  icon={item.icon}
                  title={t(item.title)}
                  description={item.description}
                  approved={item.approved}
                  onStatusChange={() =>
                    handleChangeStatus(item.title.toLowerCase())
                  }
                  isAdmin={repairAdminMode}
                />
              ))}
            </div>
          </div>
        )}
        {repairAdminMode && (
          <RepairAdminActions
            status={repairDetails?.status}
            onChangeStatus={handleChangeStatus}
          />
        )}
        {/* Cancel Repair Button */}
        {(repairDetails?.status?.toLowerCase() === "pending" ||
          repairDetails?.status?.toLowerCase() === "draft") && (
          <>
            <button
              className={styles.cancelRepair}
              onClick={handleCancelRepairClick}
            >
              {t("Cancel Repair")}
            </button>

            {/* Confirmation Modal */}
            {showCancelModal && (
              <div className={styles.modal}>
                <div className={styles.modalContent}>
                  <p>{t("Are you sure you want to cancel this repair?")}</p>
                  <button
                    onClick={handleConfirmCancelRepair}
                    className={styles.confirmButton}
                  >
                    {t("Yes")}
                  </button>
                  <button
                    onClick={handleCloseModal}
                    className={styles.cancelButton}
                  >
                    {t("No")}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <MosaikFooter />
    </main>
  );
};

export default RepairDetail;
