// MosaikHeaderWithBack.js
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import RolesManager from "./RolesManager"; // Import RolesManager component
import axios from "axios";
import styles from "./MosaikHeaderWithBack.module.css";
import profilePicPlaceholder from "../assets/images/placeholder.webp";
import backArrowIcon from "../assets/icons/arrow-back.png";
import ProfileManager from "./ProfileManager";
import settingsicon from "../assets/images/repair-icon.png";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { getFileUrl } from "../services/mosaikUploadService";
import { getProfilePictureUrl } from "../utils/getProfileUrl";

const MosaikHeaderWithBack = ({ pageTitle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isProfileManagerVisible, setIsProfileManagerVisible] = useState(false);
  const [isRolesManagerOpen, setIsRolesManagerOpen] = useState(false);
  const [organisationLogo, setOrganisationLogo] = useState(null); // New state for organization logo
  const { authState, setAuthState } = useContext(AuthContext);
  const token = authState.accessToken;
  const loggedUserId = authState.user?.id;

  // Define your main module paths
  const mainModulePaths = [
    "/mosaik-tasks",
    "/mosaik-library",
    "/mosaik-feedback",
    "/mosaik-orders",
    "/mosaik-support",
    "/mosaik-repairs",
    "/mosaik-chats",
    "/mosaik-alerts",
    // Add other main module paths as needed
  ];

  // Define detail routes and their corresponding main module paths
  const detailRouteMap = {
    "/repair-detail/:id": "/mosaik-repairs",
    "/task-detail/:taskId": "/mosaik-tasks",
    "/order-detail/:id": "/mosaik-orders",
    "/employee/:id": "/mosaik-teams",
    "/group/:id": "/mosaik-teams",
    // Add other detail routes and their main module paths
  };

  // Fetch user details from the API

  useEffect(() => {
    if (loggedUserId && token) {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/${loggedUserId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const userData = response.data;
          // const profilePictureUrl = userData.profilePhotoFile?.fileUrl
          //   ? userData.profilePhotoFile.fileUrl.startsWith("https")
          //     ? userData.profilePhotoFile.fileUrl
          //     : `https://${userData.profilePhotoFile.fileUrl}`
          //   : profilePicPlaceholder;
          let profilePictureUrl = await getProfilePictureUrl(userData);

          setAuthState((prevState) => ({
            ...prevState,
            user: {
              ...prevState.user,
              id: userData.id,
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              mobile: userData.mobile,
              profilePicture: profilePictureUrl, // Use the correct profile picture URL
              status: userData.status, // Add user status
              roles: userData.roles || [], // Ensure roles are fetched
            },
          }));
        } catch (error) {
          console.error("Error fetching user details:", error);

          if (error.response && error.response.status === 401) {
            // Handle unauthorized error
            console.error("Unauthorized access. Redirecting to login.");
            navigate("/login");
          }
        }

     
      };
      const fetchOrganisationLogo = async () => {
        if (authState.currentOrganisationId) {
          try {
            let orgLogo = null;
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/organisations/${authState.currentOrganisationId}/files`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            const profileFiles = response.data.filter(
              (file) => file.moduleField === "profile"
            );

            if (profileFiles.length > 0) {
              // Sort by createdAt descending and pick the latest file
              profileFiles.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              );
              const latestProfileFile = profileFiles[0];
              orgLogo = await getFileUrl(latestProfileFile.id); // Fetch URL using getFileUrl
              console.log(orgLogo); // Debug log
            } else {
              console.log(`No profile files found for org`);
            }
            // const orgLogo = await getFileUrl(response.data?.files?.[0]?.id) || profilePicPlaceholder;

            // const orgLogo = response.data?.files?.[0]?.fileUrl
            //   ? `https://${response.data.files[0].fileUrl}`
            //   : profilePicPlaceholder;
            setOrganisationLogo(orgLogo);
          } catch (error) {
            console.error(t("Error fetching organization logo:"), error);
          }
        }
      };

      fetchUserDetails();
      fetchOrganisationLogo();
    }
  }, [loggedUserId, token, setAuthState]);

  const toggleProfileManager = () => {
    setIsProfileManagerVisible(!isProfileManagerVisible);
  };

  const userRoles = authState.user?.roles || [];
  const isAdminOrOwner =
    userRoles.includes("admin") || userRoles.includes("owner");

  const handleRolesPermissionsClick = () => {
    setIsRolesManagerOpen(true);
  };
  const closeRolesManager = () => {
    setIsRolesManagerOpen(false);
  };

  // Function to determine if current path is a detail route
  const getMainModulePath = () => {
    for (const [detailRoute, mainRoute] of Object.entries(detailRouteMap)) {
      const match = matchPath(
        { path: detailRoute, end: false },
        location.pathname
      );
      if (match) {
        return mainRoute;
      }
    }
    return null;
  };

  const handleBackClick = useCallback(() => {
    const mainPath = getMainModulePath();

    if (mainPath) {
      // If on a detail route, navigate to the corresponding main module path
      navigate(mainPath);
    } else if (mainModulePaths.includes(location.pathname)) {
      // If on a main module path, navigate back using browser history
      navigate(-1);
    } else {
      // Default fallback
      navigate("/mosaik-home");
    }
  }, [navigate, location.pathname]);

  return (
    <>
      <header className={styles.headerContainer}>
        <button
          className={styles.headerLeft}
          onClick={handleBackClick}
          aria-label={t("Back")}
          style={{
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: 0,
          }}
        >
          <img
            src={backArrowIcon}
            alt={t("Back")}
            className={styles.backArrow}
          />
        </button>
        <div className={styles.headerTitle}>
          <h2>{pageTitle}</h2>
        </div>
        <div
          className={styles.headerRight}
          onClick={toggleProfileManager}
          style={{ cursor: "pointer" }}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              toggleProfileManager();
            }
          }}
          aria-label={t("Profile Manager")}
        >
          <div
            className={styles.profilePic}
            style={{
              backgroundImage: `url(${
                authState.user?.profilePicture || profilePicPlaceholder
              })`,
            }}
          >
            {authState.user?.status === "online" && (
              <span className={styles.onlineIndicator}></span>
            )}
          </div>
        </div>
        <div
          className={styles.mosaikSelectedOrg} // New div for selected organization logo
          style={{
            backgroundImage: `url(${
              organisationLogo || profilePicPlaceholder
            })`,
          }}
        ></div>
      </header>

      {isProfileManagerVisible && (
        <div
          className={styles.profileManagerOverlay}
          onClick={toggleProfileManager}
        >
          <ProfileManager closeProfile={toggleProfileManager} />
        </div>
      )}
      {isRolesManagerOpen && <RolesManager onClose={closeRolesManager} />}
    </>
  );
};

export default MosaikHeaderWithBack;
