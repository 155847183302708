import React, { useState, useEffect, useContext } from "react";
import styles from "./RolesManager.module.css";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const RolesManager = ({ onClose, resourceId, resourceType }) => {
  const { t } = useTranslation(); // Initialize t
  const { authState } = useContext(AuthContext);
  const token = authState.accessToken;
  const userId = authState.user?.id;
  const currentOrganisationId = authState.currentOrganisationId;

  const [employees, setEmployees] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedEmployeeRoles, setSelectedEmployeeRoles] = useState({});
  const [selectedGroupRoles, setSelectedGroupRoles] = useState({});
  const [employeePermissions, setEmployeePermissions] = useState({});
  const [groupPermissions, setGroupPermissions] = useState({});
  const [permissionsNames, setPermissionsNames] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentUserRole, setcurrentUserRole] = useState("read");
  const apiUrl = process.env.REACT_APP_API_URL;

  const location = useLocation();

  const getResourceInfoFromURL = () => {
    const path = location.pathname;
    let resourceType = null;
    let resourceId = null;

    if (path.includes("/mosaik-library/manual/")) {
      resourceType = "libraryContent";
      resourceId = path.split("/mosaik-library/manual/")[1];
    } else if (path.includes("/mosaik-library/category/")) {
      resourceType = "libraryCategory";
      resourceId = path.split("/mosaik-library/category/")[1];
    }

    return { resourceType, resourceId };
  };

  if (!resourceId || !resourceType) {
    const { resourceType, resourceId } = getResourceInfoFromURL();
  }

  // Pagination and search states
  const [currentPage, setCurrentPage] = useState(1);
  const [groupCurrentPage, setGroupCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const [searchTerm, setSearchTerm] = useState("");
  const [groupSearchTerm, setGroupSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("Employees");

  useEffect(() => {
    if (resourceId) {
      fetchEmployees();
      fetchGroups();
    }
  }, [resourceId]);

  const fetchEmployees = async () => {
    setIsLoading(true);
    try {
      const manualItem = await axios.get(
        `${apiUrl}/libraryContents/${resourceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(first)
      if (manualItem?.data?.contentOwner?.id === userId) {
        setcurrentUserRole("owner");
        console.log("current USER ROLE", currentUserRole);
      }
      const response = await axios.get(`${apiUrl}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const filteredEmployees = response.data.filter(
        (employee) => employee.id !== userId // Exclude current user
      );

      setEmployees(filteredEmployees);

      setIsLoading(false);

      response.data.forEach((employee) => {
        fetchEmployeePermissions(employee.id);
      });
    } catch (error) {
      console.error(
        t("Error fetching employees:"),
        error.response?.data || error.message
      );
      setIsLoading(false);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get(`${apiUrl}/groups`, {
        params: {
          "where[organisation][id]": currentOrganisationId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGroups(response.data);

      response.data.forEach((group) => {
        fetchGroupPermissions(group.id);
      });
    } catch (error) {
      console.error(
        t("Error fetching groups:"),
        error.response?.data || error.message
      );
    }
  };

  const fetchEmployeePermissions = async (userId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/users/${userId}/permissionUserGroups`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("RESPONSE DATA", response.data);
      // Filter the response data based on resourceId and appModule
      const filteredPermissions = response.data.filter((permissionData) => {
        const libraryContentId = permissionData.libraryContent?.id;
        const matchesResourceId = resourceId
          ? libraryContentId === resourceId
          : true;
        const matchesAppModule = resourceType
          ? permissionData.appModule === resourceType
          : true;

        return matchesResourceId && matchesAppModule;
      });
      setEmployeePermissions((prevPermissions) => ({
        ...prevPermissions,
        [userId]: filteredPermissions,
      }));

      filteredPermissions.forEach((permissionData) => {
        if (
          permissionData.permission &&
          !permissionsNames[permissionData.permission.id]
        ) {
          fetchPermissionName(permissionData.id);
        }
      });
    } catch (error) {
      console.error(
        t(`Error fetching permissions for user ${userId}:`),
        error.response?.data || error.message
      );
    }
  };

  const fetchGroupPermissions = async (groupId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/groups/${groupId}/permissionUserGroups`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGroupPermissions((prevPermissions) => ({
        ...prevPermissions,
        [groupId]: response.data,
      }));

      response.data.forEach((permissionGroup) => {
        if (
          permissionGroup.permission &&
          !permissionsNames[permissionGroup.id]
        ) {
          fetchPermissionName(permissionGroup.id);
        }
      });
    } catch (error) {
      console.error(
        t(`Error fetching permissions for group ${groupId}:`),
        error.response?.data || error.message
      );
    }
  };

  const fetchPermissionName = async (permissionId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/permissionUserGroups/${permissionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("RESPONSE DATA", response.data);
      setPermissionsNames((prevNames) => ({
        ...prevNames,
        [permissionId]: response.data.permission,
      }));
    } catch (error) {
      console.error(
        t(`Error fetching permission name for permission ${permissionId}:`),
        error.response?.data || error.message
      );
    }
  };

  const handleEmployeeRoleChange = (userId, role) => {
    setSelectedEmployeeRoles((prevRoles) => ({
      ...prevRoles,
      [userId]: role,
    }));
  };

  const handleGroupRoleChange = (groupId, role) => {
    setSelectedGroupRoles((prevRoles) => ({
      ...prevRoles,
      [groupId]: role,
    }));
  };

  const handleRemovePermission = async (userId, permissionId) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      await axios.delete(`${apiUrl}/permissionUserGroups/${permissionId}`, {
        headers,
      });
      fetchEmployeePermissions(userId);
    } catch (error) {
      console.error(
        t(`Error removing permission for user ${userId}:`),
        error.response?.data || error.message
      );
    }
  };

  const handleRemoveGroupPermission = async (groupId, permissionId) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      await axios.delete(`${apiUrl}/permissionUserGroups/${permissionId}`, {
        headers,
      });
      fetchGroupPermissions(groupId);
    } catch (error) {
      console.error(
        t(`Error removing permission for group ${groupId}:`),
        error.response?.data || error.message
      );
    }
  };

  const handleSaveRoles = async () => {
    try {
      const userInfo = authState.user?.id;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      console.log("RESOURCE TYPE", resourceType);
      let apiEndpoint = "";
      let appModule = "";
      switch (resourceType) {
        case "libraryCategory":
        case "libraryContent":
          apiEndpoint = `${apiUrl}/${
            resourceType === "libraryCategory"
              ? "libraryCategories"
              : "libraryContents"
          }/${resourceId}`;
          appModule = resourceType;
          break;
        default:
          throw new Error(t("Invalid resource type"));
      }

      // Handle employee roles
      for (const userId in selectedEmployeeRoles) {
        const role = selectedEmployeeRoles[userId];

        const permissionResponse = await axios.get(`${apiUrl}/permissions`, {
          headers,
        });
        let permissionId = null;
        const existingPermission = permissionResponse.data.find(
          (p) => p.permission === role
        );

        if (existingPermission) {
          permissionId = existingPermission.id;
        }
        console.log("USER ID", userId);
        console.log("USER ID", userInfo);
        if (userInfo && userId) {
          const createPermissionResponse = await axios.post(
            `${apiUrl}/permissionUserGroups`,
            {
              appModule: appModule,
              permission: role,
              grantedBy: {
                id: userInfo,
              },
              user: {
                id: userId,
              },
              libraryContent: {
                id: resourceId,
              },
            },
            { headers }
          );
        }
      }

      // Handle group roles
      for (const groupId in selectedGroupRoles) {
        const role = selectedGroupRoles[groupId];

        const permissionResponse = await axios.get(`${apiUrl}/permissions`, {
          headers,
        });
        let permissionId = null;
        const existingPermission = permissionResponse.data.find(
          (p) => p.name === role
        );

        if (existingPermission) {
          permissionId = existingPermission.id;
        }

        const createPermissionResponse = await axios.post(
          `${apiUrl}/permissionUserGroups`,
          {
            appModule: appModule,
            permission: role,
            grantedBy: {
              id: userInfo,
            },
            group: {
              id: groupId,
            },
            libraryContent: {
              id: resourceId,
            },
          },
          { headers }
        );
        permissionId = createPermissionResponse.data.id;
      }

      alert(t("Roles updated successfully!"));
      setSelectedEmployeeRoles({});
      setSelectedGroupRoles({});
      onClose();
    } catch (error) {
      console.error(
        t("Error saving roles:"),
        error.response?.data || error.message
      );
      alert(t("Failed to save roles. Please try again."));
    }
  };

  const renderPermissions = (userId) => {
    const permissions = employeePermissions[userId];
    console.log("PERMISSION NAMES", permissionsNames);
    if (!permissions || permissions.length === 0) {
      return t("No Permissions assigned");
    }

    return (
      <div className={styles.permissionsContainer}>
        {permissions.map((perm) => (
          <div key={perm.id} className={styles.permissionChip}>
            {permissionsNames[perm.permission?.id] || perm.permission?.id}
            {currentUserRole === "owner" && (
              <button
                className={styles.removeButton}
                onClick={() => handleRemovePermission(userId, perm.id)}
              >
                {permissionsNames[perm?.id] || t("Unknown Permission")}
                &nbsp;&nbsp;&times;
              </button>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderGroupPermissions = (groupId) => {
    const permissions = groupPermissions[groupId];
    if (!permissions || permissions.length === 0) {
      return t("No Permissions assigned");
    }

    return (
      <div className={styles.permissionsContainer}>
        {permissions.map((perm) => (
          <div key={perm.id} className={styles.permissionChip}>
            {permissionsNames[perm.permission?.id] || perm.permission?.id}
            <button
              className={styles.removeButton}
              onClick={() => handleRemoveGroupPermission(groupId, perm.id)}
            >
              {permissionsNames[perm?.id] || t("Unknown Permission")}
              &nbsp;&nbsp;&times;
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderRoleOptions = (userId) => {
    const assignedPermissions =
      employeePermissions[userId]?.map((perm) => permissionsNames[perm?.id]) ||
      [];
    const availableRoles = ["owner", "write", "read"].filter(
      (role) => !assignedPermissions.includes(role)
    );

    return availableRoles.map((role) => (
      <option key={role} value={role}>
        {t(role)}
      </option>
    ));
  };

  const renderRoleOptionsForGroups = (groupId) => {
    const assignedPermissions =
      groupPermissions[groupId]?.map(
        (perm) => permissionsNames[perm.permission?.id]
      ) || [];
    const availableRoles = ["owner", "write", "read"].filter(
      (role) => !assignedPermissions.includes(role)
    );

    return availableRoles.map((role) => (
      <option key={role} value={role}>
        {t(role)}
      </option>
    ));
  };

  // Employee pagination and search
  const filteredEmployees = employees.filter((employee) => {
    const fullName = `${employee.firstName} ${employee.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);
  const indexOfLastEmployee = currentPage * itemsPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;
  const currentEmployees = filteredEmployees.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  );

  // Group pagination and search
  const filteredGroups = groups.filter((group) => {
    const groupName = group.name.toLowerCase();
    return groupName.includes(groupSearchTerm.toLowerCase());
  });

  const groupTotalPages = Math.ceil(filteredGroups.length / itemsPerPage);
  const indexOfLastGroup = groupCurrentPage * itemsPerPage;
  const indexOfFirstGroup = indexOfLastGroup - itemsPerPage;
  const currentGroups = filteredGroups.slice(
    indexOfFirstGroup,
    indexOfLastGroup
  );

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>{t('Assign Permissions for')} {resourceType}</h2>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>

        <div className={styles.tabsNative}>
          <button
            className={activeTab === "Employees" ? styles.activeTab : ""}
            onClick={() => setActiveTab("Employees")}
          >
            {t('Employees')}
          </button>
          <button
            className={activeTab === "Groups" ? styles.activeTab : ""}
            onClick={() => setActiveTab("Groups")}
          >
            {t('Groups')}
          </button>
        </div>

        {activeTab === "Employees" && (
          <>
            <div className={styles.searchBar}>
              <input
                type="text"
                placeholder={t('Search employees...')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {isLoading ? (
              <p>{t('Loading employees...')}</p>
            ) : (
              <div className={styles.employeesList}>
                {currentEmployees.map((employee) => (
                  <div key={employee.id} className={styles.employeeItem}>
                    <div className={styles.employeeDetails}>
                      <span>
                        {employee.firstName} {employee.lastName}
                      </span>
                      <div>{renderPermissions(employee.id)}</div>
                    </div>
                    <div className={styles.assignRoleContainer}>
                      {/* Check if the employee has the owner permission for the specific resource */}
                      {employeePermissions[userId]?.some(
                        (permissionData) =>
                          permissionData.libraryContent?.id === resourceId &&
                          permissionData.permission === "owner"
                      ) ? (
                        <select
                          value={selectedEmployeeRoles[employee.id] || ""}
                          onChange={(e) =>
                            handleEmployeeRoleChange(
                              employee.id,
                              e.target.value
                            )
                          }
                        >
                          <option value="">{t('Select Permission')}</option>
                          {renderRoleOptions(employee.id)}
                        </select>
                      ) : (
                        <span>{t('No permission to assign roles')}</span>
                      )}
                    </div>
                  </div>
                ))}
                <div className={styles.pagination}>
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    {t('Previous')}
                  </button>
                  <span>
                    {t('Page')} {currentPage} {t('of')} {totalPages}
                  </span>
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    {t('Next')}
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        {activeTab === "Groups" && (
          <>
            <div className={styles.searchBar}>
              <input
                type="text"
                placeholder={t('Search groups...')}
                value={groupSearchTerm}
                onChange={(e) => setGroupSearchTerm(e.target.value)}
              />
            </div>
            {isLoading ? (
              <p>{t('Loading groups...')}</p>
            ) : (
              <div className={styles.groupsList}>
                {currentGroups.map((group) => (
                  <div key={group.id} className={styles.groupItem}>
                    <div className={styles.groupDetails}>
                      <span>{group.name}</span>
                      <div>{renderGroupPermissions(group.id)}</div>
                    </div>
                    <div className={styles.assignRoleContainer}>
                      <select
                        value={selectedGroupRoles[group.id] || ""}
                        onChange={(e) =>
                          handleGroupRoleChange(group.id, e.target.value)
                        }
                      >
                        <option value="">{t('Select Role')}</option>
                        {renderRoleOptionsForGroups(group.id)}
                      </select>
                    </div>
                  </div>
                ))}
                <div className={styles.pagination}>
                  <button
                    onClick={() =>
                      setGroupCurrentPage(groupCurrentPage - 1)
                    }
                    disabled={groupCurrentPage === 1}
                  >
                    {t('Previous')}
                  </button>
                  <span>
                    {t('Page')} {groupCurrentPage} {t('of')} {groupTotalPages}
                  </span>
                  <button
                    onClick={() =>
                      setGroupCurrentPage(groupCurrentPage + 1)
                    }
                    disabled={groupCurrentPage === groupTotalPages}
                  >
                    {t('Next')}
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        <div className={styles.saveButtonContainer}>
          <button onClick={handleSaveRoles} className={styles.saveButton}>
            {t('Save Roles')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RolesManager;
