import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MosaikHeaderWithBack from '../MosaikHeaderWithBack';
import MosaikFooter from '../MosaikFooter';
import styles from './MosaikCalendar.module.css';

const MosaikCalendar = () => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [events, setEvents] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentView, setCurrentView] = useState('day'); // 'day', 'week', 'month'
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  // References to the day and week view containers to get their heights dynamically
  const dayViewRef = useRef(null);
  const weekViewRef = useRef(null);

  // Fetch events from API
  useEffect(() => {
    const fetchEvents = async () => {
      const accessToken = localStorage.getItem('accessToken');
      try {
        const response = await fetch(`${apiUrl}/events`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();

        // Map data to the expected format
        const mappedEvents = data.map(({ event, selfHosted }) => ({
          id: event.id,
          title: event.title || t('No Title'),
          start: moment(event.startDate),
          end: moment(event.endDate),
          attendees: event.invitees.attendees.length,
          description: event.description || t('No Description'),
          isSelfHosted: selfHosted,
        }));

        setEvents(mappedEvents);
      } catch (error) {
        console.error(t('Error fetching events:'), error);
      }
    };

    fetchEvents();
  }, [apiUrl, t]);

  const handleEventClick = (id) => {
    navigate(`/mosaik-calendar/event/${id}`);
  };

  const handleEditEvent = (id) => {
    navigate(`/mosaik-calendar/edit-event/${id}`);
  };

  const handlePlusClick = () => {
    navigate('/mosaik-calendar/create-event');
  };

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const handleWeekdayClick = (dayIndex) => {
    setSelectedDate(moment(selectedDate).day(dayIndex));
    setCurrentView('day');
  };

  const renderViewButtons = () => {
    return (
      <div className={styles.viewButtons}>
        <button
          className={`${styles.viewButton} ${currentView === 'day' ? styles.activeView : ''}`}
          onClick={() => setCurrentView('day')}
        >
          {t('Day')}
        </button>
        <button
          className={`${styles.viewButton} ${currentView === 'week' ? styles.activeView : ''}`}
          onClick={() => setCurrentView('week')}
        >
          {t('Week')}
        </button>
        <button
          className={`${styles.viewButton} ${currentView === 'month' ? styles.activeView : ''}`}
          onClick={() => setCurrentView('month')}
        >
          {t('Month')}
        </button>
      </div>
    );
  };

  /**
   * Calculate the top position in pixels based on the event's start time.
   * @param {moment.Moment} eventStart 
   * @param {number} containerHeight 
   * @returns {number} top position in pixels
   */
  const calculateTopPosition = (eventStart, containerHeight) => {
    // Total minutes from the start of the day to the event's start time
    const minutesSinceStartOfDay = eventStart.hours() * 60 + eventStart.minutes();
    
    // Debug log to verify the calculated minutes and container height
    console.log('Event Start:', eventStart.format('YYYY-MM-DD HH:mm:ss'), 
                'Minutes Since Start of Day:', minutesSinceStartOfDay, 
                'Container Height:', (minutesSinceStartOfDay / 1440) * containerHeight);
  
    // Calculate position as a fraction of the day (1440 minutes)
    return (minutesSinceStartOfDay / 1440) * containerHeight;
  };

  /**
   * Calculate the height in pixels based on the event's duration.
   * @param {moment.Moment} eventStart 
   * @param {moment.Moment} eventEnd 
   * @param {number} containerHeight 
   * @returns {number} height in pixels
   */
  const calculateEventHeight = (eventStart, eventEnd, containerHeight) => {
    const durationMinutes = eventEnd.diff(eventStart, 'minutes');
    return (durationMinutes / 1500) * containerHeight;
  };

  const renderCalendarDayView = () => {
    // Filter and sort events for the selected day
    const sortedEvents = events
      .filter((event) => event.start.isSame(selectedDate, 'day'))
      .filter((event) =>
        event.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => a.start - b.start);

    // Reference to the container height
    const containerHeight = dayViewRef.current ? dayViewRef.current.clientHeight : 1380; // Default to 1440px if ref is not set

    // Preprocess events to calculate top and height in pixels
    const processedEvents = sortedEvents.map((event) => {
      const eventStart = event.start;
      const eventEnd = event.end || eventStart.clone().add(1, 'hour'); // Default to 1-hour if no end
      const topPx = calculateTopPosition(eventStart, containerHeight);
      const heightPx = calculateEventHeight(eventStart, eventEnd, containerHeight);

      return {
        ...event,
        topPx,
        heightPx,
      };
    });

    // Generate hours for the day (used for time labels)
    const hoursInDay = Array.from({ length: 24 }, (_, i) =>
      moment(selectedDate).startOf('day').add(i, 'hours')
    );

    return (
      <div>
        {/* Selected Date Header */}
        <h2 className={styles.selectedDate}>{selectedDate.format('dddd, MMMM DD YYYY')}</h2>

        {/* Weekdays Header */}
        <div className={styles.weekdays}>
          {[t("SU"), t("MO"), t("TU"), t("WE"), t("TH"), t("FR"), t("SA")].map(
            (day, index) => (
              <div
                key={index}
                className={`${styles.day} ${
                  index === selectedDate.day() ? styles.activeDay : ""
                }`}
                onClick={() => handleWeekdayClick(index)}
              >
                {day}
              </div>
            )
          )}
        </div>

        {/* Day View Container */}
        <div className={styles.dayViewContainer} ref={dayViewRef}>
          {/* Time Labels */}
          <div className={styles.timeLabels}>
            <div className={styles.allDayLabel}>{t('All Day')}</div>
            {hoursInDay.map((hour, index) => (
              <div key={index} className={styles.timeLabel}>
                {hour.format('hh:00 A')}
              </div>
            ))}
          </div>

          {/* Time Slots */}
          <div className={styles.timeSlots}>
            {/* All Day Slot */}
            <div className={styles.allDaySlot}></div>

            {/* Hourly Slots */}
            {hoursInDay.map((hour, index) => (
              <div key={index} className={styles.timeSlot}></div>
            ))}

            {/* Render Events */}
            {processedEvents.map((event) => (
              <div
                key={event.id}
                className={styles.eventBlock}
                style={{
                  top: `${event.topPx}px`,
                  height: `${event.heightPx}px`,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEventClick(event.id);
                }}
              >
                <div className={styles.eventTitle}>{event.title}</div>
                {event.isSelfHosted && (
                  <div className={styles.actions}>
                    <button
                      className={styles.editButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditEvent(event.id);
                      }}
                      aria-label={t('Edit event')}
                    >
                      {t('Edit')}
                    </button>
                  </div>
                )}
                {/* Optional: Event Description and Attendees */}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderCalendarWeekView = () => {
    // Determine start and end of the week
    const startOfWeek = moment(selectedDate).startOf('week');
    const endOfWeek = moment(selectedDate).endOf('week');

    // Generate array of days for the week
    const weekDays = Array.from({ length: 7 }, (_, i) => moment(startOfWeek).add(i, 'days'));

    // Filter and sort events within the week
    const weekEvents = events
      .filter((event) =>
        event.start.isBetween(startOfWeek, endOfWeek, null, '[]')
      )
      .filter((event) =>
        event.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => a.start - b.start);

    // Reference to the container height
    const containerHeight = weekViewRef.current ? weekViewRef.current.clientHeight : 1380; // Default to 1440px

    // Preprocess events for the week
    const processedWeekEvents = weekEvents.map((event) => {
      const topPx = calculateTopPosition(event.start, containerHeight);
      const heightPx = calculateEventHeight(event.start, event.end, containerHeight);

      return {
        ...event,
        topPx,
        heightPx,
      };
    });

    // Group events by day
    const eventsByDay = weekDays.map(day => {
      return processedWeekEvents.filter(event => event.start.isSame(day, 'day'));
    });

    return (
      <div>
        {/* Selected Week Header */}
        <h2 className={styles.selectedDate}>
          {startOfWeek.format('MMMM DD YYYY')} - {endOfWeek.format('MMMM DD YYYY')}
        </h2>

        {/* Week View Container */}
        <div className={styles.weekViewContainer} ref={weekViewRef}>
          {/* Time Labels Column */}
          <div className={styles.weekTimeLabels}>
            <div className={styles.allDayLabel}>{t('All Day')}</div>
            {Array.from({ length: 24 }, (_, i) =>
              moment(selectedDate).startOf('day').add(i, 'hours')
            ).map((hour, index) => (
              <div key={index} className={styles.timeLabel}>
                {hour.format('hh:00 A')}
              </div>
            ))}
          </div>

          {/* Week Days Columns */}
          <div className={styles.weekDaysContainer}>
            {weekDays.map((day, index) => (
              <div
                key={index}
                className={styles.weekDayColumn}
                onClick={() => {
                  setSelectedDate(moment(day));
                  setCurrentView('day');
                }}
              >
                {/* Day Header */}
                <div className={styles.weekDayHeader}>
                  {day.format('ddd, MMM D')}
                </div>

                {/* Events for the Day */}
                <div className={styles.weekDayEvents}>
                  {eventsByDay[index].length > 0 ? (
                    eventsByDay[index].map(event => (
                      <div
                        key={event.id}
                        className={styles.weekEventBlock}
                        style={{
                          top: `${event.topPx}px`,
                          height: `${event.heightPx}px`,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEventClick(event.id);
                        }}
                      >
                        <div className={styles.weekEventTitle}>{event.title}</div>
                        {event.isSelfHosted && (
                          <button
                            className={styles.editButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditEvent(event.id);
                            }}
                            aria-label={t('Edit event')}
                          >
                            {t('Edit')}
                          </button>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className={styles.noEventsText}>{t('No events')}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderCalendarMonthView = () => {
    const startOfMonth = moment(selectedDate).startOf('month').startOf('week');
    const endOfMonth = moment(selectedDate).endOf('month').endOf('week');

    const day = startOfMonth.clone().subtract(1, 'day');
    const calendar = [];

    while (day.isBefore(endOfMonth, 'day')) {
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, 'day').clone())
      );
    }

    // Filter events within the month
    const monthEvents = events
      .filter((event) =>
        event.start.isBetween(startOfMonth, endOfMonth, null, '[]')
      )
      .filter((event) =>
        event.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => a.start - b.start);

    return (
      <div>
        {/* Selected Month Header */}
        <h2 className={styles.selectedDate}>{selectedDate.format('MMMM YYYY')}</h2>

        {/* Weekday Headers */}
        <div className={styles.monthWeekdays}>
          {[t("SU"), t("MO"), t("TU"), t("WE"), t("TH"), t("FR"), t("SA")].map((day, index) => (
            <div key={index} className={styles.monthWeekday}>
              {day}
            </div>
          ))}
        </div>

        {/* Calendar Grid */}
        <div className={styles.monthCalendar}>
          {calendar.map((week, weekIndex) => (
            <div key={weekIndex} className={styles.monthWeek}>
              {week.map((dayItem, dayIndex) => {
                const dayEvents = monthEvents.filter(event => event.start.isSame(dayItem, 'day'));
                const isCurrentMonth = dayItem.isSame(selectedDate, 'month');

                return (
                  <div
                    key={dayIndex}
                    className={`${styles.monthDay} ${isCurrentMonth ? '' : styles.notCurrentMonth}`}
                    onClick={() => {
                      setSelectedDate(moment(dayItem));
                      setCurrentView('day');
                    }}
                  >
                    <div className={styles.monthDayHeader}>
                      {dayItem.date()}
                    </div>
                    <div className={styles.monthDayEvents}>
                      {dayEvents.map(event => (
                        <div
                          key={event.id}
                          className={styles.monthEventBlock}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEventClick(event.id);
                          }}
                        >
                          <div className={styles.monthEventTitle}>{event.title}</div>
                          {event.isSelfHosted && (
                            <button
                              className={styles.editButton}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditEvent(event.id);
                              }}
                              aria-label={t('Edit event')}
                            >
                              {t('Edit')}
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderCalendar = () => {
    switch (currentView) {
      case 'day':
        return renderCalendarDayView();
      case 'week':
        return renderCalendarWeekView();
      case 'month':
        return renderCalendarMonthView();
      default:
        return renderCalendarDayView();
    }
  };

  return (
    <div>
      <MosaikHeaderWithBack pageTitle={t('Calendar')} />
      <div className={styles.calendarContainer}>
        {/* Header Section */}
        <div className={styles.header}>
          <h1>{t('Upcoming Events')}</h1>
          <div className={styles.eventcontrols}>
            <button onClick={toggleSearchBar}>
              <img src={require('../../assets/images/search-button.png')} alt={t('Search')} />
            </button>
            <button onClick={handlePlusClick}>
              <img src={require('../../assets/images/plus-button.png')} alt={t('Add Event')} />
            </button>
          </div>
        </div>

        {/* Search Bar */}
        {showSearchBar && (
          <div className={styles.searchField}>
            <input 
              type="text" 
              placeholder={t('Enter keywords to search...')} 
              className={styles.searchInput} 
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        )}

        {/* View Selection Buttons */}
        {renderViewButtons()}

        {/* Date Picker */}
        <div className={styles.datePicker}>
          <input
            type="date"
            value={selectedDate.format('YYYY-MM-DD')}
            onChange={(e) => setSelectedDate(moment(e.target.value))}
            className={styles.dateInput}
          />
        </div>

        {/* Render Selected Calendar View */}
        {renderCalendar()}
      </div>
      <MosaikFooter />
    </div>
  );
};

export default MosaikCalendar;
